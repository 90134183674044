import React from 'react'
import Select from "react-select";
import { useParams } from "react-router-dom";

const PosSelect = (props) => {
  const { order_id } = useParams();
  return (
    <Select
    //isDisabled={order_id}
    //isDisabled ? "#0a8d6ea3" :
      maxMenuHeight='200px'
      styles={{
        control: (provided,{ isDisabled}) => ({
          ...provided,
          backgroundColor: '#0a8d6e',
          color: '#000000',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '100%',
          borderRadius: '.5rem',
          height: '2.5rem',
          justifyContent: 'center',
          cursor: 'pointer',
        }),
        menu: (provided) => ({
          ...provided,
          maxWidth: '100%',
          zIndex: 99,
          //left: 'calc((100% - 180px) / 2);',
        }),
        valueContainer: (provided) => ({
          ...provided,
          flexGrow: 0,
          flexBasis: '65%',
          paddingRight: 0,
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: 'white',
          padding: 0,
          fontSize: '0.8rem',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: 'white',
          fontSize: '0.8rem',
          fontWeight: '600',
          textTransform: 'uppercase',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'white',
          fontSize: '0.8rem',
          fontWeight: '600',
          textTransform: 'uppercase',
        }),
        input: (provided) => ({
          ...provided,
          color: 'white',
          fontSize: '0.8rem',
          fontWeight: '600',
          textTransform: 'uppercase',
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'white' : state.isFocused ? '#0a8d6e' : provided.color,
          backgroundColor: state.isSelected ? '#0a8d6e' : provided.backgroundColor,
          fontSize: '0.8rem',
          fontWeight: '600',
          textTransform: 'uppercase',
          whiteSpace:"nowrap",
        })
      }}
      components={{
        DropdownIndicator: () => (<i className="fa-sharp fa fa-caret-down text-white lg-text" />)
      }}
      {...props}
    />
  )
}

export default PosSelect