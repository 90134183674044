import React, { useState, useEffect,useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Select, { createFilter } from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../../../../BaseUrl";
import axios from "axios";
import {
  _t,
  getCookie,
  getSystemSettings,
  customStyle
} from "../../../../../../functions/Functions";
import {UserContext} from "../../../../../../contexts/User";
import { RestaurantContext } from "../../../../../../contexts/Restaurant";
import { SettingsContext } from "../../../../../../contexts/Settings";
import MultiAddress from "./MultiAddress";

const CallCenterModal = ({
  modal9Show,
  setModal9Show,
  setModalOrderType,
  setOrderDetails,
  orderDetails,
  selectedParty,
  checkDelivery,
  newSettings,
  handleSetBranch,
  zoneArea,
  setZoneArea,
  area,
  setArea,
  setDeliveryCharges,
  selectedBranch,
  workPeriodScreen
}) => {    
    const {
    //waiter
    authUserInfo
  } = useContext(UserContext);
  const {
    branchForSearch,
    areaList,
    branchForListSearch,
  } = useContext(RestaurantContext);
  const {
    generalSettings,
  } = useContext(SettingsContext);
  // muti address
  const [addressList, setAddressList] = useState([]);
  const [showCustomer,setShowCustomer] = useState(false);
  const [loading,setLoading] = useState(false);
  const [customer, setCustomer] = useState({
    id:null,
    name:"",
    email:"",
    phn_no:"",
    address:"",
    ref_no:"",
    alt_phn_no: "",
    ntn_no: "",
    nearest_landmark: "",
  });
  const { order_id } = useParams();
  //const [filterCustomer,setFilterCustomer] = useState([])

  useEffect(() => {
    if (
        (selectedParty &&
        selectedParty?.show_ref_no === 1 &&
        selectedParty.name.match(/food(.*)panda/i)) || orderDetails.newCustomerInfo.name === "Food Panda"
    ) {
      setTimeout(() => {  
        setOrderDetails((curr)=>({
          ...curr,
          newCustomerInfo:{
            ...curr.newCustomerInfo,
            name: orderDetails.newCustomerInfo.name === "Food Panda" ? "" : "Food Panda",
            phn_no: orderDetails.newCustomerInfo.name === "Food Panda" ? "" :  "00000000000",
          }
        }));
      },10);
    }
  
  }, [selectedParty,orderDetails?.branch]);

  useEffect(()=>{
    setCustomer({
      id: orderDetails.newCustomerInfo?.id,
      name: orderDetails.newCustomerInfo?.name,
      email: orderDetails.newCustomerInfo?.email,
      phn_no: orderDetails.newCustomerInfo?.phn_no,
      address: orderDetails.newCustomerInfo?.address,
      ref_no: orderDetails.newCustomerInfo?.ref_no,
      alt_phn_no: orderDetails.newCustomerInfo?.alt_phn_no,
      ntn_no: orderDetails.newCustomerInfo.ntn_no,
      nearest_landmark: orderDetails.newCustomerInfo.nearest_landmark,
    })
    if(!orderDetails.newCustomerInfo?.name && !orderDetails.newCustomerInfo.phn_no) {
      setAddressList([]);
    }
  },[orderDetails.newCustomerInfo])
  
  // submit customer info
  const handleCustomer = async(e) => {
    e.preventDefault();
    const isCallCenterUser = authUserInfo.details.user_type === "call_center";
    if(isCallCenterUser){
      if(checkDelivery && (zoneArea === "" || zoneArea === null)){
        toast.error("Please select area");
        return false
      }
      if(newSettings.workPeriod === null || workPeriodScreen == 0){
        toast.error("Please select branch with open workperiod!");
        return false
      }
      if(customer.name == "" || customer.phn_no == ""){
        toast.error(`Please fill customer ${customer.name == "" ? "name" : ""} ${ customer.name == "" && customer.phn_no == "" ? "&" : ""} ${customer.phn_no == "" ? "phone number" : ""}`);
        return false
      }
    }
    setLoading(true);
    const customerValidation =  order_id || (customer.ref_no?.length !== undefined &&
    customer.ref_no?.length !== 0 && customer.ref_no?.length  >= selectedParty.ref_min &&
    customer.ref_no?.length <= selectedParty.ref_max);
    const checkCustomer = customer.name !== "" && customer.phn_no !== "";

    const customerInfo = {
      id: customer.id || null,
      name: customer.name || "",
      email: customer.email || "",
      phn_no: customer.phn_no || "",
      address: customer.address || "",
      alt_phn_no: customer?.alt_phn_no || "",
      ref_no: customer.ref_no || "",
      ntn_no:  customer.ntn_no || "",
      nearest_landmark:  customer.nearest_landmark || "",
      is_confirm: true,
    };
  
    const setCustomerInfo = {
      ...orderDetails,
      newCustomerInfo: customerInfo,
    }
      
    const updateCustomerInfo = () => {
      setOrderDetails(setCustomerInfo);
      setModal9Show(false);
    };

    const customerNotConfirm = () => {
      setOrderDetails((curr)=>({
        ...curr,
        newCustomerInfo:{
          ...setCustomerInfo.newCustomerInfo,
          is_confirm: false,
        }
      }));
    }

    const updateCustomerFoodPanda = (dupRef = "") =>{
      if(dupRef === "exist"){
        customerNotConfirm();
        toast.error("Ref no is already exist!");
      }else{
        setOrderDetails(setCustomerInfo);
        setModal9Show(false);
      }
    }
                                
    if (
        selectedParty && parseInt(selectedParty.show_ref_no) === 1 &&
        (selectedParty.name.match(/food(.*)panda/i))
    ) {

      if (
        (customer.name !== "" && customerValidation)
      ) {
        //duplicate ref no api
        const refUrl = BASE_URL + "/settings/check-duplicate-ref-no/" + customer.ref_no;
        await axios
        .get(refUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          updateCustomerFoodPanda(res.data)
        })
        .catch((err)=> updateCustomerFoodPanda());
       
      } else {
        customerNotConfirm();
        toast.error(`Please fill ${customer.name == "" ? customer.name : ""} ${!customerValidation ? "ref no between " + selectedParty.ref_min + "-" +selectedParty.ref_max + " digits." : ""}`);
      }
    }else if(selectedParty && parseInt(selectedParty.show_ref_no) === 1 && checkDelivery && !customerValidation && !checkCustomer){
      customerNotConfirm();
      toast.error(`Please fill customer ${customer.name == "" ? "name" : ""} ${ customer.name == "" && customer.phn_no == "" ? "&" : ""} ${customer.phn_no == "" ? "phone number" : ""} ${!customerValidation ? " ref no between " + selectedParty.ref_min + "-" + selectedParty.ref_max + " digits." : ""}`);
    }else if(selectedParty && parseInt(selectedParty.show_ref_no) === 1 && !customerValidation){
      customerNotConfirm();
      toast.error(`Please fill ref no between ${selectedParty.ref_min}-${selectedParty.ref_max} digits.`);
    }else if(checkDelivery && !checkCustomer){
      toast.error(`Please fill customer ${customer.name == "" ? "name" : ""} ${ customer.name == "" && customer.phn_no == "" ? "&" : ""} ${customer.phn_no == "" ? "phone number" : ""}`);
    }else{
      updateCustomerInfo();
    }
    setLoading(false);
  };
  
  //search customer
  const customerSearch = (customers) => {
    if(customers?.address_book?.length > 1 ){
      setShowCustomer(true);
      setAddressList(customers.address_book);
    }else{
      setShowCustomer(false);
      setAddressList([]);
    }
    setCustomer({
      ...customer,
      id: customers.id,
      name: customers.name,
      email: customers.email ? customers.email : "",
      phn_no: customers.phn_no ? customers.phn_no : "",
      alt_phn_no: customers?.alt_phn_no ? customers.alt_phn_no : "",
      ntn_no : customers.ntn_no ? customers.ntn_no : "",
      address: customers?.address_book?.length > 1 ? customers.address_book[0].address : (customers.address ? customers.address : ""),
    });
  };

   //handle on change input
   const handleChangeInput = (e) =>{
    const {name, value} = e.target;
    setCustomer({
      ...customer,
      [name]: value,
    })
  }

  //Debounce logic and searched customer
  const [searchedCustomer, setSearchedCustomer] = useState([]);
  const [noOptionMessage, setNoOptionMessage] = useState('Enter Phone No To Search');
  const [inputValue, setInputValue] = useState('');
  const [loader,setLoader]=useState(false);
  const handleChange = (value) => {
    const numericInput = value.replace(/\D/g, '');    
    setInputValue(numericInput)
    setSearchedCustomer([])    
  };

  const handleDebounce = async () => {    
    const url = BASE_URL + "/settings/get-all-customer";          
    try {
      const res = await axios
        .post(url, {customer_phn_no:inputValue}, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        if(res.data){              
          setSearchedCustomer(res.data[0]);
          setLoader(false);         
          if(res.data[0].length === 0){
            setCustomer({
              ...customer,
              id: null,
              name:"",
              email:"",
              address: "",
              ref_no: "",
              alt_phn_no: "",
              phn_no: inputValue
            });
              setNoOptionMessage('No Customer Exist');
          }                    
        }          
    } catch (error) {
      setLoader(false);
      toast.error(
        "Please try again",
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }                    
      );
    }
  }

  useEffect(() => {    
    if(inputValue !== '' && inputValue.length > 0){
      setLoader(true)
      const timer = setTimeout(() => {
        console.log("Debounced input value:", inputValue);
        handleDebounce();        
      }, 2000); 
      return () => clearTimeout(timer);
    }else{
      setSearchedCustomer([]);
      setLoader(false);
      setNoOptionMessage('Enter Phone No To Search')
    }
  }, [inputValue]);
    
  // select area
  const handleSetArea = async(area)=>{
    setArea(area)
    const areaUrl = BASE_URL + "/settings/get-area-zone-details/" + area.area_id;
    await axios
    .get(areaUrl, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
      const data = res.data.details
      setZoneArea(data);
      handleSetBranch(branchForListSearch[data.branch_id]);
      if(data.free_delivery === 1){
        setDeliveryCharges(0);
      }else{
        setDeliveryCharges(data.fee ? parseFloat(data.fee) : 0);
      }
    
    })
    .catch((err)=>console.log(err));
  }
   
  return (
    <>
      <Modal
        show={modal9Show}
        onHide={() => {
          const isCallCenterUser = authUserInfo.details.user_type === "call_center";
          if(isCallCenterUser){
            if(checkDelivery && (zoneArea === "" || zoneArea === null)){
              toast.error("Please select area");
              return false
            }
            if(newSettings.workPeriod === null || workPeriodScreen == 0){
              toast.error("Please select branch with open workperiod!");
              return false
            }
          }
          toast.error("Please press confirm button");
          setModal9Show(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{zIndex: "1048"}}
        centered
      
      >
        <Modal.Header closeButton className="customer-header">
          <Modal.Title className="customer-title" id="contained-modal-title-vcenter">
            {/* Modal Header */}
            <div className="container-fluid mt-1">
              <h4 className="fw-bold mb-0 input-field" id="exampleModalLabel">
                Call Center: Customer
              </h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Modal Body */}
          <div className="modal-body pt-0">
            <div className="container-fluid" style={{ paddingLeft: "0px" }}>
                  
              <div className="row">
                {
                  checkDelivery && (
                    <div className="col-6">
                      <label
                        for="recipient-name"
                        className="col-form-label fw-bold d-flex justify-content-start"
                      >
                        Select Area
                      </label>
                      <Select
                        options={areaList && areaList}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.area_id}
                        value={area ? area : ""}
                        classNamePrefix="select"
                        className="xsm-text"
                        onChange={handleSetArea}
                        maxMenuHeight="200px"
                        placeholder="Area"
                        styles={customStyle}
                      />
                      </div>
                    )
                  }
                  <div className="col-6">
                    <label
                      for="recipient-name"
                      className="col-form-label fw-bold d-flex justify-content-start"
                    >
                      Select Branch
                    </label>
                    <Select
                      isDisabled={checkDelivery}
                      options={branchForSearch}
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      classNamePrefix="select"
                      className="xsm-text"
                      onChange={handleSetBranch}
                      maxMenuHeight="200px"
                      placeholder="Branch"
                      styles={customStyle}
                      value={selectedBranch ? selectedBranch : ""}
                    />
                  </div>
              </div>
              
              <div className="row">
                <div className="col-6 mr-2">
                  <label
                    className="col-form-label fw-bold d-flex justify-content-start"
                  >
                    By Name / By Phone
                  </label>
                  <Select
                    isDisabled={selectedParty && selectedParty.name?.match(/food(.*)panda/i)}
                    options={searchedCustomer}
                    components={makeAnimated()}
                    getOptionLabel={(option) => option.name_phone}
                    getOptionValue={(option) => option.id}
                    classNamePrefix="select"
                    className="xsm-text"
                    onChange={customerSearch}
                    styles={customStyle}
                    onInputChange={(e)=>handleChange(e)}                  
                    maxMenuHeight="200px"
                    placeholder="Customer Search"
                    noOptionsMessage={() => {
                      return loader ? 
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div> : noOptionMessage
                    }}
                    isClearable 
                  />
                </div>
                {
                  addressList && addressList.length > 0 &&
                  <div className="col-3 d-flex align-items-end mb-1">
                    <button className="btn btn-sm btn-primary" onClick={()=>setShowCustomer(true)}>Multi Address</button>
                  </div>
                }
              </div>
              
              <h4 className="d-flex justify-content-start fw-bold mb-1 mt-3">
                Add Customer
              </h4>
              <form 
              onSubmit={handleCustomer}
              >
                <div className="row">
                  <div className="col-4">
                    <label
                      for="recipient-name"
                      className="col-form-label fw-bold d-flex justify-content-start p-0"
                    >
                      Name
                    </label>
                    <input
                      disabled={selectedParty && selectedParty?.show_ref_no === 1 && selectedParty.name.match(/food(.*)panda/i)}
                      type="text"
                      className="form-control mb-2 customer-input"
                      id="recipient-name"
                      name="name"
                      placeholder="Name"
                      onChange={handleChangeInput}
                      value={customer.name}
                    />
                  </div>
                  <div className="col-4">
                    <label
                      for="phone"
                      className="col-form-label fw-bold d-flex justify-content-start p-0"
                    >
                      Phone
                    </label>
                    <input
                      //type="number"
                      type="text" 
                      maxlength="15" 
                      pattern="^\+?\d{0,9}-?\d{0,9}$"
                      className="form-control customer-input"
                      onKeyDown={(evt) => ["e", "E","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                      onWheel={(e) => e.target.blur()}  
                      id="phone"
                      placeholder="0304***"
                      onChange={(e) => {
                        let phoneNumber = e.target.value.replace(/[^0-9+\-]/g, '');
                        // Replace +92 with 0
                        phoneNumber = phoneNumber.replace(/^\+?92/, '0');
                        setCustomer({
                          ...customer,
                          phn_no: phoneNumber
                        });
                      } }
                      value={customer.phn_no}
                    />
                  </div>
                  <div className="col-4">
                    <label
                      for="alt-no"
                      className="col-form-label fw-bold d-flex justify-content-start p-0"
                    >
                      Alt Phone
                    </label>
                    <input
                      //type="number"
                      type="text" 
                      maxlength="12" 
                      pattern="^\+?\d{0,9}-?\d{0,9}$"
                      className="form-control customer-input"
                      onKeyDown={(evt) => ["e", "E","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                      onWheel={(e) => e.target.blur()}  
                      id="alt-no"
                      name="alt_phn_no"
                      placeholder="0304***"
                      onChange={handleChangeInput}
                      value={customer.alt_phn_no}
                    />
                  </div>
                </div>
                <div className="row">
                  
                  <div className="col-6">
                    <label
                      for="email"
                      className="col-form-label fw-bold d-flex justify-content-start p-0"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control customer-input"
                      id="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleChangeInput}
                      value={customer.email || ""}
                    />
                  </div>
                  <div className="col-6">
                    <label
                      for="ref"
                      className="col-form-label fw-bold d-flex justify-content-start p-0"
                    >
                      Ref No
                    </label>
                    <input
                      // disabled={order_id}
                      type="text"
                      className="form-control customer-input"
                      id="ref"
                      name="ref_no"
                      placeholder="Ref No"
                      onChange={handleChangeInput}
                      value={customer.ref_no}
                      minLength={
                        selectedParty &&
                        selectedParty.show_ref_no === 1 &&
                        selectedParty.ref_min > 0 && selectedParty.ref_min
                      }
                      maxLength={
                        selectedParty &&
                        selectedParty.show_ref_no === 1 &&
                        selectedParty.ref_max > 0 ? selectedParty.ref_max : 20
                      }
                    />
                  </div>

                  {/* address */}
                  <div className="row mt-2 pr-0">
                    <label
                      for="address"
                      className="col-form-label fw-bold d-flex justify-content-start pt-0 pb-0"
                    >
                      Delivery Address
                    </label>
                  
                    <div className="col-12 pr-0">
                      <input
                        required={checkDelivery && (selectedParty && !selectedParty.name.match(/food(.*)panda/i))}
                        type="text"
                        className="form-control customer-input"
                        id="address"
                        name="address"
                        placeholder="Address"
                        onChange={handleChangeInput}
                        value={customer.address}
                      />
                    </div>
                  </div>

                  {/* nearest landmark */}
                  <div className="row mt-2 pr-0">
                    <div className="col-6">
                      <label
                        for="nearest_landmark"
                        className="col-form-label fw-bold d-flex justify-content-start pt-0 pb-0"
                      >
                        Nearest Landmark
                      </label>
                  
                      <div className="col-12 pr-0">
                        <input
                          type="text"
                          className="form-control customer-input"
                          id="nearest_landmark"
                          name="nearest_landmark"
                          placeholder="nearest landmark"
                          onChange={handleChangeInput}
                          value={customer.nearest_landmark}
                        />
                      </div>
                    </div>

                      <div className="col-6">
                        <label
                          for="ntn_no"
                          className="col-form-label fw-bold d-flex justify-content-start pt-0 pb-0"
                        >
                          NTN No
                        </label>
                    
                        <div className="col-12 pr-0">
                          <input
                            type="text"
                            className="form-control customer-input"
                            id="ntn_no"
                            name="ntn_no"
                            placeholder="ntn no"
                            onChange={handleChangeInput}
                            value={customer.ntn_no}
                          />
                        </div>
                      </div>
                  </div> 
                </div>
                <Modal.Footer
                style={{
                  borderTop: "none",
                  justifyContent: "space-between",
                  display: "block",
                  marginBottom:"-15px",
                  paddingRight:"0"
                }}
                >
                  {/* Modal Footer */}
                  <div className="modal-footer" style={{ borderTop: "none",paddingBottom:"0",paddingRight:"0" }}>
                    <div className="container-fluid " style={{ padding: "0px" }}>
                      <div className="row d-flex justify-content-end">
                      {
                        loading && (
                          <div class="spinner-border text-primary mt-1" role="status">
                          <span class="sr-only">Loading...</span>
                          </div>
                        )
                      }
                      <div className="col-sm-3 mb-1">
                        <button
                          type="button"
                          className="btn w-100 fw-bold rounded-25"
                          style={{
                            backgroundColor: "#939393",
                            color: "#fff",
                            borderRadius: "0.5rem",
                          }}
                          onClick={() => {
                            setModalOrderType(true);
                            setModal9Show(false)
                          }}
                        >
                          Back
                        </button>
                      </div>
                      
                      <div className="col-sm-3 mb-1">
                        <button
                          type="submit"
                          className="btn w-100 fw-bold rounded-25"
                          style={{
                            backgroundColor: "#0a8d6e",
                            color: "#fff",
                            borderRadius: "0.5rem",
                          }}
                        
                        >
                          CONFIRM
                        </button>
                      </div>
                        {
                          /*
                        <div className="col-md-3 mb-1">
                          <button
                            type="button"
                            className="btn w-100 fw-bold rounded-25"
                            style={{
                              backgroundColor: "#939393",
                              color: "#fff",
                              borderRadius: "0.5rem",
                            }}
                            onClick={() => setModal9Show(false)}
                          >
                            CANCEL
                          </button>
                        </div>
                          */}
                      </div>
                    </div>
                  </div>
                </Modal.Footer>
              </form>
            </div>
          </div>
        </Modal.Body>
      
      </Modal>
      {/*multi address Modal*/}
      <MultiAddress
        showCustomer={showCustomer}
        setShowCustomer={setShowCustomer}
        addressList={addressList}
        handleChangeInput={handleChangeInput}
      />
    </>
  )
}

export default CallCenterModal
