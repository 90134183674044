import React, { useEffect, useState, useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const HourlyBar = ({ data, heading }) => {
    const [formatData, setFormatData] = useState([]);
    const [time, setTime] = useState([]);
   const componentRef = useRef();

    useEffect(() => {
      data && setFormatData(Object.values(data));
      data && setTime(Object.keys(data))
    }, [data]);

    const getStyleForTable = (value) =>{

     if(heading === "Orders By Hours"){
      if(!value || value == 0){
       return "tablecolor5"
      }else if(value > 0 && value < 7){
        return "tablecolor1"
      }else if(value >= 7 && value < 13){
        return "tablecolor2"
      }else if(value >= 13 && value < 20){
        return "tablecolor3"
      }else{
        return "tablecolor4"
      }
     }else if(heading === "Sales By Hours"){
      if(!value || value == 0){
        return "tablecolor5"
      }else if(value > 0 && value < 5000){
        return "tablecolor1"
      }else if(value >= 5000 && value < 10000){
        return "tablecolor2"
      }else if(value >= 10000 && value < 20000){
        return "tablecolor3"
      }else {
        return "tablecolor4"
      }
     }
    }

    const getHourData = (hour) =>{
      if(heading === "Sales By Hours"){
       return hour ? hour.toFixed(2) : "0"
      }else{
       return hour ? hour : "0"
      }
    }

    const getHourlyReport= (e)=>{
      componentRef.current.handleDownload();
    }

    let mon = 0; 
    let tue = 0;
    let wed = 0;
    let thu = 0;
    let fri = 0;
    let sat = 0;
    let sun = 0;
        
  return (
    <div className="col col-6 row mx-0 mt-3 px-2">
      <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table={heading}
      filename={heading}
      sheet="sheet 1"
      buttonText="Sales Detail"
      ref={componentRef}
      />
    <div className="row px-0 mx-0 justify-content-between col col-12 ">
    <div className="dashboard__card col col-12 w-100 flex-basis-100">
     <div className="d-flex align-items-center justify-content-between">
      {heading ? <h5 className="mb-1 mt-0">{heading}</h5> : ""}
      <button
      className="btn btn-primary text-uppercase sm-text px-2 py-1 rounded"
      onClick={(e) => getHourlyReport(e)}
      >
      download report
      </button>
     </div>
     {
      heading === "Sales By Hours" ? (
        <div className="d-flex align-items-center mt-1">
          <div className="d-flex align-items-center dashboard_table_head">
           0 <div className="mx-2 tablecolor5 tableColorDef"></div>
          </div>
          <div className="d-flex align-items-center dashboard_table_head">
           1-4999 <div className="mx-2 tablecolor1 tableColorDef"></div>
          </div>
          <div className="d-flex align-items-center dashboard_table_head">
           5000-9999 <div className="mx-2 tablecolor2 tableColorDef"></div>
          </div>
          <div className="d-flex align-items-center dashboard_table_head">
           10000-19999 <div className="mx-2 tablecolor3 tableColorDef"></div>
          </div>
          <div className="d-flex align-items-center dashboard_table_head">
            20000 & above<div className="mx-2 tablecolor4 tableColorDef"></div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center mt-1">
          <div className="d-flex align-items-center dashboard_table_head">
           0 <div className="mx-2 tablecolor5 tableColorDef"></div>
          </div>
          <div className="d-flex align-items-center dashboard_table_head">
           1-6 <div className="mx-2 tablecolor1 tableColorDef"></div>
          </div>
          <div className="d-flex align-items-center dashboard_table_head">
           7-12 <div className="mx-2 tablecolor2 tableColorDef"></div>
          </div>
          <div className="d-flex align-items-center dashboard_table_head">
           13-20 <div className="mx-2 tablecolor3 tableColorDef"></div>
          </div>
          <div className="d-flex align-items-center dashboard_table_head">
           20 & above <div className="mx-2 tablecolor4 tableColorDef"></div>
          </div>
        </div>
      )
     }
     
    <div className="d-flex flex-col my-1">
      <table id={heading} className="table table-striped table-sm text-center mt-3">
        <thead className="bg-info text-white text-uppercase">
         <tr style={{background:"#0a8d6e"}}>
          <th scope="col" className="dashboard_table_head">
          </th>
          <th scope="col" className="dashboard_table_head">
            Mon
          </th>
          <th scope="col" className="dashboard_table_head">
            Tue
          </th>
          <th scope="col" className="dashboard_table_head">
            Wed
          </th>
          <th scope="col" className="dashboard_table_head">
           Thu
          </th>
          <th scope="col" className="dashboard_table_head">
            Fri
          </th>
          <th scope="col" className="dashboard_table_head">
            Sat
          </th>
          <th scope="col" className="dashboard_table_head">
            Sun
          </th>
         </tr>
        </thead>
        <tbody>
          {
            data && formatData?.map((hourData,index)=>{
              mon += hourData[0];
              tue += hourData[1];
              wed += hourData[2];
              thu += hourData[3];
              fri += hourData[4];
              sat += hourData[5];
              sun += hourData[6]
                return(
                  <tr>
                    <td className={`text-capitalized dashboard_table_body`}>
                     {time[index]}
                    </td>
                    <td className={`text-capitalized dashboard_table_body ${getStyleForTable(hourData[0])}`}>
                     {getHourData(hourData[0])}
                    </td>
                    <td className={`text-capitalized dashboard_table_body ${getStyleForTable(hourData[1])}`}>
                     {getHourData(hourData[1])}
                    </td>
                    <td className={`text-capitalized dashboard_table_body ${getStyleForTable(hourData[2])}`}>
                     {getHourData(hourData[2])}
                    </td>
                    <td className={`text-capitalized dashboard_table_body ${getStyleForTable(hourData[3])}`}>
                     {getHourData(hourData[3])}
                    </td>
                    <td className={`text-capitalized dashboard_table_body ${getStyleForTable(hourData[4])}`}>
                     {getHourData(hourData[4])}
                    </td>
                    <td className={`text-capitalized dashboard_table_body ${getStyleForTable(hourData[5])}`}>
                     {getHourData(hourData[5])}
                    </td>
                    <td className={`text-capitalized dashboard_table_body ${getStyleForTable(hourData[6])}`}>
                     {getHourData(hourData[6])}
                    </td>
                   </tr>
                )
            })
          }
           <tr>
             <td className="dashboard_table_head">Total</td>
             <td className="dashboard_table_head">{getHourData(mon)}</td>
             <td className="dashboard_table_head">{getHourData(tue)}</td>
             <td className="dashboard_table_head">{getHourData(wed)}</td>
             <td className="dashboard_table_head">{getHourData(thu)}</td>
             <td className="dashboard_table_head">{getHourData(fri)}</td>
             <td className="dashboard_table_head">{getHourData(sat)}</td>
             <td className="dashboard_table_head">{getHourData(sun)}</td>
           </tr>
         </tbody>
       </table>  
      </div> 
     </div>   
    </div>
  </div>
  )
}

export default HourlyBar
