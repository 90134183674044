import React from 'react'
import {
    _t,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next"; 

const ItemCancelModal = ({deleteReasonModalShowing, handleCancelEmpty, itemToDelete, passcodeApplied, handleCancelOrderReasonSubmit, handleItemChange= null}) => {
    const { t } = useTranslation();

  return (
    <>
        <div
          className={`modal fade ${deleteReasonModalShowing ? "showing" : ""}`}
          id="deleteReasonModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header align-items-center">
                <div>
                 <div className="lg-text">
                  Cancel Order
                 </div>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => handleCancelEmpty()}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleCancelOrderReasonSubmit(e, itemToDelete);
                  }}
                  autoComplete="off"
                >
                  {passcodeApplied ? (
                    <div className="form-group mb-4">
                      <label
                        htmlFor="passcode"
                        className="font-weight-bold mr-4 lg-text"
                      >
                        Enter passcode
                      </label>
                      <input
                        type="text"
                        className="form-control secure-password-field w-50 d-inline-block"
                        name="passcode"
                        id="passcode"
                        placeholder="Password"
                        required
                      />
                    </div>
                  ) : null}
                  <div data-simplebar style={{ maxHeight: "50vh" }}>
                    <table className="table mb-4 table-bordered table-striped">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            S/L
                          </th>
                          <th scope="col">Food Item</th>
                          <th scope="col" className="text-center">
                            Quantity
                          </th>
                          <th scope="col" className="col-4 text-center">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemToDelete?.orderedItems &&
                        itemToDelete?.orderedItems?.length
                          ? itemToDelete?.orderedItems.map((item, index) => (
                              <tr key={`item-${item.id}`}>
                                <th scope="row" className="text-center">
                                  {index + 1}
                                </th>
                                <td>
                                  <p className="mb-0">{item.food_item}</p>
                                  {item.variation ? (
                                    <p className="mb-0">
                                      {_t(t("Variation"))}:{" "}
                                      <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-normal">
                                        {item.variation}
                                      </span>
                                    </p>
                                  ) : null}
                                  {item.properties ? (
                                    JSON.parse(item.properties).length ? (
                                      <p className="mb-0">
                                        {_t(t("Properties"))}:{" "}
                                        {JSON.parse(item.properties).map(
                                          (property) => (
                                            <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-normal">
                                              {property.property}{" "}
                                              {property.quantity}x
                                            </span>
                                          )
                                        )}
                                      </p>
                                    ) : null
                                  ) : null}
                                </td>
                                <td className="text-center">{item.quantity}</td>
                                <td className="t-width-max col-4 text-uppercase">
                                  <div
                                    className="form-check form-check-inline"
                                    role="button"
                                  >
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name={`item-${item.id}-made`}
                                      id={`item-${item.id}-made-1`}
                                      value="made"
                                      required
                                      defaultChecked
                                      onChange={(e)=> handleItemChange && handleItemChange(e,itemToDelete)}
                                    />
                                    <label
                                      htmlFor={`item-${item.id}-made-1`}
                                      className="form-check-label"
                                    >
                                      Made
                                    </label>
                                  </div>
                                  <div
                                    className="form-check form-check-inline ml-4"
                                    role="button"
                                  >
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      name={`item-${item.id}-made`}
                                      id={`item-${item.id}-made-0`}
                                      value="unmade"
                                      required
                                      onChange={(e)=> handleItemChange && handleItemChange(e,itemToDelete)}
                                    />
                                    <label
                                      htmlFor={`item-${item.id}-made-0`}
                                      className="form-check-label"
                                    >
                                      Unmade
                                    </label>
                                  </div>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <div className="row m-0 mt-2 align-items-center">
                    <div className="col row m-0 align-items-center">
                      <label
                        htmlFor="deleteReason"
                        className="form-label mb-0 w-auto font-weight-bold"
                      >
                        Reason
                      </label>
                      <div className="col">
                        <input
                          className="form-control"
                          type="text"
                          id="deleteReason"
                          name="deleteReason"
                          required
                        />
                      </div>
                    </div>
                    <div className="w-auto">
                      <button
                        type="submit"
                        className="d-inline-block btn btn-primary"
                      >
                        Done
                      </button>
                      <button
                        type="button"
                        className="d-inline-block btn btn-dark ml-2"
                        onClick={(e) => { handleCancelEmpty() }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default ItemCancelModal
