import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  getSystemSettings,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const ProductionReport = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
    const { authUserInfo } = useContext(UserContext);
    let { branchForSearch, ingredientItemForSearch } = useContext(RestaurantContext);
    // let { foodForPOS } = useContext(FoodContext);      
  
    //all data
    const [reportData, setReportData] = useState(null);
    const componentRef = useRef();
    const componentRefThermal = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [branch, setBranch] = useState(null);
    const [item, setItem] = useState(null); 
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [option,setOption] = useState("excel");

    const [branchSearch, setBranchSearch] = useState(null);
    useEffect(() => {
        setSiteName(getSystemSettings(generalSettings, "siteName"));
        if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
          setBranchSearch(
            branchForSearch.filter(
              (branch) => branch.id === authUserInfo.details.branch_id
            )
          );
        } else {
          setBranchSearch(branchForSearch);
        }
    
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, [generalSettings]);

      const thermalPrint = useReactToPrint({
        content: () => componentRefThermal.current,
      });

      const handleBranch=(branch)=>{
        setBranch(branch)
      }

      const handleItem=(item)=>{
        setItem(item)
      }

      const handleOption  = (e) =>{
        setOption(e.target.value)
      }
  
    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      setGeneratedReport(false)   
      if (        
        startDate !== null &&
        endDate !== null &&
        branch !== null
      ) {
        setDataPaginating(true);
        var fromDate = moment(startDate).format("YYYY-MM-DD");
        var toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-production-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", branch?.id);
        formData.append("item_id", item ? item?.id : "");
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            console.log("ReportResult", res);
            setGeneratedReport(true);
            setReportData(res.data)
            setDataPaginating(false);
            if(option==="excel"){
              componentRef.current.handleDownload();
            }else{
              thermalPrint();
            }
          })
          .catch((error) => {
            setDataPaginating(false);
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date, to date and branch"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Production Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Production Report"
          sheet="sheet 1"
          buttonText="Production Detail"
          ref={componentRef}
        />        
  
         {/* Excel Report*/}
         <div className="d-none">
          <div>           
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {reportData !== null &&
                      reportData !== undefined &&
                      generatedReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                                className="table table-bordered table-hover min-table-height mt-3"
                                id="table-to-xls"
                              >
                                <div className="page-break"></div>
                                <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                    <th colspan="10" style={{ border: "0px" }}>
                                      <div className="row">
                                        <div className="col-12">
                                          <h3 className="text-center mb-0">
                                            {siteName}
                                          </h3>
                                          <h3 className="text-center mt-0">
                                            Production Report
                                          </h3>
                                          <p className="text-center">
                                            FROM&nbsp;
                                            <Moment format="LL">
                                              {startDate}
                                            </Moment>
                                            &nbsp;TO&nbsp;
                                            <Moment format="LL">{endDate}</Moment>
                                          </p>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>                                
                                </thead>

                                {reportData && reportData !== null && reportData.map((value, index) => {
                                  return (<div key={index}>
                                    <thead className="align-middle">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Document No"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Document Date"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Item Name"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Qty"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Actual Qty"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Rate"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Amount"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Additional Amount"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Unit"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Remarks"))}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                      {/* {console.log("309 ", reportData)} */}
                                      {/* loop here*/}
                                      <div>
                                        <tr>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.document_identity}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.document_date}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.item_name}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.qty}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.actual_qty}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.cost}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.amount}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.additional_amount}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.unit_code}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {value.remarks}
                                          </td>
                                        </tr>
                                      </div>
                                    </tbody>

                                    {value?.itemDetails?.length > 0 && <div>
                                      <thead className="align-middle">
                                        <tr>
                                        <th colSpan="2"></th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Item Name"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Qty"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Cost"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Cost Amt."))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Branch Name"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Unit"))}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="align-middle">
                                        {value.itemDetails.map((item, index) => {
                                          return (<div key={index}>
                                            <tr>
                                            <td colSpan="2"></td>
                                              <td
                                                scope="row"
                                                className="lg-text text-capitalize align-middle text-center"
                                              >
                                                {item.ingredient_name}
                                              </td>
                                              <td
                                                scope="row"
                                                className="lg-text text-capitalize align-middle text-center"
                                              >
                                                {item.ingredient_qty}
                                              </td>
                                              <td
                                                scope="row"
                                                className="lg-text text-capitalize align-middle text-center"
                                              >
                                                {item.cost_price}
                                              </td>
                                              <td
                                                scope="row"
                                                className="lg-text text-capitalize align-middle text-center"
                                              >
                                                {item.cost_amount}
                                              </td>
                                              <td
                                                scope="row"
                                                className="lg-text text-capitalize align-middle text-center"
                                              >
                                                {value.branch_name}
                                              </td>
                                              <td
                                                scope="row"
                                                className="lg-text text-capitalize align-middle text-center"
                                              >
                                                {item.unit_code}
                                              </td>
                                            </tr>                                          
                                          </div>)
                                        })}                                      
                                      </tbody>
                                      <thead>
                                        <th colSpan="6"></th>
                                      </thead>
                                    </div>
                                    }
                                  </div>)
                                })
                                }
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>

       {/* print */}
       <div className="d-none">
        <div ref={componentRefThermal}>
          <div className="fk-print"  style={{margin:"20px 30px"}}>
            <div className="row">
              <div className="col-12">
                  <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      {siteName}
                    </span>
                  </div>
                  <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                     FROM&nbsp;
                      <Moment format="LL">
                       {startDate}
                      </Moment>
                      &nbsp;TO&nbsp;
                      <Moment format="LL">{endDate}</Moment>
                    </span>
                  </div>
                  <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      Print Time:{" "} 
                      <Moment format="LLL">
                        {new Date()}
                      </Moment>
                    </span>
                  </div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    Production Report
                  </span>
                  {/*production wise report */}
                  <div className="col-12 mt-2">
                     {
                      reportData &&
                      reportData !== null && reportData.map((value, index) => {
                        return(<div>
                            <table className="table table-bordered-sm">
                              <thead className="align-middle">
                                <th scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Doc No</strong></th>
                                <th scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Doc Date</strong></th>
                                <th scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Item</strong></th>
                                  <th scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Qty</strong></th>
                                  <th scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Actual Qty</strong></th>
                                  <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Rate</strong></th>
                              </thead>
                              <tbody className="align-middle">
                                <tr className="align-middle">
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.document_identity}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.document_date}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.item_name}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.qty}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.actual_qty}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.cost}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table className="table table-bordered-sm">
                              <thead className="align-middle">
                                <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Amount</strong></th>
                                <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Additional Amt</strong></th>
                                <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Unit</strong></th>
                                <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Remarks</strong></th>
                              </thead>
                              <tbody className="align-middle">
                                <tr className="align-middle">
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.amount}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.additional_amount}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.unit_code}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.remarks}</td>
                                </tr> 
                              </tbody>
                            </table>
    
                            {value?.itemDetails?.length > 0 && <table className="table table-bordered-sm mb-5">
                            <thead className="align-middle">
                              <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Item</strong></th>
                              <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Qty</strong></th>
                                <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Cost</strong></th>
                                <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Cost Amt.</strong></th>
                              <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Branch</strong></th>
                              <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Unit</strong></th>
                            </thead>
                            <tbody className="align-middle">
                              {value.itemDetails.map((item, ind) => {
                                return (<tr className="align-middle" key={ind}>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.ingredient_name}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{parseFloat(item.ingredient_qty).toFixed(2)}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{parseFloat(item.cost_price).toFixed(2)}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{parseFloat(item.cost_amount).toFixed(2)}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{value.branch_name}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.unit_code}</td>
                                </tr>)
                              })}
                            </tbody>
                            </table>}
                          </div>
                        )
                      })
                      }
                  </div>
              </div> 
            </div> 
          </div> 
        </div> 
       </div>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                     
                      {/* spin loading ends */}
  
                      {/* Loading effect {loading === true ? (
                        tableLoading()
                      ) : ( */}
                      
                        <div key="smtp-form">
                          <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="row t-mb-15 mb-md-0" >
                              <ul className="t-list fk-breadcrumb col-6">
                                <li className="fk-breadcrumb__list">
                                  <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Production Report"))}
                                  </span>
                                </li>
                              </ul>
                              <div className="col-6 d-flex justify-content-end p-0">
                              <div className="d-inline mr-3 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="excel_report"
                                  name="excel_report"
                                  value="excel"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "excel"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="excel_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Excel"))}
                                </label>
                              </div>

                              <div className="d-inline mr-2 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="print_report"
                                  name="print_report"
                                  value="print"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "print"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="print_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-print" aria-hidden="true"></i> {_t(t("Print"))}
                                </label>
                              </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>

                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>                                                                                 
  
                            <div className="col-3">
                              <Select
                                options={branchSearch && branchSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                value={branch}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleBranch} 
                                styles={customStyle}                               
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a branch")) + ".."}
                              />
                            </div>

                            <div className="col-3">
                              <Select
                                options={ingredientItemForSearch && ingredientItemForSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                value={item}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleItem}
                                styles={customStyle}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select item")) + ".."}
                              />
                            </div> 

                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected("excel")}
                              >
                               PRODUCTION REPORT
                              </button>
                            </div>                             
                          </div>                           
                          
                        </div>                                          
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
  
      </>
    );
}

export default ProductionReport;