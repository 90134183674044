import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  tableLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";
import DatePicker from "react-datepicker";

const toastObj = {
  position: "bottom-center",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  className: "text-center toast-notification",
}

const FinishGoodsAdjustmentAdd = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
      //common
      loading,
      setLoading,
    } = useContext(SettingsContext);
  
    const {
      branchForSearch,      
    } = useContext(RestaurantContext)

    let {
        // foodGroupForSearch,
        foodForPOS,
    } = useContext(FoodContext);      
  
    let {
      //goods adjustment
      setGoodsAdjustmentList,
      setGoodsAdjustmentForSearch,
    } = useContext(UserContext);
    // console.log("cehck =>",foodForPOS)

    //select style
 const customStyle = {
  control: (provided) => ({
    ...provided,
    minHeight: "2rem",
    marginTop: "0",
    marginBottom: "0",
  }),
  placeholder:(provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    alignSelf: "center",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 0.4rem",
    svg: {
      height: "2rem",
    },
  }),
};
  
    // States hook here
    //new customer
    let [newGoodsAdjustment, setNewGoodsAdjustment] = useState({
      adjust_no: "",
      adjust_date: null,
      branch: null,
      remarks: '',      
    });      
  
    //set name, phn no hook
    const handlesetNewGoodsAdjustment = (e) => {
      setNewGoodsAdjustment({ ...newGoodsAdjustment, [e.target.name]: e.target.value });
    };
  
    const handleSetBranch = (branch) => {
      setNewGoodsAdjustment({ ...newGoodsAdjustment, branch});
      setNewRow([]);
    }
  
    const handleClearStates = (e) => {
      setNewGoodsAdjustment({
        adjust_no: "",
        adjust_date: null,
        branch: null,
        remarks: '',
      })      
    }
  
    const validateAndGetFormData = () => {
      const formData = {};
      if (!newGoodsAdjustment.adjust_date) {
        toast.error('Please select a date', toastObj);
        return false;
      } else {
        let dateObj = newGoodsAdjustment.adjust_date
        let date =
          `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)}-${dateObj.getDate()}`;
        formData.document_date = date
      }
      if (!newGoodsAdjustment.branch || !newGoodsAdjustment.branch.id) {
        toast.error('Please select a branch', toastObj);
        return false;
      } else {
        formData.branch_id = newGoodsAdjustment.branch.id
      }
      if (!newRow || !newRow.length>0) {
        toast.error('Please add rows', toastObj);
        return false;
      } else {
        let slugArray=[];
        let isDuplicate=false;
        newRow.map((finishGoods,index) => {
            if(finishGoods.item_name === ""){
              slugArray.push();          
            }
            else{
              isDuplicate = slugArray.some((item) => item.item_id === finishGoods.item_name.id);
                slugArray.push({
                  qty: parseInt(finishGoods.qty),
                  item_id: finishGoods.item_name.id,
                  item_name: finishGoods.item_name.name,
                  actual_stock:finishGoods.actual_stock,
                  adjusted_qty:finishGoods.adjusted_qty,                         
                });
             }
             return;
            });
            if(!isDuplicate){
                if(newRow.length === slugArray.length){
                    formData.items = slugArray;
                }else{
                    toast.error('Items selection is required', toastObj);
                    return;
                }
            }else{
                toast.error('Cant Select Same Items', toastObj);
                return;
            }
      }
      formData.remarks = newGoodsAdjustment.remarks
      return formData;
    }
  
    const onSuccessSave = (res) => {
      handleClearStates()
      setGoodsAdjustmentList(res.data[0]);
      setGoodsAdjustmentForSearch(res.data[1]);
      setLoading(false);
      history.push("/dashboard/manage/stock/finished-goods-adjustment")
      toast.success(`${_t(t("Finished Goods adjustment has been added."))}`, toastObj);
    }
  
    const onFailedSave = (error) => {
      setLoading(false);
      toast.error(
        `${_t(t("Please try again."))}`,
        toastObj
      );
    }
  
    //Save New customer
    const handleSaveNewCustomer = (e) => {
      e.preventDefault();
      const formData = validateAndGetFormData()
      if (!formData)
      return;
      setLoading(true)
      const url = BASE_URL + `/settings/finished-goods-adjustment`;
      console.log('check formData', formData);
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then(onSuccessSave)
        .catch(onFailedSave);
    };    

    const [newRow,setNewRow]=useState([]);
    const [rowId,setRowId]=useState(0);
     const addNewRow=()=>{
      if(newGoodsAdjustment.branch !== null){
        setNewRow((pre)=>[...pre,{
          id: rowId,
          item_name:"",
          qty:"",
          actual_stock:"-",
          adjusted_qty:"-",                  
        }])
        setRowId(rowId+1);
      }else{
        toast.error(`${_t(t("Please select branch"))}`, toastObj);
      }   
    }
  
    const delRow=(id)=>{
      let row=[...newRow]
      row= row.filter((item,i)=>id!==item.id);
      setNewRow(row);
    }

    //set items hook
    const handleSetItems = async (index,item) => {
        newRow[index].item_name = item;
        newRow[index].actual_stock = "-";
        setNewRow([...newRow])
        getStock(index,item.id);
    };

    const getStock = async (index,id)=>{
        const url =
      BASE_URL +
        "/settings/get-finished-good-stock/" + id + "/branch/" + newGoodsAdjustment?.branch?.id
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
            if(res){
              newRow[index].actual_stock = res.data;
              setNewRow([...newRow]);
            }          
          } catch {
            toast.error(`${_t(t("Please try again"))}`, toastObj);
          }
    }

    const handleItemQty = (e,index) => {
        newRow[index].qty = e.target.value;
        newRow[index].adjusted_qty = e.target.value ? (e.target.value - newRow[index].actual_stock) : "0"
        setNewRow([...newRow]);
    }
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Goods adjustment Add"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div className="fk-scroll--pos-menu" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(loading) && "loadingBlur"}`}></div>
                            <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {/* {loading === true ? (
                    tableLoading()
                  ) : ( */}
                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Add Goods Adjustment"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center"></div>
                        </div>
                      </div>  

                      <form
                      className="row card p-2 mx-3 mb-5 table-text"
                    onSubmit={
                      (e) =>
                        handleSaveNewCustomer(e)
                    }
                    autoComplete="off"
                  >
                    <div className="col-12">
                    <div className="row">
                      <div className="form-group col-4 mt-3">
                        <label htmlFor="adjust_no" className="form-label">
                          {_t(t("Adjustment Number"))}{" "}
                          <span className="text-primary">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control table-text"
                          id="adjust_no"
                          name="adjust_no"
                          placeholder="Auto no"
                          value={newGoodsAdjustment.adjust_no || ""}
                          disabled                          
                        />
                      </div>

                      <div className="form-group col-4 mt-3">
                        <label
                          htmlFor="adjust_date"
                          className="form-label"
                        >
                          {_t(t("Adjustment Date"))}
                          <span className="text-danger">*</span>
                        </label>
                        <DatePicker
                          selected={newGoodsAdjustment.adjust_date}
                          onChange={(date) => {                            
                            setNewGoodsAdjustment({
                              ...newGoodsAdjustment,
                              adjust_date: date
                            })
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control table-text"
                          placeholderText={_t(t("Adjustment Date"))}
                          disabled={newGoodsAdjustment.edit}
                        />
                      </div>

                      <div className="form-group col-4 mt-3">
                        <label htmlFor="branch" className="form-label">
                        {_t(t("Branch"))}
                        <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={branchForSearch}
                          value={newGoodsAdjustment.branch || null}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          onChange={handleSetBranch}
                          maxMenuHeight="200px"
                          placeholder={
                            _t(t("Please select a branch")) + ".."
                          }
                          isDisabled={newGoodsAdjustment.edit}
                          styles={customStyle}
                        />
                      </div>
                    </div>

                    <div className="row">  
                    <div className="form-group mt-3 col-12">
                        <label htmlFor="remarks" className="form-label">
                          {_t(t("Remarks"))}{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control table-text"
                          id="remarks"
                          name="remarks"
                          placeholder="Remarks"
                          value={newGoodsAdjustment.remarks || ""}
                          onChange={handlesetNewGoodsAdjustment}
                          disabled={newGoodsAdjustment.edit}
                        />
                      </div>                                          
                    </div>
                                  
                    <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>
                                      {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}                                      
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"15rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item Name"))}
                                          </span>
                                        </th>                                                                                

                                        <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{minWidth: '100px'}}
                                        >
                                            <span style={{fontWeight:500}}>
                                                {_t(t("Actual Stock"))}
                                            </span>
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{minWidth: '100px', maxWidth: '200px'}}
                                        >
                                            <span style={{fontWeight:500}}>
                                                {_t(t("Qty"))}
                                            </span>
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{minWidth: '100px'}}
                                        >
                                            <span style={{fontWeight:500}}>
                                                {_t(t("Adjusted Qty"))}
                                            </span>
                                        </th>                                        

                                        {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (finishGoods, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                    <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}&nbsp;
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                                onClick={()=>{delRow(finishGoods.id)}}
                                                ></i>
                                              </td>                                              
                                              <td className="table-text text-capitalize align-middle text-center">
                                                <Select
                                                  menuPosition="fixed"
                                                  options={foodForPOS !== null ? foodForPOS : []}
                                                  components={makeAnimated()}
                                                  getOptionLabel={(option) => option.name}
                                                  getOptionValue={(option) => option.id}
                                                  onChange={(e)=>handleSetItems(index,e)}
                                                  value={finishGoods.item_name}
                                                  styles={customStyle}
                                                  placeholder={
                                                  _t(t("Please select items")) + ".."
                                                  }                                                
                                                />
                                              </td>                                              
                                              
                                              <td className="table-text text-capitalize align-middle text-center">
                                                {finishGoods.actual_stock}
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                              <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={finishGoods.id}
                                                  name={finishGoods.id}
                                                  value={finishGoods.qty}
                                                  onChange={(e)=>handleItemQty(e,index)}
                                                  placeholder="Qty"
                                                  required
                                                  readOnly={finishGoods.actual_stock !== '-' ? false : true}                                                  
                                                />
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                                {finishGoods.adjusted_qty}
                                              </td>                                              

                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(finishGoods.id)}}></i>&nbsp;
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                  <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}                                             
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}
                                  </table>
                                </div> 

                    <div className="form-group mt-4 pb-2">                            
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/finished-goods-adjustment"))}
                                //   data-dismiss="modal"
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                    </div>
                    </div>
                  </form>                    
                    </div>
                  {/* // )} */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default FinishGoodsAdjustmentAdd;
