import React, { useState, useEffect } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie } from "../functions/Functions";

//3rd party packages

// creating context api
const FinanceContext = React.createContext();

const FinanceProvider = ({ children }) => {
  // State hooks  here
  //loading
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);

  //website
//   const [foodListWeb, setFoodListWeb] = useState(null);
//   const [foodStockWeb, setFoodStockWeb] = useState(null);
//   const [foodGroupWeb, setFoodGroupWeb] = useState(null);
//   const [propertyGroupWeb, setPropertyGroupWeb] = useState(null);
//   const [workPeriodWeb, setWorkPeriodWeb] = useState(null);
//   const [onlineOrdersCustomer, setOnlineOrdersCustomer] = useState(null);

  //pos page online orders
//   const [onlineOrdersAdmin, setOnlineOrdersAdmin] = useState(null);
//   const [onlineOrdersAdminForSearch, setOnlineOrdersAdminForSearch] =
//     useState(null);
    
      //pos page food panda orders
//   const [foodPandaOrders, setFoodPandaOrders] = useState(null);
//   const [foodPandaOrdersForSearch, setFoodPandaOrdersForSearch] =
//     useState(null);
  //foods
//   const [foodList, setFoodList] = useState(null);
//   const [foodForSearch, setFoodForSearch] = useState(null);

  //foods for pos
//   const [foodForPOS, setFoodForPOS] = useState(null);

  //food stock
//   const [foodItemStock, setFoodItemStock] = useState(null);
//   const [foodPurchaseHistory, setFoodPurchaseHistory] = useState(null);
//   const [foodPurchaseHistoryForSearch, setFoodPurchaseHistoryForSearch] =
//     useState(null);

  //food group
//   const [foodGroupList, setFoodGroupList] = useState(null);
//   const [foodGroupForSearch, setFoodGroupforSearch] = useState(null);
//   const [foodGroupForSearchForPOS, setFoodGroupforSearchForPOS] =
//     useState(null);

  //food unit
//   const [foodUnitList, setFoodUnitList] = useState(null);
//   const [foodUnitForSearch, setFoodUnitforSearch] = useState(null);

  //variation
//   const [variationList, setVariationList] = useState(null);
//   const [variationForSearch, setVariationForSearch] = useState(null);

  //property Group
//   const [propertyGroupList, setPropertyGroupList] = useState(null);
//   const [propertyGroupForSearch, setPropertyGroupForSearch] = useState(null);

  //property Item
//   const [propertyItemList, setPropertyItemList] = useState(null);
//   const [propertyItemForSearch, setPropertyItemForSearch] = useState(null);
//   const [propertyItemGroup, setPropertyItemGroup] = useState(null);

  //stock
//   let [items, setItems] = useState(null);
//   let [theGroups, setTheGroups] = useState(null);
//   let [itemsFood, setItemsFood] = useState(null);
//   let [theGroupsFood, setTheGroupsFood] = useState(null);

//   //food grops menu
//   const [availableFoodGroups, setAvailableFoodGroups] = useState({});

//   const [getOrderDetailsState, setGetOrderDetailsState] = useState(null);

//   const [propertyItemForSearch, setPropertyItemForSearch] = useState(null);
//   const [propertyItemGroup, setPropertyItemGroup] = useState(null);

  // localStorage helper
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  // localStorage helper
  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };

  //check if offline helper
  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  };

  //useEffect- to get data on render
  useEffect(() => {
    //call- unauthenticated
    // getFoodWeb();
    //call if authenticated
    if (getCookie() !== undefined || offline(BASE_URL + "/auth/user")) {
        // getCoaL1();
        // getCoaL2();
        // getCoaL3();
        // getBPList();
        // getCPList();
        // getJVList();
        // getOAList();
        //comment
        // getTransactionAccount();
    //   getGl();
    //   getPropertyGroup();
    //   getFoodGroupWithMenu();
    }
  }, []);

  // Helper
//   const setIngredientsStock = (res) => {
//     setItems(res.data[0]);
//     setTheGroups(res.data[1]);
//     setLoading(false);
//   };

  //stock  // Priority ->
//   const getIngredientStock = async (id) => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-closing-items/" + id;
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setIngredientsStock(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setIngredientsStock(parseRes);
//       }
//     }
//   };

  // Helper
//   const setFoodsStockReport = (res) => {
//     setItemsFood(res.data[0]);
//     setTheGroupsFood(res.data[1]);
//     setLoading(false);
//   };

  // Priority ->
//   const getFoodStockReport = async (id) => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-closing-items-food/" + id;
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setFoodsStockReport(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setFoodsStockReport(parseRes);
//       }
//     }
//   };

  // Helper
//   const setFoodsWeb = (res) => {
//     setFoodListWeb(res.data[0]);
//     setFoodGroupWeb(res.data[1]);
//     setPropertyGroupWeb(res.data[2]);
//     setWorkPeriodWeb(res.data[3]);
//     setFoodStockWeb(res.data[[4]]);
//   };

  //website
  //get foods web  // Priority ->
//   const getFoodWeb = async () => {
//     setLoading(true);
//     const foodItemWebUrl = BASE_URL + "/website/home";
//     try {
//       const res = await axios.get(foodItemWebUrl);
//       setFoodsWeb(res);
//       setDataToLocal(foodItemWebUrl, res);
//     } catch (error) {
//       if (offline(foodItemWebUrl)) {
//         let parseRes = getDataFromLocal(foodItemWebUrl);
//         setFoodsWeb(parseRes);
//       }
//     }
//   };

  // Helper
//   const setOnlineOrderCustomer = (res) => {
//     setOnlineOrdersCustomer(res.data[1]);
//     setLoading(false);
//   };

  //get online orders customer  // Priority ->
//   const getOnlineOrdersCustomer = async () => {
//     setLoading(true);
//     const ordersUrl = BASE_URL + "/website/online-orders/customer";
//     try {
//       const res = await axios.get(ordersUrl, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setOnlineOrderCustomer(res);
//       setDataToLocal(ordersUrl, res);
//     } catch (error) {
//       if (offline(ordersUrl)) {
//         let parseRes = getDataFromLocal(ordersUrl);
//         setOnlineOrderCustomer(parseRes);
//       }
//     }
//   };

  // Helper
//   const setOlineOrderAdmin = (res) => {
//     setOnlineOrdersAdmin(res.data[0]);
//     setOnlineOrdersAdminForSearch(res.data[1]);
//     setLoading(false);
//   };

  //get online orders  // Priority ->
//   const getOnlineOrders = async () => {
//     setLoading(true);
//     const ordersUrl = BASE_URL + "/website/online-orders";
//     try {
//       const res = await axios.get(ordersUrl, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setOlineOrderAdmin(res);
//       setDataToLocal(ordersUrl, res);
//     } catch (error) {
//       if (offline(ordersUrl)) {
//         let parseRes = getDataFromLocal(ordersUrl);
//         setOlineOrderAdmin(parseRes);
//       }
//     }
//   };

  // Helper
//   const setPaginatedOnlineOrder = (res) => {
//     setOnlineOrdersAdmin(res.data[0]);
//     setOnlineOrdersAdminForSearch(res.data[1]);
//     setDataPaginating(false);
//   };

  // get paginated online orders // Priority ->
//   const setPaginatedOnlineOrders = async (pageNo) => {
//     setDataPaginating(true);
//     const url = BASE_URL + "/website/online-orders?page=" + pageNo;
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setPaginatedOnlineOrder(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setPaginatedOnlineOrder(parseRes);
//       }
//     }
//   };

    // Helper
    // const setFoodPandaOrder= (res) => {
    //   setFoodPandaOrders(res.data[0]);
    //   setFoodPandaOrdersForSearch(res.data[1]);
    //   setLoading(false);
    // };
  
    //get food panda orders  // Priority ->
    // const getFoodPandaOrders = async () => {
    //   setLoading(true);
    //   const ordersUrl = BASE_URL + "/website/online-orders-fp";
    //   try {
    //     const res = await axios.get(ordersUrl, {
    //       headers: { Authorization: `Bearer ${getCookie()}` },
    //     });
    //     setFoodPandaOrder(res);
    //     setDataToLocal(ordersUrl, res);
    //   } catch (error) {
    //     if (offline(ordersUrl)) {
    //       let parseRes = getDataFromLocal(ordersUrl);
    //       setFoodPandaOrder(parseRes);
    //     }
    //   }
    // };
  
    // Helper
    // const setPaginatedFoodPandaOrder = (res) => {
    //   setFoodPandaOrders(res.data[0]);
    //   setFoodPandaOrdersForSearch(res.data[1]);
    //   setDataPaginating(false);
    // };
  
    // get paginated food panda orders // Priority ->
    // const setPaginatedPandaOrders = async (pageNo) => {
    //   setDataPaginating(true);
    //   const url = BASE_URL + "/website/online-orders-fp?page=" + pageNo;
    //   try {
    //     const res = await axios.get(url, {
    //       headers: { Authorization: `Bearer ${getCookie()}` },
    //     });
    //     setPaginatedFoodPandaOrder(res);
    //     setDataToLocal(url, res);
    //   } catch (error) {
    //     if (offline(url)) {
    //       let parseRes = getDataFromLocal(url);
    //       setPaginatedFoodPandaOrder(parseRes);
    //     }
    //   }
    // };

  // Helper
//   const setFoods = (res) => {
//     setFoodList(res.data[0]);
//     setFoodForSearch(res.data[1]);
//     setLoading(false)
//   };

  //get foods  // Priority ->
//   const getFood = async () => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-food-item";
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setFoods(res);
//       //setDataToLocal(url, res);
//     } catch (error) {
//         if (offline(url)) {
//           let parseRes = getDataFromLocal(url);
//           //setFoods(parseRes);
//         }
//     }
//   };

  // Helper
//   const setPaginatedFoods = (res) => {
//     setFoodList(res.data[0]);
//     setFoodForSearch(res.data[1]);
//     setDataPaginating(false);
//   };

  // get paginated food  // Priority ->
//   const setPaginatedFood = async (pageNo) => {
//     setDataPaginating(true);
//     const url = BASE_URL + "/settings/get-food-item?page=" + pageNo;
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setPaginatedFoods(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setPaginatedFoods(parseRes);
//       }
//     }
//   };

  // get food groups food items
//   const getFoodGroupWithMenu = () => {
//     const url = BASE_URL + "/settings/get-food-group-with-menu";
//     axios
//       .get(url, { headers: { Authorization: `Bearer ${getCookie()}` } })
//       .then((res) => {
//         setAvailableFoodGroups(res.data);
//         setDataToLocal(url, res);
//         // save to ls
//       })
//       .catch((err) => {
//         // pwa work
//         if (offline(url)) {
//           let parseRes = getDataFromLocal(url);
//           setAvailableFoodGroups(parseRes.data);
//         }
//       });
//   };
  //get food items for POS  // Priority ->
//   const getFoodItemsForPOS = async (toSetLoading = false) => {
//     if (!toSetLoading) setLoading(true);
//     const url = BASE_URL + "/settings/get-enabled-item";
//     const res = await axios
//       .get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       })
//       .then((res) => {
//         setFoodForPOS(res.data[1]);
//         setDataToLocal(url, res);
//       })
//       .catch((error) => {
//         if (offline(url)) {
//           let parseRes = getDataFromLocal(url);
//           setFoodForPOS(parseRes.data[1]);
//           // setFoods(parseRes.data[1]);
//         }
//       });
//   };

  // Helper
//   const setFoodsGroup = (res) => {
//     setFoodGroupList(res.data[0]);
//     setFoodGroupforSearch(res.data[1]);
//     setFoodItemStock(res.data[2]);
//     setLoading(false);
//   };

  //get food groups  // Priority
//   const getFoodGroup = async () => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-food-group";
//     return axios
//       .get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       })
//       .then((res) => {
//         setFoodsGroup(res);
//         setDataToLocal(url, res);
//       })
//       .catch((error) => {
//         if (offline(url)) {
//           let parseRes = getDataFromLocal(url);
//           setFoodsGroup(parseRes);
//         }
//       });
//   };

//   const getFoodGroupForPOS = async () => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-food-group_status";
//     return axios
//       .get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       })
//       .then((res) => {
//         setFoodGroupforSearchForPOS(res.data[1]);
//         setDataToLocal(url, res);
//         setLoading(false);
//       })
//       .catch((error) => {
//         if (offline(url)) {
//           let parseRes = getDataFromLocal(url);
//           setFoodGroupforSearchForPOS(parseRes.data[1]);
//         }
//       });
//   };

  // Helper
//   const setPaginatedFoodsGroup = (res) => {
//     setFoodGroupList(res.data[0]);
//     setFoodGroupforSearch(res.data[1]);
//     setFoodItemStock(res.data[2]);
//     setDataPaginating(false);
//   };

  // get paginated food groups  // Priority ->
//   const setPaginatedFoodGroup = async (pageNo) => {
//     setDataPaginating(true);
//     const url = BASE_URL + "/settings/get-food-group?page=" + pageNo;
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setPaginatedFoodsGroup(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setPaginatedFoodsGroup(parseRes);
//       }
//     }
//   };

  //Helper
//   const setFoodsUnit = (res) => {
//     setFoodUnitList(res.data[0]);
//     setFoodUnitforSearch(res.data[1]);
//     setLoading(false);
//   };

  //get food units  // Priority ->
//   const getFoodUnit = async () => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-food-unit";
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setFoodsUnit(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setFoodsUnit(parseRes);
//       }
//     }
//   };

  // Helper
//   const setPaginatedFoodsUnit = (res) => {
//     setFoodUnitList(res.data[0]);
//     setFoodUnitforSearch(res.data[1]);
//     setDataPaginating(false);
//   };

  // get paginated food units // Priority ->
//   const setPaginatedFoodUnit = async (pageNo) => {
//     setDataPaginating(true);
//     const url = BASE_URL + "/settings/get-food-unit?page=" + pageNo;
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setPaginatedFoodsUnit(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setPaginatedFoodsUnit(parseRes);
//       }
//     }
//   };

  // Helper
//   const setVariationsList = (res) => {
//     setVariationList(res.data[0]);
//     setVariationForSearch(res.data[1]);
//     setLoading(false);
//   };

  //get variations  //Priority ->
//   const getVariation = async () => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-variation";
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setVariationsList(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setVariationsList(parseRes);
//       }
//     }
//   };

  // Helper
//   const setPaginatedVariations = (res) => {
//     setVariationList(res.data[0]);
//     setVariationForSearch(res.data[1]);
//     setDataPaginating(false);
//   };

  // get paginated variations  // Priority ->
//   const setPaginatedVariation = async (pageNo) => {
//     setDataPaginating(true);
//     const url = BASE_URL + "/settings/get-variation?page=" + pageNo;
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setPaginatedVariations(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setPaginatedVariations(parseRes);
//       }
//     }
//   };

  // Helper
//   const setPropertyGroups = (res) => {
//     setPropertyGroupList(res.data[0]);
//     setPropertyGroupForSearch(res.data[1]);
//     setLoading(false);
//   };

  //get property group  // Priority ->
//   const getPropertyGroup = async () => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-property-group";
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setPropertyGroups(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setPropertyGroups(parseRes);
//       }
//     }
//   };

  // Helper
//   const setPaginatedPropertiesGroup = (res) => {
//     setPropertyGroupList(res.data[0]);
//     setPropertyGroupForSearch(res.data[1]);
//     setDataPaginating(false);
//   };

  // get paginated property group  // Priority ->
//   const setPaginatedPropertyGroup = async (pageNo) => {
//     setDataPaginating(true);
//     const url = BASE_URL + "/settings/get-property-group?page=" + pageNo;
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setPaginatedPropertiesGroup(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setPaginatedPropertiesGroup(parseRes);
//       }
//     }
//   };

  // Helper
//   const setPropertyItems = (res) => {
//     setPropertyItemList(res.data[0]);
//     setPropertyItemForSearch(res.data[0]);
//     setPropertyItemGroup(res.data[1]);
//     setLoading(false);
//   };

  //get property item  // Priority ->
//   const getPropertyItem = async (slug) => {
//     setLoading(true);
//     //const propertyItemUrl = BASE_URL + "/settings/get-property-item/" + slug;
//     //Change fahad //
//     const propertyItemUrl = BASE_URL + "/settings/get-property-item/" + slug;
//     try {
//       const res = await axios.get(propertyItemUrl, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setPropertyItems(res);
//       setDataToLocal(propertyItemUrl, res);
//     } catch (error) {
//       if (offline(propertyItemUrl)) {
//         let parseRes = getDataFromLocal(propertyItemUrl);
//         setPropertyItems(parseRes);
//       }
//     }
//   };

  // Helper
//   const setFoodsPurchase = (res) => {
//     setFoodPurchaseHistory(res.data[0]);
//     setFoodPurchaseHistoryForSearch(res.data[1]);
//     setLoading(false);
//   };

  //get food purchases  // Priority ->
//   const getFoodPurchase = async () => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-food_purchase";
//     try {
//       const res = await axios.get(url);
//       setDataToLocal(url, res);
//       setFoodsPurchase(res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setFoodsPurchase(parseRes);
//       }
//     }
//   };

  // Helper
//   const setPaginatedFoodsPurchase = (res) => {
//     setFoodPurchaseHistory(res.data[0]);
//     setFoodPurchaseHistoryForSearch(res.data[1]);
//     setDataPaginating(false);
//   };

  // get paginated purchases  // Priority ->
//   const setPaginatedFoodPurchase = async (pageNo) => {
//     setDataPaginating(true);
//     const url = BASE_URL + "/settings/get-food_purchase?page=" + pageNo;
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setPaginatedFoodsPurchase(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//       if (offline(url)) {
//         let parseRes = getDataFromLocal(url);
//         setPaginatedFoodsPurchase(parseRes);
//       }
//     }
//   };

  //Helper
//   const setGetOrderDetails = (res) => {
//     setGetOrderDetailsState(res.data.data[0]);
//   };

  //Get Order Detail  // Priority ->
//   async function getOrderDetails(id) {
//     const url = BASE_URL + "/settings/get-order/" + id;
//     return axios
//       .get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       })
//       .then(async (res) => {
//         console.log("indexOrder", res);
//         setGetOrderDetails(res);
//         return Promise.resolve(res.data.data[0]);
//         setDataToLocal(url, res);
//       })
//       .catch(async (error) => {
//         if (offline(url)) {
//           let parseRes = getDataFromLocal(url);
//           setGetOrderDetails(parseRes);
//           return Promise.resolve(parseRes.data.data[0]);
//         }
//       });
//   }

// Helper
// const setGl = (res) => {
//     setGlListData(res.data[0]);
//     setGlForSearch(res.data[1]);
//     setLoading(false)
//   };

  //get Gl  // Priority ->
//   const getGl = async () => {
//     setLoading(true);
//     const url = BASE_URL + "/settings/get-food-item";
//     try {
//       const res = await axios.get(url, {
//         headers: { Authorization: `Bearer ${getCookie()}` },
//       });
//       setGl(res);
//       setDataToLocal(url, res);
//     } catch (error) {
//         if (offline(url)) {
//           let parseRes = getDataFromLocal(url);
//           setGl(parseRes);
//         }
//     }
//   };

//Coa1 list
const [coa1, setCoa1] = useState(null);
const [coa1ForSearch, setCoa1ForSearch] = useState(null);
  // States hook here
  const [saveData, setSaveData] = useState([
    {name:"cash_account_id",value:""},
    {name:"withholding_tax_account_id",value:""},
    {name:"other_tax_account_id",value:""},
    {name:"bank_account_id",value:""},
    {name:"inventory_account_id",value:""},
    {name:"revenue_account_id",value:""},
    {name:"cogs_id",value:""},
    {name:"purchase_discount_id",value:""},
    {name:"sales_discount_id",value:""},
    {name:"purchase_tax_id",value:""},
    {name:"sales_tax_id",value:""},
    {name:"supplier_cash_account_id",value:""},
    {name:"supplier_payable_account_id",value:""}
  ]);

  const [cashAcc,setCashAcc]=useState([]);
  const [whtAcc,setWhtAcc]=useState("");
  const [otherTaxAcc,setOtherTaxAcc]=useState("");
  const [bankAcc,setBankAcc]=useState([]);
  const [invAcc,setInvAcc]=useState("");
  const [revAcc,setRevAcc]=useState("");
  const [cogs,setCogs]=useState("");
  const [purchaseDis,setPurchaseDis]=useState("");
  const [salesDis,setSalesDis]=useState("");
  const [purchaseTax,setPurchaseTax]=useState("");
  const [salesTax,setSalesTax]=useState("");
  const [suppCashAcc,setSuppCashAcc]=useState("");
  const [suppPayableAcc,setSuppPayableAcc]=useState("");


const getModuleSetting=async(coa3ForSearchSettings)=>{
  setLoading(true);
  const moduleUrl = BASE_URL + "/settings/get-module-setting";
  try {
    const res = await axios.get(moduleUrl, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    });
    // console.log("check get data==>",res.data);
    res.data.map((acc)=>{
      if(acc.field === "cash_account_id"){
        //set state for dropdown default values
       let cashAccId =  JSON.parse(acc.value)
       let cash =  cashAccId.map((cash)=>{
        return coa3ForSearchSettings.find((coa3)=> coa3.id == cash)
       });
       setCashAcc(cash);
       //set savedata values for not empty on load
       saveData[0].value = cashAccId
       setSaveData([...saveData]);

      }
      else if(acc.field === "withholding_tax_account_id"){
        //set state for dropdown default values
        let withTax = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setWhtAcc(withTax)
        //set savedata values for not empty on load
        saveData[1].value = withTax == "" ? "" :  acc.value
        setSaveData([...saveData]);
      }
      else if(acc.field === "other_tax_account_id"){
        //set state for dropdown default values
        let otherTax = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setOtherTaxAcc(otherTax)
        //set savedata values for not empty on load
        saveData[2].value = otherTax == "" ? "" : acc.value
        setSaveData([...saveData]);
      }else if(acc.field === "bank_account_id"){
        //set state for dropdown default values
        let bankAccId =  JSON.parse(acc.value)
       let bankIdArr =  bankAccId.map((bank)=>{
        return coa3ForSearchSettings.find((coa3)=> coa3.id == bank)
       });
       setBankAcc(bankIdArr);
       //set savedata values for not empty on load
       saveData[3].value = bankAccId?.length == 0 ? "" : bankAccId
       setSaveData([...saveData]);
        
      }else if(acc.field === "inventory_account_id"){
        //set state for dropdown default values
        let inventoryAcc = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setInvAcc(inventoryAcc);
        //set savedata values for not empty on load
        saveData[4].value = inventoryAcc == "" ? "" : acc.value
        setSaveData([...saveData]);
        
      }else if(acc.field === "revenue_account_id"){
        //set state for dropdown default values
        let revenueAcc = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setRevAcc(revenueAcc);
        //set savedata values for not empty on load
        saveData[5].value = revenueAcc == "" ? "" : acc.value
        setSaveData([...saveData]);
        
      }else if(acc.field === "cogs_id"){
        //set state for dropdown default values
        let costOfGoods = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setCogs(costOfGoods);
        //set savedata values for not empty on load
        saveData[6].value = costOfGoods == "" ? "" : acc.value
        setSaveData([...saveData]);
        
      }else if(acc.field === "purchase_discount_id"){
        //set state for dropdown default values
        let purchaseDis = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setPurchaseDis(purchaseDis);
        //set savedata values for not empty on load
        saveData[7].value = purchaseDis == "" ? "" : acc.value
        setSaveData([...saveData]);
        
      }else if(acc.field === "sales_discount_id"){
        //set state for dropdown default values
        let salesDis = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setSalesDis(salesDis);
        //set savedata values for not empty on load
        saveData[8].value = salesDis == "" ? "" : acc.value
        setSaveData([...saveData]);
        
      }else if(acc.field === "purchase_tax_id"){
        //set state for dropdown default values
        let purchaseTax = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setPurchaseTax(purchaseTax);
        //set savedata values for not empty on load
        saveData[9].value = purchaseTax == "" ? "" : acc.value
        setSaveData([...saveData]);
        
      }else if(acc.field === "sales_tax_id"){
        //set state for dropdown default values
        let salesTax = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setSalesTax(salesTax);
        //set savedata values for not empty on load
        saveData[10].value = salesTax == "" ? "" : acc.value
        setSaveData([...saveData]);
      }else if(acc.field === "supplier_cash_account_id"){
        //set state for dropdown default values
        let suppCash = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setSuppCashAcc(suppCash);
        //set savedata values for not empty on load
        saveData[11].value = suppCash == "" ? "" : acc.value
        setSaveData([...saveData]);
      }else if(acc.field === "supplier_payable_account_id"){
        //set state for dropdown default values
        let suppPayable = coa3ForSearchSettings.find((coa3)=> coa3.id == acc.value);
        setSuppPayableAcc(suppPayable);
        //set savedata values for not empty on load
        saveData[12].value = suppPayable == "" ? "" : acc.value
        setSaveData([...saveData]);
      }
    })
    setLoading(false)
  } catch (error) {
    setLoading(false)
      console.log("error==>",error)
  }
}

// Helper
const setCoaL1 = (res) => {
    setCoa1(res.data[0]);
    setCoa1ForSearch(res.data[1]);
    setLoading(false)
  };

//get Coa level 1  // Priority ->
  const getCoaL1 = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-coa-level1";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setCoaL1(res);
    //   setDataToLocal(url, res);
    } catch (error) {
      setLoading(false)
        console.log("error==>",error)
        // if (offline(url)) {
        //   let parseRes = getDataFromLocal(url);
        //   setGl(parseRes);
        // }
    }
  };


  //helper
  const setPaginatedCoa1s = (res) => {
    setCoa1(res.data[0]);
    setCoa1ForSearch(res.data[1]);
    setDataPaginating(false);
  };

  //get paginated coa1
  const setPaginatedCoa1 = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-coa-level1?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedCoa1s(res);
    //   localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      setDataPaginating(false)
        console.log("error==>",error)
    //   if (!navigator.onLine && localStorage.getItem(url) != null) {
    //     let parseRes = JSON.parse(localStorage.getItem(url));
    //     setPaginatedSchemes(parseRes);
    //   }
    }
  };

  //Coa2 list
  const [coa2, setCoa2] = useState(null);
  const [coa2ForSearch, setCoa2ForSearch] = useState(null);

  // Helper
const setCoaL2 = (res) => {
    setCoa2(res.data[0]);
    setCoa2ForSearch(res.data[1]);
    setLoading(false)
    // console.log("check in ctx==>",res)
  };

//get Coa level 2  // Priority ->
  const getCoaL2 = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-coa-level2";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setCoaL2(res);
    //   setDataToLocal(url, res);
    } catch (error) {
      setLoading(false)
        console.log("error==>",error)
        // if (offline(url)) {
        //   let parseRes = getDataFromLocal(url);
        //   setGl(parseRes);
        // }
    }
  };

  //helper
  const setPaginatedCoa2s = (res) => {
    setCoa2(res.data[0]);
    setCoa2ForSearch(res.data[1]);
    setDataPaginating(false);
  };

  //get paginated coa2
  const setPaginatedCoa2 = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-coa-level2?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedCoa2s(res);
    //   localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      setDataPaginating(false)
        console.log("error==>",error)
    //   if (!navigator.onLine && localStorage.getItem(url) != null) {
    //     let parseRes = JSON.parse(localStorage.getItem(url));
    //     setPaginatedSchemes(parseRes);
    //   }
    }
  };

    //Coa3 list
    const [coa3, setCoa3] = useState(null);
    const [coa3ForSearch, setCoa3ForSearch] = useState(null);
  
    // Helper
  const setCoaL3 = (res) => {
      setCoa3(res.data[0]);
      setCoa3ForSearch(res.data[1]);
      setLoading(false)
      res.data[1] &&
      getModuleSetting(res.data[1])
    };
  
  //get Coa level 3  // Priority ->
    const getCoaL3 = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-coa-level3";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setCoaL3(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false)
          console.log("error==>",error)
          // if (offline(url)) {
          //   let parseRes = getDataFromLocal(url);
          //   setGl(parseRes);
          // }
      }
    };
  
    //helper
    const setPaginatedCoa3s = (res) => {
      setCoa3(res.data[0]);
      setCoa3ForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated coa3
    const setPaginatedCoa3 = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-coa-level3?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedCoa3s(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        setDataPaginating(false)
          console.log("error==>",error)
      //   if (!navigator.onLine && localStorage.getItem(url) != null) {
      //     let parseRes = JSON.parse(localStorage.getItem(url));
      //     setPaginatedSchemes(parseRes);
      //   }
      }
    };


    // //bank transaction accounts
    // const [bankAccounts,setBankAccounts]=useState(null);

    // const getTransactionAccount = async () => {
    //   setLoading(true);
    //   const url = BASE_URL + "/settings/get-bank-account";
    //   try {
    //     const res = await axios.get(url, {
    //       headers: { Authorization: `Bearer ${getCookie()}` },
    //     });
    //     setBankAccounts(res.data);
    //   //   setDataToLocal(url, res);
    //   } catch (error) {
    //       console.log("error==>",error)
    //       // if (offline(url)) {
    //       //   let parseRes = getDataFromLocal(url);
    //       //   setGl(parseRes);
    //       // }
    //   }
    // };

    //Bank payment list
    const [bankPaymentList, setBankPaymentList] = useState(null);
    const [bankPaymentListForSearch, setBankPaymentListForSearch] = useState(null);
  
    // Helper
  const setBPList = (res) => {
      setBankPaymentList(res.data[0]);
      setBankPaymentListForSearch(res.data[1]);
      setLoading(false)
    };
  
  //get bank payment list  // Priority ->
    const getBPList = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-bank-payment";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setBPList(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false)
          console.log("error==>",error)
          // if (offline(url)) {
          //   let parseRes = getDataFromLocal(url);
          //   setGl(parseRes);
          // }
      }
    };

    //helper
    const setPaginatedBPLists = (res) => {
      setBankPaymentList(res.data[0]);
      setBankPaymentListForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated BPList
    const setPaginatedBPList = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-bank-payment?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedBPLists(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        setDataPaginating(false)
          console.log("error==>",error)
      //   if (!navigator.onLine && localStorage.getItem(url) != null) {
      //     let parseRes = JSON.parse(localStorage.getItem(url));
      //     setPaginatedSchemes(parseRes);
      //   }
      }
    };

    //Cash payment list
    const [cashPaymentList, setCashPaymentList] = useState(null);
    const [cashPaymentListForSearch, setCashPaymentListForSearch] = useState(null);
  
    // Helper
    const setCPList = (res) => {
      setCashPaymentList(res.data[0]);
      setCashPaymentListForSearch(res.data[1]);
      setLoading(false)
    };
  
  //get cash payment list  // Priority ->
    const getCPList = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-cash-payment";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setCPList(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false)
          console.log("error==>",error)
          // if (offline(url)) {
          //   let parseRes = getDataFromLocal(url);
          //   setGl(parseRes);
          // }
      }
    };

    //helper
    const setPaginatedCPLists = (res) => {
      setCashPaymentList(res.data[0]);
      setCashPaymentListForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated CPList
    const setPaginatedCPList = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-cash-payment?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedCPLists(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        setDataPaginating(false)
          console.log("error==>",error)
      //   if (!navigator.onLine && localStorage.getItem(url) != null) {
      //     let parseRes = JSON.parse(localStorage.getItem(url));
      //     setPaginatedSchemes(parseRes);
      //   }
      }
    };

    //Journal voucher list
    const [journalVoucherList, setJournalVoucherList] = useState(null);
    const [journalVoucherListForSearch, setJournalVoucherListForSearch] = useState(null);
  
    // Helper
    const setJVList = (res) => {
      setJournalVoucherList(res.data[0]);
      setJournalVoucherListForSearch(res.data[1]);
      setLoading(false)
    };
  
  //get journal voucher list  // Priority ->
    const getJVList = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-journal-voucher";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setJVList(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false)
          console.log("error==>",error)
          // if (offline(url)) {
          //   let parseRes = getDataFromLocal(url);
          //   setGl(parseRes);
          // }
      }
    };

    //helper
    const setPaginatedJVLists = (res) => {
      setJournalVoucherList(res.data[0]);
      setJournalVoucherListForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated JVList
    const setPaginatedJVList = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-journal-voucher?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedJVLists(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
          console.log("error==>",error)
      //   if (!navigator.onLine && localStorage.getItem(url) != null) {
      //     let parseRes = JSON.parse(localStorage.getItem(url));
      //     setPaginatedSchemes(parseRes);
      //   }
      }
    };

    //Opening account list
    const [openingAccountList, setOpeningAccountList] = useState(null);
    const [openingAccountListForSearch, setOpeningAccountListForSearch] = useState(null);
  
    // Helper
    const setOAList = (res) => {
      setOpeningAccountList(res.data[0]);
      setOpeningAccountListForSearch(res.data[1]);
      setLoading(false)
    };
  
  //get opening account list  // Priority ->
    const getOAList = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-opening-account";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setOAList(res);
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false);
          console.log("error==>",error)
          // if (offline(url)) {
          //   let parseRes = getDataFromLocal(url);
          //   setGl(parseRes);
          // }
      }
    };

    //helper
    const setPaginatedOALists = (res) => {
      setOpeningAccountList(res.data[0]);
      setOpeningAccountListForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    //get paginated JVList
    const setPaginatedOAList = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-opening-account?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedOALists(res);
      //   localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
          console.log("error==>",error)
      //   if (!navigator.onLine && localStorage.getItem(url) != null) {
      //     let parseRes = JSON.parse(localStorage.getItem(url));
      //     setPaginatedSchemes(parseRes);
      //   }
      }
    };

  return (
    <FinanceContext.Provider
      value={{
        // common
        loading,
        setLoading,

        //coa1
        coa1, 
        setCoa1,
        coa1ForSearch,
        setCoa1ForSearch,
        getCoaL1,
        setPaginatedCoa1,

        //coa2
        coa2, 
        setCoa2,
        coa2ForSearch,
        setCoa2ForSearch,
        getCoaL2,
        setPaginatedCoa2,

        //coa3
        coa3, 
        setCoa3,
        coa3ForSearch,
        setCoa3ForSearch,
        getCoaL3,
        setPaginatedCoa3,

        //BPList
        bankPaymentList,
        setBankPaymentList,
        bankPaymentListForSearch,
        setBankPaymentListForSearch,
        getBPList,
        setPaginatedBPList,

        //CPList
        cashPaymentList,
        setCashPaymentList,
        cashPaymentListForSearch,
        setCashPaymentListForSearch,
        getCPList,
        setPaginatedCPList,

        //JVList
        journalVoucherList,
        setJournalVoucherList,
        journalVoucherListForSearch,
        setJournalVoucherListForSearch,
        getJVList,
        setPaginatedJVList,

        //OAList
        openingAccountList,
        setOpeningAccountList,
        openingAccountListForSearch,
        setOpeningAccountListForSearch,
        getOAList,
        setPaginatedOAList,

        //transaction account
        // bankAccounts,
        // setBankAccounts,

        //pagination
        dataPaginating,
        setDataPaginating,

        // account module
        saveData,
        setSaveData,
        cashAcc,
        setCashAcc,
        whtAcc,
        setWhtAcc,
        otherTaxAcc,
        setOtherTaxAcc,
        bankAcc,
        setBankAcc,
        invAcc,
        setInvAcc,
        revAcc,
        setRevAcc,
        cogs,
        setCogs,
        purchaseDis,
        setPurchaseDis,
        salesDis,
        setSalesDis,
        purchaseTax,
        setPurchaseTax,
        salesTax,
        setSalesTax,
        suppCashAcc,
        setSuppCashAcc,
        suppPayableAcc,
        setSuppPayableAcc,
      }}
    >
      {children}
    </FinanceContext.Provider>
  );
};

export { FinanceContext, FinanceProvider };
