import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAy1BC0AzmzAWDilv4qCC7C-PP1VlTSYuE",
  authDomain: "indolj-pos-e3881.firebaseapp.com",
  databaseURL: "https://indolj-pos-e3881-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "indolj-pos-e3881",
  storageBucket: "indolj-pos-e3881.firebasestorage.app",
  messagingSenderId: "862249935086",
  appId: "1:862249935086:web:95c6b1c28fc5fdfda153ff"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getDatabase(firebaseApp);

