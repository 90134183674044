import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SketchPicker } from "react-color";
import Switch from "react-switch";
import reactCSS from "reactcss";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const General = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    loading,
    setLoading,
    generalSettings,
    setGeneralSettings,
    dataPaginating,
  } = useContext(SettingsContext);
  const { orderTypeList } = useContext(RestaurantContext);

  // States hook here
  // tabs onchange state
  const [key, setKey] = useState('general_settings');
  
  //color picker
  let [colorPick, setColorPick] = useState({
    displayColorPicker: false,
    color: getSystemSettings(generalSettings, "type_background"),
  });

  //clock color picker
  let [colorPickClock, setColorPickClock] = useState({
    displayColorPickerClock: false,
    color: getSystemSettings(generalSettings, "type_clock"),
  });

  //color text
  let [colorTextPick, setColorTextPick] = useState({
    displayColorTextPicker: false,
    color: getSystemSettings(generalSettings, "type_color"),
  });

  //new item
  let [newSettings, setNewSettings] = useState({
    footerText: getSystemSettings(generalSettings, "type_footer"),
    printFooter: getSystemSettings(generalSettings, "type_print_footer"),
    printHeading: getSystemSettings(generalSettings, "type_print_heading"),
    preSettledBilledHeading: getSystemSettings(
      generalSettings,
      "pre_settled_billed_heading"
    ),
    tax_type_discount: getSystemSettings(generalSettings, "tax_type_discount"),
    service_charges_type: getSystemSettings(generalSettings, "service_charges_type"),
    print_logo: getSystemSettings(generalSettings, "print_logo"),
    print_sold_items: getSystemSettings(generalSettings, "print_sold_items"),
    view_expense: getSystemSettings(generalSettings, "view_expense"),
    delivery_charges_addition: getSystemSettings(generalSettings, "delivery_charges_addition"),
    print_orderParty: getSystemSettings(generalSettings, "print_orderParty"),
    print_heading: getSystemSettings(generalSettings, "print_heading"),
    print_bill_footer: getSystemSettings(generalSettings, "print_bill_footer"),
    print_branchPhone: getSystemSettings(generalSettings, "print_branchPhone"),
    print_branchAddress: getSystemSettings(generalSettings, "print_branchAddress"),
    print_siteName: getSystemSettings(generalSettings, "print_siteName"),
    print_branchName: getSystemSettings(generalSettings, "print_branchName"),
    print_payment_type: getSystemSettings(generalSettings, "print_paymentType"),
    view_cash_card: getSystemSettings(generalSettings, "view_cash_card"),
    siteName: getSystemSettings(generalSettings, "siteName"),
    address: getSystemSettings(generalSettings, "address"),
    // order_time: getSystemSettings(generalSettings, "order_time"),
    fgs_enable : getSystemSettings(generalSettings, "fgs_enable"),
    recipe_enable : getSystemSettings(generalSettings, "recipe_enable"),
    day_end_stock : getSystemSettings(generalSettings, "day_end_stock"),
    ideal_food_cost : getSystemSettings(generalSettings, "ideal_food_cost"),
    customer_validation : getSystemSettings(generalSettings, "customer_validation"),
    user_wise_integration: getSystemSettings(generalSettings, "user_wise_integration"),
    day_end_cash: getSystemSettings(generalSettings, "day_end_cash"),
    print_callcenter_kot: getSystemSettings(
      generalSettings,
      "print_callcenter_kot"
    ),
    phnNo: getSystemSettings(generalSettings, "phnNo"),
    online_order_status: getSystemSettings(generalSettings, "online_order_status"),
    waiter_order_type: getSystemSettings(generalSettings, "waiter_order_type") ? JSON.parse(getSystemSettings(generalSettings, "waiter_order_type")) : null,
    physical_stock: getSystemSettings(generalSettings, "physical_stock") === "1" ? 1 : 0,
    token_text: getSystemSettings(generalSettings, "token_text"),
    duplicate_print_text: getSystemSettings(generalSettings, "duplicate_print_text"),
    vat: getSystemSettings(generalSettings, "type_vat"),
    cgst: getSystemSettings(generalSettings, "cgst"),
    sgst: getSystemSettings(generalSettings, "sgst"),
    tax: getSystemSettings(generalSettings, "tax"),
    sDiscount: getSystemSettings(generalSettings, "sDiscount"),
    fgs_enable : getSystemSettings(generalSettings, "fgs_enable"),
    recipe_enable : getSystemSettings(generalSettings, "recipe_enable"),
    day_open : getSystemSettings(generalSettings, "day_open"),
    order_no : getSystemSettings(generalSettings, "order_no"),
    offers_lock_status : getSystemSettings(generalSettings, "offers_lock_status"),
    foodPanda_integration: getSystemSettings(generalSettings, "foodPanda_integration"),
    call_center_enable: getSystemSettings(generalSettings, "call_center_enable"),
    image: null,
    favicon: null,
    timezone: null,
    print_setting: 0,
    zreport_setting: 0,
    selectedTimeZone: getSystemSettings(generalSettings, "timezone"),
    print_pos_setting: getSystemSettings(generalSettings, "print_pos_setting"),
    delivery_charges_lock: getSystemSettings(
      generalSettings,
      "delivery_charges_lock"
    ),
    till_user: getSystemSettings(generalSettings, "till_user"),
    view_userOrder : getSystemSettings(generalSettings, "view_userOrder"),
    pos_discount: getSystemSettings(generalSettings, "pos_discount"),
    print_kitchen_bill:
      getSystemSettings(generalSettings, "print_kitchen_bill") === "1" ? 1 : 0,
    play_sound:
      getSystemSettings(generalSettings, "play_sound") === "1" ? 1 : 0,

    table_waiter:
      getSystemSettings(generalSettings, "table_waiter") === "1" ? 1 : 0,
    order_round_off:
      getSystemSettings(generalSettings, "order_round_off") === "1" ? 1 : 0,  
    direct_ip_print: getSystemSettings(generalSettings, "direct_ip_print") === "1" ? 1 : 0,  
    open_qty_box: getSystemSettings(generalSettings, "open_qty_box") === "1" ? 1 : 0,
    // onlyVat:
    //   getSystemSettings(generalSettings, "vat_system") === "igst"
    //     ? true
    //     : false,
    onlyVat:
      getSystemSettings(generalSettings, "vat_system") === "igst"
        ? 1
        : getSystemSettings(generalSettings, "vat_system") === "cgst"
        ? 2
        : 3,
  });

  //useEffect == componentDidMount()
  useEffect(() => {
    setColorPick({
      displayColorPicker: false,
      color: getSystemSettings(generalSettings, "type_background"),
    });

    setColorPickClock({
      displayColorPickerClock: false,
      color: getSystemSettings(generalSettings, "type_clock"),
    });

    setColorTextPick({
      displayColorTextPicker: false,
      color: getSystemSettings(generalSettings, "type_color"),
    });

    setNewSettings({
      footerText: getSystemSettings(generalSettings, "type_footer"),
      printFooter: getSystemSettings(generalSettings, "type_print_footer"),
      printHeading: getSystemSettings(generalSettings, "type_print_heading"),
      preSettledBilledHeading: getSystemSettings(
        generalSettings,
        "pre_settled_billed_heading"
      ),
      tax_type_discount: getSystemSettings(generalSettings, "tax_type_discount"),
      service_charges_type: getSystemSettings(generalSettings, "service_charges_type"),
      print_logo: getSystemSettings(generalSettings, "print_logo"),
      print_sold_items: getSystemSettings(generalSettings, "print_sold_items"),
      view_expense: getSystemSettings(generalSettings, "view_expense"),
      print_orderParty: getSystemSettings(generalSettings, "print_orderParty"),
      print_heading: getSystemSettings(generalSettings, "print_heading"),
      print_bill_footer: getSystemSettings(generalSettings, "print_bill_footer"),
      print_branchPhone: getSystemSettings(generalSettings, "print_branchPhone"),
      print_branchAddress: getSystemSettings(generalSettings, "print_branchAddress"),
      print_siteName: getSystemSettings(generalSettings, "print_siteName"),
      print_branchName: getSystemSettings(generalSettings, "print_branchName"),
      print_payment_type: getSystemSettings(generalSettings, "print_paymentType"),
      view_cash_card: getSystemSettings(generalSettings, "view_cash_card"),
      siteName: getSystemSettings(generalSettings, "siteName"),
      address: getSystemSettings(generalSettings, "address"),
      print_callcenter_kot: getSystemSettings(
        generalSettings,
        "print_callcenter_kot"
      ),
      // order_time: getSystemSettings(generalSettings, "order_time"),
      order_no : getSystemSettings(generalSettings, "order_no"),
      fgs_enable : getSystemSettings(generalSettings, "fgs_enable"),
      recipe_enable : getSystemSettings(generalSettings, "recipe_enable"),
      day_end_stock : getSystemSettings(generalSettings, "day_end_stock"),
      ideal_food_cost : getSystemSettings(generalSettings, "ideal_food_cost"),
      customer_validation : getSystemSettings(generalSettings, "customer_validation"),
      user_wise_integration: getSystemSettings(generalSettings, "user_wise_integration"),
      day_end_cash: getSystemSettings(generalSettings, "day_end_cash"),
      day_open : getSystemSettings(generalSettings, "day_open"),
      foodPanda_integration: getSystemSettings(generalSettings, "foodPanda_integration"),
      phnNo: getSystemSettings(generalSettings, "phnNo"),
      online_order_status: getSystemSettings(generalSettings, "online_order_status"),
      waiter_order_type: getSystemSettings(generalSettings, "waiter_order_type") ? JSON.parse(getSystemSettings(generalSettings, "waiter_order_type")) : null,
      physical_stock: getSystemSettings(generalSettings, "physical_stock") === "1" ? 1 : 0, 
      token_text: getSystemSettings(generalSettings, "token_text"),
      duplicate_print_text: getSystemSettings(generalSettings, "duplicate_print_text"),
      vat: getSystemSettings(generalSettings, "type_vat"),
      cgst: getSystemSettings(generalSettings, "cgst"),
      sgst: getSystemSettings(generalSettings, "sgst"),
      tax: getSystemSettings(generalSettings, "tax"),
      sDiscount: getSystemSettings(generalSettings, "sDiscount"),
      offers_lock_status : getSystemSettings(generalSettings, "offers_lock_status"),
      delivery_charges: getSystemSettings(generalSettings, "delivery_charges"),
      delivery_charges_lock: getSystemSettings(
        generalSettings,
        "delivery_charges_lock"
      ),
      call_center_enable: getSystemSettings(generalSettings, "call_center_enable"),
      till_user: getSystemSettings(generalSettings, "till_user"),
      view_userOrder : getSystemSettings(generalSettings, "view_userOrder"),
      image: null,
      favicon: null,
      timezone: null,
      print_setting: 0,
      selectedTimeZone: getSystemSettings(generalSettings, "timezone"),
      print_pos_setting: getSystemSettings(
        generalSettings,
        "print_pos_setting"
      ),
      party_commission: getSystemSettings(generalSettings, "party_commission"),
      tax_type: getSystemSettings(generalSettings, "tax_type"),
      payment_type_tax_calculation: getSystemSettings(
        generalSettings,
        "payment_type_tax_calculation"
      ),
      pos_discount: getSystemSettings(generalSettings, "pos_discount"),
      print_kitchen_bill:
        getSystemSettings(generalSettings, "print_kitchen_bill") === "1"
          ? 1
          : 0,
      play_sound:
        getSystemSettings(generalSettings, "play_sound") === "1" ? 1 : 0,

      table_waiter:
        getSystemSettings(generalSettings, "table_waiter") === "1" ? 1 : 0,
        
      order_round_off:
        getSystemSettings(generalSettings, "order_round_off") === "1" ? 1 : 0,
      direct_ip_print: getSystemSettings(generalSettings, "direct_ip_print") === "1" ? 1 : 0,
      open_qty_box: getSystemSettings(generalSettings, "open_qty_box") === "1" ? 1 : 0,  
      // onlyVat:
      //   getSystemSettings(generalSettings, "vat_system") === "igst"
      //     ? true
      //     : false,
      onlyVat:
        getSystemSettings(generalSettings, "vat_system") === "igst"
          ? 1
          : getSystemSettings(generalSettings, "vat_system") === "cgst"
          ? 2
          : 3,
    });
  }, [generalSettings]);


  //on change order time 
  const handleOrderTime = (e) =>{
    const newValue = parseInt(e.target.value);
    if (newValue && newValue >= 1) {
      setNewSettings({ ...newSettings, [e.target.name]: newValue?.toString() });
    }else{
      setNewSettings({ ...newSettings, [e.target.name]: "" });
    }
  }

  //on change input field
  const handleChange = (e) => {
    setNewSettings({ ...newSettings, [e.target.name]: e.target.value });
  };

  //set image hook
  const handleItemImage = (e) => {
    setNewSettings({
      ...newSettings,
      [e.target.name]: e.target.files[0],
    });
  };

  //favicon
  const handleItemFavicon = (e) => {
    setNewSettings({
      ...newSettings,
      [e.target.name]: e.target.files[0],
    });
  };

  //color picker's function
  const handleClick = () => {
    setColorPick({
      ...colorPick,
      displayColorPicker: !colorPick.displayColorPicker,
    });
  };

  const handleClose = () => {
    setColorPick({ ...colorPick, displayColorPicker: false });
  };

  const handleChangeColor = (color) => {
    setColorPick({ ...colorPick, color: color.hex });
  };

  //clock color picker's function
  const handleClickClock = () => {
    setColorPickClock({
      ...colorPickClock,
      displayColorPickerClock: !colorPickClock.displayColorPickerClock,
    });
  };

  const handleCloseClock = () => {
    setColorPickClock({ ...colorPickClock, displayColorPickerClock: false });
  };

  const handleChangeColorClock = (color) => {
    setColorPickClock({ ...colorPickClock, color: color.hex });
  };

  //text color
  const handleClickText = () => {
    setColorTextPick({
      ...colorTextPick,
      displayColorTextPicker: !colorTextPick.displayColorTextPicker,
    });
  };

  const handleCloseText = () => {
    setColorTextPick({ ...colorTextPick, displayColorTextPicker: false });
  };

  const handleChangeColorText = (color) => {
    setColorTextPick({ ...colorTextPick, color: color.hex });
  };

  const handlePosReceiptOption = (e) => {
    setNewSettings({
      ...newSettings,
      print_pos_setting: e.target.value,
    });
  };

  const handleDayEndOption = (e) => {
    setNewSettings({
      ...newSettings,
      day_end_stock: e.target.value,
    });
  };

  const handleCallCenterReceiptOption = (e) => {
    setNewSettings({
      ...newSettings,
      print_callcenter_kot: e.target.value,
    });
  };

  const handleDeliveryChargesSettingsOption = (e) => {
    setNewSettings({
      ...newSettings,
      delivery_charges_lock: e.target.value,
    })
  }

  const handleOfferStatus = (e) => {
    setNewSettings({
      ...newSettings,
      offers_lock_status : e.target.value,
    });
  };

  const handleOnlineOrderSettingsOption = (e) => {
    setNewSettings({
      ...newSettings,
      online_order_status: e.target.value,
    })
  }

  //waiter od type
  const handleWaiterOdType = (e) => {
    const value = parseInt(e.target.value);
    let updatedViewUserOrder = newSettings.waiter_order_type ? [...newSettings.waiter_order_type] : []; // Clone the current array
  
    // If the checkbox is checked, add the value to the array
    if (!updatedViewUserOrder.includes(value)) {
      updatedViewUserOrder.push(value);
    } else {
      // If unchecked, remove the value from the array
      updatedViewUserOrder = updatedViewUserOrder.filter((item) => item !== value);
    }
    setNewSettings((prevState) => ({
      ...prevState,
      waiter_order_type: updatedViewUserOrder?.length > 0 ? updatedViewUserOrder : null,
    }));
  } 

  const handleViewUserOrder = (e) => {
    setNewSettings({
      ...newSettings,
      view_userOrder: e.target.value,
    })
  }

  const handleTillUserSettingsOption = (e) => {
    setNewSettings({
      ...newSettings,
      till_user: e.target.value,
    })
  }

  const handlePartyCommissionOption = (e) => {
    setNewSettings({
      ...newSettings,
      party_commission: e.target.value,
    });
  };

  const handleTaxTypeOption = (e) => {
    setNewSettings({
      ...newSettings,
      tax_type: e.target.value,
    });
  };

  const handleTaxTypeDiscountOption = (e) => {
    setNewSettings({
      ...newSettings,
      tax_type_discount: e.target.value,
    });
  };

  const handlePaymentTypeTaxCalculation = (e) => {
    setNewSettings({
      ...newSettings,
      payment_type_tax_calculation: e.target.checked ? "1" : "0",
    });
  };

  // const handlePosEnableDiscount = (e) => {
  //     if(e.target.checked){
  //       setNewSettings({
  //         ...newSettings,
  //         pos_discount: "1"
  //       })

  //     }else{
  //       setNewSettings({
  //         ...newSettings,
  //         pos_discount: "0"
  //       })
  //     }
  // }
  
  //send req to server
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let formData = new FormData();
    formData.append("image", newSettings.image);
    formData.append("favicon", newSettings.favicon);
    formData.append("type_background", colorPick.color);
    formData.append("type_clock", colorPickClock.color);
    formData.append("type_color", colorTextPick.color);
    //formData.append("type_footer", newSettings.footerText);
    formData.append("type_footer", "Powered By Indolj || All Rights Reserved");
    formData.append("type_print_footer", newSettings.printFooter);
    formData.append("type_print_heading", newSettings.printHeading);
    formData.append(
      "pre_settled_billed_heading",
      newSettings.preSettledBilledHeading
    );
    formData.append("siteName", newSettings.siteName);
    formData.append("address", newSettings.address);
    formData.append("print_callcenter_kot", newSettings.print_callcenter_kot);
    formData.append("phnNo", newSettings.phnNo);
    formData.append("sDiscount", newSettings.sDiscount);
    formData.append("offers_lock_status", newSettings.offers_lock_status);
    formData.append("tax_type_discount", newSettings.tax_type_discount);
    formData.append("service_charges_type", newSettings.service_charges_type);
    formData.append("delivery_charges_lock", newSettings.delivery_charges_lock);
    formData.append("till_user", newSettings.till_user);
    formData.append("view_userOrder", newSettings.view_userOrder);
    formData.append("online_order_status", newSettings.online_order_status);
    formData.append("waiter_order_type", JSON.stringify(newSettings.waiter_order_type));
    formData.append("delivery_charges", newSettings.delivery_charges);
    formData.append("timezone", newSettings.timezone);
    formData.append("print_pos_setting", newSettings.print_pos_setting);
    formData.append("party_commission", newSettings.party_commission);
    formData.append("tax_type", newSettings.tax_type);
    formData.append("physical_stock", newSettings.physical_stock);
    formData.append("token_text", newSettings.token_text);
    formData.append("duplicate_print_text", newSettings.duplicate_print_text);
    // formData.append("order_time", newSettings.order_time);
    formData.append(
      "payment_type_tax_calculation",
      newSettings.payment_type_tax_calculation
    );
    formData.append("foodPanda_integration", newSettings.foodPanda_integration);
    formData.append("pos_discount", newSettings.pos_discount);
    formData.append("print_kitchen_bill", newSettings.print_kitchen_bill);
    formData.append("play_sound", newSettings.play_sound);
    formData.append("print_logo", newSettings.print_logo);
    formData.append("print_sold_items", newSettings.print_sold_items);
    formData.append("view_expense", newSettings.view_expense);
    formData.append("delivery_charges_addition", newSettings.delivery_charges_addition);
    formData.append("print_siteName", newSettings.print_siteName);
    formData.append("print_branchName", newSettings.print_branchName);
    formData.append("print_branchAddress", newSettings.print_branchAddress);
    formData.append("print_branchPhone", newSettings.print_branchPhone);
    formData.append("print_heading", newSettings.print_heading);
    formData.append("print_bill_footer", newSettings.print_bill_footer);
    formData.append("print_paymentType", newSettings.print_payment_type);
    formData.append("view_cash_card",newSettings.view_cash_card);
    formData.append("print_orderParty", newSettings.print_orderParty);
    formData.append("table_waiter", newSettings.table_waiter);
    formData.append("order_round_off", newSettings.order_round_off);
    formData.append("direct_ip_print", newSettings.direct_ip_print);
    formData.append("open_qty_box", newSettings.open_qty_box);
    formData.append("fgs_enable", newSettings.fgs_enable);
    formData.append("recipe_enable", newSettings.recipe_enable);
    formData.append("customer_validation", newSettings.customer_validation);
    formData.append("user_wise_integration", newSettings.user_wise_integration);
    formData.append("day_end_cash", newSettings.day_end_cash);
    formData.append("day_open", newSettings.day_open);
    formData.append("order_no", newSettings.order_no);
    formData.append("day_end_stock", newSettings.day_end_stock);
    formData.append("ideal_food_cost", newSettings.ideal_food_cost);
    formData.append("call_center_enable", newSettings.call_center_enable);
    formData.append(
      "vat_system",
      newSettings.onlyVat === 1
        ? "igst"
        : newSettings.onlyVat === 2
        ? "cgst"
        : "tax"
    );
    if (newSettings.onlyVat === 1) {
      formData.append("type_vat", newSettings.vat);
    } else if (newSettings.onlyVat === 2) {
      formData.append("cgst", newSettings.cgst);
      formData.append("sgst", newSettings.sgst);
    } else {
      formData.append("tax", newSettings.tax);
    }
    const url = BASE_URL + "/settings/general-settings";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setGeneralSettings(res.data);
        console.log("SettingsData", res.data);
        setNewSettings({
          footerText: getSystemSettings(res.data, "type_footer"),
          printFooter: getSystemSettings(res.data, "type_print_footer"),
          printHeading: getSystemSettings(
            res.data,
            "type_print_heading"
          ),
          preSettledBilledHeading: getSystemSettings(
            res.data,
            "pre_settled_billed_heading"
          ),
          tax_type_discount: getSystemSettings(res.data, "tax_type_discount"),
          service_charges_type: getSystemSettings(res.data, "service_charges_type"),
          print_logo: getSystemSettings(res.data, "print_logo"),
          print_sold_items: getSystemSettings(res.data, "print_sold_items"),
          print_orderParty: getSystemSettings(res.data, "print_orderParty"),
          print_heading: getSystemSettings(res.data, "print_heading"),
          print_bill_footer: getSystemSettings(res.data, "print_bill_footer"),
          print_branchPhone: getSystemSettings(res.data, "print_branchPhone"),
          print_branchAddress: getSystemSettings(res.data, "print_branchAddress"),
          print_siteName: getSystemSettings(res.data, "print_siteName"),
          print_branchName: getSystemSettings(res.data, "print_branchName"),
          print_payment_type: getSystemSettings(res.data, "print_paymentType"),
          view_cash_card: getSystemSettings(res.data, "view_cash_card"),
          siteName: getSystemSettings(res.data, "siteName"),
          view_expense: getSystemSettings(res.data, "view_expense"),
          offers_lock_status : getSystemSettings(res.data, "offers_lock_status"),
          token_text: getSystemSettings(res.data, "token_text"),
          address: getSystemSettings(res.data, "address"),
          // order_time: getSystemSettings(res.data, "order_time"),
          fgs_enable : getSystemSettings(res.data, "fgs_enable"),
          recipe_enable : getSystemSettings(res.data, "recipe_enable"),
          day_end_stock : getSystemSettings(res.data, "day_end_stock"),
          ideal_food_cost : getSystemSettings(res.data, "ideal_food_cost"),
          day_open : getSystemSettings(res.data, "day_open"),
          order_no : getSystemSettings(res.data, "order_no"),
          print_callcenter_kot: getSystemSettings(
            res.data,
            "print_callcenter_kot"
          ),
          foodPanda_integration: getSystemSettings(res.data, "foodPanda_integration"),
          physical_stock: getSystemSettings(res.data, "physical_stock"), 
          phnNo: getSystemSettings(res.data, "phnNo"),
          vat: getSystemSettings(res.data, "type_vat"),
          cgst: getSystemSettings(res.data, "cgst"),
          sgst: getSystemSettings(res.data, "sgst"),
          tax: getSystemSettings(res.data, "tax"),
          sDiscount: getSystemSettings(res.data, "sDiscount"),
          online_order_status: getSystemSettings(res.data, "online_order_status"),
          waiter_order_type: getSystemSettings(res.data, "waiter_order_type") ? JSON.parse(getSystemSettings(res.data, "waiter_order_type")) : null,
          till_user: getSystemSettings(res.data, "till_user"),
          view_userOrder : getSystemSettings(res.data, "view_userOrder"),
          delivery_charges: getSystemSettings(res.data, "delivery_charges"),
          delivery_charges_lock: getSystemSettings(
            res.data,
            "delivery_charges_lock"
          ),
          call_center_enable: getSystemSettings(generalSettings, "call_center_enable"),
          image: null,
          favicon: null,
          print_setting:0,
          selectedTimeZone: getSystemSettings(res.data, "timezone"),
          timezone: null,
          print_kitchen_bill:
            getSystemSettings(res.data, "print_kitchen_bill") === "1" ? 1 : 0,
          play_sound: getSystemSettings(res.data, "play_sound") === "1" ? 1 : 0,
          table_waiter:
            getSystemSettings(res.data, "table_waiter") === "1" ? 1 : 0,
          order_round_off:  getSystemSettings(res.data, "order_round_off") === "1" ? 1 : 0,
          direct_ip_print: getSystemSettings(res.data, "direct_ip_print") === "1" ? 1 : 0, 
          open_qty_box : getSystemSettings(res.data, "open_qty_box") === "1 " ? 1 : 0, 
          onlyVat:
            getSystemSettings(generalSettings, "vat_system") === "igst"
              ? 1
              : getSystemSettings(generalSettings, "vat_system") === "cgst"
              ? 2
              : 3,
        });
        setLoading(false);
        toast.success(`${_t(t("Settings has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //color picker css
  const styles = reactCSS({
    default: {
      color: {
        height: "24px",
        borderRadius: "2px",
        background: colorPick.color,
      },
      swatch: {
        background: "#fff",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  //clock color picker css
  const stylesClock = reactCSS({
    default: {
      color: {
        height: "24px",
        borderRadius: "2px",
        background: colorPickClock.color,
      },
      swatch: {
        background: "#fff",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  //text color picker css
  const stylesText = reactCSS({
    default: {
      color: {
        height: "24px",
        borderRadius: "2px",
        background: colorTextPick.color,
      },
      swatch: {
        background: "#fff",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <>
      <Helmet>
        <title>{_t(t("General Settings"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("General Settings"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>

                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 sm-text my-2"
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                          >
                            <Tab eventKey="general_settings" title="General Settings">                                                          
                              <div className="col-12">
                                <div className="settings_headings">
                                  Site Setting
                                </div>
                                <div className="row mt-1">
                                  <div className="form-group col-3">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="siteName"
                                        className="control-label"
                                      >
                                        {_t(t("Site's name"))}
                                      </label>
                                    </div>
                                    <div className="mb-2">
                                      <input
                                        type="text"
                                        className="form-control sm-text"
                                        id="siteName"
                                        name="siteName"
                                        onChange={handleChange}
                                        value={newSettings.siteName}
                                        placeholder="e.g. indolj Restaurant"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group col-6">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="address"
                                        className="control-label"
                                      >
                                        {_t(t("Address"))}
                                      </label>
                                    </div>
                                    <div className="mb-2">
                                      <input
                                        type="text"
                                        className="form-control sm-text"
                                        id="address"
                                        name="address"
                                        onChange={handleChange}
                                        value={newSettings.address}
                                        placeholder="e.g. Type address"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group col-3">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="phnNo"
                                        className="control-label"
                                      >
                                        {_t(t("Phone number"))}
                                      </label>
                                    </div>
                                    <div className="mb-2">
                                      <input
                                        type="text"
                                        className="form-control sm-text"
                                        id="phnNo"
                                        name="phnNo"
                                        onChange={handleChange}
                                        value={newSettings.phnNo}
                                        placeholder="e.g. +8801xxx"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group mt-3">
                                  <div className="mb-2">
                                    <label className="control-label">
                                      {_t(t("Background of logo"))}...
                                      <span className="text-danger">*</span>
                                      <span className="text-secondary ml-1">
                                        ({" "}
                                        {_t(
                                          t(
                                            "Please pick a color, click on the color below"
                                          )
                                        )}
                                        )
                                      </span>
                                    </label>
                                  </div>
                                  <div className="row">
                                    <div
                                      style={styles.swatch}
                                      onClick={handleClick}
                                      className="rounded-md col-12 col-md-3"
                                    >
                                      <div
                                        style={styles.color}
                                        className="form-control"
                                      />
                                    </div>
                                    {colorPick.displayColorPicker && (
                                      <div style={styles.popover}>
                                        <div
                                          style={styles.cover}
                                          onClick={handleClose}
                                        />
                                        <SketchPicker
                                          color={colorPick.color}
                                          onChange={handleChangeColor}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="form-group mt-3">
                                  <div className="mb-2">
                                    <label className="control-label">
                                      {_t(t("Background of footer clock"))}...
                                      <span className="text-danger">*</span>
                                      <span className="text-secondary ml-1">
                                        ({" "}
                                        {_t(
                                          t(
                                            "Please pick a color, click on the color below"
                                          )
                                        )}
                                        )
                                      </span>
                                    </label>
                                  </div>
                                  <div className="row">
                                    <div
                                      style={stylesClock.swatch}
                                      onClick={handleClickClock}
                                      className="rounded-md col-12 col-md-3"
                                    >
                                      <div
                                        style={stylesClock.color}
                                        className="form-control"
                                      />
                                    </div>
                                    {colorPickClock.displayColorPickerClock && (
                                      <div style={stylesClock.popover}>
                                        <div
                                          style={stylesClock.cover}
                                          onClick={handleCloseClock}
                                        />
                                        <SketchPicker
                                          color={colorPickClock.color}
                                          onChange={handleChangeColorClock}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="form-group mt-3">
                                  <div className="mb-2">
                                    <label className="control-label">
                                      {_t(t("Text color of currency, clock"))}...
                                      <span className="text-danger">*</span>
                                      <span className="text-secondary ml-1">
                                        ({" "}
                                        {_t(
                                          t(
                                            "Please pick a color, click on the color below"
                                          )
                                        )}
                                        )
                                      </span>
                                    </label>
                                  </div>
                                  <div className="row">
                                    <div
                                      style={stylesText.swatch}
                                      onClick={handleClickText}
                                      className="rounded-md col-12 col-md-3"
                                    >
                                      <div
                                        style={stylesText.color}
                                        className="form-control"
                                      />
                                    </div>
                                    {colorTextPick.displayColorTextPicker && (
                                      <div style={stylesText.popover}>
                                        <div
                                          style={stylesText.cover}
                                          onClick={handleCloseText}
                                        />
                                        <SketchPicker
                                          color={colorTextPick.color}
                                          onChange={handleChangeColorText}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="form-group mt-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="image"
                                      className="control-label"
                                    >
                                      {_t(t("Logo"))}
                                      <small className="text-secondary ml-1">
                                        (
                                        {_t(
                                          t(
                                            "Background color will be hidden/shown depending on width of this image"
                                          )
                                        )}
                                        )
                                      </small>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="file"
                                      className="form-control sm-text"
                                      id="image"
                                      name="image"
                                      onChange={handleItemImage}
                                    />
                                  </div>
                                </div>

                                <div className="form-group mt-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="favicon"
                                      className="control-label"
                                    >
                                      {_t(t("Favicon"))}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="file"
                                      className="form-control sm-text"
                                      id="favicon"
                                      name="favicon"
                                      onChange={handleItemFavicon}
                                    />
                                  </div>
                                </div>

                                <div className="settings_headings">
                                 Service Charge and Tax Configuration
                                </div>
                                <div className="form-group mt-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="discount"
                                      className="control-label"
                                    >
                                      {_t(t("Vat System"))}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <select
                                      className="form-control sm-text"
                                      onChange={(e) => {
                                        if (e.target.value === "igst") {
                                          // setNewSettings({
                                          //   ...newSettings,
                                          //   onlyVat: false,
                                          // });
                                          setNewSettings({
                                            ...newSettings,
                                            onlyVat: 1,
                                          });
                                        } else if (e.target.value === "cgst") {
                                          setNewSettings({
                                            ...newSettings,
                                            onlyVat: 2,
                                          });
                                        } else {
                                          setNewSettings({
                                            ...newSettings,
                                            onlyVat: 3,
                                          });
                                        }
                                      }}
                                    >
                                      <option
                                        value="igst"
                                        defaultValue={newSettings.onlyVat === 1}
                                      >
                                        {_t(t("Vat"))}
                                      </option>
                                      <option
                                        value="cgst"
                                        defaultValue={newSettings.onlyVat === 2}
                                      >
                                        {_t(t("cgst + sgst"))}
                                      </option>
                                      <option
                                        value="tax"
                                        defaultValue={newSettings.onlyVat === 3}
                                      >
                                        {_t(t("Tax"))}
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                {newSettings.onlyVat === 1 ? (
                                  <div className="form-group mt-0 ml-3 alert alert-info">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="vat"
                                        className="control-label"
                                      >
                                        {_t(t("VAT"))} (%)
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                    </div>
                                    <div className="mb-2">
                                      <input
                                        type="number"
                                        step="0.01"
                                        onKeyDown={(evt) =>
                                          ["e", "E", "+", "-"].includes(evt.key) &&
                                          evt.preventDefault()
                                        }
                                        min="0"
                                        className="form-control sm-text"
                                        id="vat"
                                        name="vat"
                                        onChange={handleChange}
                                        value={newSettings.vat}
                                        placeholder="e.g. Type vat %"
                                        required
                                      />
                                    </div>
                                  </div>
                                ) : newSettings.onlyVat === 2 ? (
                                  <div className="alert ml-3 alert-info">
                                    <div className="form-group mt-2">
                                      <div className="mb-2">
                                        <label
                                          htmlFor="cgst"
                                          className="control-label"
                                        >
                                          {_t(t("CGST"))} (%)
                                          <span className="text-danger">
                                            *
                                          </span>{" "}
                                        </label>
                                      </div>
                                      <div className="mb-2">
                                        <input
                                          type="number"
                                          step="0.01"
                                          onKeyDown={(evt) =>
                                            ["e", "E", "+", "-"].includes(evt.key) &&
                                            evt.preventDefault()
                                          }
                                          min="0"
                                          className="form-control sm-text"
                                          id="cgst"
                                          name="cgst"
                                          onChange={handleChange}
                                          value={newSettings.cgst}
                                          placeholder="e.g. Type cgst %"
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group mt-2">
                                      <div className="mb-2">
                                        <label
                                          htmlFor="sgst"
                                          className="control-label"
                                        >
                                          {_t(t("SGST"))} (%)
                                          <span className="text-danger">
                                            *
                                          </span>{" "}
                                        </label>
                                      </div>
                                      <div className="mb-2">
                                        <input
                                          type="number"
                                          step="0.01"
                                          onKeyDown={(evt) =>
                                            ["e", "E", "+", "-"].includes(evt.key) &&
                                            evt.preventDefault()
                                          }
                                          min="0"
                                          className="form-control sm-text"
                                          id="sgst"
                                          name="sgst"
                                          onChange={handleChange}
                                          value={newSettings.sgst}
                                          placeholder="e.g. Type sgst %"
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="form-group mt-0 ml-3 alert alert-info">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="tax"
                                        className="control-label"
                                      >
                                        {_t(t("Tax"))} (%)
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                    </div>
                                    <div className="mb-2">
                                      <input
                                        type="number"
                                        step="0.01"
                                        onKeyDown={(evt) =>
                                          ["e", "E", "+", "-"].includes(evt.key) &&
                                          evt.preventDefault()
                                        }
                                        min="0"
                                        className="form-control sm-text"
                                        id="tax"
                                        name="tax"
                                        onChange={handleChange}
                                        value={newSettings.tax}
                                        placeholder="e.g. Type tax %"
                                        required
                                      />
                                    </div>
                                  </div>
                                )}

                                {/*Enable Discount  */}
                                {/* <div className="form-check mt-5">
                                  <div className="mb-2">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="enable_discount"
                                      name="pos_discount"
                                      value="1"
                                      onChange={ (e) => { handlePosEnableDiscount(e) } }
                                      checked={
                                        (newSettings.pos_discount === '1') ? 1 : 0
                                      }
                                    />
                                    <label
                                      htmlFor="enable_discount"
                                      className="control-label"
                                    >
                                      {_t(t("Enable Discount"))}
                                    </label>
                                  </div>
                                </div> */}
                                <div className="form-group mt-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="discount"
                                      className="control-label"
                                    >
                                      {_t(t("Service Charge & Discount type"))}{" "}
                                      <span className="text-secondary">
                                        (Selected:{" "}
                                        {getSystemSettings(
                                          generalSettings,
                                          "sDiscount"
                                        )}
                                        )
                                      </span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <select
                                      className="form-control table-text"
                                      onChange={(e) => {
                                        setNewSettings({
                                          ...newSettings,
                                          sDiscount: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" className="py-3">
                                        Please select a type
                                      </option>
                                      <option value="percentage">
                                        Percentage (%)
                                      </option>
                                      <option value="flat">Flat (cash)</option>
                                    </select>
                                  </div>
                                </div>
                                
                                {/*tax type: before tax after tax */}
                                <div className="row">
                                  <div className="form-radio mt-4 col-6">
                                    <div className="mb-2">
                                      <label className="control-label">
                                        {_t(t("Tax Type"))}
                                      </label>
                                    </div>

                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="tax_type_inclusive"
                                        name="tax_type_setting"
                                        value="inclusive"
                                        onChange={(e) => {
                                          handleTaxTypeOption(e);
                                        }}
                                        checked={
                                          newSettings.tax_type === "inclusive" ? 1 : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="tax_type_inclusive"
                                      >
                                        {_t(t("Inclusive"))}
                                      </label>
                                    </div>

                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="tax_type_exclusive"
                                        name="tax_type_setting"
                                        value="exclusive"
                                        onChange={(e) => {
                                          handleTaxTypeOption(e);
                                        }}
                                        checked={
                                          newSettings.tax_type === "exclusive" ? 1 : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="tax_type_exclusive"
                                      >
                                        {_t(t("Exclusive"))}
                                      </label>
                                    </div>
                                  </div>

                                  {/*tax type: before tax after tax discount*/}
                                  <div className="form-radio mt-4 col-6">
                                  <div className="mb-2">
                                    <label className="control-label">
                                      {_t(t("Tax Type Discount"))}
                                    </label>
                                  </div>

                                  <div className="d-inline mr-4">
                                    <input
                                      type="radio"
                                      className="form-check-input pointer-cursor"
                                      id="tax_discount_before"
                                      name="tax_type_discount"
                                      value="tax_discount_before"
                                      onChange={(e) => {
                                        handleTaxTypeDiscountOption(e);
                                      }}
                                      checked={
                                        newSettings.tax_type_discount === "tax_discount_before" ? 1 : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="tax_discount_before"
                                    >
                                      {_t(t("Discount Before Tax"))}
                                    </label>
                                  </div>

                                  <div className="d-inline mr-4">
                                    <input
                                      type="radio"
                                      className="form-check-input pointer-cursor"
                                      id="tax_discount_after"
                                      name="tax_type_discount"
                                      value="tax_discount_after"
                                      onChange={(e) => {
                                        handleTaxTypeDiscountOption(e);
                                      }}
                                      checked={
                                        newSettings.tax_type_discount === "tax_discount_after" ? 1 : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="tax_discount_after"
                                    >
                                      {_t(t("Discount After Tax"))}
                                    </label>
                                  </div>
                                  </div>
                                </div>

                                {/*service type: before tax after tax*/}
                                <div className="form-radio mt-4">
                                <div className="mb-2">
                                  <label className="control-label">
                                    {_t(t("Service Charges Type"))}
                                  </label>
                                </div>

                                <div className="d-inline mr-4">
                                  <input
                                    type="radio"
                                    className="form-check-input pointer-cursor"
                                    id="sc_before"
                                    name="service_charges_type"
                                    value="1"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    checked={
                                      parseInt(newSettings.service_charges_type) === 1 ? 1 : 0
                                    }
                                  />
                                  <label
                                    className="form-radio-label pointer-cursor ml-2"
                                    htmlFor="sc_before"
                                  >
                                    {_t(t("Service Charges Before Tax"))}
                                  </label>
                                </div>

                                  <div className="d-inline mr-4">
                                    <input
                                      type="radio"
                                      className="form-check-input pointer-cursor"
                                      id="sc_after"
                                      name="service_charges_type"
                                      value="0"
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      checked={
                                        parseInt(newSettings.service_charges_type) === 0 ? 1 : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="sc_after"
                                    >
                                      {_t(t("Service Charges After Tax"))}
                                    </label>
                                   </div>
                                </div>

                                {/* payment type wise calculation */}
                                <div className="form-radio mt-4">
                                  <div className="d-inline mr-4">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="payment_type_tax_calculation"
                                      name="payment_type_tax_calculation"
                                      value="1"
                                      onChange={(e) => {
                                        handlePaymentTypeTaxCalculation(e);
                                      }}
                                      checked={
                                        newSettings.payment_type_tax_calculation ===
                                        "1"
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="payment_type_tax_calculation"
                                    >
                                      {_t(
                                        t(
                                          "View Tax Calculation on different Payment Type"
                                        )
                                      )}
                                    </label>
                                  </div>
                                </div>                                                                                                                                          

                                <div className="form-group mt-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="Timezone"
                                      className="control-label"
                                    >
                                      {_t(t("Timezone"))}{" "}
                                      <span className="text-secondary">
                                        (Selected: {newSettings.selectedTimeZone})
                                      </span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <select
                                      className="form-control table-text"
                                      onChange={(e) => {
                                        setNewSettings({
                                          ...newSettings,
                                          timezone: e.target.value,
                                        });
                                      }}
                                    >
                                      <optgroup label="Please select a timezone">
                                        <option value="" className="py-3">
                                          Please select a timezone
                                        </option>
                                      </optgroup>
                                      <optgroup label="General">
                                        <option value="GMT">GMT timezone</option>
                                        <option value="UTC">UTC timezone</option>
                                      </optgroup>
                                      <optgroup label="Africa">
                                        <option value="Africa/Abidjan">
                                          (GMT/UTC + 00:00) Abidjan
                                        </option>
                                        <option value="Africa/Accra">
                                          (GMT/UTC + 00:00) Accra
                                        </option>
                                        <option value="Africa/Addis_Ababa">
                                          (GMT/UTC + 03:00) Addis Ababa
                                        </option>
                                        <option value="Africa/Algiers">
                                          (GMT/UTC + 01:00) Algiers
                                        </option>
                                        <option value="Africa/Asmara">
                                          (GMT/UTC + 03:00) Asmara
                                        </option>
                                        <option value="Africa/Bamako">
                                          (GMT/UTC + 00:00) Bamako
                                        </option>
                                        <option value="Africa/Bangui">
                                          (GMT/UTC + 01:00) Bangui
                                        </option>
                                        <option value="Africa/Banjul">
                                          (GMT/UTC + 00:00) Banjul
                                        </option>
                                        <option value="Africa/Bissau">
                                          (GMT/UTC + 00:00) Bissau
                                        </option>
                                        <option value="Africa/Blantyre">
                                          (GMT/UTC + 02:00) Blantyre
                                        </option>
                                        <option value="Africa/Brazzaville">
                                          (GMT/UTC + 01:00) Brazzaville
                                        </option>
                                        <option value="Africa/Bujumbura">
                                          (GMT/UTC + 02:00) Bujumbura
                                        </option>
                                        <option value="Africa/Cairo">
                                          (GMT/UTC + 02:00) Cairo
                                        </option>
                                        <option value="Africa/Casablanca">
                                          (GMT/UTC + 00:00) Casablanca
                                        </option>
                                        <option value="Africa/Ceuta">
                                          (GMT/UTC + 01:00) Ceuta
                                        </option>
                                        <option value="Africa/Conakry">
                                          (GMT/UTC + 00:00) Conakry
                                        </option>
                                        <option value="Africa/Dakar">
                                          (GMT/UTC + 00:00) Dakar
                                        </option>
                                        <option value="Africa/Dar_es_Salaam">
                                          (GMT/UTC + 03:00) Dar es Salaam
                                        </option>
                                        <option value="Africa/Djibouti">
                                          (GMT/UTC + 03:00) Djibouti
                                        </option>
                                        <option value="Africa/Douala">
                                          (GMT/UTC + 01:00) Douala
                                        </option>
                                        <option value="Africa/El_Aaiun">
                                          (GMT/UTC + 00:00) El Aaiun
                                        </option>
                                        <option value="Africa/Freetown">
                                          (GMT/UTC + 00:00) Freetown
                                        </option>
                                        <option value="Africa/Gaborone">
                                          (GMT/UTC + 02:00) Gaborone
                                        </option>
                                        <option value="Africa/Harare">
                                          (GMT/UTC + 02:00) Harare
                                        </option>
                                        <option value="Africa/Johannesburg">
                                          (GMT/UTC + 02:00) Johannesburg
                                        </option>
                                        <option value="Africa/Juba">
                                          (GMT/UTC + 03:00) Juba
                                        </option>
                                        <option value="Africa/Kampala">
                                          (GMT/UTC + 03:00) Kampala
                                        </option>
                                        <option value="Africa/Khartoum">
                                          (GMT/UTC + 03:00) Khartoum
                                        </option>
                                        <option value="Africa/Kigali">
                                          (GMT/UTC + 02:00) Kigali
                                        </option>
                                        <option value="Africa/Kinshasa">
                                          (GMT/UTC + 01:00) Kinshasa
                                        </option>
                                        <option value="Africa/Lagos">
                                          (GMT/UTC + 01:00) Lagos
                                        </option>
                                        <option value="Africa/Libreville">
                                          (GMT/UTC + 01:00) Libreville
                                        </option>
                                        <option value="Africa/Lome">
                                          (GMT/UTC + 00:00) Lome
                                        </option>
                                        <option value="Africa/Luanda">
                                          (GMT/UTC + 01:00) Luanda
                                        </option>
                                        <option value="Africa/Lubumbashi">
                                          (GMT/UTC + 02:00) Lubumbashi
                                        </option>
                                        <option value="Africa/Lusaka">
                                          (GMT/UTC + 02:00) Lusaka
                                        </option>
                                        <option value="Africa/Malabo">
                                          (GMT/UTC + 01:00) Malabo
                                        </option>
                                        <option value="Africa/Maputo">
                                          (GMT/UTC + 02:00) Maputo
                                        </option>
                                        <option value="Africa/Maseru">
                                          (GMT/UTC + 02:00) Maseru
                                        </option>
                                        <option value="Africa/Mbabane">
                                          (GMT/UTC + 02:00) Mbabane
                                        </option>
                                        <option value="Africa/Mogadishu">
                                          (GMT/UTC + 03:00) Mogadishu
                                        </option>
                                        <option value="Africa/Monrovia">
                                          (GMT/UTC + 00:00) Monrovia
                                        </option>
                                        <option value="Africa/Nairobi">
                                          (GMT/UTC + 03:00) Nairobi
                                        </option>
                                        <option value="Africa/Ndjamena">
                                          (GMT/UTC + 01:00) Ndjamena
                                        </option>
                                        <option value="Africa/Niamey">
                                          (GMT/UTC + 01:00) Niamey
                                        </option>
                                        <option value="Africa/Nouakchott">
                                          (GMT/UTC + 00:00) Nouakchott
                                        </option>
                                        <option value="Africa/Ouagadougou">
                                          (GMT/UTC + 00:00) Ouagadougou
                                        </option>
                                        <option value="Africa/Porto-Novo">
                                          (GMT/UTC + 01:00) Porto-Novo
                                        </option>
                                        <option value="Africa/Sao_Tome">
                                          (GMT/UTC + 00:00) Sao Tome
                                        </option>
                                        <option value="Africa/Tripoli">
                                          (GMT/UTC + 02:00) Tripoli
                                        </option>
                                        <option value="Africa/Tunis">
                                          (GMT/UTC + 01:00) Tunis
                                        </option>
                                        <option value="Africa/Windhoek">
                                          (GMT/UTC + 02:00) Windhoek
                                        </option>
                                      </optgroup>
                                      <optgroup label="America">
                                        <option value="America/Adak">
                                          (GMT/UTC - 10:00) Adak
                                        </option>
                                        <option value="America/Anchorage">
                                          (GMT/UTC - 09:00) Anchorage
                                        </option>
                                        <option value="America/Anguilla">
                                          (GMT/UTC - 04:00) Anguilla
                                        </option>
                                        <option value="America/Antigua">
                                          (GMT/UTC - 04:00) Antigua
                                        </option>
                                        <option value="America/Araguaina">
                                          (GMT/UTC - 03:00) Araguaina
                                        </option>
                                        <option value="America/Argentina/Buenos_Aires">
                                          (GMT/UTC - 03:00) Argentina/Buenos Aires
                                        </option>
                                        <option value="America/Argentina/Catamarca">
                                          (GMT/UTC - 03:00) Argentina/Catamarca
                                        </option>
                                        <option value="America/Argentina/Cordoba">
                                          (GMT/UTC - 03:00) Argentina/Cordoba
                                        </option>
                                        <option value="America/Argentina/Jujuy">
                                          (GMT/UTC - 03:00) Argentina/Jujuy
                                        </option>
                                        <option value="America/Argentina/La_Rioja">
                                          (GMT/UTC - 03:00) Argentina/La Rioja
                                        </option>
                                        <option value="America/Argentina/Mendoza">
                                          (GMT/UTC - 03:00) Argentina/Mendoza
                                        </option>
                                        <option value="America/Argentina/Rio_Gallegos">
                                          (GMT/UTC - 03:00) Argentina/Rio Gallegos
                                        </option>
                                        <option value="America/Argentina/Salta">
                                          (GMT/UTC - 03:00) Argentina/Salta
                                        </option>
                                        <option value="America/Argentina/San_Juan">
                                          (GMT/UTC - 03:00) Argentina/San Juan
                                        </option>
                                        <option value="America/Argentina/San_Luis">
                                          (GMT/UTC - 03:00) Argentina/San Luis
                                        </option>
                                        <option value="America/Argentina/Tucuman">
                                          (GMT/UTC - 03:00) Argentina/Tucuman
                                        </option>
                                        <option value="America/Argentina/Ushuaia">
                                          (GMT/UTC - 03:00) Argentina/Ushuaia
                                        </option>
                                        <option value="America/Aruba">
                                          (GMT/UTC - 04:00) Aruba
                                        </option>
                                        <option value="America/Asuncion">
                                          (GMT/UTC - 03:00) Asuncion
                                        </option>
                                        <option value="America/Atikokan">
                                          (GMT/UTC - 05:00) Atikokan
                                        </option>
                                        <option value="America/Bahia">
                                          (GMT/UTC - 03:00) Bahia
                                        </option>
                                        <option value="America/Bahia_Banderas">
                                          (GMT/UTC - 06:00) Bahia Banderas
                                        </option>
                                        <option value="America/Barbados">
                                          (GMT/UTC - 04:00) Barbados
                                        </option>
                                        <option value="America/Belem">
                                          (GMT/UTC - 03:00) Belem
                                        </option>
                                        <option value="America/Belize">
                                          (GMT/UTC - 06:00) Belize
                                        </option>
                                        <option value="America/Blanc-Sablon">
                                          (GMT/UTC - 04:00) Blanc-Sablon
                                        </option>
                                        <option value="America/Boa_Vista">
                                          (GMT/UTC - 04:00) Boa Vista
                                        </option>
                                        <option value="America/Bogota">
                                          (GMT/UTC - 05:00) Bogota
                                        </option>
                                        <option value="America/Boise">
                                          (GMT/UTC - 07:00) Boise
                                        </option>
                                        <option value="America/Cambridge_Bay">
                                          (GMT/UTC - 07:00) Cambridge Bay
                                        </option>
                                        <option value="America/Campo_Grande">
                                          (GMT/UTC - 03:00) Campo Grande
                                        </option>
                                        <option value="America/Cancun">
                                          (GMT/UTC - 05:00) Cancun
                                        </option>
                                        <option value="America/Caracas">
                                          (GMT/UTC - 04:30) Caracas
                                        </option>
                                        <option value="America/Cayenne">
                                          (GMT/UTC - 03:00) Cayenne
                                        </option>
                                        <option value="America/Cayman">
                                          (GMT/UTC - 05:00) Cayman
                                        </option>
                                        <option value="America/Chicago">
                                          (GMT/UTC - 06:00) Chicago
                                        </option>
                                        <option value="America/Chihuahua">
                                          (GMT/UTC - 07:00) Chihuahua
                                        </option>
                                        <option value="America/Costa_Rica">
                                          (GMT/UTC - 06:00) Costa Rica
                                        </option>
                                        <option value="America/Creston">
                                          (GMT/UTC - 07:00) Creston
                                        </option>
                                        <option value="America/Cuiaba">
                                          (GMT/UTC - 03:00) Cuiaba
                                        </option>
                                        <option value="America/Curacao">
                                          (GMT/UTC - 04:00) Curacao
                                        </option>
                                        <option value="America/Danmarkshavn">
                                          (GMT/UTC + 00:00) Danmarkshavn
                                        </option>
                                        <option value="America/Dawson">
                                          (GMT/UTC - 08:00) Dawson
                                        </option>
                                        <option value="America/Dawson_Creek">
                                          (GMT/UTC - 07:00) Dawson Creek
                                        </option>
                                        <option value="America/Denver">
                                          (GMT/UTC - 07:00) Denver
                                        </option>
                                        <option value="America/Detroit">
                                          (GMT/UTC - 05:00) Detroit
                                        </option>
                                        <option value="America/Dominica">
                                          (GMT/UTC - 04:00) Dominica
                                        </option>
                                        <option value="America/Edmonton">
                                          (GMT/UTC - 07:00) Edmonton
                                        </option>
                                        <option value="America/Eirunepe">
                                          (GMT/UTC - 05:00) Eirunepe
                                        </option>
                                        <option value="America/El_Salvador">
                                          (GMT/UTC - 06:00) El Salvador
                                        </option>
                                        <option value="America/Fort_Nelson">
                                          (GMT/UTC - 07:00) Fort Nelson
                                        </option>
                                        <option value="America/Fortaleza">
                                          (GMT/UTC - 03:00) Fortaleza
                                        </option>
                                        <option value="America/Glace_Bay">
                                          (GMT/UTC - 04:00) Glace Bay
                                        </option>
                                        <option value="America/Godthab">
                                          (GMT/UTC - 03:00) Godthab
                                        </option>
                                        <option value="America/Goose_Bay">
                                          (GMT/UTC - 04:00) Goose Bay
                                        </option>
                                        <option value="America/Grand_Turk">
                                          (GMT/UTC - 04:00) Grand Turk
                                        </option>
                                        <option value="America/Grenada">
                                          (GMT/UTC - 04:00) Grenada
                                        </option>
                                        <option value="America/Guadeloupe">
                                          (GMT/UTC - 04:00) Guadeloupe
                                        </option>
                                        <option value="America/Guatemala">
                                          (GMT/UTC - 06:00) Guatemala
                                        </option>
                                        <option value="America/Guayaquil">
                                          (GMT/UTC - 05:00) Guayaquil
                                        </option>
                                        <option value="America/Guyana">
                                          (GMT/UTC - 04:00) Guyana
                                        </option>
                                        <option value="America/Halifax">
                                          (GMT/UTC - 04:00) Halifax
                                        </option>
                                        <option value="America/Havana">
                                          (GMT/UTC - 05:00) Havana
                                        </option>
                                        <option value="America/Hermosillo">
                                          (GMT/UTC - 07:00) Hermosillo
                                        </option>
                                        <option value="America/Indiana/Indianapolis">
                                          (GMT/UTC - 05:00) Indiana/Indianapolis
                                        </option>
                                        <option value="America/Indiana/Knox">
                                          (GMT/UTC - 06:00) Indiana/Knox
                                        </option>
                                        <option value="America/Indiana/Marengo">
                                          (GMT/UTC - 05:00) Indiana/Marengo
                                        </option>
                                        <option value="America/Indiana/Petersburg">
                                          (GMT/UTC - 05:00) Indiana/Petersburg
                                        </option>
                                        <option value="America/Indiana/Tell_City">
                                          (GMT/UTC - 06:00) Indiana/Tell City
                                        </option>
                                        <option value="America/Indiana/Vevay">
                                          (GMT/UTC - 05:00) Indiana/Vevay
                                        </option>
                                        <option value="America/Indiana/Vincennes">
                                          (GMT/UTC - 05:00) Indiana/Vincennes
                                        </option>
                                        <option value="America/Indiana/Winamac">
                                          (GMT/UTC - 05:00) Indiana/Winamac
                                        </option>
                                        <option value="America/Inuvik">
                                          (GMT/UTC - 07:00) Inuvik
                                        </option>
                                        <option value="America/Iqaluit">
                                          (GMT/UTC - 05:00) Iqaluit
                                        </option>
                                        <option value="America/Jamaica">
                                          (GMT/UTC - 05:00) Jamaica
                                        </option>
                                        <option value="America/Juneau">
                                          (GMT/UTC - 09:00) Juneau
                                        </option>
                                        <option value="America/Kentucky/Louisville">
                                          (GMT/UTC - 05:00) Kentucky/Louisville
                                        </option>
                                        <option value="America/Kentucky/Monticello">
                                          (GMT/UTC - 05:00) Kentucky/Monticello
                                        </option>
                                        <option value="America/Kralendijk">
                                          (GMT/UTC - 04:00) Kralendijk
                                        </option>
                                        <option value="America/La_Paz">
                                          (GMT/UTC - 04:00) La Paz
                                        </option>
                                        <option value="America/Lima">
                                          (GMT/UTC - 05:00) Lima
                                        </option>
                                        <option value="America/Los_Angeles">
                                          (GMT/UTC - 08:00) Los Angeles
                                        </option>
                                        <option value="America/Lower_Princes">
                                          (GMT/UTC - 04:00) Lower Princes
                                        </option>
                                        <option value="America/Maceio">
                                          (GMT/UTC - 03:00) Maceio
                                        </option>
                                        <option value="America/Managua">
                                          (GMT/UTC - 06:00) Managua
                                        </option>
                                        <option value="America/Manaus">
                                          (GMT/UTC - 04:00) Manaus
                                        </option>
                                        <option value="America/Marigot">
                                          (GMT/UTC - 04:00) Marigot
                                        </option>
                                        <option value="America/Martinique">
                                          (GMT/UTC - 04:00) Martinique
                                        </option>
                                        <option value="America/Matamoros">
                                          (GMT/UTC - 06:00) Matamoros
                                        </option>
                                        <option value="America/Mazatlan">
                                          (GMT/UTC - 07:00) Mazatlan
                                        </option>
                                        <option value="America/Menominee">
                                          (GMT/UTC - 06:00) Menominee
                                        </option>
                                        <option value="America/Merida">
                                          (GMT/UTC - 06:00) Merida
                                        </option>
                                        <option value="America/Metlakatla">
                                          (GMT/UTC - 09:00) Metlakatla
                                        </option>
                                        <option value="America/Mexico_City">
                                          (GMT/UTC - 06:00) Mexico City
                                        </option>
                                        <option value="America/Miquelon">
                                          (GMT/UTC - 03:00) Miquelon
                                        </option>
                                        <option value="America/Moncton">
                                          (GMT/UTC - 04:00) Moncton
                                        </option>
                                        <option value="America/Monterrey">
                                          (GMT/UTC - 06:00) Monterrey
                                        </option>
                                        <option value="America/Montevideo">
                                          (GMT/UTC - 03:00) Montevideo
                                        </option>
                                        <option value="America/Montserrat">
                                          (GMT/UTC - 04:00) Montserrat
                                        </option>
                                        <option value="America/Nassau">
                                          (GMT/UTC - 05:00) Nassau
                                        </option>
                                        <option value="America/New_York">
                                          (GMT/UTC - 05:00) New York
                                        </option>
                                        <option value="America/Nipigon">
                                          (GMT/UTC - 05:00) Nipigon
                                        </option>
                                        <option value="America/Nome">
                                          (GMT/UTC - 09:00) Nome
                                        </option>
                                        <option value="America/Noronha">
                                          (GMT/UTC - 02:00) Noronha
                                        </option>
                                        <option value="America/North_Dakota/Beulah">
                                          (GMT/UTC - 06:00) North Dakota/Beulah
                                        </option>
                                        <option value="America/North_Dakota/Center">
                                          (GMT/UTC - 06:00) North Dakota/Center
                                        </option>
                                        <option value="America/North_Dakota/New_Salem">
                                          (GMT/UTC - 06:00) North Dakota/New Salem
                                        </option>
                                        <option value="America/Ojinaga">
                                          (GMT/UTC - 07:00) Ojinaga
                                        </option>
                                        <option value="America/Panama">
                                          (GMT/UTC - 05:00) Panama
                                        </option>
                                        <option value="America/Pangnirtung">
                                          (GMT/UTC - 05:00) Pangnirtung
                                        </option>
                                        <option value="America/Paramaribo">
                                          (GMT/UTC - 03:00) Paramaribo
                                        </option>
                                        <option value="America/Phoenix">
                                          (GMT/UTC - 07:00) Phoenix
                                        </option>
                                        <option value="America/Port-au-Prince">
                                          (GMT/UTC - 05:00) Port-au-Prince
                                        </option>
                                        <option value="America/Port_of_Spain">
                                          (GMT/UTC - 04:00) Port of Spain
                                        </option>
                                        <option value="America/Porto_Velho">
                                          (GMT/UTC - 04:00) Porto Velho
                                        </option>
                                        <option value="America/Puerto_Rico">
                                          (GMT/UTC - 04:00) Puerto Rico
                                        </option>
                                        <option value="America/Rainy_River">
                                          (GMT/UTC - 06:00) Rainy River
                                        </option>
                                        <option value="America/Rankin_Inlet">
                                          (GMT/UTC - 06:00) Rankin Inlet
                                        </option>
                                        <option value="America/Recife">
                                          (GMT/UTC - 03:00) Recife
                                        </option>
                                        <option value="America/Regina">
                                          (GMT/UTC - 06:00) Regina
                                        </option>
                                        <option value="America/Resolute">
                                          (GMT/UTC - 06:00) Resolute
                                        </option>
                                        <option value="America/Rio_Branco">
                                          (GMT/UTC - 05:00) Rio Branco
                                        </option>
                                        <option value="America/Santarem">
                                          (GMT/UTC - 03:00) Santarem
                                        </option>
                                        <option value="America/Santiago">
                                          (GMT/UTC - 03:00) Santiago
                                        </option>
                                        <option value="America/Santo_Domingo">
                                          (GMT/UTC - 04:00) Santo Domingo
                                        </option>
                                        <option value="America/Sao_Paulo">
                                          (GMT/UTC - 02:00) Sao Paulo
                                        </option>
                                        <option value="America/Scoresbysund">
                                          (GMT/UTC - 01:00) Scoresbysund
                                        </option>
                                        <option value="America/Sitka">
                                          (GMT/UTC - 09:00) Sitka
                                        </option>
                                        <option value="America/St_Barthelemy">
                                          (GMT/UTC - 04:00) St. Barthelemy
                                        </option>
                                        <option value="America/St_Johns">
                                          (GMT/UTC - 03:30) St. Johns
                                        </option>
                                        <option value="America/St_Kitts">
                                          (GMT/UTC - 04:00) St. Kitts
                                        </option>
                                        <option value="America/St_Lucia">
                                          (GMT/UTC - 04:00) St. Lucia
                                        </option>
                                        <option value="America/St_Thomas">
                                          (GMT/UTC - 04:00) St. Thomas
                                        </option>
                                        <option value="America/St_Vincent">
                                          (GMT/UTC - 04:00) St. Vincent
                                        </option>
                                        <option value="America/Swift_Current">
                                          (GMT/UTC - 06:00) Swift Current
                                        </option>
                                        <option value="America/Tegucigalpa">
                                          (GMT/UTC - 06:00) Tegucigalpa
                                        </option>
                                        <option value="America/Thule">
                                          (GMT/UTC - 04:00) Thule
                                        </option>
                                        <option value="America/Thunder_Bay">
                                          (GMT/UTC - 05:00) Thunder Bay
                                        </option>
                                        <option value="America/Tijuana">
                                          (GMT/UTC - 08:00) Tijuana
                                        </option>
                                        <option value="America/Toronto">
                                          (GMT/UTC - 05:00) Toronto
                                        </option>
                                        <option value="America/Tortola">
                                          (GMT/UTC - 04:00) Tortola
                                        </option>
                                        <option value="America/Vancouver">
                                          (GMT/UTC - 08:00) Vancouver
                                        </option>
                                        <option value="America/Whitehorse">
                                          (GMT/UTC - 08:00) Whitehorse
                                        </option>
                                        <option value="America/Winnipeg">
                                          (GMT/UTC - 06:00) Winnipeg
                                        </option>
                                        <option value="America/Yakutat">
                                          (GMT/UTC - 09:00) Yakutat
                                        </option>
                                        <option value="America/Yellowknife">
                                          (GMT/UTC - 07:00) Yellowknife
                                        </option>
                                      </optgroup>
                                      <optgroup label="Antarctica">
                                        <option value="Antarctica/Casey">
                                          (GMT/UTC + 08:00) Casey
                                        </option>
                                        <option value="Antarctica/Davis">
                                          (GMT/UTC + 07:00) Davis
                                        </option>
                                        <option value="Antarctica/DumontDUrville">
                                          (GMT/UTC + 10:00) DumontDUrville
                                        </option>
                                        <option value="Antarctica/Macquarie">
                                          (GMT/UTC + 11:00) Macquarie
                                        </option>
                                        <option value="Antarctica/Mawson">
                                          (GMT/UTC + 05:00) Mawson
                                        </option>
                                        <option value="Antarctica/McMurdo">
                                          (GMT/UTC + 13:00) McMurdo
                                        </option>
                                        <option value="Antarctica/Palmer">
                                          (GMT/UTC - 03:00) Palmer
                                        </option>
                                        <option value="Antarctica/Rothera">
                                          (GMT/UTC - 03:00) Rothera
                                        </option>
                                        <option value="Antarctica/Syowa">
                                          (GMT/UTC + 03:00) Syowa
                                        </option>
                                        <option value="Antarctica/Troll">
                                          (GMT/UTC + 00:00) Troll
                                        </option>
                                        <option value="Antarctica/Vostok">
                                          (GMT/UTC + 06:00) Vostok
                                        </option>
                                      </optgroup>
                                      <optgroup label="Arctic">
                                        <option value="Arctic/Longyearbyen">
                                          (GMT/UTC + 01:00) Longyearbyen
                                        </option>
                                      </optgroup>
                                      <optgroup label="Asia">
                                        <option value="Asia/Aden">
                                          (GMT/UTC + 03:00) Aden
                                        </option>
                                        <option value="Asia/Almaty">
                                          (GMT/UTC + 06:00) Almaty
                                        </option>
                                        <option value="Asia/Amman">
                                          (GMT/UTC + 02:00) Amman
                                        </option>
                                        <option value="Asia/Anadyr">
                                          (GMT/UTC + 12:00) Anadyr
                                        </option>
                                        <option value="Asia/Aqtau">
                                          (GMT/UTC + 05:00) Aqtau
                                        </option>
                                        <option value="Asia/Aqtobe">
                                          (GMT/UTC + 05:00) Aqtobe
                                        </option>
                                        <option value="Asia/Ashgabat">
                                          (GMT/UTC + 05:00) Ashgabat
                                        </option>
                                        <option value="Asia/Baghdad">
                                          (GMT/UTC + 03:00) Baghdad
                                        </option>
                                        <option value="Asia/Bahrain">
                                          (GMT/UTC + 03:00) Bahrain
                                        </option>
                                        <option value="Asia/Baku">
                                          (GMT/UTC + 04:00) Baku
                                        </option>
                                        <option value="Asia/Bangkok">
                                          (GMT/UTC + 07:00) Bangkok
                                        </option>
                                        <option value="Asia/Barnaul">
                                          (GMT/UTC + 07:00) Barnaul
                                        </option>
                                        <option value="Asia/Beirut">
                                          (GMT/UTC + 02:00) Beirut
                                        </option>
                                        <option value="Asia/Bishkek">
                                          (GMT/UTC + 06:00) Bishkek
                                        </option>
                                        <option value="Asia/Brunei">
                                          (GMT/UTC + 08:00) Brunei
                                        </option>
                                        <option value="Asia/Chita">
                                          (GMT/UTC + 09:00) Chita
                                        </option>
                                        <option value="Asia/Choibalsan">
                                          (GMT/UTC + 08:00) Choibalsan
                                        </option>
                                        <option value="Asia/Colombo">
                                          (GMT/UTC + 05:30) Colombo
                                        </option>
                                        <option value="Asia/Damascus">
                                          (GMT/UTC + 02:00) Damascus
                                        </option>
                                        <option value="Asia/Dhaka">
                                          (GMT/UTC + 06:00) Dhaka
                                        </option>
                                        <option value="Asia/Dili">
                                          (GMT/UTC + 09:00) Dili
                                        </option>
                                        <option value="Asia/Dubai">
                                          (GMT/UTC + 04:00) Dubai
                                        </option>
                                        <option value="Asia/Dushanbe">
                                          (GMT/UTC + 05:00) Dushanbe
                                        </option>
                                        <option value="Asia/Gaza">
                                          (GMT/UTC + 02:00) Gaza
                                        </option>
                                        <option value="Asia/Hebron">
                                          (GMT/UTC + 02:00) Hebron
                                        </option>
                                        <option value="Asia/Ho_Chi_Minh">
                                          (GMT/UTC + 07:00) Ho Chi Minh
                                        </option>
                                        <option value="Asia/Hong_Kong">
                                          (GMT/UTC + 08:00) Hong Kong
                                        </option>
                                        <option value="Asia/Hovd">
                                          (GMT/UTC + 07:00) Hovd
                                        </option>
                                        <option value="Asia/Irkutsk">
                                          (GMT/UTC + 08:00) Irkutsk
                                        </option>
                                        <option value="Asia/Jakarta">
                                          (GMT/UTC + 07:00) Jakarta
                                        </option>
                                        <option value="Asia/Jayapura">
                                          (GMT/UTC + 09:00) Jayapura
                                        </option>
                                        <option value="Asia/Jerusalem">
                                          (GMT/UTC + 02:00) Jerusalem
                                        </option>
                                        <option value="Asia/Kabul">
                                          (GMT/UTC + 04:30) Kabul
                                        </option>
                                        <option value="Asia/Kamchatka">
                                          (GMT/UTC + 12:00) Kamchatka
                                        </option>
                                        <option value="Asia/Karachi">
                                          (GMT/UTC + 05:00) Karachi
                                        </option>
                                        <option value="Asia/Kathmandu">
                                          (GMT/UTC + 05:45) Kathmandu
                                        </option>
                                        <option value="Asia/Khandyga">
                                          (GMT/UTC + 09:00) Khandyga
                                        </option>
                                        <option value="Asia/Kolkata">
                                          (GMT/UTC + 05:30) Kolkata
                                        </option>
                                        <option value="Asia/Krasnoyarsk">
                                          (GMT/UTC + 07:00) Krasnoyarsk
                                        </option>
                                        <option value="Asia/Kuala_Lumpur">
                                          (GMT/UTC + 08:00) Kuala Lumpur
                                        </option>
                                        <option value="Asia/Kuching">
                                          (GMT/UTC + 08:00) Kuching
                                        </option>
                                        <option value="Asia/Kuwait">
                                          (GMT/UTC + 03:00) Kuwait
                                        </option>
                                        <option value="Asia/Macau">
                                          (GMT/UTC + 08:00) Macau
                                        </option>
                                        <option value="Asia/Magadan">
                                          (GMT/UTC + 10:00) Magadan
                                        </option>
                                        <option value="Asia/Makassar">
                                          (GMT/UTC + 08:00) Makassar
                                        </option>
                                        <option value="Asia/Manila">
                                          (GMT/UTC + 08:00) Manila
                                        </option>
                                        <option value="Asia/Muscat">
                                          (GMT/UTC + 04:00) Muscat
                                        </option>
                                        <option value="Asia/Nicosia">
                                          (GMT/UTC + 02:00) Nicosia
                                        </option>
                                        <option value="Asia/Novokuznetsk">
                                          (GMT/UTC + 07:00) Novokuznetsk
                                        </option>
                                        <option value="Asia/Novosibirsk">
                                          (GMT/UTC + 06:00) Novosibirsk
                                        </option>
                                        <option value="Asia/Omsk">
                                          (GMT/UTC + 06:00) Omsk
                                        </option>
                                        <option value="Asia/Oral">
                                          (GMT/UTC + 05:00) Oral
                                        </option>
                                        <option value="Asia/Phnom_Penh">
                                          (GMT/UTC + 07:00) Phnom Penh
                                        </option>
                                        <option value="Asia/Pontianak">
                                          (GMT/UTC + 07:00) Pontianak
                                        </option>
                                        <option value="Asia/Pyongyang">
                                          (GMT/UTC + 08:30) Pyongyang
                                        </option>
                                        <option value="Asia/Qatar">
                                          (GMT/UTC + 03:00) Qatar
                                        </option>
                                        <option value="Asia/Qyzylorda">
                                          (GMT/UTC + 06:00) Qyzylorda
                                        </option>
                                        <option value="Asia/Rangoon">
                                          (GMT/UTC + 06:30) Rangoon
                                        </option>
                                        <option value="Asia/Riyadh">
                                          (GMT/UTC + 03:00) Riyadh
                                        </option>
                                        <option value="Asia/Sakhalin">
                                          (GMT/UTC + 11:00) Sakhalin
                                        </option>
                                        <option value="Asia/Samarkand">
                                          (GMT/UTC + 05:00) Samarkand
                                        </option>
                                        <option value="Asia/Seoul">
                                          (GMT/UTC + 09:00) Seoul
                                        </option>
                                        <option value="Asia/Shanghai">
                                          (GMT/UTC + 08:00) Shanghai
                                        </option>
                                        <option value="Asia/Singapore">
                                          (GMT/UTC + 08:00) Singapore
                                        </option>
                                        <option value="Asia/Srednekolymsk">
                                          (GMT/UTC + 11:00) Srednekolymsk
                                        </option>
                                        <option value="Asia/Taipei">
                                          (GMT/UTC + 08:00) Taipei
                                        </option>
                                        <option value="Asia/Tashkent">
                                          (GMT/UTC + 05:00) Tashkent
                                        </option>
                                        <option value="Asia/Tbilisi">
                                          (GMT/UTC + 04:00) Tbilisi
                                        </option>
                                        <option value="Asia/Tehran">
                                          (GMT/UTC + 03:30) Tehran
                                        </option>
                                        <option value="Asia/Thimphu">
                                          (GMT/UTC + 06:00) Thimphu
                                        </option>
                                        <option value="Asia/Tokyo">
                                          (GMT/UTC + 09:00) Tokyo
                                        </option>
                                        <option value="Asia/Ulaanbaatar">
                                          (GMT/UTC + 08:00) Ulaanbaatar
                                        </option>
                                        <option value="Asia/Urumqi">
                                          (GMT/UTC + 06:00) Urumqi
                                        </option>
                                        <option value="Asia/Ust-Nera">
                                          (GMT/UTC + 10:00) Ust-Nera
                                        </option>
                                        <option value="Asia/Vientiane">
                                          (GMT/UTC + 07:00) Vientiane
                                        </option>
                                        <option value="Asia/Vladivostok">
                                          (GMT/UTC + 10:00) Vladivostok
                                        </option>
                                        <option value="Asia/Yakutsk">
                                          (GMT/UTC + 09:00) Yakutsk
                                        </option>
                                        <option value="Asia/Yekaterinburg">
                                          (GMT/UTC + 05:00) Yekaterinburg
                                        </option>
                                        <option value="Asia/Yerevan">
                                          (GMT/UTC + 04:00) Yerevan
                                        </option>
                                      </optgroup>
                                      <optgroup label="Atlantic">
                                        <option value="Atlantic/Azores">
                                          (GMT/UTC - 01:00) Azores
                                        </option>
                                        <option value="Atlantic/Bermuda">
                                          (GMT/UTC - 04:00) Bermuda
                                        </option>
                                        <option value="Atlantic/Canary">
                                          (GMT/UTC + 00:00) Canary
                                        </option>
                                        <option value="Atlantic/Cape_Verde">
                                          (GMT/UTC - 01:00) Cape Verde
                                        </option>
                                        <option value="Atlantic/Faroe">
                                          (GMT/UTC + 00:00) Faroe
                                        </option>
                                        <option value="Atlantic/Madeira">
                                          (GMT/UTC + 00:00) Madeira
                                        </option>
                                        <option value="Atlantic/Reykjavik">
                                          (GMT/UTC + 00:00) Reykjavik
                                        </option>
                                        <option value="Atlantic/South_Georgia">
                                          (GMT/UTC - 02:00) South Georgia
                                        </option>
                                        <option value="Atlantic/St_Helena">
                                          (GMT/UTC + 00:00) St. Helena
                                        </option>
                                        <option value="Atlantic/Stanley">
                                          (GMT/UTC - 03:00) Stanley
                                        </option>
                                      </optgroup>
                                      <optgroup label="Australia">
                                        <option value="Australia/Adelaide">
                                          (GMT/UTC + 10:30) Adelaide
                                        </option>
                                        <option value="Australia/Brisbane">
                                          (GMT/UTC + 10:00) Brisbane
                                        </option>
                                        <option value="Australia/Broken_Hill">
                                          (GMT/UTC + 10:30) Broken Hill
                                        </option>
                                        <option value="Australia/Currie">
                                          (GMT/UTC + 11:00) Currie
                                        </option>
                                        <option value="Australia/Darwin">
                                          (GMT/UTC + 09:30) Darwin
                                        </option>
                                        <option value="Australia/Eucla">
                                          (GMT/UTC + 08:45) Eucla
                                        </option>
                                        <option value="Australia/Hobart">
                                          (GMT/UTC + 11:00) Hobart
                                        </option>
                                        <option value="Australia/Lindeman">
                                          (GMT/UTC + 10:00) Lindeman
                                        </option>
                                        <option value="Australia/Lord_Howe">
                                          (GMT/UTC + 11:00) Lord Howe
                                        </option>
                                        <option value="Australia/Melbourne">
                                          (GMT/UTC + 11:00) Melbourne
                                        </option>
                                        <option value="Australia/Perth">
                                          (GMT/UTC + 08:00) Perth
                                        </option>
                                        <option value="Australia/Sydney">
                                          (GMT/UTC + 11:00) Sydney
                                        </option>
                                      </optgroup>
                                      <optgroup label="Europe">
                                        <option value="Europe/Amsterdam">
                                          (GMT/UTC + 01:00) Amsterdam
                                        </option>
                                        <option value="Europe/Andorra">
                                          (GMT/UTC + 01:00) Andorra
                                        </option>
                                        <option value="Europe/Astrakhan">
                                          (GMT/UTC + 04:00) Astrakhan
                                        </option>
                                        <option value="Europe/Athens">
                                          (GMT/UTC + 02:00) Athens
                                        </option>
                                        <option value="Europe/Belgrade">
                                          (GMT/UTC + 01:00) Belgrade
                                        </option>
                                        <option value="Europe/Berlin">
                                          (GMT/UTC + 01:00) Berlin
                                        </option>
                                        <option value="Europe/Bratislava">
                                          (GMT/UTC + 01:00) Bratislava
                                        </option>
                                        <option value="Europe/Brussels">
                                          (GMT/UTC + 01:00) Brussels
                                        </option>
                                        <option value="Europe/Bucharest">
                                          (GMT/UTC + 02:00) Bucharest
                                        </option>
                                        <option value="Europe/Budapest">
                                          (GMT/UTC + 01:00) Budapest
                                        </option>
                                        <option value="Europe/Busingen">
                                          (GMT/UTC + 01:00) Busingen
                                        </option>
                                        <option value="Europe/Chisinau">
                                          (GMT/UTC + 02:00) Chisinau
                                        </option>
                                        <option value="Europe/Copenhagen">
                                          (GMT/UTC + 01:00) Copenhagen
                                        </option>
                                        <option value="Europe/Dublin">
                                          (GMT/UTC + 00:00) Dublin
                                        </option>
                                        <option value="Europe/Gibraltar">
                                          (GMT/UTC + 01:00) Gibraltar
                                        </option>
                                        <option value="Europe/Guernsey">
                                          (GMT/UTC + 00:00) Guernsey
                                        </option>
                                        <option value="Europe/Helsinki">
                                          (GMT/UTC + 02:00) Helsinki
                                        </option>
                                        <option value="Europe/Isle_of_Man">
                                          (GMT/UTC + 00:00) Isle of Man
                                        </option>
                                        <option value="Europe/Istanbul">
                                          (GMT/UTC + 02:00) Istanbul
                                        </option>
                                        <option value="Europe/Jersey">
                                          (GMT/UTC + 00:00) Jersey
                                        </option>
                                        <option value="Europe/Kaliningrad">
                                          (GMT/UTC + 02:00) Kaliningrad
                                        </option>
                                        <option value="Europe/Kiev">
                                          (GMT/UTC + 02:00) Kiev
                                        </option>
                                        <option value="Europe/Lisbon">
                                          (GMT/UTC + 00:00) Lisbon
                                        </option>
                                        <option value="Europe/Ljubljana">
                                          (GMT/UTC + 01:00) Ljubljana
                                        </option>
                                        <option value="Europe/London">
                                          (GMT/UTC + 00:00) London
                                        </option>
                                        <option value="Europe/Luxembourg">
                                          (GMT/UTC + 01:00) Luxembourg
                                        </option>
                                        <option value="Europe/Madrid">
                                          (GMT/UTC + 01:00) Madrid
                                        </option>
                                        <option value="Europe/Malta">
                                          (GMT/UTC + 01:00) Malta
                                        </option>
                                        <option value="Europe/Mariehamn">
                                          (GMT/UTC + 02:00) Mariehamn
                                        </option>
                                        <option value="Europe/Minsk">
                                          (GMT/UTC + 03:00) Minsk
                                        </option>
                                        <option value="Europe/Monaco">
                                          (GMT/UTC + 01:00) Monaco
                                        </option>
                                        <option value="Europe/Moscow">
                                          (GMT/UTC + 03:00) Moscow
                                        </option>
                                        <option value="Europe/Oslo">
                                          (GMT/UTC + 01:00) Oslo
                                        </option>
                                        <option value="Europe/Paris">
                                          (GMT/UTC + 01:00) Paris
                                        </option>
                                        <option value="Europe/Podgorica">
                                          (GMT/UTC + 01:00) Podgorica
                                        </option>
                                        <option value="Europe/Prague">
                                          (GMT/UTC + 01:00) Prague
                                        </option>
                                        <option value="Europe/Riga">
                                          (GMT/UTC + 02:00) Riga
                                        </option>
                                        <option value="Europe/Rome">
                                          (GMT/UTC + 01:00) Rome
                                        </option>
                                        <option value="Europe/Samara">
                                          (GMT/UTC + 04:00) Samara
                                        </option>
                                        <option value="Europe/San_Marino">
                                          (GMT/UTC + 01:00) San Marino
                                        </option>
                                        <option value="Europe/Sarajevo">
                                          (GMT/UTC + 01:00) Sarajevo
                                        </option>
                                        <option value="Europe/Simferopol">
                                          (GMT/UTC + 03:00) Simferopol
                                        </option>
                                        <option value="Europe/Skopje">
                                          (GMT/UTC + 01:00) Skopje
                                        </option>
                                        <option value="Europe/Sofia">
                                          (GMT/UTC + 02:00) Sofia
                                        </option>
                                        <option value="Europe/Stockholm">
                                          (GMT/UTC + 01:00) Stockholm
                                        </option>
                                        <option value="Europe/Tallinn">
                                          (GMT/UTC + 02:00) Tallinn
                                        </option>
                                        <option value="Europe/Tirane">
                                          (GMT/UTC + 01:00) Tirane
                                        </option>
                                        <option value="Europe/Ulyanovsk">
                                          (GMT/UTC + 04:00) Ulyanovsk
                                        </option>
                                        <option value="Europe/Uzhgorod">
                                          (GMT/UTC + 02:00) Uzhgorod
                                        </option>
                                        <option value="Europe/Vaduz">
                                          (GMT/UTC + 01:00) Vaduz
                                        </option>
                                        <option value="Europe/Vatican">
                                          (GMT/UTC + 01:00) Vatican
                                        </option>
                                        <option value="Europe/Vienna">
                                          (GMT/UTC + 01:00) Vienna
                                        </option>
                                        <option value="Europe/Vilnius">
                                          (GMT/UTC + 02:00) Vilnius
                                        </option>
                                        <option value="Europe/Volgograd">
                                          (GMT/UTC + 03:00) Volgograd
                                        </option>
                                        <option value="Europe/Warsaw">
                                          (GMT/UTC + 01:00) Warsaw
                                        </option>
                                        <option value="Europe/Zagreb">
                                          (GMT/UTC + 01:00) Zagreb
                                        </option>
                                        <option value="Europe/Zaporozhye">
                                          (GMT/UTC + 02:00) Zaporozhye
                                        </option>
                                        <option value="Europe/Zurich">
                                          (GMT/UTC + 01:00) Zurich
                                        </option>
                                      </optgroup>
                                      <optgroup label="Indian">
                                        <option value="Indian/Antananarivo">
                                          (GMT/UTC + 03:00) Antananarivo
                                        </option>
                                        <option value="Indian/Chagos">
                                          (GMT/UTC + 06:00) Chagos
                                        </option>
                                        <option value="Indian/Christmas">
                                          (GMT/UTC + 07:00) Christmas
                                        </option>
                                        <option value="Indian/Cocos">
                                          (GMT/UTC + 06:30) Cocos
                                        </option>
                                        <option value="Indian/Comoro">
                                          (GMT/UTC + 03:00) Comoro
                                        </option>
                                        <option value="Indian/Kerguelen">
                                          (GMT/UTC + 05:00) Kerguelen
                                        </option>
                                        <option value="Indian/Mahe">
                                          (GMT/UTC + 04:00) Mahe
                                        </option>
                                        <option value="Indian/Maldives">
                                          (GMT/UTC + 05:00) Maldives
                                        </option>
                                        <option value="Indian/Mauritius">
                                          (GMT/UTC + 04:00) Mauritius
                                        </option>
                                        <option value="Indian/Mayotte">
                                          (GMT/UTC + 03:00) Mayotte
                                        </option>
                                        <option value="Indian/Reunion">
                                          (GMT/UTC + 04:00) Reunion
                                        </option>
                                      </optgroup>
                                      <optgroup label="Pacific">
                                        <option value="Pacific/Apia">
                                          (GMT/UTC + 14:00) Apia
                                        </option>
                                        <option value="Pacific/Auckland">
                                          (GMT/UTC + 13:00) Auckland
                                        </option>
                                        <option value="Pacific/Bougainville">
                                          (GMT/UTC + 11:00) Bougainville
                                        </option>
                                        <option value="Pacific/Chatham">
                                          (GMT/UTC + 13:45) Chatham
                                        </option>
                                        <option value="Pacific/Chuuk">
                                          (GMT/UTC + 10:00) Chuuk
                                        </option>
                                        <option value="Pacific/Easter">
                                          (GMT/UTC - 05:00) Easter
                                        </option>
                                        <option value="Pacific/Efate">
                                          (GMT/UTC + 11:00) Efate
                                        </option>
                                        <option value="Pacific/Enderbury">
                                          (GMT/UTC + 13:00) Enderbury
                                        </option>
                                        <option value="Pacific/Fakaofo">
                                          (GMT/UTC + 13:00) Fakaofo
                                        </option>
                                        <option value="Pacific/Fiji">
                                          (GMT/UTC + 12:00) Fiji
                                        </option>
                                        <option value="Pacific/Funafuti">
                                          (GMT/UTC + 12:00) Funafuti
                                        </option>
                                        <option value="Pacific/Galapagos">
                                          (GMT/UTC - 06:00) Galapagos
                                        </option>
                                        <option value="Pacific/Gambier">
                                          (GMT/UTC - 09:00) Gambier
                                        </option>
                                        <option value="Pacific/Guadalcanal">
                                          (GMT/UTC + 11:00) Guadalcanal
                                        </option>
                                        <option value="Pacific/Guam">
                                          (GMT/UTC + 10:00) Guam
                                        </option>
                                        <option value="Pacific/Honolulu">
                                          (GMT/UTC - 10:00) Honolulu
                                        </option>
                                        <option value="Pacific/Johnston">
                                          (GMT/UTC - 10:00) Johnston
                                        </option>
                                        <option value="Pacific/Kiritimati">
                                          (GMT/UTC + 14:00) Kiritimati
                                        </option>
                                        <option value="Pacific/Kosrae">
                                          (GMT/UTC + 11:00) Kosrae
                                        </option>
                                        <option value="Pacific/Kwajalein">
                                          (GMT/UTC + 12:00) Kwajalein
                                        </option>
                                        <option value="Pacific/Majuro">
                                          (GMT/UTC + 12:00) Majuro
                                        </option>
                                        <option value="Pacific/Marquesas">
                                          (GMT/UTC - 09:30) Marquesas
                                        </option>
                                        <option value="Pacific/Midway">
                                          (GMT/UTC - 11:00) Midway
                                        </option>
                                        <option value="Pacific/Nauru">
                                          (GMT/UTC + 12:00) Nauru
                                        </option>
                                        <option value="Pacific/Niue">
                                          (GMT/UTC - 11:00) Niue
                                        </option>
                                        <option value="Pacific/Norfolk">
                                          (GMT/UTC + 11:00) Norfolk
                                        </option>
                                        <option value="Pacific/Noumea">
                                          (GMT/UTC + 11:00) Noumea
                                        </option>
                                        <option value="Pacific/Pago_Pago">
                                          (GMT/UTC - 11:00) Pago Pago
                                        </option>
                                        <option value="Pacific/Palau">
                                          (GMT/UTC + 09:00) Palau
                                        </option>
                                        <option value="Pacific/Pitcairn">
                                          (GMT/UTC - 08:00) Pitcairn
                                        </option>
                                        <option value="Pacific/Pohnpei">
                                          (GMT/UTC + 11:00) Pohnpei
                                        </option>
                                        <option value="Pacific/Port_Moresby">
                                          (GMT/UTC + 10:00) Port Moresby
                                        </option>
                                        <option value="Pacific/Rarotonga">
                                          (GMT/UTC - 10:00) Rarotonga
                                        </option>
                                        <option value="Pacific/Saipan">
                                          (GMT/UTC + 10:00) Saipan
                                        </option>
                                        <option value="Pacific/Tahiti">
                                          (GMT/UTC - 10:00) Tahiti
                                        </option>
                                        <option value="Pacific/Tarawa">
                                          (GMT/UTC + 12:00) Tarawa
                                        </option>
                                        <option value="Pacific/Tongatapu">
                                          (GMT/UTC + 13:00) Tongatapu
                                        </option>
                                        <option value="Pacific/Wake">
                                          (GMT/UTC + 12:00) Wake
                                        </option>
                                        <option value="Pacific/Wallis">
                                          (GMT/UTC + 12:00) Wallis
                                        </option>
                                      </optgroup>
                                    </select>
                                  </div>
                                </div>
                               {/*print bill heading*/}
                                <div className="settings_headings">
                                  Print Bill and Footer Setting
                                </div>
                                <div className="row mt-4">
                                  <div className="form-group col-6">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="printHeading"
                                        className="control-label"
                                      >
                                        {_t(t("Print Bill Heading"))}
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                    </div>
                                    <div className="mb-2">
                                      <textarea
                                        className="form-control sm-text pt-4"
                                        id="printHeading"
                                        name="printHeading"
                                        onChange={handleChange}
                                        value={newSettings.printHeading}
                                        placeholder="e.g. Welcome to our restaurant"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group col-6">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="preSettledBilledHeading"
                                        className="control-label"
                                      >
                                        {_t(t("Pre Settled Billed Heading"))}
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                    </div>
                                    <div className="mb-2">
                                      <textarea
                                        className="form-control sm-text pt-4"
                                        id="preSettledBilledHeading"
                                        name="preSettledBilledHeading"
                                        onChange={handleChange}
                                        value={newSettings.preSettledBilledHeading}
                                        placeholder="e.g. pre settled billed heading"
                                        // required
                                      />
                                    </div>
                                  </div>
                                </div>
                                
                                <div className="row mt-4">
                                  <div className="form-group col-6">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="printFooter"
                                        className="control-label"
                                      >
                                        {_t(t("Print Bill Footer"))}
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                    </div>
                                    <div className="mb-2">
                                      <textarea
                                        className="form-control sm-text pt-4"
                                        id="printFooter"
                                        name="printFooter"
                                        onChange={handleChange}
                                        value={newSettings.printFooter}
                                        placeholder="e.g. wifi: pizzaburg, password: 1234"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group col-6">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="footerText"
                                        className="control-label"
                                      >
                                        {_t(t("Website Footer Text"))}
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                    </div>
                                    <div className="mb-2">
                                      <textarea
                                        readOnly
                                        className="form-control sm-text pt-4"
                                        id="footerText"
                                        name="footerText"
                                        onChange={handleChange}
                                        value={newSettings.footerText}
                                        placeholder="e.g. powered by indolij | All rights reserved | 2020"
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>                                
                              </div>
                            </Tab>
                            <Tab eventKey="print_setting" title="Print Setting">
                              {/*printing option: Both KOT and Customer or single  */}
                              <div className="settings_headings"> 
                                Pos Recipt Setting
                              </div>
                              <div className="row mt-1">
                                <div className="form-radio col-5">
                                    <div className="mb-2">
                                      <label className="control-label">
                                        {_t(t("POS Receipt Options"))}
                                      </label>
                                    </div>
                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="print_pos_both"
                                        name="print_pos_setting"
                                        value="Both"
                                        onChange={(e) => {
                                          handlePosReceiptOption(e);
                                        }}
                                        checked={
                                          newSettings.print_pos_setting === "Both"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="print_pos_both"
                                      >
                                        {_t(t("Both"))}
                                      </label>
                                    </div>

                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="print_pos_kitchen"
                                        name="print_pos_setting"
                                        value="Kitchen"
                                        onChange={(e) => {
                                          handlePosReceiptOption(e);
                                        }}
                                        checked={
                                          newSettings.print_pos_setting === "Kitchen"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="print_pos_kitchen"
                                      >
                                        {_t(t("Kitchen Receipt"))}
                                      </label>
                                    </div>

                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="print_pos_customer"
                                        name="print_pos_setting"
                                        value="Customer"
                                        onChange={(e) => {
                                          handlePosReceiptOption(e);
                                        }}
                                        checked={
                                          newSettings.print_pos_setting === "Customer"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="print_pos_customer"
                                      >
                                        {_t(t("Customer Receipt"))}
                                      </label>
                                    </div>
                                </div>                              
                                {/*printing option: Both KOT call center  */}
                                <div className="form-radio col-5">
                                    <div className="mb-2">
                                      <label className="control-label">
                                        {_t(t("Call Center Recipt"))}
                                      </label>
                                    </div>
                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="print_callcenter_"
                                        name="print_callcenter_kot"
                                        value="1"
                                        onChange={(e) => {
                                          handleCallCenterReceiptOption(e);
                                        }}
                                        checked={
                                          newSettings.print_callcenter_kot === "1"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="print_callcenter_"
                                      >
                                        {_t(t("Yes"))}
                                      </label>
                                    </div>

                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="print_callcenter"
                                        name="print_callcenter_kot"
                                        value="0"
                                        onChange={(e) => {
                                          handleCallCenterReceiptOption(e);
                                        }}
                                        checked={
                                          newSettings.print_callcenter_kot === "0"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="print_callcenter"
                                      >
                                        {_t(t("No"))}
                                      </label>
                                    </div>
                                </div>
                              </div>
                              <div className="form-check mt-4">
                                  <input
                                    type="checkbox"
                                    className="form-check-input pointer-cursor"
                                    id="print_kitchen_bill"
                                    name="print_kitchen_bill"
                                    onChange={() => {
                                      setNewSettings({
                                        ...newSettings,
                                        print_kitchen_bill:
                                          parseInt(
                                            newSettings.print_kitchen_bill
                                          ) === 0
                                            ? 1
                                            : 0,
                                      });
                                    }}
                                    checked={
                                      parseInt(newSettings.print_kitchen_bill) === 1
                                    }
                                  />
                                  <label
                                    className="form-check-label  pointer-cursor"
                                    htmlFor="print_kitchen_bill"
                                  >
                                    {_t(t("Print order details in kitchen?"))}
                                  </label>
                              </div>
                              {/*  */}
                              {/* print settings */}
                              <div className="settings_headings"> 
                                Print Recipt Setting
                              </div>
                              <div className="form-radio mt-3">
                                  <div className="d-inline mr-4">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="print_setting"
                                      name="print_setting"
                                      value= {1}
                                      onChange={(e) => {
                                        setNewSettings({
                                          ...newSettings,
                                          print_setting: newSettings.print_setting === 1 ? 0 : 1
                                        })
                                      }}
                                      checked={
                                        newSettings.print_setting ===
                                        1
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="print_setting"
                                    >
                                      {_t(
                                        t(
                                          "Print Settings"
                                        )
                                      )}
                                    </label>
                                  </div>
                              </div>
                              {/* site name */}
                                {
                                  newSettings.print_setting === 1 ? (
                                  <div>
                                    {/*logo */}
                                    <div className="form-radio mt-3">
                                      <div className="d-inline mr-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input pointer-cursor"
                                          id="print_logo"
                                          name="print_logo"
                                          value="1"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_logo: newSettings.print_logo === "1" ? "0" : "1"
                                            })
                                          }}
                                          checked={
                                            newSettings.print_logo === "1"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="print_logo"
                                        >
                                          {_t(
                                            t(
                                              "View Logo"
                                            )
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                    {/* site name */}
                                    <div className="form-radio mt-3">
                                      <div className="d-inline mr-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input pointer-cursor"
                                          id="print_siteName"
                                          name="print_siteName"
                                          value="1"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_siteName: newSettings.print_siteName === "1" ? "0" : "1"
                                            })
                                          }}
                                          checked={
                                            newSettings.print_siteName === "1"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="print_siteName"
                                        >
                                          {_t(
                                            t(
                                              "View Site Name"
                                            )
                                          )}
                                        </label>
                                      </div>
                                    </div>
  
                                    {/* branch name */}
                                    <div className="form-radio mt-3">
                                      <div className="d-inline mr-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input pointer-cursor"
                                          id="print_branchName"
                                          name="print_branchName"
                                          value="1"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_branchName: newSettings.print_branchName === "1" ? "0" : "1"
                                            })
                                          }}
                                          checked={
                                            newSettings.print_branchName === "1"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="print_branchName"
                                        >
                                          {_t(
                                            t(
                                              "View Branch Name"
                                            )
                                          )}
                                        </label>
                                      </div>
                                    </div>

                                    {/* branch address */}
                                    <div className="form-radio mt-3">
                                      <div className="d-inline mr-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input pointer-cursor"
                                          id="print_branchAddress"
                                          name="print_branchAddress"
                                          value="1"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_branchAddress: newSettings.print_branchAddress === "1" ? "0" : "1"
                                            })
                                          }}
                                          checked={
                                            newSettings.print_branchAddress === "1"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="print_branchAddress"
                                        >
                                          {_t(
                                            t(
                                              "View Branch Address"
                                            )
                                          )}
                                        </label>
                                      </div>
                                    </div>

                                    {/* branch phone no */}
                                    <div className="form-radio mt-3">
                                      <div className="d-inline mr-4">
                                          <input
                                          type="checkbox"
                                          className="form-check-input pointer-cursor"
                                          id="print_branchPhone"
                                          name="print_branchPhone"
                                          value="1"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_branchPhone: newSettings.print_branchPhone === "1" ? "0" : "1"
                                            })
                                          }}
                                          checked={
                                              newSettings.print_branchPhone === "1"
                                              ? 1
                                              : 0
                                          }
                                          />
                                          <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="print_branchPhone"
                                          >
                                          {_t(
                                              t(
                                              "View Branch Phone No."
                                              )
                                          )}
                                          </label>
                                      </div>
                                    </div>

                                    {/* print heading  */}
                                    <div className="form-radio mt-3">
                                      <div className="d-inline mr-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input pointer-cursor"
                                          id="print_heading"
                                          name="print_heading"
                                          value="1"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_heading: newSettings.print_heading === "1" ? "0" : "1"
                                            })
                                          }}
                                          checked={
                                          newSettings.print_heading === "1"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                            className="form-radio-label pointer-cursor ml-2"
                                            htmlFor="print_heading"
                                        >
                                            {_t(
                                            t(
                                                "View Print Heading"
                                            )
                                            )}
                                        </label>
                                      </div>
                                    </div>

                                    {/* print footer  */}
                                    <div className="form-radio mt-3">
                                      <div className="d-inline mr-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input pointer-cursor"
                                          id="print_bill_footer"
                                          name="print_bill_footer"
                                          value="1"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_bill_footer: newSettings.print_bill_footer === "1" ? "0" : "1"
                                            })
                                          }}
                                          checked={
                                          newSettings.print_bill_footer === "1"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                            className="form-radio-label pointer-cursor ml-2"
                                            htmlFor="print_bill_footer"
                                        >
                                            {_t(
                                            t(
                                                "View Print Bill Footer"
                                            )
                                            )}
                                        </label>
                                      </div>
                                    </div>

                                    {/* print payment type  */}
                                    <div className="form-radio mt-3">
                                      <div className="d-inline mr-4">
                                        <input
                                          type="checkbox"
                                          className="form-check-input pointer-cursor"
                                          id="print_payment_type"
                                          name="print_payment_type"
                                          value="1"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_payment_type: newSettings.print_payment_type === "1" ? "0" : "1"
                                            })
                                          }}
                                          checked={
                                          newSettings.print_payment_type === "1"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                            className="form-radio-label pointer-cursor ml-2"
                                            htmlFor="print_payment_type"
                                        >
                                            {_t(
                                            t(
                                                "View Print Payment Type"
                                            )
                                            )}
                                        </label>
                                      </div>
                                    </div> 

                                      {/* print cash card type  */}
                                      <div className="form-radio mt-3">
                                        <div className="d-inline mr-4">
                                            <input
                                              type="checkbox"
                                              className="form-check-input pointer-cursor"
                                              id="view_cash_card"
                                              name="view_cash_card"
                                              value="1"
                                              onChange={(e) => {
                                                setNewSettings({
                                                  ...newSettings,
                                                  view_cash_card: newSettings.view_cash_card === "1" ? "0" : "1"
                                                })
                                              }}
                                              checked={
                                              newSettings.view_cash_card === "1"
                                                  ? 1
                                                  : 0
                                              }
                                            />
                                            <label
                                                className="form-radio-label pointer-cursor ml-2"
                                                htmlFor="view_cash_card"
                                            >
                                                {_t(
                                                t(
                                                    "View Cash Card Payment Type"
                                                )
                                                )}
                                            </label>
                                        </div>
                                      </div> 

                                    {/*order type party */}
                                    <div className="form-radio mt-3">
                                      <div className="mb-2">
                                      <label className="control-label">
                                          {_t(t("View order type/party on print"))}
                                      </label>
                                      </div>

                                      <div className="d-inline mr-4">
                                      <input
                                          type="radio"
                                          className="form-check-input pointer-cursor"
                                          id="both"
                                          name="both"
                                          value="both"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_orderParty: e.target.value
                                            })
                                          }}
                                          checked={
                                          newSettings.print_orderParty === "both" ? 1 : 0
                                          }
                                      />
                                      <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="both"
                                      >
                                          {_t(t("both"))}
                                      </label>
                                      </div>

                                      <div className="d-inline mr-4">
                                        <input
                                          type="radio"
                                          className="form-check-input pointer-cursor"
                                          id="order_type"
                                          name="order_type"
                                          value="order_type"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_orderParty: e.target.value
                                            })
                                          }}
                                          checked={
                                          newSettings.print_orderParty === "order_type" ? 1 : 0
                                          }
                                          />
                                          <label
                                              className="form-radio-label pointer-cursor ml-2"
                                              htmlFor="order_type"
                                          >
                                              {_t(t("Order Type"))}
                                          </label>
                                      </div>
                                      <div className="d-inline mr-4">
                                        <input
                                          type="radio"
                                          className="form-check-input pointer-cursor"
                                          id="party"
                                          name="party"
                                          value="party"
                                          onChange={(e) => {
                                            setNewSettings({
                                              ...newSettings,
                                              print_orderParty: e.target.value
                                            })
                                          }}
                                          checked={
                                          newSettings.print_orderParty === "party" ? 1 : 0
                                          }
                                          />
                                          <label
                                              className="form-radio-label pointer-cursor ml-2"
                                              htmlFor="party"
                                          >
                                              {_t(t("party"))}
                                          </label>
                                      </div>
                                    </div> 
                                  </div>
                                  ): null
                                }

                              {/* zreport settings */}
                              <div className="settings_headings"> 
                                Workperiod Report Setting
                              </div>
                              <div className="form-radio mt-3">
                                  <div className="d-inline mr-4">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="zreport_setting"
                                      name="zreport_setting"
                                      value= {1}
                                      onChange={(e) => {
                                        setNewSettings({
                                          ...newSettings,
                                          zreport_setting: newSettings.zreport_setting === 1 ? 0 : 1
                                        })
                                      }}
                                      checked={
                                        newSettings.zreport_setting ===
                                        1
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="zreport_setting"
                                    >
                                      {_t(
                                        t(
                                          "ZReport Setting"
                                        )
                                      )}
                                    </label>
                                  </div>
                              </div>
                                {
                                  newSettings.zreport_setting === 1 ? (
                                    <div className="form-radio mt-3">
                                    <div className="d-inline mr-4">
                                      <input
                                        type="checkbox"
                                        className="form-check-input pointer-cursor"
                                        id="print_sold_items"
                                        name="print_sold_items"
                                        value="1"
                                        onChange={(e) => {
                                          setNewSettings({
                                            ...newSettings,
                                            print_sold_items: newSettings.print_sold_items === "1" ? "0" : "1"
                                          })
                                        }}
                                        checked={
                                          newSettings.print_sold_items ===
                                          "1"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="print_sold_items"
                                      >
                                        {_t(
                                          t(
                                            "View Sold Items"
                                          )
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  ) : null
                                }
                                {
                                  newSettings.zreport_setting === 1 ? (
                                    <div className="form-radio mt-3">
                                    <div className="d-inline mr-4">
                                      <input
                                        type="checkbox"
                                        className="form-check-input pointer-cursor"
                                        id="view_expense"
                                        name="view_expense"
                                        value="1"
                                        onChange={(e) => {
                                          setNewSettings({
                                            ...newSettings,
                                            view_expense: newSettings.view_expense === "1" ? "0" : "1"
                                          })
                                        }}
                                        checked={
                                          newSettings.view_expense ===
                                          "1"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="view_expense"
                                      >
                                        {_t(
                                          t(
                                            "View Expenses"
                                          )
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  ) : null
                                }
                                {
                                  newSettings.zreport_setting === 1 ? (
                                    <div className="form-radio mt-3">
                                    <div className="d-inline mr-4">
                                      <input
                                        type="checkbox"
                                        className="form-check-input pointer-cursor"
                                        id="delivery_charges_addition"
                                        name="delivery_charges_addition"
                                        value="1"
                                        onChange={(e) => {
                                          setNewSettings({
                                            ...newSettings,
                                            delivery_charges_addition: newSettings.delivery_charges_addition === "1" ? "0" : "1"
                                          })
                                        }}
                                        checked={
                                          newSettings.delivery_charges_addition ===
                                          "1"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="delivery_charges_addition"
                                      >
                                        {_t(
                                          t(
                                            "Delivery Charges Addition"
                                          )
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  ) : null
                                }

                            </Tab>
                            <Tab eventKey="order_settings" title="Order Setting">
                               {/*token print:  */}
                                <div className="settings_headings">
                                  Token
                                </div>
                                <div className="form-group mt-1">
                                    <div className="mb-2">
                                      <label className="control-label">
                                      {_t(t("Token Text"))}
                                      </label>
                                    </div>
                                    <div className="d-inline mr-4">
                                      <input
                                      type="text"
                                      className="form-control sm-text"
                                      id="token_text"
                                      name="token_text"
                                      value={newSettings.token_text || ""}
                                      onChange={(e) => {
                                        setNewSettings({
                                          ...newSettings,
                                          token_text: e.target.value
                                        })
                                      }}
                                      />
                                    </div>
                                </div>
                                {/*duplicate_print_text:  */}
                                <div className="form-group mt-1">
                                    <div className="mb-2">
                                      <label className="control-label">
                                      {_t(t("Duplicate Print Text"))}
                                      </label>
                                    </div>
                                    <div className="d-inline mr-4">
                                      <input
                                      type="text"
                                      className="form-control table-text"
                                      id="duplicate_print_text"
                                      name="duplicate_print_text"
                                      value={newSettings.duplicate_print_text || ""}
                                      onChange={(e) => {
                                        setNewSettings({
                                          ...newSettings,
                                          duplicate_print_text: e.target.value
                                        })
                                      }}
                                      placeholder="Enter Text"
                                      />
                                    </div>
                                </div>
                                
                                <div className="settings_headings">
                                  Order Setting
                                </div>
                                <div className="row mt-3">
                                  <div className="form-group col-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="online_orders"
                                      className="control-label"
                                    >
                                      {_t(t("Taking online orders?"))}
                                      <span className="text-danger">*</span>{" "}
                                    </label>
                                  </div>
                                  <div className="form-radio mb-3">
                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="online_orders_take"
                                        name="online_orders_take"
                                        value="1"
                                        onChange={(e) => {
                                            handleOnlineOrderSettingsOption(e);
                                        }}
                                        checked={
                                          newSettings.online_order_status === "1"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor=" online_orders_take"
                                      >
                                        {_t(t("Yes"))}
                                      </label>
                                    </div>

                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="online_orders_not_take"
                                        name="online_orders_not_take"
                                        value="0"
                                        onChange={(e) => {
                                          handleOnlineOrderSettingsOption(e);
                                        }}
                                        checked={
                                          newSettings.online_order_status === "0"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="online_orders_not_take"
                                      >
                                        {_t(t("No"))}
                                      </label>
                                    </div>
                                  </div>

                                  </div>
                                  <div className="form-group col-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="online_orders"
                                      className="control-label"
                                    >
                                      {_t(t("Till User Orders?"))}
                                      <span className="text-danger">*</span>{" "}
                                    </label>
                                  </div>
                                  <div className="form-radio mb-3">
                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="till_user_yes"
                                        name="till_user_yes"
                                        value="1"
                                        onChange={(e) => {
                                          handleTillUserSettingsOption(e);
                                        }}
                                        checked={
                                          newSettings.till_user === "1"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="till_user_yes"
                                      >
                                        {_t(t("Yes"))}
                                      </label>
                                    </div>
                                    
                                    <div className="d-inline mr-4">
                                    <input
                                      type="radio"
                                      className="form-check-input pointer-cursor"
                                      id="till_user_no"
                                      name="till_user_no"
                                      value="0"
                                      onChange={(e) => {
                                        handleTillUserSettingsOption(e);
                                      }}
                                      checked={
                                        newSettings.till_user === "0"
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="till_user_no"
                                    >
                                      {_t(t("No"))}
                                    </label>
                                  </div>
                                </div>
                                  </div>

                                  <div className="form-group col-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="online_orders"
                                      className="control-label"
                                    >
                                      {_t(t("View Other User Orders?"))}
                                      <span className="text-danger">*</span>{" "}
                                    </label>
                                  </div>
                                  <div className="form-radio mb-3">
                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="view_user_yes"
                                        name="view_user_yes"
                                        value= "1"
                                        onChange={(e) => {
                                          handleViewUserOrder(e);
                                        }}
                                        checked={
                                          newSettings.view_userOrder === "1"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="view_user_yes"
                                      >
                                        {_t(t("Yes"))}
                                      </label>
                                    </div>
                                    
                                    <div className="d-inline mr-4">
                                    <input
                                      type="radio"
                                      className="form-check-input pointer-cursor"
                                      id="view_user_no"
                                      name="view_user_no"
                                      value= "0"
                                      onChange={(e) => {
                                        handleViewUserOrder(e);
                                      }}
                                      checked={
                                        newSettings.view_userOrder === "0"
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="view_user_no"
                                    >
                                      {_t(t("No"))}
                                    </label>
                                  </div>
                                </div>
                                  </div>

                                  <div className="form-group col-3">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="offers_lock_status"
                                        className="control-label"
                                      >
                                        {_t(t("Offer Lock"))}
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                    </div>
                                    <div className="form-radio mb-3">
                                      <div className="d-inline mr-4">
                                        <input
                                          type="radio"
                                          className="form-check-input pointer-cursor"
                                          id="offers_lock_status"
                                          name="offers_lock_status"
                                          value="1"
                                          onChange={(e) => {
                                            handleOfferStatus(e);
                                          }}
                                          checked={
                                            newSettings.offers_lock_status === "1"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="offers_lock_status"
                                        >
                                          {_t(t("Lock"))}
                                        </label>
                                      </div>

                                      <div className="d-inline mr-4">
                                        <input
                                          type="radio"
                                          className="form-check-input pointer-cursor"
                                          id="offers_lock_status_unlock"
                                          name="offers_lock_status_unlock"
                                          value="0"
                                          onChange={(e) => {
                                            handleOfferStatus(e);
                                          }}
                                          checked={
                                            newSettings.offers_lock_status === "0"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="offers_lock_status_unlock"
                                        >
                                          {_t(t("Unlock"))}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-3 ml-0">
                                  <div className="form-check col-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input pointer-cursor"
                                    id="foodPanda_integration"
                                    name="foodPanda_integration"
                                    onChange={() => {
                                      setNewSettings({
                                        ...newSettings,
                                        foodPanda_integration:
                                          parseInt(
                                            newSettings.foodPanda_integration
                                          ) === 0
                                            ? 1
                                            : 0,
                                      });
                                    }}
                                    checked={
                                      parseInt(newSettings.foodPanda_integration) === 1
                                    }
                                  />
                                  <label
                                    className="form-check-label  pointer-cursor"
                                    htmlFor="foodPanda_integration"
                                  >
                                    {_t(t("Take Food Panda Order?"))}
                                  </label>
                                  </div>

                                  <div className="form-check col-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input pointer-cursor"
                                    id="order_no"
                                    name="order_no"
                                    onChange={() => {
                                      setNewSettings({
                                        ...newSettings,
                                        order_no:
                                          parseInt(
                                            newSettings.order_no
                                          ) == 0
                                            ? 1
                                            : 0,
                                      });
                                    }}
                                    checked={
                                      parseInt(newSettings.order_no) === 1
                                    }
                                  />
                                  <label
                                    className="form-check-label pointer-cursor"
                                    htmlFor="order_no"
                                  >
                                    {_t(t("Show Order No?"))}
                                  </label>
                                  </div>

                                  <div className="form-check col-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="play_sound"
                                      name="play_sound"
                                      onChange={() => {
                                        setNewSettings({
                                          ...newSettings,
                                          play_sound:
                                            parseInt(newSettings.play_sound) === 0
                                              ? 1
                                              : 0,
                                        });
                                      }}
                                      checked={parseInt(newSettings.play_sound) === 1}
                                    />
                                    <label
                                      className="form-check-label pointer-cursor"
                                      htmlFor="play_sound"
                                    >
                                      {_t(t("Play beep sound in POS?"))}
                                    </label>
                                  </div>

                                  <div className="form-check col-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="table_waiter"
                                      name="table_waiter"
                                      onChange={() => {
                                        setNewSettings({
                                          ...newSettings,
                                          table_waiter:
                                            parseInt(newSettings.table_waiter) === 0
                                              ? 1
                                              : 0,
                                        });
                                      }}
                                      checked={
                                        parseInt(newSettings.table_waiter) === 1
                                      }
                                    />
                                    <label
                                      className="form-check-label pointer-cursor"
                                      htmlFor="table_waiter"
                                    >
                                      {_t(
                                        t(
                                          "Show table, waiter selection option in POS?"
                                        )
                                      )}
                                    </label>
                                  </div>
                                </div>

                                <div className="row mt-3 ml-0">
                                  <div className="form-check col-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="order_round_off"
                                      name="order_round_off"
                                      onChange={() => {
                                        setNewSettings({
                                          ...newSettings,
                                          order_round_off: parseInt(newSettings.order_round_off) === 0
                                              ? 1
                                              : 0,
                                        });
                                      }}
                                      checked={parseInt(newSettings.order_round_off) === 1}
                                    />
                                    <label
                                      className="form-check-label pointer-cursor"
                                      htmlFor="order_round_off"
                                    >
                                      {_t(
                                        t(
                                          "Round Off Order?"
                                        )
                                      )}
                                    </label>
                                  </div>
                                  <div className="form-check col-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="direct_ip_print"
                                      name="direct_ip_print"
                                      onChange={() => {
                                        setNewSettings({
                                          ...newSettings,
                                          direct_ip_print: parseInt(newSettings.direct_ip_print) === 0
                                              ? 1
                                              : 0,
                                        });
                                      }}
                                      checked={parseInt(newSettings.direct_ip_print) === 1}
                                    />
                                    <label
                                      className="form-check-label pointer-cursor"
                                      htmlFor="direct_ip_print"
                                    >
                                      {_t(t(
                                          "Direct Ip Print"
                                        )
                                      )}
                                    </label>
                                  </div>
                                  <div className="form-group col-4 pl-1">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="online_orders"
                                        className="control-label"
                                      >
                                        {_t(t("Waiter Selection For Order Type"))}
                                        <span className="text-danger">*</span>{" "}
                                      </label>
                                    </div>
                                    <div className="form-radio mb-3">
                                      {
                                        orderTypeList?.data && orderTypeList.data.map(list=>{
                                          return (
                                            <div className="d-inline mr-4" key={list.id}>
                                              <input
                                                type="checkbox"
                                                className="form-check-input pointer-cursor"
                                                id={list.id}
                                                name={list.id}
                                                value={list.id}
                                                onChange={handleWaiterOdType}
                                                checked={newSettings.waiter_order_type && newSettings.waiter_order_type.includes(list.id)}
                                              />
                                              <label
                                                className="form-radio-label pointer-cursor ml-2"
                                                htmlFor={list.id}
                                              >
                                                {_t(t(list.name))}
                                              </label>
                                            </div>
                                          )
                                        })
                                      }
                                      
                                    </div>
                                  </div>
                                  <div className="form-group col-2 pl-1">
                                      <input
                                        type="checkbox"
                                        className="form-check-input pointer-cursor"
                                        id="open_qty_box"
                                        name="open_qty_box"
                                        onChange={()=>{
                                          setNewSettings({
                                            ...newSettings,
                                            open_qty_box: parseInt(newSettings.open_qty_box) === 0
                                                ? 1
                                                : 0,
                                          });
                                        }}
                                        checked={newSettings.open_qty_box}
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="open_qty_box"
                                      >
                                        {_t(t("Open Qty Box"))}
                                      </label>
                                  </div>
                                </div>  

                                {/*deliery charges */}
                                <div className="settings_headings">
                                  Delivery Charges
                                </div>
                                <div className="mb-2 mt-4">
                                  <label
                                    htmlFor="delivery_charges"
                                    className="control-label"
                                  >
                                    {_t(t("Delivery Charges"))}
                                    <span className="text-danger">*</span>{" "}
                                  </label>
                                </div>
                                <div className="form-radio mb-3">
                                  <div className="d-inline mr-4">
                                    <input
                                      type="radio"
                                      className="form-check-input pointer-cursor"
                                      id="delivery_charges_lock_locked"
                                      name="delivery_charges_lock"
                                      value="1"
                                      onChange={(e) => {
                                        handleDeliveryChargesSettingsOption(e);
                                      }}
                                      checked={
                                        newSettings.delivery_charges_lock === "1"
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="delivery_charges_lock_locked"
                                    >
                                      {_t(t("Lock"))}
                                    </label>
                                  </div>

                                  <div className="d-inline mr-4">
                                    <input
                                      type="radio"
                                      className="form-check-input pointer-cursor"
                                      id="delivery_charges_lock_unlocked"
                                      name="delivery_charges_lock"
                                      value="0"
                                      onChange={(e) => {
                                        handleDeliveryChargesSettingsOption(e);
                                      }}
                                      checked={
                                        newSettings.delivery_charges_lock === "0"
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="delivery_charges_lock_unlocked"
                                    >
                                      {_t(t("Unlock"))}
                                    </label>
                                  </div>
                                </div>

                                <div className="mb-1">
                                  <input
                                    type="number"
                                    //step="0.01"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    min="0"
                                    className="form-control sm-text"
                                    id="delivery_charges"
                                    name="delivery_charges"
                                    onChange={handleChange}
                                    value={newSettings.delivery_charges}
                                    placeholder="e.g. delivery charges"
                                    required
                                  />
                                </div>                                  

                                  {/*<div className="form-group mt-4 ">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="online_orders"
                                        className="control-label"
                                      >
                                        {_t(t("Online orders time in minutes"))}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <input
                                        type="number"
                                        //step="0.01"
                                        onKeyDown={(evt) =>
                                          ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                          evt.preventDefault()
                                        }
                                        min="1"
                                        max="5"
                                        className="form-control sm-text"
                                        id="order_time"
                                        name="order_time"
                                        onChange={handleOrderTime}
                                        value={newSettings.order_time || ""}
                                        placeholder="minutes"
                                      />
                                  </div>                                                                                                      
                                      </div> */}   
                            </Tab>
                            <Tab eventKey="others" title="Others">
                               {/*customer comission option: before tax after tax */}
                               <div className="settings_headings">
                                Workperiod & Food Cost Setting
                               </div>
                               <div className="m-0 row">
                                <div className="form-check mt-4 col-6">
                                  <input
                                    type="checkbox"
                                    className="form-check-input pointer-cursor"
                                    id = "day_open"
                                    name = "day_open"
                                    onChange={() => {
                                      setNewSettings({
                                        ...newSettings,
                                        day_open:
                                          parseInt(
                                            newSettings.day_open
                                          ) == 0
                                            ? 1
                                            : 0,
                                      });
                                    }}
                                    checked={parseInt(newSettings.day_open) === 1}
                                  />
                                  <label
                                    className="form-check-label pointer-cursor"
                                    htmlFor="day_open"
                                  >
                                    {_t(t("Manager Day Open?"))}
                                  </label>
                                </div>
                                <div className="form-check mt-4 col-6">
                                    <input
                                      type="checkbox"
                                      className="form-check-input pointer-cursor"
                                      id="day_end_cash"
                                      name="day_end_cash"
                                      onChange={() => {
                                        setNewSettings({
                                          ...newSettings,
                                          day_end_cash:
                                            parseInt(
                                              newSettings.day_end_cash
                                            ) === 0
                                              ? 1
                                              : 0,
                                        });
                                      }}
                                      checked={
                                        parseInt(newSettings.day_end_cash) === 1
                                      }
                                    />
                                    <label
                                      className="form-check-label pointer-cursor"
                                      htmlFor="day_end_cash"
                                    >
                                      {_t(t("Day End Cash"))}
                                    </label>
                                </div>
                               </div>
                               
                              <div className="row mt-4">
                                <div className="form-radio col-5">
                                    <div className="mb-2">
                                      <label className="control-label">
                                        {_t(t("Ideal Food Cost Options"))}
                                      </label>
                                    </div>
                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="branch"
                                        name="ideal_food_cost"
                                        value="branch"
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        checked={
                                          newSettings.ideal_food_cost === "branch"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="branch"
                                      >
                                        {_t(t("Branch"))}
                                      </label>
                                    </div>

                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="category"
                                        name="ideal_food_cost"
                                        value="category"
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        checked={
                                          newSettings.ideal_food_cost === "category"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="category"
                                      >
                                        {_t(t("Category"))}
                                      </label>
                                    </div>

                                    <div className="d-inline mr-4">
                                      <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="food"
                                        name="ideal_food_cost"
                                        value="food"
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        checked={
                                          newSettings.ideal_food_cost === "food"
                                            ? 1
                                            : 0
                                        }
                                      />
                                      <label
                                        className="form-radio-label pointer-cursor ml-2"
                                        htmlFor="food"
                                      >
                                        {_t(t("Food"))}
                                      </label>
                                    </div>

                                </div> 
                              </div>

                               <div className="mt-4 mb-3">
                                  <div className="form-radio col-5">
                                      <div className="mb-2">
                                        <label className="control-label">
                                          {_t(t("Day End Physical Stock Options"))}
                                        </label>
                                      </div>
                                      <div className="d-inline mr-4">
                                        <input
                                          type="radio"
                                          className="form-check-input pointer-cursor"
                                          id="day_end_stock_both"
                                          name="day_end_stock_both"
                                          value="both"
                                          onChange={(e) => {
                                            handleDayEndOption(e);
                                          }}
                                          checked={
                                            newSettings.day_end_stock === "both"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="day_end_stock_both"
                                        >
                                          {_t(t("Both"))}
                                        </label>
                                      </div>

                                      <div className="d-inline mr-4">
                                        <input
                                          type="radio"
                                          className="form-check-input pointer-cursor"
                                          id="day_end_stock_food"
                                          name="day_end_stock_food"
                                          value="food"
                                          onChange={(e) => {
                                            handleDayEndOption(e);
                                          }}
                                          checked={
                                            newSettings.day_end_stock === "food"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="day_end_stock_food"
                                        >
                                          {_t(t("Finished Goods"))}
                                        </label>
                                      </div>

                                      <div className="d-inline mr-4">
                                        <input
                                          type="radio"
                                          className="form-check-input pointer-cursor"
                                          id="day_end_stock_raw"
                                          name="day_end_stock_raw"
                                          value="raw"
                                          onChange={(e) => {
                                            handleDayEndOption(e);
                                          }}
                                          checked={
                                            newSettings.day_end_stock === "raw"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="day_end_stock_raw"
                                        >
                                          {_t(t("Raw Material"))}
                                        </label>
                                      </div>

                                      <div className="d-inline mr-4">
                                        <input
                                          type="radio"
                                          className="form-check-input pointer-cursor"
                                          id="day_end_stock_off"
                                          name="day_end_stock_off"
                                          value="off"
                                          onChange={(e) => {
                                            handleDayEndOption(e);
                                          }}
                                          checked={
                                            newSettings.day_end_stock === "off"
                                              ? 1
                                              : 0
                                          }
                                        />
                                        <label
                                          className="form-radio-label pointer-cursor ml-2"
                                          htmlFor="day_end_stock_off"
                                        >
                                          {_t(t("Off"))}
                                        </label>
                                      </div>
                                  </div> 
                               </div>
                               
                              <div className="settings_headings">
                                Recipe, Finished Good & Stock Setting
                              </div>
                              <div className="form-check mt-4">
                                <input
                                  type="checkbox"
                                  className="form-check-input pointer-cursor"
                                  id="physical_stock"
                                  name="physical_stock"
                                  onChange={() => {
                                    setNewSettings({
                                      ...newSettings,
                                      physical_stock:
                                        parseInt(
                                          newSettings.physical_stock
                                        ) === 0
                                          ? 1
                                          : 0,
                                    });
                                  }}
                                  checked={
                                    parseInt(newSettings.physical_stock) === 1
                                  }
                                />
                                <label
                                  className="form-check-label pointer-cursor"
                                  htmlFor="physical_stock"
                                >
                                  {_t(t("Physical Stock?"))}
                                </label>
                              </div>                              

                              <div className="form-check mt-4">
                                <input
                                  type="checkbox"
                                  className="form-check-input pointer-cursor"
                                  id="fgs_enable"
                                  name="fgs_enable"
                                  onChange={() => {
                                    setNewSettings({
                                      ...newSettings,
                                      fgs_enable:
                                        parseInt(
                                          newSettings.fgs_enable
                                        ) == 0
                                          ? 1
                                          : 0,
                                    });
                                  }}
                                  checked={
                                    parseInt(newSettings.fgs_enable) === 1
                                  }
                                />
                                <label
                                  className="form-check-label pointer-cursor"
                                  htmlFor="fgs_enable"
                                >
                                  {_t(t("Finished Goods Stock Enable?"))}
                                </label>
                              </div>

                              <div className="form-check mt-4">
                                <input
                                  type="checkbox"
                                  className="form-check-input pointer-cursor"
                                  id="recipe_enable"
                                  name="recipe_enable"
                                  onChange={() => {
                                    setNewSettings({
                                      ...newSettings,
                                      recipe_enable:
                                        parseInt(newSettings.recipe_enable) == 0
                                          ? 1
                                          : 0,
                                    });
                                  }}
                                  checked={parseInt(newSettings.recipe_enable) === 1}
                                />
                                <label
                                  className="form-check-label pointer-cursor"
                                  htmlFor="recipe_enable"
                                >
                                  {_t(t("Recipe Enable?"))}
                                </label>
                              </div>

                              <div className="settings_headings">
                                Additional Options
                              </div>
                              <div className="form-radio mt-1">
                                  <div className="mb-2">
                                    <label className="control-label">
                                      {_t(t("Party Commission Options"))}
                                    </label>
                                  </div>

                                  <div className="d-inline mr-4">
                                    <input
                                      type="radio"
                                      className="form-check-input pointer-cursor"
                                      id="commission_before_tax"
                                      name="party_commission_setting"
                                      value="before_tax"
                                      onChange={(e) => {
                                        handlePartyCommissionOption(e);
                                      }}
                                      checked={
                                        newSettings.party_commission ===
                                        "before_tax"
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="commission_before_tax"
                                    >
                                      {_t(t("Before Tax"))}
                                    </label>
                                  </div>

                                  <div className="d-inline mr-4">
                                    <input
                                      type="radio"
                                      className="form-check-input pointer-cursor"
                                      id="commission_after_tax"
                                      name="party_commission_setting"
                                      value="after_tax"
                                      onChange={(e) => {
                                        handlePartyCommissionOption(e);
                                      }}
                                      checked={
                                        newSettings.party_commission === "after_tax"
                                          ? 1
                                          : 0
                                      }
                                    />
                                    <label
                                      className="form-radio-label pointer-cursor ml-2"
                                      htmlFor="commission_after_tax"
                                    >
                                      {_t(t("After Tax"))}
                                    </label>
                                  </div>
                              </div>                                                                

                              <div className="m-0 row">
                                <div className="form-check mt-4 col-6">
                                  <input
                                    type="checkbox"
                                    className="form-check-input pointer-cursor"
                                    id="call_center_enable"
                                    name="call_center_enable"
                                    onChange={() => {
                                      setNewSettings({
                                        ...newSettings,
                                        call_center_enable:
                                          parseInt(
                                            newSettings.call_center_enable
                                          ) === 0
                                            ? 1
                                            : 0,
                                      });
                                    }}
                                    checked={
                                      parseInt(newSettings.call_center_enable) === 1
                                    }
                                  />
                                  <label
                                    className="form-check-label  pointer-cursor"
                                    htmlFor="call_center_enable"
                                  >
                                    {_t(t("Call Center Enable"))}
                                  </label>
                                </div>                                                                                                                                

                                <div className="form-check mt-4 col-6">
                                  <input
                                    type="checkbox"
                                    className="form-check-input pointer-cursor"
                                    id="customer_validation"
                                    name="customer_validation"
                                    onChange={() => {
                                      setNewSettings({
                                        ...newSettings,
                                        customer_validation:
                                          parseInt(
                                            newSettings.customer_validation
                                          ) === 0
                                            ? 1
                                            : 0,
                                      });
                                    }}
                                    checked={
                                      parseInt(newSettings.customer_validation) === 1
                                    }
                                  />
                                  <label
                                    className="form-check-label pointer-cursor"
                                    htmlFor="customer_validation"
                                  >
                                    {_t(t("Customer Validation"))}
                                  </label>
                                </div>
                              </div>

                              <div className="form-check mt-4">
                                <input
                                  type="checkbox"
                                  className="form-check-input pointer-cursor"
                                  id="user_wise_integration"
                                  name="user_wise_integration"
                                  onChange={() => {
                                    setNewSettings({
                                      ...newSettings,
                                      user_wise_integration:
                                        parseInt(
                                          newSettings.user_wise_integration
                                        ) === 0
                                          ? 1
                                          : 0,
                                    });
                                  }}
                                  checked={
                                    parseInt(newSettings.user_wise_integration) === 1
                                  }
                                />
                                <label
                                  className="form-check-label pointer-cursor"
                                  htmlFor="user_wise_integration"
                                >
                                  {_t(t("User Wise Integration"))}
                                </label>
                              </div>

                            </Tab>

                          </Tabs>
                          <div className="form-group mt-4 pb-2">
                            <div className="col-lg-12">
                              <button
                                className="btn btn-primary px-5 rounded"
                                type="submit"
                              >
                                {_t(t("Save"))}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default General;
