import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import "./charts/dashboard.css";

import "./charts/dashboard.css";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  tableLoading,
  checkPermission,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import moment from "moment";

//pages & includes
import ReportSidebar from "./ReportSidebar";
//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";

// graphs
import { CardsWidget, ColumnsWidget, DonutWidget } from "./charts";
import ListWidget from "./charts/ListWidget";
import VerticalBarWidget from "./charts/VerticalBarWidget";
import BarWidget from "./charts/BarWidget";
import LineWidget from "./charts/LineWidget";
import RadialWidget from "./charts/RadialWidget";

const DeicdeVisual = ({ type, data, heading }) => {
  switch (type) {
    case "cards_widget":
      return <CardsWidget data={data} heading={heading} />;
    case "bars_widget":
      return <ColumnsWidget data={data} heading={heading} />;
    case "column_widget":
        return <VerticalBarWidget data={data} heading={heading} size="col-6"/>;
    case "hbars_widget" : 
      return <BarWidget data={data} heading={heading} size="col-6"/>;
    case "pie_widget" : 
     return  <RadialWidget  data={data} heading={heading}/>;
    case "line_widget" : 
      return  <LineWidget data={data} heading={heading} size="col-12"/>;
      case "donut_widget":
      return <DonutWidget data={data} heading={heading} size="col-6"/>;
    case "list_view" :
    return(
        <div>
         <ListWidget data={data} heading={heading}/>
        </div>
    );
    default:
      return null;
  }
};

const getAxiosReq = (details, params) => {
  const url = `${BASE_URL}/${details.prefix}/${details.endpoint}`;
  if (details.method === "POST") {
    const formData = {};
    if (details.params.includes("range")) {
      formData.range = 12;
    }
    for (const key in params) {
      if (details.params.includes(key)) {
        formData[key] = params[key];
      }
    }
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => res.data)
      .catch((err) => err);
  } else if (details.method === "GET")
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => res.data)
      .catch((err) => err);
};

const ConsolitedData = () => {
    const { t } = useTranslation();
  //getting context values here
  let {
    loading,
    setLoading,
    dataPaginating,
    generalSettings,
    setDataPaginating,
  } = useContext(SettingsContext);

  let { branchForSearch } = useContext(RestaurantContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branch, setBranch] = useState(null);

  const [cardData, setCardData] = useState(null);
  const [allApis, setAllApis] = useState(null);

  // data fetching
  useEffect(() => {
    setLoading(true);
    getReport(() => {
      setLoading(false);
    });
  }, []);

  //get reports
  const getReport = (cb) => {
    const url = BASE_URL + "/widgets/get-api-details-cd";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data.status == 200) {
          const allApisTemp = res.data.details;
          setAllApis(allApisTemp);
          getAllApis(allApisTemp);
        }
      })
      .catch((error) => {})
      .finally(cb);
  };

  const getAllApis = async (allApisTemp, formData = {}) => {
    setDataPaginating(true);
    const allRes = await Promise.all(
      allApisTemp.map((api) => getAxiosReq(api, formData))
    );
    setDataPaginating(false);
    setCardData(
      allRes.map((res, index) => {
        return {
          type: allApisTemp[index].web_type,
          heading: allApisTemp[index].heading,
          data: res.status == 200 ? res.details : { error: res.message },
        };
      })
    );
  };

  const handleBranch = (branch) => {
    setBranch(branch);
  };

  const handleApplyFilter = () => {
    const formData = {};
    if (branch && branch.id) formData.branch_id = branch.id;
    if (startDate) formData.from = moment(startDate).format("YYYY-MM-DD");
    if (endDate) formData.to = moment(endDate).format("YYYY-MM-DD");
    getAllApis(allApis, formData);
  };

  return (
    <>
    <Helmet>
      <title>{_t(t("Consolidate Reports"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ReportSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* Loading effect */}
                  {loading === true ? (
                    tableLoading()
                  ) : (
                    <div key="smtp-form reports-dashboard">
                      <div className="row gx-2 mx-1 align-items-center t-pt-15 pb-2">
                        <div className="col-12 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Consolidate Dashboard"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* next page data spin loading */}
                      <div className={`${dataPaginating && "loading"}`}></div>
                      {/* spin loading ends */}
                      {/*<div className="row mx-1 gx-2 mt-2">
                        <div className="col-12 col-md-2 d-md-block">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control sm-text py-2 t-mb-15 mb-md-0"
                            placeholderText={_t(t("From date"))}
                            shouldCloseOnSelect={true}
                          />
                        </div>
                        <div className="col-12 col-md-2 t-mb-15 mb-md-0">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control sm-text py-2"
                            placeholderText={_t(t("To date"))}
                            shouldCloseOnSelect={true}
                          />
                        </div>
                        <Select
                          options={branchForSearch && branchForSearch}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                          value={branch}
                          classNamePrefix="select"
                          className="xsm-text col-md-4 d-none d-md-block "
                          onChange={handleBranch}
                          maxMenuHeight="180px"                            
                          placeholder={_t(t("Select a brand")) + ".."}
                        />
                        <div className="col-5 col-md-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                          <button
                            className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded "
                            onClick={handleApplyFilter}
                          >
                            {_t(t("View Report"))}
                          </button>
                        </div>

                        <div className="col-5 col-md-8 t-mb-15 mb-md-0 d-block d-md-none">
                          <button
                            className="btn btn-block btn-primary text-uppercase sm-text rounded"
                            onClick={handleApplyFilter}
                          >
                            {_t(t("View Report"))}
                          </button>
                        </div>
                    </div>*/}
                      <div className="row gx-2 mt-2 mb-3">
                        {cardData
                          ? cardData.map((card) => (
                              <DeicdeVisual
                                type={card.type}
                                data={card.data}
                                heading={card.heading}
                              />
                            ))
                          : ""}
                      </div>
                      
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>
  )
}

export default ConsolitedData
