import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle,
  getDate,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";

const SupplierPaymentEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {id} = useParams();
  //getting context values here
  let {
    loading,
    setLoading,
    branchForSearch    
  } = useContext(RestaurantContext);

  let {
    //supplier payments
    setSupplierPaymentList,
    setSupplierPaymentForSearch,
    supplierForSearch,
    getSupplier
  } = useContext(UserContext);

  // States hook here
  //new item
  let [newSupplierPayment, setNewSupplierPayment] = useState({
    cheque_no: "",
    ref_invoice_no: "",
    payment_type: "cash",
    is_opening: 0,
    bank_name: "",
    remarks: "",
    amount: "",
    branch:null,
    supplier:null  
  });

  //datepicker
  const [documentDate, setDocumentDate] = useState(new Date());

  //datepicker
  const [chequeDate, setChequeDate] = useState(new Date());

  let common_toast = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  }

  const getSupplierPayment = async () => {
    setLoading(true);
    const url =
    BASE_URL +
      "/settings/get-supplier-payment/" +
      parseInt(id);
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res){
            const editPayment = res.data;
            if(editPayment){
              let docDate = new Date(editPayment?.document_date);
              setDocumentDate(docDate);
              let chequeDate = new Date(editPayment?.cheque_date);
              setChequeDate(chequeDate);
              setNewSupplierPayment({ ...newSupplierPayment,
                doc_no: editPayment?.document_identity,
                cheque_no: editPayment?.cheque_no,
                ref_invoice_no: editPayment?.ref_invoice_no,
                payment_type: editPayment?.payment_type,
                bank_name: editPayment?.bank_name,
                is_opening: parseInt(editPayment?.is_opening),
                remarks: editPayment?.remarks,
                amount: editPayment?.amount,
                branch: editPayment?.branch,
                supplier: editPayment?.supplier,
              });
            }
          }
          setLoading(false);
        } catch {
          setLoading(false);
        }
  }

  useEffect(()=>{
    getSupplier()
  },[])

  useEffect(()=>{
   if(id){
    getSupplierPayment();
   }
  },[id]);

  const handleSupplier = (supplier) =>{
    setNewSupplierPayment({
     ...newSupplierPayment,
      supplier
    })
  }

  const handleBranch = (branch) =>{
      setNewSupplierPayment({
       ...newSupplierPayment,
        branch
      })
  }

  const handleChequeNo = (e) => {
    setNewSupplierPayment({ ...newSupplierPayment, cheque_no: e.target.value });
  }

  const handleOpeningType = (e) => {
    const value = parseInt(e.target.value);
    setNewSupplierPayment({ ...newSupplierPayment, is_opening: value, payment_type: "cash" });
    
  }

  const handlePaymentType = (e) => {
    setNewSupplierPayment({ ...newSupplierPayment, payment_type: e.target.value });
  }

  const handleBankName = (e) => {
    setNewSupplierPayment({ ...newSupplierPayment, bank_name: e.target.value });
  }

  const handleInvNo = (e) => {
    setNewSupplierPayment({ ...newSupplierPayment, ref_invoice_no: e.target.value });
  }

  const handleAmount = (e) => {
    setNewSupplierPayment({ ...newSupplierPayment, amount: e.target.value });
  }

  const handleRemarks = (e) => {
    setNewSupplierPayment({ ...newSupplierPayment, remarks: e.target.value });
  }

  //post req of stock item add
  const spItemAxios = async () => {
    let { cheque_no, amount, bank_name, payment_type, ref_invoice_no, is_opening, remarks } = newSupplierPayment
    setLoading(true);
    let date = getDate(documentDate);
    let chq_date = chequeDate && getDate(chequeDate);
    let formData = {
      document_date: date,
      payment_type,
      remarks,
      amount,
      is_opening,
      ref_invoice_no,
      branch_id: newSupplierPayment.branch.id,
      supplier_id: newSupplierPayment.supplier.id
    };

    if (payment_type === "bank") {
      formData["cheque_date"] = chq_date;
      formData["bank_name"] = bank_name;
      formData["cheque_no"] = cheque_no;
    }

    const url = BASE_URL + "/settings/update-supplier-payment/" + id;

    try {
      const res = await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setSupplierPaymentList(res.data[0]);
      setSupplierPaymentForSearch(res.data[1]);
      setLoading(false);
      toast.success(`${_t(t("Supplier payment has been updated"))}`, common_toast);
      history.push(
        "/dashboard/manage/stock/supplier-payment"
      )
    } catch (error) {
      console.log("check error==>", error);
      setLoading(false);
      toast.error(`${_t(t("Please try again later"))}`, common_toast);
    }

  };

  //send to server
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      newSupplierPayment.ref_invoice_no !== "" &&
      documentDate !== null &&
      newSupplierPayment.amount !== "" &&
      newSupplierPayment.branch && newSupplierPayment.supplier
    ) {
      if (newSupplierPayment.payment_type === "bank") {
        if (newSupplierPayment.cheque_no !== "" &&
          chequeDate !== null &&
          newSupplierPayment.bank_name !== "") {
          spItemAxios();
        } else {
          toast.error(`${_t(t("Please Select Cheque date, type Cheque no and bank name"))}`, common_toast);
        }
      }
      else {
        spItemAxios();
      }
    } else {
      toast.error(`${_t(t("Please fill all the required fields"))}`, common_toast);
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Edit Supplier Payment"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Edit Supplier Payment"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>

                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={handleSubmit}
                        >
                          <div className="col-12">
                            <div className="row">
                              <div className="form-group col-4 mt-3">
                                <div className="mb-2">
                                  <label
                                    htmlFor="document no"
                                    className="control-label"
                                  >
                                    {_t(t("Document no"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    readOnly
                                    type="text"
                                    className="form-control table-text"
                                    id="document_no"
                                    name="document_no"
                                    placeholder="Auto no"
                                    required
                                    value={newSupplierPayment.doc_no}
                                  />
                                </div>
                              </div>

                              <div className="form-group col-4 text-right mt-3">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="transfer_date"
                                    className="control-label"
                                  >
                                    {_t(t("Document Date"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <DatePicker
                                    selected={documentDate}
                                    onChange={(date) => setDocumentDate(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("Document date"))}
                                    shouldCloseOnSelect={false}
                                    required
                                  />
                                </div>
                              </div>

                              <div className="form-group mt-3 col-4">
                                <div className="mb-2">
                                    <label
                                        htmlFor="paymentType"
                                        className="control-label"
                                    >
                                        {_t(t("Payment Mode"))}
                                        <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div
                                    className="mb-2 d-flex align-items-center"
                                    style={{ height: "2rem" }}
                                >
                                    <div className="d-flex align-items-center">
                                        <input
                                            style={{ fontSize: "1.2rem" }}
                                            type="radio"
                                            className="form-check-input pointer-cursor table-text"
                                            id="opening"
                                            name="opening"
                                            value="1"
                                            onChange={(e) => {
                                                handleOpeningType(e);
                                            }}
                                            checked={newSupplierPayment.is_opening === 1}
                                        />
                                        <label
                                            className="form-radio-label pointer-cursor ml-2 mt-2"
                                            htmlFor="opening"
                                            style={{ fontSize: "0.9rem" }}
                                        >
                                            {_t(t("Opening"))}
                                        </label>
                                    </div>
                                    <div className="d-flex align-items-center ml-4">
                                        <input
                                            style={{ fontSize: "1.2rem" }}
                                            type="radio"
                                            className="form-check-input pointer-cursor table-text"
                                            id="payment"
                                            name="payment"
                                            value="0"
                                            onChange={(e) => {
                                                handleOpeningType(e);
                                            }}
                                            checked={newSupplierPayment.is_opening === 0}
                                        />
                                        <label
                                            className="form-radio-label pointer-cursor ml-2 mt-2"
                                            htmlFor="payment"
                                            style={{ fontSize: "0.9rem" }}
                                        >
                                            {_t(t("Payment"))}
                                        </label>
                                    </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              {
                                  newSupplierPayment.is_opening === 0 && (
                                    <div className="form-group mt-3 col-3">
                                      <div className="mb-2">
                                        <label
                                          htmlFor="paymentType"
                                          className="control-label"
                                        >
                                          {_t(t("Payment Type"))}
                                          <span className="text-danger">*</span>
                                        </label>
                                      </div>
                                      <div
                                        className="mb-2 d-flex align-items-center"
                                        style={{ height: "2rem" }}
                                      >
                                        <div className="d-flex align-items-center">
                                          <input
                                            style={{ fontSize: "1.2rem" }}
                                            type="radio"
                                            className="form-check-input pointer-cursor table-text"
                                            id="cash"
                                            name="cash"
                                            value="cash"
                                            onChange={(e) => {
                                              handlePaymentType(e);
                                            }}
                                            checked={
                                              newSupplierPayment.payment_type === "cash" ? 1 : 0
                                            }
                                          />
                                          <label
                                            className="form-radio-label pointer-cursor ml-2 mt-2"
                                            htmlFor="cash"
                                            style={{ fontSize: "0.9rem" }}
                                          >
                                            {_t(t("Cash"))}
                                          </label>
                                        </div>
                                        <div className="d-flex align-items-center ml-4">
                                          <input
                                            style={{ fontSize: "1.2rem" }}
                                            type="radio"
                                            className="form-check-input pointer-cursor table-text"
                                            id="bank"
                                            name="bank"
                                            value="bank"
                                            onChange={(e) => {
                                              handlePaymentType(e);
                                            }}
                                            checked={
                                              newSupplierPayment.payment_type === "bank" ? 1 : 0
                                            }
                                          />
                                          <label
                                            className="form-radio-label pointer-cursor ml-2 mt-2"
                                            htmlFor="bank"
                                            style={{ fontSize: "0.9rem" }}
                                          >
                                            {_t(t("Bank"))}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              }

                                <div className={`form-group mt-2 ${ newSupplierPayment.is_opening ? "col-4" : "col-3"}`}>
                                    <div className="mb-2">
                                        <label
                                            htmlFor="bank_name"
                                            className="control-label"
                                        >
                                            {_t(t("Branch"))}
                                            <span className="text-danger">*</span>
                                        </label>
                                    </div>
                                    <div className="mb-2">
                                        <Select
                                        options={branchForSearch ? branchForSearch : []}
                                        components={makeAnimated()}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        classNamePrefix="select"
                                        className="xsm-text"
                                        value={newSupplierPayment.branch}
                                        onChange={handleBranch}
                                        maxMenuHeight="200px"
                                        styles={customStyle}
                                        isClearable
                                        placeholder={_t(t("Select Branch")) + ".."}
                                      />
                                    </div>
                                </div>
                                <div className={`form-group mt-2 ${ newSupplierPayment.is_opening ? "col-4" : "col-3"}`}>
                                    <div className="mb-2">
                                        <label
                                            htmlFor="bank_name"
                                            className="control-label"
                                        >
                                            {_t(t("Supplier"))}
                                            <span className="text-danger">*</span>
                                        </label>
                                    </div>
                                    <div className="mb-2">
                                        <Select
                                        options={supplierForSearch ? supplierForSearch : []}
                                        components={makeAnimated()}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        classNamePrefix="select"
                                        className="xsm-text"
                                        value={newSupplierPayment.supplier}
                                        onChange={handleSupplier}
                                        maxMenuHeight="200px"
                                        styles={customStyle}
                                        isClearable
                                        placeholder={_t(t("Select Supplier")) + ".."}
                                      />
                                    </div>
                                </div>
                                <div className={`form-group mt-2 ${ newSupplierPayment.is_opening ? "col-4" : "col-3"}`}>
                                    <div className="mb-2">
                                        <label
                                            htmlFor="ref_invoice_no"
                                            className="control-label"
                                        >
                                            {_t(t("Ref Invoice No"))}
                                            <span className="text-danger">*</span>
                                        </label>
                                    </div>
                                    <div className="mb-2">
                                        <input
                                        type="text"
                                        className="form-control table-text"
                                        id="ref_invoice_no"
                                        name="ref_invoice_no"
                                        onChange={handleInvNo}
                                        value={newSupplierPayment.ref_invoice_no || ""}
                                        placeholder="Ref invoice no"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                              {newSupplierPayment.payment_type === "bank" && <>

                                <div className="form-group mt-2 col-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="bank_name"
                                      className="control-label"
                                    >
                                      {_t(t("Bank Name"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="text"
                                      className="form-control table-text"
                                      id="bank_name"
                                      name="bank_name"
                                      onChange={handleBankName}
                                      value={newSupplierPayment.bank_name || ""}
                                      placeholder="Bank Name"
                                    />
                                  </div>
                                </div>

                                <div className="form-group mt-2 col-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="cheque_no"
                                      className="control-label"
                                    >
                                      {_t(t("Cheque No"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="number"
                                      step="0.001"
                                      min="0"
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                        evt.preventDefault()
                                      }
                                      onWheel={(e) => e.target.blur()}
                                      className="form-control table-text"
                                      id={"cheque_no"}
                                      name={"cheque_no"}
                                      onChange={handleChequeNo}
                                      value={
                                        newSupplierPayment.cheque_no
                                      }
                                      placeholder="Cheque no"
                                    />
                                  </div>
                                </div>

                                <div className="form-group mt-2 col-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="cheque_date"
                                      className="control-label"
                                    >
                                      {_t(t("Cheque Date"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <DatePicker
                                      selected={chequeDate}
                                      onChange={(date) => setChequeDate(date)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control table-text"
                                      placeholderText={_t(t("Cheque date"))}
                                      shouldCloseOnSelect={false}
                                      required
                                    />
                                  </div>
                                </div></>}


                              <div className="form-group mt-2 col-4">
                                <div className="mb-2">
                                  <label
                                    htmlFor="Amount"
                                    className="control-label"
                                  >
                                    {_t(t("Amount"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    type="number"
                                    step="0.001"
                                    min="0"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                    className="form-control table-text"
                                    id={"amount"}
                                    name={"amount"}
                                    onChange={handleAmount}
                                    value={
                                      newSupplierPayment.amount
                                    }
                                    placeholder="Amount"
                                  />
                                </div>
                              </div>

                              <div className="form-group mt-2 col-8">
                                <div className="mb-2">
                                  <label
                                    htmlFor="Remarks"
                                    className="control-label"
                                  >
                                    {_t(t("Remarks"))}
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    type="text"
                                    className="form-control table-text"
                                    id="remarks"
                                    name="remarks"
                                    onChange={handleRemarks}
                                    value={newSupplierPayment.remarks || ""}
                                    placeholder="Remarks"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group mt-4 pb-2">

                              <div className="d-flex justify-content-end align-items-center">
                                <div className="col-3 d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="btn rounded hov-btn mr-3"
                                    onClick={() => (history.push("/dashboard/manage/stock/supplier-payment"))}
                                  //   data-dismiss="modal"
                                  >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                  </button>
                                  <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-primary rounded"
                                  >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Update"))}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
}

export default SupplierPaymentEdit