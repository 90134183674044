import React, { useEffect, useState } from "react";
import { qtyCounter } from "../helpers";
import "./itemModal.css";
import { toast } from "react-toastify";
import {
  currencySymbolLeft,
  currencySymbolRight,
} from "../../../../../../functions/Functions";

const Property = ({
  name,
  price,
  selected,
  selectGroup,
  initialQty,
  setQty,
  setSelected,
  showCounter,
  itemQty,
  selectedPropertyGroup,
}) => {
  // PACKAGES SETTINGS OBJECT
  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  const [tempQty, setTempQty] = useState(0);

  useEffect(() => {
    if (selected && !tempQty) setTempQty(1);
  }, [selected]);
  useEffect(() => setTempQty(initialQty), [initialQty]);
  return (
    <div
      className={`card m-2 green bg-grid col-3-newclass border-0 pointer item-property-card ${
        selected ? "item-property-card-selected" : ""
      }`}
      onClick={setSelected}
    >
      <div className="card-body text-center counter-card">
        <span className="d-block m-0">{name}</span>
        <span className="d-block m-0">{currencySymbolLeft()} {price}  {currencySymbolRight()}</span>
        {showCounter && selected ? (
          <div className="row-counter row text-center">
            <div
              class="minus col-newclass p-2  pointer"
              onClick={(e) => {
                e.stopPropagation();
                setTempQty(qtyCounter("decreament", tempQty));
                setQty(qtyCounter("decreament", tempQty));
              }}
            >
              <i className="fa fa-minus "></i>
             </div>
              <div className="qty col-6 p-2">
               <div className="rounded-circle d-inline-block bg-light count px-2">
                
                {tempQty}
               </div>

              {/* <div className="rounded-circle d-inline-block bg-light count px-2"
                onClick={(e)=>{
                  e.stopPropagation();
                }}
               >
                 <input 
                 type="number"
                 onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                 className="w-100"
                 value= {tempQty}
                 onChange={(e)=>{
                   e.stopPropagation();
                   e.nativeEvent.stopImmediatePropagation();
                  let qty = e.target.value ;
                  console.log("abcd44",qty,tempQty)
                  if(qty < tempQty){
                      setTempQty(qty);
                      setQty(qty);
                  }else{
                    if (selectGroup?.limit > 0) {
                      if (qty <= selectGroup.limit) {
                          setTempQty(qty);
                          setQty(qty);
                      } else {
                        toast.error("You can't select more than " + selectGroup.limit +" items");
                      }
                    } else {
                       setTempQty( qty);
                       setQty( qty);
                    }
                  }
                 }}
                 />
                </div>*/}
            </div>
            <div
              class="plus col p-2 pointer"
              onClick={(e) => {
                e.stopPropagation();
                if (selectGroup?.limit > 0) {
                  if (itemQty[selectedPropertyGroup] < selectGroup.limit) {
                    setTempQty(qtyCounter("increament", tempQty));
                    setQty(qtyCounter("increament", tempQty));
                  } else {
                    toast.error("You can't select more than " + selectGroup.limit +" items");
                  }
                } else {
                  setTempQty(qtyCounter("increament", tempQty));
                  setQty(qtyCounter("increament", tempQty));
                }
              }}
            >
              <i class="fa fa-plus"></i>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const PropertyGroup = (props) => {
  console.log(props.items);
  const getInitialQty = (property) => {
    if (props.isProperty) {
      if (props.selected && props.selected[property[props.idKey]]) {
        if (props.selected[property[props.idKey]].hasOwnProperty("quantity"))
          return props.selected[property[props.idKey]].quantity;
      }
    }
    return 0;
  };
  const handleQtyChange = (obj, qty) => {
    
    props.setOnClick(obj, qty);
  };
  
  return (
    <>
      <h4 className="font-weight-bold ml-3 mb-0">{props.name}</h4>
      <p className="select-one-text ml-3 my-0"> 
    
      {
       props.name !== "Variations" && props?.selectGroup?.required === 1 ? (
       <span>
          <span className="required">
            *
          </span>
          {
            props?.selectGroup?.multi_select === "custom" ? (
            <span>
              Select Atleast One.
            </span> )
            :
            <span>Mandatory Selection.</span>
          }
       </span> )
        :
         <span>Select Any</span>
      }
       
        <span>
        { props.name !== "Variations" &&
          props?.selectGroup?.limit &&
          props?.selectGroup?.limit > 0 ?
            " You can select " +
              props.selectGroup.limit 
             + " items."
          : ""
        }
        </span>
      </p>
      <div className="container itemModal item-scroll" >
      
        <div className="d-flex flex-row flex-wrap subitems-wrapper">
          {props.items &&
            props.items.map((property, index) => (
              <Property
                key={index}
                selected={Boolean(
                  props.selected && props.selected[property[props.idKey]]
                )}
                name={property[props.nameKey]}
                price={property[props.priceKey]}
                showCounter={
                  property.allow_multi_quantity &&
                  property.allow_multi_quantity === 1
                }
                initialQty={getInitialQty(property)}
                setQty={(qty) => handleQtyChange(property, qty)}
                setSelected={() => {
                  console.log('pos log props.tempQty', props.tempQty)
                  console.log('pos log props.selected', props.selected)
                  if (Boolean(
                    props.selected && props.selected[property[props.idKey]]
                  ) && !(property.allow_multi_quantity &&
                    property.allow_multi_quantity === 1)) {
                      // for deselecting
                      props.setOnClick(property)
                    }
                  else if ( props.selectGroup?.limit > 0 ) {
                    if (
                      (!props.selected  || (
                        props.selected &&
                        Object.keys(props.selected).length==0
                      )) &&
                      props.tempQty[props.selectedPropertyGroup] == 1 &&
                      props.selectGroup?.limit == 1
                    ) {
                      props.setOnClick(property)
                    } else {
                      props.tempQty[props.selectedPropertyGroup] < props.selectGroup?.limit
                        ? props.setOnClick(property)
                        : toast.error("You can't select more than "+ props.selectGroup.limit +" items");
                    }
                  } else {
                    props.setOnClick(property);
                  }
                }}
                itemQty={props.tempQty}
                selectedPropertyGroup={props.selectedPropertyGroup}
                selectGroup={props.selectGroup}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default PropertyGroup;
