import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  getSystemSettings,
  currencySymbolRight,
  customStyle,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

const ItemWise = () => {
  const { t } = useTranslation();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } = useContext(SettingsContext);
  let { branchForSearch } = useContext(RestaurantContext);
  const { partyForSearch, authUserInfo } = useContext(UserContext);
  // States hook here
  // const [amountChart, setAmountChart] = useState({
  //   options: {
  //     chart: {
  //       id: "basic-bar",
  //     },
  //     xaxis: {
  //       categories: [],
  //     },
  //     labels: { show: false },
  //   },
  //   series: [
  //     {
  //       name: _t(t("Amount")),
  //       data: [],
  //     },
  //   ],
  // });

  //all data
  const [reportData, setReportData] = useState(null);
  const [reportSummaryData, setReportSummaryData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branch, setBranch] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [sumQuantity, setSumQuantity] = useState(0);
  const [sumPrice, setSumPrice] = useState(0);
  const [siteName, setSiteName] = useState("");
  const [discount, setDiscount] = useState(0);
  const [cancellation, setCancellation] = useState(0);
  const [refund, setRefund] = useState(0);
  const [saleTax, setSaleTax] = useState(0);
  const [DeliveryCharges, setDeliveryCharges] = useState(0);
  const [netSales, setNetSales] = useState(0);
  const [branchSearch, setBranchSearch] = useState(null);

  //useEffect == componentDidMount()
  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  //Print Report
  const ReportPrint = useRef();

  //Print Report Summary
  const ReportPrintSummary = useRef();

  const handleBranch = (branch) => {
    setBranch(branch);
  };

  //get ItemWise reports filter
  const getItemWiseSelected = () => {
    if ((authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") && branch === undefined) {
      toast.error(`${_t(t("Please select Branch to generate report"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }

    if (startDate !== null && endDate !== null) {
      const sDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
      console.log("start date ", startDate, sDate);
      setLoading(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate =  moment(endDate).format("YYYY-MM-DD");
      
      console.log("start date ", fromDate);
      const url = BASE_URL + "/settings/get-food-item-report";
      let formData = {
        fromDate,
        toDate,
        branch,
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          let formattedAmount = res.data[1].map((item) =>
            parseFloat(formatPrice(item))
          );
          console.log("result : ", res);
          // setAmountChart({
          //   ...amountChart,
          //   options: {
          //     ...amountChart.options,
          //     xaxis: {
          //       ...amountChart.options.xaxis,
          //       categories: res.data[0],
          //       labels: { show: false },
          //     },
          //   },
          //   series: [
          //     { name: amountChart.series[0].name, data: formattedAmount },
          //   ],
          // });
          setReportData(res.data[2]);
          setDiscount(res.data[4]);
          setSaleTax(res.data[5]);
          setDeliveryCharges(res.data[6]);
          setGeneratedReport(true);
          // setRefund(res.data[7]);
          // setCancellation(res.data[8]);          
          // const sum_qty = res.data[2].reduce(function (a, b) {
          //   return a + parseFloat(b.quantity);
          // }, 0);
          // setSumQuantity(sum_qty);          
          // setSumPrice(res.data[9]);
          // setNetSales(res.data[10]);
          // setCancellation(res.data[11]);
          setLoading(false);
          ReportPrint.current.handleDownload();
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select all the field to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  //get ItemWise summary filter
  const getItemWiseSelectedSummary = () => {    
    if (startDate !== null && endDate !== null) {
      setLoading(true);

      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate = moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/item-cost-summary-report";

      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("branch_id", branch ? branch?.id : "");

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          
          setReportSummaryData(res.data);

          setLoading(false);
          setGeneratedReport(true);
          ReportPrintSummary.current.handleDownload();          
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, to date and branch"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  let total_cost = 0;
  let grand_total_cost = 0;
  let avg = 0;
  let tax_amount = 0;
  let total_sales = 0
  let sum_tax_amount = 0;
  let inclusive_vat = 0;
  let after_tax_price = 0;
  let sum_after_tax_price = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Item Food Cost report"))}</title>
      </Helmet>

      <ReactHTMLTableToExcel
        id="test-table-xls-button-summary"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls-summary"
        filename="Item Wise Summary"
        sheet="sheet 1"
        buttonText="Summary as XLS"
        ref={ReportPrintSummary}
      />

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Item Wise Report"
        sheet="sheet 1"
        buttonText="Download as XLS"
        ref={ReportPrint}
      />    

      {/* Print Button */}
      <div className="d-none">
        <div>          
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              {/* <div className="page-break"></div> */}
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colSpan="18" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {/* {siteName + " (" + branch.name + ")"} */}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Item Wise Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Group"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Variation"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Properties"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Qty"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sale Price"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sale Price Variation"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sale Price Properties"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sales Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Tax Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Sales Amount With Tax"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("% of Till"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Cost Price"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Cost Price variation"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Cost Price properties"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Total Cost"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("FC %age"))}
                                  </th>
                                  {/* <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th> */}
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here*/}
                                {Object.keys(reportData).map(
                                  (group_name, index) => {
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {reportData[group_name].map(
                                          (item, i) => {
                                             total_sales += parseFloat(item.price); 

                                            inclusive_vat =
                                              (parseFloat(item.tax) + 100) /
                                              100;
                                            after_tax_price =
                                              item.price / inclusive_vat;

                                            tax_amount =
                                              item.price - after_tax_price;

                                            sum_tax_amount += tax_amount;
                                            sum_after_tax_price +=
                                              after_tax_price;

                                            total_cost =
                                              (parseFloat(item.cost_price) +
                                                parseFloat(
                                                  item.cost_price_variation
                                                ) +
                                                parseFloat(
                                                  item.cost_price_properties
                                                )) *
                                              parseFloat(item.quantity);
                                              avg = total_cost > 0 && item.price > 0 ?
                                                   ((parseFloat(total_cost) / parseFloat(item.price)) * 100) 
                                                   : 0
                                              grand_total_cost += total_cost;
                                              const tillPercent = Number (sumPrice) > 0 ? Number((formatPrice(parseFloat(item.price)) /
                                                                  formatPrice(parseFloat(sumPrice))) * 100).toFixed(2) : 0;

                                            return (
                                              <tr
                                                className="align-middle"
                                                key={i}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {i + 1}
                                                </th>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.food_item}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.food_group}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.variation !== null
                                                    ? item.variation
                                                    : "-"}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.property_items}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.quantity}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.sale_price_item}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.sale_price_variation}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.sale_price_properties}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(item.price)}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(tax_amount)}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(after_tax_price)}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {tillPercent}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.cost_price !== null
                                                    ? item.cost_price
                                                    : 0}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.cost_price_variation !==
                                                  null
                                                    ? item.cost_price_variation
                                                    : 0}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {item.cost_price_properties !==
                                                  null
                                                    ? item.cost_price_properties
                                                    : 0}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(total_cost)}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {formatPrice(avg)}
                                                </td>
                                                {/* <td className="xsm-text align-middle text-center">
                                          <Moment format="LL">
                                            {item.created_at}
                                          </Moment>
                                        </td> */}
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                          <th colspan="4"></th>
                                          <th className="xsm-text align-middle text-left">
                                            Total
                                          </th>
                                          <th className="xsm-text align-middle text-left">
                                            {sumQuantity}
                                          </th>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <th className="xsm-text align-middle text-left">
                                            {formatPrice(total_sales)}
                                          </th>
                                          <th className="xsm-text align-middle text-left">
                                            {formatPrice(sum_tax_amount)}
                                          </th>
                                          <th className="xsm-text align-middle text-left">
                                            {formatPrice(sum_after_tax_price)}
                                          </th>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className="xsm-text align-middle text-left">
                                            {formatPrice(grand_total_cost)}
                                          </td>
                                          <th className="xsm-text align-middle text-left">
                                            {formatPrice( sum_after_tax_price > 0 ? 
                                              ((grand_total_cost / sum_after_tax_price) * 100)
                                              : 0
                                            )}
                                          </th>
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                                {
                                  <>
                                    <tr>
                                      {/* <th colspan="4"></th>
                                      <td className="xsm-text align-middle text-left">
                                        Total
                                      </td>
                                      <td className="xsm-text align-middle text-left">
                                        {sumQuantity}
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(sumPrice)}
                                      </td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(sum_tax_amount)}
                                      </td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(sum_after_tax_price)}
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(grand_total_cost)}
                                      </td>
                                      <td className="xsm-text align-middle text-left">
                                        {formatPrice(
                                          (grand_total_cost /
                                            sum_after_tax_price) *
                                            100
                                        )}
                                      </td> */}
                                    </tr>
                                    {/* <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Gross Sales
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(sumPrice)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Discount
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(discount)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Cancel Order
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(cancellation)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Add Sales Tax
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(saleTax)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Delivery Charges
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(DeliveryCharges)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Net Sales Including Tax
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(netSales)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th colspan="5"></th>
                                      <th className="xsm-text align-middle text-left">
                                        Refund
                                      </th>
                                      <td className="xsm-text align-middle text-center">
                                        {formatPrice(refund)}
                                      </td>
                                    </tr> */}
                                  </>
                                }
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Print Button Summary */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportSummaryData !== null &&
                    reportSummaryData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls-summary"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="11" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {/* {siteName + " (" + branch.name + ")"} */}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Item Wise Report Summary
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>                                
                              </thead>

                              <tbody className="align-middle">
                                {/* loop here*/}
                                {Object.keys(reportSummaryData[0]).map(
                                  (group_name, index) => {
                                    let totalAmount = 0;
                                    let totalCost = 0;
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Branch Name"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Food Group"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Food Item"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Food Item With Addon"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Variation"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Qty"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Rate"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Amount"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Cost Price"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("Cost Amount"))}
                                          </th>
                                          <th
                                            scope="col"
                                            className="sm-text text-capitalize align-middle text-center border-1 border"
                                          >
                                            {_t(t("FC %age"))}
                                          </th>
                                        </tr>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {reportSummaryData[0][group_name].map(
                                          (item, i) => {   
                                            totalAmount += parseFloat(item?.Total);
                                            totalCost += parseFloat(item?.cost_price * item?.quantity);
                                            const fcPercent = Number(item?.Total) > 0 ? parseFloat((parseFloat(item?.cost_price * item?.quantity).toFixed(3)/item?.Total)*100).toFixed(2) : 0;

                                            return (
                                              <tr
                                                className="align-middle"
                                                key={i}
                                              >
                                                <th
                                                  scope="col"
                                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                                >                                                  
                                                </th> 
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.food_group}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.food_sorting}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.food_item}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.variation_name ? item.variation_name : "-"}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.quantity}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.price}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.Total}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.cost_price}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {parseFloat(item?.cost_price * item?.quantity).toFixed(3)}
                                                </td>
                                                <td className="xsm-text align-middle text-center">
                                                  {fcPercent}
                                                </td>                                                                                                                                                                                       
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <td className="xsm-text align-middle text-left">
                                            <strong>Total Amount</strong>
                                          </td>
                                          <td className="xsm-text align-middle text-left">
                                            {parseFloat(totalAmount).toFixed(2)}
                                          </td>
                                          <td></td>                                          
                                          <td className="xsm-text align-middle text-left">
                                            {parseFloat(totalCost).toFixed(2)}
                                          </td>
                                          <td className="xsm-text align-middle text-left">
                                            {parseFloat((parseFloat(totalCost).toFixed(2)/parseFloat(totalAmount).toFixed(2))*100).toFixed(2)}
                                          </td>                                                                                  
                                        </tr>

                                        {/* Branch Calculation */}
                                        {reportSummaryData[1] && reportSummaryData[1] !== null && <>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>Order Amount</strong></td>
                                            <td>{reportSummaryData[1][group_name][0]?.order_bill}</td>                                                                                                                                                                                
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>Tax</strong></td>
                                            <td>{reportSummaryData[1][group_name][0]?.tax}</td>                                                                                                                                                                                
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>Delivery Charges</strong></td>
                                            <td>{reportSummaryData[1][group_name][0]?.service_charge}</td>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>Discount</strong></td>
                                            <td>{reportSummaryData[1][group_name][0]?.discount}</td>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>Total</strong></td>
                                            <td>{reportSummaryData[1][group_name][0]?.order_bill + reportSummaryData[1][group_name][0]?.tax + parseInt(reportSummaryData[1][group_name][0]?.service_charge) - reportSummaryData[1][group_name][0]?.discount}</td>
                                          </tr>
                                          <tr></tr>
                                        </>}
                                      </div>
                                    );
                                  }
                                )}                                
                              </tbody>
                                                    
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <>
                        <div key="smtp-form mt-2">
                          <div className="row gx-2 align-items-center t-pt-2 t-pb-2">
                            <div className="col-12 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize mt-3">
                                    {_t(t("Item Food Cost Report"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-2">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("From Date"))}
                                shouldCloseOnSelect={false}                                
                              />
                            </div>

                            <div className="col-2">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("To Date"))}
                                shouldCloseOnSelect={false}                                
                              />
                            </div>


                            <div className="col-3">
                              <Select
                                options={branchSearch ? branchSearch : []}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={branch}
                                styles={customStyle}
                                classNamePrefix="select"
                                className="table-text"                               
                                onChange={handleBranch}
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a branch")) + ".."}
                              />
                            </div>

                            <div className="col-1">
                            </div>
                            
                            <div className="col-4 d-flex justify-content-end">
                              {/* Action Button */}
                             <button
                                className="btn btn-primary sm-text text-capitalize mr-2 px-2 rounded"
                                onClick={() => getItemWiseSelected()}
                              >
                                GENERATE REPORT
                              </button>
                              <button
                                className="btn btn-primary sm-text px-2 rounded text-capitalize"
                                onClick={() => getItemWiseSelectedSummary()}
                              >
                                GENERATE SUMMARY
                              </button>
                             
                            </div>                         
                          </div>                          
                        </div>
                       </>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default ItemWise;
