import React from 'react';
import { Modal } from 'react-bootstrap';

const KdsModal = ({show,handleClose,itemData}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title className='m-0'><h5 className='m-0'>{itemData.token}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{itemData.name}</h6>
          {/* Full-size image */}
          <div className='text-center'>
            <img
              src={itemData.url}
              alt="Full-size"
              className="img-thumbnail"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default KdsModal
