import React, { useEffect, useContext, useState,useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from "react-moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import { MdDelete } from 'react-icons/md';
// import StockReport from "./StockReport";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import { FoodContext } from "../../../../../contexts/Food"
import { FinanceContext } from "../../../../../contexts/Finance";

const LedgerReport = () => {
  const { t } = useTranslation();
  let { generalSettings } =
      useContext(SettingsContext);
    //finance context
    const {
        loading,
        setLoading,
        //coa3
        coa3ForSearch,
    } = useContext(FinanceContext);

    //toast obj
    let common_toast={
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }

    //reportFields state
    const [reportFields,setReportFields]=useState({
      from_date:"",
      to_date:"",
      account:"",
    });

    //report states
    const [reportData, setReportData] = useState([]);
    const [generatedReport, setGeneratedReport] = useState(false);
  
    //select style
    const customStyle = {
        control: (provided) => ({
          ...provided,
          minHeight : '2rem',
          marginTop:"0",
          marginBottom:"0",
        
        }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        singleValue: (provided) =>({
          ...provided,
          alignSelf: "center",
        }),
        input:(provided)=>({
          ...provided,
         margin:"0",
        }),
        valueContainer: (provided) => ({
          ...provided,
          marginTop:"0",
          padding:"0 0.5rem",
         
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding:"0 0.4rem",
          "svg": {
            height: '2rem',
          }
        }),
      }

      const componentRef = useRef();

      const handleFromDate=(from_date)=>{
        setReportFields({
          ...reportFields,
          from_date
        })
      }

      const handleToDate=(to_date)=>{
        setReportFields({
          ...reportFields,
          to_date
        })
      }

      const handleAccount=(account)=>{
        setReportFields({
          ...reportFields,
          account
        })
      }

      const handleSubmit=(e)=>{
        e.preventDefault();
        let {from_date , to_date ,account} = reportFields
        if((from_date !== "" && from_date !== null) && (to_date !== "" && to_date !== null)){
          // setLoading(true);
          const url = BASE_URL + "/settings/get-consumption-report";
          let formData = new FormData();
          formData.append('from_date', moment(from_date).format("YYYY-MM-DD"));
          formData.append('to_date', moment(to_date).format("YYYY-MM-DD"));
          formData.append('coa_id', account ? account.id : "");

          componentRef.current.handleDownload();

          // return axios
          // .post(url, formData, {
          //   headers: { Authorization: `Bearer ${getCookie()}` },
          // })
          // .then((res) => {
          //   // setReportData(res.data[0]);
          //   // setGeneratedReport(true);
          //   // componentRef.current.handleDownload();            
          //   setLoading(false);
          // })
          // .catch((error) => {            
          //   setLoading(false);
          //   toast.error(`${_t(t("Please try again"))}`,common_toast);
          // });
          
        }else{
          toast.error(`${_t(t("Please select from date & to date"))}`,common_toast);
        }
      }


  return (
    <>
    <Helmet>
      <title>{_t(t("Accounts Ledger Report"))}</title>
    </Helmet>

    <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-xls"
      filename="Accounts Ledger Report"
      sheet="sheet 1"
      buttonText="Report"
      ref={componentRef}
    />

    {/* Print Button */}
    <div className="d-none">
      <div>
        <style type="text/css" media="print">
          {
            "\
        @page { size: landscape; }\
      "
          }
        </style>
        <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {
                // reportData !== null &&
                //   reportData !== undefined &&
                //   generatedReport === true && 
                  (
                    <>
                      <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                          <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="table-to-xls"
                          >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                              <tr style={{ border: "0px" }}>
                                <th colspan="12" style={{ border: "0px" }}>
                                  <div className="row">
                                    <div className="col-12">
                                      <h3 className="text-center mb-0">
                                        {getSystemSettings(generalSettings, "siteName")}
                                      </h3>
                                      <h3 className="text-center mt-0">
                                        Accounts Ledger Report
                                      </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {reportFields.from_date}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{reportFields.to_date}</Moment>
                                        </p>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                              <tr>
                              <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                            >
                            {_t(t("Code"))}
                            </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Doc Date"))}
                                </th>
                                
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Doc No"))}
                                </th>
                                
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Cheque No"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Remarks"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Debit"))}
                                </th>
                               
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Credit"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Balance"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {console.log("309 ", reportData)}
                              {/* loop here*/}
                              { reportData &&
                                Object.keys(reportData).map(
                                  (group_name, index) => {
                                    let Amount = 0; 
                                    let stock_Qty = 0;
                                    
                                    return(
                                    <div key={index}>
                                  
                                    <tr>
                                    <th
                                      scope="row"
                                      className="lg-text text-capitalize align-middle text-center"
                                    >
                                      {group_name}
                                    </th>
                                  </tr>
                              {reportData[group_name].map((item, i) => {
                                 Amount += parseFloat(item.amount);
                                 stock_Qty += parseFloat(item.stock_qty);
                                
                                return (
                                  <tr>
                                  <td></td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center "
                                    >
                                      {i + 1}
                                    </td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                    <Moment format="LL">

                                      {item.document_date}
                                      </Moment>
                                    </td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.branch_name}
                                    </td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.unit_name}
                                    </td>
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.stock_qty}
                                    </td>
                                   {/* <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {item.item_name}
                                    </td>  */}
                                    
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {formatPrice(item.avg_rate)}
                                    </td>
                                  
                                    <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {formatPrice(item.amount)}
                                    </td>
                                  </tr>
                                );
                              })}
                               <tr>
                                <th
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle text-center"
                                >
                                  Total
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            
                              <td
                              scope="row"
                              className="xsm-text text-capitalize align-middle text-center"
                              >
                                {formatPrice(stock_Qty)}
                              </td>
                              <td></td>
                              <td
                              scope="row"
                              className="xsm-text text-capitalize align-middle text-center"
                              >
                                {formatPrice(Amount)}
                              </td>
                               </tr>
                              </div>
                              );
                            }
                            )
                           }
                              {/*<tr>
                                <th className="sm-text text-capitalize align-middle text-center border-1 border"></th>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border"></td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                  Total
                                </td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                  {formatPrice(stock_inn)}
                                </td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                  {formatPrice(stock_out)}
                                </td>
                                <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                  {formatPrice(amount)}
                                </td>
                             </tr>*/}
                            </tbody>
                            

                            {/* <tfoot style={{ border: "0px" }}>
                              <tr style={{ border: "0px" }}>
                                <td
                                  colspan="7"
                                  className="text-center"
                                  style={{ border: "0px" }}
                                >
                                  <h5 className="mt-3">
                                    Powered by indolj.pk
                                  </h5>
                                </td>
                              </tr>
                            </tfoot> */}
                          </table>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <AccountsSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${(loading) && "loadingBlur"}`}></div>
                  <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {/* {loading === true ? (
                    tableLoading()
                  ) : ( */}
                    <div key="fragment-3">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Ledger Report"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center"></div>
                        </div>
                      </div>

                      {/* Form starts here */}
                      <form
                        className="row card p-2 mx-3 mb-5 table-text"
                        onSubmit={handleSubmit}
                      >
                        <div className="col-12">
                          <div>                            
                            <div className="row">
                            <div className="form-group col-3 text-right mt-2">
                              <div className="mb-2 text-left">
                                <label
                                  htmlFor="fromDate"
                                  className="control-label"
                                >
                                  {_t(t("From Date"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <DatePicker
                                  selected={reportFields.from_date}
                                  onChange={(date) => handleFromDate(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control table-text"
                                  placeholderText={_t(t("From Date"))}
                                  shouldCloseOnSelect={false}                                  
                                />
                              </div>
                            </div>

                            <div className="form-group col-3 text-right mt-2">
                              <div className="mb-2 text-left">
                                <label
                                  htmlFor="toDate"
                                  className="control-label"
                                >
                                  {_t(t("To Date"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <DatePicker
                                  selected={reportFields.to_date}
                                  onChange={(date) => handleToDate(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control table-text"
                                  placeholderText={_t(t("To Date"))}
                                  shouldCloseOnSelect={false}                                  
                                />
                              </div>
                            </div>

                            <div className="form-group mt-2 col-4">
                              <div className="mb-2 text-left">
                                <label
                                  htmlFor="transaction"
                                  className="control-label"
                                >
                                  {_t(t("Chart Of Account"))}                                  
                                </label>
                              </div>
                              <Select
                                options={coa3ForSearch ? coa3ForSearch : []}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.level3_name}
                                getOptionValue={(option) => option.id}
                                value={reportFields.account || ""}
                                classNamePrefix="select table-text"
                                onChange={(acc)=>handleAccount(acc)}                                
                                placeholder={
                                  _t(t("Please select an account")) + ".."
                                }
                                styles={customStyle}
                                required
                              />
                              </div>
                            
                              <div className="form-group mt-4 col-2">                                                                                                                        
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded mt-2"
                                >
                                    <i className="fa fa-table" aria-hidden="true"></i> {_t(t("Print Ledger"))}
                                </button>                                                                                                                                                                                       
                              </div>
                            </div>

                             
                        </div>                                                                        
                        </div>
                      </form>
                    </div>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
      {/* main body ends */}
  </>
  )
}

export default LedgerReport;
