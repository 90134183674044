import React, { useState, useContext,} from "react";
import { NavLink } from "react-router-dom";
import Switch from "react-switch";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const KdsSettingList = () => {
    const { t } = useTranslation();
    //getting context values here
    let {
      //common
      loading,
      setLoading,
    } = useContext(SettingsContext);
  
    let {
      //KDS Setting
      kdsList,
      setKdsList,
      kdsListForSearch,
      setKdsListForSearch,
      // getKdsList,
  
      branchForSearch,
      //pagination
      dataPaginating,
    } = useContext(RestaurantContext);

    //search result
    let [searchedKitchen, setSearchedKitchen] = useState({
      list: null,
      searched: false,
    });
  
    //search purchase here
    const handleSearch = (e) => {
      let searchInput = e.target.value.toLowerCase();
      if (searchInput.length === 0) {
        setSearchedKitchen({ ...searchedKitchen, searched: false });
      } else {
        let searchedList = kdsListForSearch.filter((item) => {
          //name
          let lowerCaseName = item.name.toLowerCase();
          return (
            lowerCaseName.includes(searchInput)
          );
        });
        setSearchedKitchen({
          ...searchedKitchen,
          list: searchedList,
          searched: true,
        });
      }
    };


    const handleUpdateStatus = (item) => {
      setLoading(true)
      let formData = {
        id: item.id,
        status: item.status == 1 ? 0 : 1,
      };

    const url = BASE_URL + `/settings/kdssettings-status`;
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setKdsList(res.data);
        setKdsListForSearch(res.data);
        setSearchedKitchen({
          ...searchedKitchen,
          list: res.data,
        });        
        toast.success(`${_t(t("Status has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
    }
  
    return (
      <>
        <Helmet>
          <title>{_t(t("KDS Setting"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div className="fk-scroll--pos-menu" data-simplebar>
                    <div className="t-pl-15 t-pr-15">                     
                        <div key="fragment3">
                          {/* next page data spin loading */}
                          <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          {/* spin loading ends */}
  
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {!searchedKitchen.searched
                                      ? _t(t("KDS Setting List"))
                                      : _t(t("Search Result"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center">
                                {/* Search customer */}
                                <div className="col-md-9 t-mb-15 mb-md-0">
                                  <div className="input-group">
                                    <div className="form-file">
                                      <input
                                        type="text"
                                        className="form-control border-0 form-control--light-1 rounded-left"
                                        placeholder={_t(t("Search")) + ".."}
                                        onChange={handleSearch}
                                      />
                                    </div>
                                    <button
                                      className="btn btn-primary rounded-right"
                                      type="button"
                                    >
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
  
                                {/* Add customer modal trigger button */}
                                <div className="col-md-3 text-md-right">
                                  <NavLink
                                    to="/dashboard/manage/restaurant/kds-setting-add"
                                    className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  >
                                    {_t(t("add new"))}
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Table */}
                          <div className="">
                            <table className="table table-bordered table-hover min-table-height">
                              <thead className="align-middle">
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Kitchen Name"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Status"))}
                                  </th>                                  
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Action"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                {!searchedKitchen.searched
                                  ? [
                                    kdsList && [
                                      kdsList.length ===
                                        0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        kdsList.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1}
                                                </th>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>
  
                                                <td className="xsm-text align-middle text-center">
                                                  <Switch
                                                  checked={item?.status}
                                                  name="status"
                                                  onChange={(e) => {
                                                    handleUpdateStatus(item)                                                  
                                                  }}
                                                  height={22}
                                                  width={44}
                                                  offColor="#ee5253"
                                                  />
                                                </td>                                                
  
                                                <td className="xsm-text text-capitalize align-middle text-center">                                                
                                                  <div className="d-flex justify-content-center">                                                    
                                                    <NavLink
                                                    className="btn btn-primary ml-2 rounded-sm"
                                                    to={`/dashboard/manage/restaurant/kds-setting-edit/${item.id}`}
                                                    style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                   >
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                    </div>
                                                   </NavLink>                                                    
                                                    </div>
                                                  
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]
                                  : [
                                    /* searched data, logic === haveData*/
                                    searchedKitchen && [
                                      searchedKitchen.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedKitchen.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1}
                                                </th>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>
  
                                                <td className="xsm-text align-middle text-center">
                                                  <Switch
                                                  checked={item?.status}
                                                  name="status"
                                                  onChange={(e) => {
                                                    handleUpdateStatus(item)                                                  
                                                  }}
                                                  height={22}
                                                  width={44}
                                                  offColor="#ee5253"
                                                  />
                                                </td>                                                
  
                                                <td className="xsm-text text-capitalize align-middle text-center">                                                
                                                  <div className="d-flex justify-content-center">                                                    
                                                    <NavLink
                                                    className="btn btn-primary ml-2 rounded-sm"
                                                    to={`/dashboard/manage/restaurant/kds-setting-edit/${item.id}`}
                                                    style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                   >
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                    </div>
                                                   </NavLink>                                                    
                                                    </div>
                                                  
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                              </tbody>
                            </table>
                          </div>
                        </div>                    
                    </div>
                  </div>
                </div>                
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>        
      </>
    );
}

export default KdsSettingList