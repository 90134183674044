import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartThemeColors } from "./index";


const LineWidget = ({ data, heading, size, handleReq=null, url=null, loading=false }) => {
    const [formatData, setFormatData] = useState(null);
    const [branchData,setBranchData] = useState(null);
    
    useEffect(() => {
      setBranchData([]);
      if(data?.date || data?.values){
        setFormatData(data.date);
        const finalData = [];
        data?.values && Object.keys(data.values).forEach((key, index) => {
          finalData.push({
              name: key,
              data: Object.values(data.values)[index],
          });
        });
        setBranchData(finalData);
      }else{
        setFormatData(null)
      }
    }, [data]);
      
  return (
    <div className={`col row mx-0 mt-3 ${size} px-2 position-relative`}>
      <div className="row px-0 mx-0 justify-content-between col col-12 ">
        <div className="dashboard__card col col-12 w-100 flex-basis-100">
          {heading ? <h5 className="mb-1 mt-0">{heading}</h5> : ""}
          <div className="d-flex flex-col mx-3 my-2" style={{ gap: "1rem" }}>
          </div>
          {branchData && formatData ? (
            <Chart
              width="100%"
              height="300px"
              type="line"
              series={branchData}
              options={{
                chart: {
                  type: "line",
                  toolbar: {
                    show: false
                  }
                },
                markers: {
                    size: [5, 5]
                },
                stroke: {
                    curve: 'smooth',
                  },
                plotOptions: {
                  bar: {
                    distributed: true,
                    borderRadius: 10,
                  },
                },
                colors: chartThemeColors,
                dataLabels: {
                  enabled: false,
                },
                tooltip: {/*
                  enabled: true,
                  custom: ({ series, seriesIndex, dataPointIndex }) =>
                  console.log("abcd44",seriesIndex,series,dataPointIndex)
                    `<div class='bar-widget__tooltip'>
                <p class='bar-widget__tooltip-primary'>${
                    formatData.bar[dataPointIndex].value_formatted
                    ? parseFloat(formatData.bar[dataPointIndex].value_formatted)?.toFixed(2)
                    : parseFloat(formatData.bar[dataPointIndex].value)?.toFixed(2)
                }</p>
                
                </div>`,
              */},
                xaxis: {
                  categories: formatData?.map(date => date),
                  labels: { 
                    show: true,
                    style:{
                      fontSize:"10.5px"
                    }
                  },
                },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return value ? value?.toFixed(0) : value;
                    },
                    style:{
                      fontSize:"10.5px"
                    }
                  },
                  tickAmount: 4,
                },
                legend:{
                  position:"top",
                  fontSize:"10.5px",
                  offsetY:0,
                }
              }}
            />
          ):(
            <div className="btn_load btn_load_radial">
              {
                loading ? (
                  <>
                    {/*loading */}
                    <div className={`${(loading) && "loadingBlur"}`}></div>
                    <div className={`${(loading) && "lds-roller"}`} style={{top:"38%",left:"47%"}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </>
                ) : (
                  <button className="btn btn-sm btn-primary rounded-md" onClick={()=>url && handleReq(url)}>Load Data</button>
                ) 
              }
            </div>
          )}
        
        </div>
      </div>
    </div>
  )
}

export default LineWidget
