import React, { useContext } from "react";
import {
  _t,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { SettingsContext } from "../../../../../contexts/Settings";
import "../stock/stock.css"

const SupplierPaymentPrint = ({componentRef, thermalPrintData, cheque_date, doc_date}) => {
    const { generalSettings } = useContext(SettingsContext);
    
  return (
    
    <>
       {/* Thermal Print expense */}
      <div className="d-none">
      <div ref={componentRef}>
        {  thermalPrintData &&        
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div>
                      <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                        Supplier Payment
                      </span>                    
                      <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                        {getSystemSettings(generalSettings, "siteName")}
                      </span>                    
                      <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                        {thermalPrintData.branch.name}
                      </span>
                    </div>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className=" mt-4 d-block fk-print-text_pos text-uppercase print-text">                                         
                      Document Date :                                                             
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">                                         
                      {doc_date?.toDateString()}                                                               
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      Document No :        
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      {thermalPrintData.doc_no}        
                    </span>
                  </div>                  

                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      Supplier Name :
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      {thermalPrintData.supplier.name}
                    </span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      Payment Type :
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      {thermalPrintData.payment_type}
                    </span>
                  </div>
                  
                  {thermalPrintData.payment_type==="bank" && <><div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      Bank Name :
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      {thermalPrintData.bank_name}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      Cheque No :
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      {thermalPrintData.cheque_no}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      Cheque Date :
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      {cheque_date?.toDateString()}
                    </span>
                  </div></>}
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      Ref Inv No :
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase table-text">
                      {thermalPrintData.ref_invoice_no}
                    </span>
                  </div>
                  <div className="w-100 d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text" >
                      Amount :
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase table-text">
                      {thermalPrintData.amount}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      Remarks :        
                    </span>
                    <span className="mt-4 d-block fk-print-text_pos text-uppercase print-text">
                      {thermalPrintData.remarks}        
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 mb-2 d-block fk-print-text_pos font-weight-bold text-uppercase table-text" style={{width:"20%"}}>
                      Signature:
                    </span>
                    <span className="mt-4 mb-2 d-block fk-print-text_pos font-weight-bold text-uppercase table-text" style={{width:"60%",textAlign:"right"}}>
                      ________________
                    </span>                                       
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-between w-100">
                    <span className="mt-4 mb-2 d-block fk-print-text_pos font-weight-bold text-uppercase table-text" style={{width:"20%"}}>
                      Cashier:
                    </span>
                    <span className="mt-4 mb-2 d-block fk-print-text_pos font-weight-bold text-uppercase table-text" style={{width:"60%",textAlign:"right"}}>
                      ________________
                    </span>                                       
                  </div>                                             */}
             </div> 
            </div> 
           </div> 
          </div>           
        }
      </div> 
    </div>
    </>
  )
}

export default SupplierPaymentPrint