import React from "react";
import { Route, Redirect } from "react-router-dom";

//importing context consumer here

//functions
import { getCookie } from "../functions/Functions";

//3rd party packages
const CustomerRoute = ({ children, ...rest }) => {
  //getting context values here
  return (
    <Route
      {...rest}
      render={() => {
        return getCookie() !== undefined ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        );
      }}
    ></Route>
  );
};
export default CustomerRoute;
