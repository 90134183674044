import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";
import DatePicker from "react-datepicker";

const toastObj = {
  position: "bottom-center",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  className: "text-center toast-notification",
}

const FinishGoodsAdjustmentView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    //getting context values here
    let {
      //common
      loading,
      setLoading,
    } = useContext(SettingsContext);
  
    const {
      branchForSearch,      
    } = useContext(RestaurantContext)

    let {
        // foodGroupForSearch,
        foodForPOS,
    } = useContext(FoodContext);      

    //select style
 const customStyle = {
  control: (provided) => ({
    ...provided,
    minHeight: "2rem",
    marginTop: "0",
    marginBottom: "0",
  }),
  placeholder:(provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    alignSelf: "center",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: "0",
    padding: "0 0.4rem",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 0.4rem",
    svg: {
      height: "2rem",
    },
  }),
};
  
    // States hook here
    //new customer
    let [newGoodsAdjustment, setNewGoodsAdjustment] = useState({
      adjust_no: "",
      adjust_date: null,
      branch: null,
      remarks: '',      
    }); 

    const [editStock,setEditStock]=useState(null);
    //row id
    let rowId = useRef(0);
    const [newRow,setNewRow]=useState([]);

    //Get Specific item for edit
  const getFinishedGoodsItem = async () => {
    setLoading(true);
    const url =
    BASE_URL +
      "/settings/finished-goods-adjustment/" +
      parseInt(params.id);
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res){            
            setEditStock(res.data)
          }
          setLoading(false);
        } catch {
          setLoading(false);
          toast.error(`${_t(t("Something went wrong"))}`, toastObj);
        }
      };
    
    useEffect(()=>{
        getFinishedGoodsItem();
    },[])

    useEffect(()=>{
        if(editStock && editStock !== null){
          let docDate=new Date(editStock?.document_date);
          let branch=branchForSearch?.find((item)=>editStock?.branch_id === item.id)          
          setNewGoodsAdjustment({
            ...newGoodsAdjustment,
            adjust_date:docDate,
            adjust_no: editStock?.document_identity,
            branch,
            remarks:editStock?.remarks,
          })        
          let rowArr=[];
          if(editStock?.finished_good_adjustment_details && editStock?.finished_good_adjustment_details.length > 0){
            editStock.finished_good_adjustment_details.map((item,index)=>{
              let food_item = foodForPOS?.find((v)=>v.id===item.item_id);            
              rowArr.push({
                id: rowId.current,
                item_name: food_item,
                qty:parseInt(item.qty),               
                actual_stock:parseInt(item.actual_stock),
                adjusted_qty:parseInt(item.adjusted_qty), 
              })
              rowId.current += 1
            })
            setNewRow([...rowArr]);
          }
        }
        },[editStock,branchForSearch])
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Goods adjustment View"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div className="fk-scroll--pos-menu" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(loading) && "loadingBlur"}`}></div>
                            <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {/* {loading === true ? (
                    tableLoading()
                  ) : ( */}
                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("View Goods Adjustment"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center"></div>
                        </div>
                      </div>  

                      <form
                      className="row card p-2 mx-3 mb-5 table-text"
                      autoComplete="off"                   
                  >
                    <div className="col-12">
                    <div className="row">
                      <div className="form-group col-4 mt-3">
                        <label htmlFor="adjust_no" className="form-label">
                          {_t(t("Adjustment Number"))}{" "}
                          <span className="text-primary">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control table-text"
                          id="adjust_no"
                          name="adjust_no"
                          placeholder="Auto no"
                          value={newGoodsAdjustment.adjust_no || ""}
                          disabled                          
                        />
                      </div>

                      <div className="form-group col-4 mt-3">
                        <label
                          htmlFor="adjust_date"
                          className="form-label"
                        >
                          {_t(t("Adjustment Date"))}
                          <span className="text-danger">*</span>
                        </label>
                        <DatePicker
                          selected={newGoodsAdjustment.adjust_date}                        
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control table-text"
                          placeholderText={_t(t("Adjustment Date"))}
                          disabled={true}
                        />
                      </div>

                      <div className="form-group col-4 mt-3">
                        <label htmlFor="branch" className="form-label">
                        {_t(t("Branch"))}
                        <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={branchForSearch}
                          value={newGoodsAdjustment.branch || null}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"                        
                          maxMenuHeight="200px"
                          placeholder={
                            _t(t("Please select a branch")) + ".."
                          }
                          isDisabled={true}
                          styles={customStyle}
                        />
                      </div>
                    </div>                    

                    <div className="row">  
                    <div className="form-group mt-3 col-12">
                        <label htmlFor="remarks" className="form-label">
                          {_t(t("Remarks"))}{" "}
                        </label>
                        <input
                        readOnly
                          type="text"
                          className="form-control table-text"
                          id="remarks"
                          name="remarks"
                          placeholder="Remarks"
                          value={newGoodsAdjustment.remarks || ""}                  
                        />
                      </div>                      
                    </div>
                                  
                    <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>                                      
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"15rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item Name"))}
                                          </span>
                                        </th>                                                                                

                                        <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{minWidth: '100px'}}
                                        >
                                            <span style={{fontWeight:500}}>
                                                {_t(t("Actual Stock"))}
                                            </span>
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{minWidth: '100px', maxWidth: '200px'}}
                                        >
                                            <span style={{fontWeight:500}}>
                                                {_t(t("Qty"))}
                                            </span>
                                        </th>

                                        <th
                                            scope="col"
                                            className="table-text text-capitalize align-middle text-center border-1 border"
                                            style={{minWidth: '100px'}}
                                        >
                                            <span style={{fontWeight:500}}>
                                                {_t(t("Adjusted Qty"))}
                                            </span>
                                        </th>                                        
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (finishGoods, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >                                              
                                              <td className="table-text text-capitalize align-middle text-center">
                                                <Select
                                                  isDisabled={true}
                                                  menuPosition="fixed"
                                                  options={foodForPOS !== null ? foodForPOS : []}
                                                  components={makeAnimated()}
                                                  getOptionLabel={(option) => option.name}
                                                  getOptionValue={(option) => option.id}                                                
                                                  value={finishGoods.item_name}
                                                  styles={customStyle}
                                                  placeholder={
                                                  _t(t("Please select items")) + ".."
                                                  }                                                
                                                />
                                              </td>                                              
                                              
                                              <td className="table-text text-capitalize align-middle text-center">
                                                {finishGoods.actual_stock}
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                              <input
                                              readOnly
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={finishGoods.id}
                                                  name={finishGoods.id}
                                                  value={finishGoods.qty}                                                
                                                  placeholder="Qty"
                                                  required                                                  
                                                />
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                                {finishGoods.adjusted_qty}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}
                                  </table>
                                </div> 

                    <div className="form-group mt-4 pb-2">                            
                        <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/finished-goods-adjustment"))}                                
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>                                
                            </div>                                                    
                        </div>
                    </div>
                    </div>
                  </form>                    
                    </div>
                  {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default FinishGoodsAdjustmentView;