import React, { useContext,useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

//importing context consumer here
import { UserContext } from "../contexts/User";
//context consumer
import { SettingsContext } from "../contexts/Settings";
import { RestaurantContext } from "../contexts/Restaurant";
import { FoodContext } from "../contexts/Food";
//functions
import { _t, getCookie } from "../functions/Functions";
import { BASE_URL } from "../BaseUrl";
import axios from "axios";
//3rd party packages
const RestaurantRoute = ({ children, ...rest }) => {

   //getting context values here
   let {
    loading,
    getPermissionGroups,
    getLanguages,
    getCurrency,
  } = useContext(SettingsContext);

  let {
    getBranch,
    getTable,
    getScheme,
    getCard,
    getDeptTag,
    getOrderType,
    getPaymentType,
    getPaymentTypeTax,
    getWorkPeriod,

  } = useContext(RestaurantContext);

  let {
    getFood,
    getFoodGroup,
    getFoodGroupForPOS,
    getPropertyGroup,
    getVariation,
    getFoodItemsForPOS,
  } = useContext(FoodContext);
  //getting context values here
  const { authUserInfo,getParty,getAuthUser } = useContext(UserContext);
 
  const offline = (user) => {
    return !navigator.onLine && localStorage.getItem(user) != null;
  }
 const user = BASE_URL + "/auth/user";

 useEffect(()=>{
     if(offline(user)){
      //getAuthUser();
      //  getPermissionGroups();
      //   getBranch();
      //   getLanguages();
      //   getCurrency();
      //   getWorkPeriod();
      //   getTable();
      //   getScheme();
      //   getCard();
      //   getDeptTag();
      //   getPaymentType();
      //   getPaymentTypeTax();
      //    getFood();
      //     getParty();
      //   getFoodGroup();
      //   getFoodGroupForPOS();
      //   getFoodItemsForPOS();
      //   getPropertyGroup();
      //   getVariation();
      //   getOrderType();
     }
 },[])

  //redirect if customer
  if (authUserInfo.details && authUserInfo.details.user_type === "customer") {
    return (
      <Route
        render={() => {
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }}
      ></Route>
    );
  }

  return (
    <Route
      {...rest}
      render={() => {
        return getCookie() !== undefined || offline(user) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { alert: "You need to login first!" },
            }}
          />
        );
      }}
    ></Route>
  );
};
export default RestaurantRoute;
