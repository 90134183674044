import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import makeAnimated from "react-select/animated";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatetimeRangePicker from "react-datetime-range-picker";
import { MdDelete } from 'react-icons/md';

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import { FoodContext } from "../../../../../contexts/Food";
import { FinanceContext } from "../../../../../contexts/Finance";

import { data } from "jquery";

const ChartAccount2 = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //finance context
    const {
      loading,
      setLoading,

      // coa level 1
        coa1,
        setCoa1,
        coa1ForSearch,
        setCoa1ForSearch,
        setPaginatedCoa1,

      //coa2
      coa2, 
      setCoa2,
      coa2ForSearch,
      setCoa2ForSearch,
      getCoaL2,
      setPaginatedCoa2,

        //pagination
        dataPaginating,
        setDataPaginating,
    } = useContext(FinanceContext);
  
    const [chartOfAccount,setChartOfAccount] = useState({
      id:null,
      account_type:"",
      chart_of_acc_l1:"",
      code:"",
      name:"",
      status:1,
      edit: false,
      uploading: false,
    })
    
    //search result new
    let [searchedCoa2, setSearchedCoa2] = useState({
      list: null,
      searched: false,
    });
    
    //new
    const handleSetCoa1 = (account) =>{
      setChartOfAccount({
       ...chartOfAccount,
       chart_of_acc_l1:account,
      });
     }

    const handleSetCode = (e) =>{
      setChartOfAccount({
       ...chartOfAccount,
       code:e.target.value,
      });
     }

     const handleSetName = (e) =>{
      setChartOfAccount({
       ...chartOfAccount,
       name:e.target.value,
      });
     }

     const handleStatus=(e)=>{
      setChartOfAccount({
        ...chartOfAccount,
        status:chartOfAccount.status == 1 ? 0 : 1,
       });
     }

      //submit New acount
  const handleSaveNewAccount = (e) => {
    e.preventDefault();
    if(chartOfAccount.chart_of_acc_l1===""){
      toast.error(`${_t(t("Please Select Account Level-1"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
    else{
    setLoading(true)
    setChartOfAccount({...chartOfAccount,uploading:true})
    const coa2Url = BASE_URL + `/settings/create-coa-level2`;
    let formData = new FormData();
    // formData.append("account_type", chartOfAccount.account_type);
    formData.append("coa_level1_id",chartOfAccount.chart_of_acc_l1.id)
    formData.append("level2_code", chartOfAccount.code);
    formData.append("level2_name", chartOfAccount.name);
    formData.append("level2_status", chartOfAccount.status);

    return axios
      .post(coa2Url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setChartOfAccount({
          account_type: "",
          chart_of_acc_l1:"",
          code: "",
          name: "",
          status:1,
          edit: false,
          uploading: false,
        });
        setCoa2(res.data[0]);
        setCoa2ForSearch(res.data[1]);
        setSearchedCoa2({
          ...searchedCoa2,
          list: res.data[1],
        });
       
        setLoading(false);

        toast.success(`${_t(t("Chart of account Level-2 has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setChartOfAccount({
          ...chartOfAccount,
          uploading: false,
        });

            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          
      });
    }
  };

  //set edit true & values
  const handleSetEdit = (id) => {
    let account = coa2ForSearch.filter((item) => {
      return item.id === id;
    });

    let dropdown = coa1ForSearch.find((glType)=> account[0].coa_level1_id === glType.id)
    if(dropdown !== undefined){
      setChartOfAccount({
      ...chartOfAccount,
      id:account[0].id,
      chart_of_acc_l1:dropdown,
      code:account[0].level2_code,
      name:account[0].level2_name,
      status:account[0].level2_status,
      uploading: false,
      edit:true,
    })
    }

  }

      //submit updated account
      const handleUpdateAccount = (e) => {
        e.preventDefault();
        setChartOfAccount({
          ...chartOfAccount,
          uploading: true,
        });
        setLoading(true)
    
        const coa2Url = BASE_URL + `/settings/update-coa-level2`;
        let formData = new FormData();
        formData.append("coa_level1_id", parseInt(chartOfAccount.chart_of_acc_l1.id));
        formData.append("level2_code", chartOfAccount.code);
        formData.append("level2_name", chartOfAccount.name);
        formData.append("level2_status", parseInt(chartOfAccount.status));
        formData.append("id", parseInt(chartOfAccount.id));     
    
        return axios
          .post(coa2Url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setCoa2(res.data[0])
            setCoa2ForSearch(res.data[1]);
            setSearchedCoa2({
              ...searchedCoa2,
              list: res.data[1],
            });
            setChartOfAccount({
              id:null,
              account_type: "",
              chart_of_acc_l1:"",
              code: "",
              name: "",
              status:1,
              edit: false,
              uploading: false,
            });
           
            setLoading(false);
    
            toast.success(`${_t(t("Chart of account Level-2 has been updated"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            document.getElementById('close-btn').click();
          })
          .catch((error) => {
            setLoading(false);
            setChartOfAccount({
              ...chartOfAccount,
              uploading: false,
            });
    
                toast.error(`${_t(t("Please try again"))}`, {
                  position: "bottom-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                });
              
          });
      };
  
       //delete account here
  const handleDeleteAccount = (slug) => {
    setLoading(true);
    const coa2Url = BASE_URL + `/settings/delete-coa-level2/${slug}`;
    return axios
      .get(coa2Url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setCoa2(res.data[0])
        setCoa2ForSearch(res.data[1]);
        setSearchedCoa2({
          ...searchedCoa2,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Chart of account level-2 has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

    //search account here
    const handleSearch = (e) => {
      let searchInput = e.target.value.toLowerCase();
      if (searchInput.length === 0) {
        setSearchedCoa2({ ...searchedCoa2, searched: false });
      } else {
        let searchedList = coa2ForSearch.filter((item) => {
          let lowerCaseItemName = item.level2_name.toLowerCase();
          let lowerCaseItemLevel1Name = item.level1_name.toLowerCase();
          let lowerCaseItemCode = item.level2_code.toLowerCase();
          return lowerCaseItemName.includes(searchInput) ||
          lowerCaseItemLevel1Name.includes(searchInput) ||
          lowerCaseItemCode.includes(searchInput)
        });
        setSearchedCoa2({
          ...searchedCoa2,
          list: searchedList,
          searched: true,
        });
      }
    };
  
    //delete confirmation modal of paymentType
    const handleDeleteConfirmation = (slug) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card card-body">
              <h1>{_t(t("Are you sure?"))}</h1>
              <p className="text-center">{_t(t("You want to delete this?"))}</p>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary rounded"
                  onClick={() => {
                    handleDeleteAccount(slug);
                    onClose();
                  }}
                >
                  {_t(t("Yes, delete it!"))}
                </button>
                <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                  {_t(t("No"))}
                </button>
              </div>
            </div>
          );
        },
      });
    };

    const customSelectStyle={
      control: (baseStyles, state) => ({
        ...baseStyles,
        fontSize:"14px"
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: '14px',
      })
    }

  return (
    <>
    <Helmet>
      <title>{_t(t("Chart of Account level 2"))}</title>
    </Helmet>

     {/* Add modal */}
     <div className="modal fade" id="addAccount" aria-hidden="true">
     <div className="modal-dialog modal-lg">
       <div className="modal-content">
         <div className="modal-header align-items-center">
           <div className="fk-sm-card__content">
             <h5 className="text-capitalize fk-sm-card__title">
               {!chartOfAccount.edit
                 ? _t(t("Add new Chart of account Level-2"))
                 : _t(t("Update Chart of account Level-2"))}
               </h5>
           </div>
           <button
             type="button"
             id="close-btn"
             className="btn-close"
             data-dismiss="modal"
             aria-label="Close"
           ></button>
         </div>
         <div className="modal-body">
           {/* show form or show saving loading */}
           {chartOfAccount.uploading === false ? (
             <div key="fragment-dept-tag-1">
               <form
                 onSubmit={
                   !chartOfAccount.edit
                     ? handleSaveNewAccount
                     : handleUpdateAccount
                 }
               >

                 <div className="mt-3">
                 <label htmlFor="name" className="form-label">
                   {_t(t("Chart Of Account Level 1"))}{" "}
                   <small className="text-danger">*</small>
                 </label>
                 <Select
                    options={coa1ForSearch && coa1ForSearch}
                    components={makeAnimated()}
                     getOptionLabel={(option) => option.level1_name}
                     getOptionValue={(option) => option.id}
                    classNamePrefix="account"
                    className="xsm-text"
                    onChange={handleSetCoa1}
                    maxMenuHeight="200px"
                    value={chartOfAccount.chart_of_acc_l1 || ""}   
                    styles={customSelectStyle}                
                  />
               </div>

                 {/*acc code */}
                 <div className="mt-3">
                 <label htmlFor="name" className="form-label">
                   {_t(t("Code"))}{" "}
                   <small className="text-danger">*</small>
                 </label>
                 <input
                   type="text"
                   className="form-control"
                   id="code"
                   name="code"
                   placeholder="code"
                   required
                   onChange={handleSetCode}
                   value={chartOfAccount.code}
                 />
               </div>

               <div className="mt-3">
               <label htmlFor="name" className="form-label">
                 {_t(t("Name"))}{" "}
                 <small className="text-danger">*</small>
               </label>
               <input
                 type="text"
                 className="form-control"
                 id="name"
                 name="name"
                 placeholder="name"
                 required
                 onChange={handleSetName}
                 value={chartOfAccount.name}
               />               
             </div>
             <div className="form-check mt-4 ml-0 mr-2 p-0">
                      <Switch
                        name="status"
                        height={22}
                        width={44}
                        offColor="#ee5253"
                        className="float-left"
                        checked={chartOfAccount.status}
                        onChange={handleStatus}                        
                      />
                      <label
                        className="form-check-label ml-2"
                        htmlFor="status"
                      >
                        {_t(t("Status"))}
                      </label>
                    </div>
                 <div className="addons-list__item mt-3 mx-1 mb-1 fk-addons-table__body">

                 <div className="mt-4">
                   <div className="row d-flex justify-content-end">
                     <div className="col-3">
                       <button
                         disabled={loading}
                         type="submit"
                         className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                       >
                         {!chartOfAccount.edit ? _t(t("Save")) : _t(t("Update"))}
                       </button>
                     </div>
                     <div className="col-3">
                       <button
                         type="button"
                         className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                         data-dismiss="modal"
                         onClick={()=>{
                          setChartOfAccount({
                            account_type: "",
                            chart_of_acc_l1:"",
                            code: "",
                            name: "",
                            status:1,
                            edit: false,
                            uploading: false,
                          });
                         }}
                       >
                         {_t(t("Close"))}
                       </button>
                     </div>
                   </div>
                 </div>
                 </div>
               </form>
             </div>
           ) : (
             <div key="fragment2">
               <div className="text-center text-primary font-weight-bold text-uppercase">
                 {_t(t("Please wait"))}
               </div>
               {modalLoading(3)}
               <div className="mt-4">
                 <div className="row d-flex justify-content-end">
                   <div className="col-3">
                     <button
                       type="button"
                       className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                       onClick={(e) => {
                         e.preventDefault();
                       }}
                     >
                       { !chartOfAccount.edit ? _t(t("Save")) : _t(t("Update"))}
                     </button>
                   </div>
                   <div className="col-3">
                     <button
                       type="button"
                       className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                       data-dismiss="modal"
                     >
                       {_t(t("Close"))}
                     </button>
                   </div>
                 </div>
               </div>
             </div>
           )}
         </div>
       </div>
     </div>
   </div>
   {/* Add modal Ends*/}

   {/* main body */}
   <main id="main" data-simplebar>
     <div className="container-fluid">
       <div className="row t-mt-10 gx-2">
         {/* left Sidebar */}
         <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
           <AccountsSidebar />
         </div>
         {/* left Sidebar ends */}

         {/* Rightbar contents */}
         <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
           <div className="t-bg-white">
             <div className="fk-scroll--pos-table-menu" data-simplebar>
               <div className="t-pl-15 t-pr-15">
                 {/* Loading effect */}
                 {/* {chartOfAccount.uploading === true || loading === true ? (
                   tableLoading()
                 ) : ( */}
                   <div key="fragment3">
                     {/* next page data spin loading */}
                     {/* spin loading ends */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                     {/* spin loading ends */}

                     <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                       <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                         <ul className="t-list fk-breadcrumb">
                           <li className="fk-breadcrumb__list">
                             <span className="t-link fk-breadcrumb__link text-capitalize">
                               {!searchedCoa2.searched
                                 ? _t(t("Chart of Account Level-2"))
                                 : _t(t("Search Result"))}
                             </span>
                           </li>
                         </ul>
                       </div>
                       <div className="col-md-6 col-lg-7">
                         <div className="row gx-3 align-items-center">
                           {/* Search group */}
                           <div className="col-md-9 t-mb-15 mb-md-0">
                             <div className="input-group">
                               <div className="form-file">
                                 <input
                                   type="text"
                                   className="form-control border-0 form-control--light-1 rounded-left"
                                   placeholder={_t(t("Search")) + ".."}
                                   onChange={handleSearch}
                                 />
                               </div>
                               <button
                                 className="btn btn-primary rounded-right"
                                 type="button"
                               >
                                 <i
                                   className="fa fa-search"
                                   aria-hidden="true"
                                 ></i>
                               </button>
                             </div>
                           </div>

                           {/* Add group modal trigger button */}
                           <div className="col-md-3 text-md-right">
                             <button
                               type="button"
                               className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                               data-toggle="modal"
                               data-target="#addAccount"
                               onClick={() => {
                                 setChartOfAccount({
                                   ...chartOfAccount,
                                   edit: false,
                                   uploading: false,
                                 });
                               }}
                             >
                               {_t(t("add new"))}
                             </button>
                           </div>
                         </div>
                       </div>
                     </div>
                     {/* Table */}
                     <div className="table-responsive">
                       <table className="table table-bordered table-hover min-table-height">
                         <thead className="align-middle">
                           <tr>
                             <th
                               scope="col"
                               className="sm-text text-capitalize align-middle text-center border-1 border"
                             >
                               {_t(t("S/L"))}
                             </th>

                             <th
                               scope="col"
                               className="sm-text text-capitalize align-middle text-center border-1 border"
                             >
                               {_t(t("Chart of Account L-1"))}
                             </th>

                             <th
                               scope="col"
                               className="sm-text text-capitalize align-middle text-center border-1 border"
                             >
                               {_t(t("Code"))}
                             </th>

                             <th
                               scope="col"
                               className="sm-text text-capitalize align-middle text-center border-1 border"
                             >
                               {_t(t("Name"))}
                             </th>

                             <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Status"))}
                                  </th>

                             <th
                               scope="col"
                               className="sm-text text-capitalize align-middle text-center border-1 border"
                             >
                               {_t(t("Action"))}
                             </th>
                           </tr>
                         </thead>
                         <tbody className="align-middle">
                           {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                           {!searchedCoa2.searched
                             ? [
                                 coa2 && [
                                  coa2.data.length === 0 ? (
                                     <tr className="align-middle">
                                       <td
                                         scope="row"
                                         colSpan="6"
                                         className="xsm-text align-middle text-center"
                                       >
                                         {_t(t("No data available"))}
                                       </td>
                                     </tr>
                                   ) : (
                                      coa2.data.map((item, index) => {
                                       return (
                                         <tr
                                           className="align-middle"
                                           key={index}
                                         >
                                           <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (coa2.current_page -
                                                      1) *
                                                      coa2.per_page}
                                                </th>

                                           <td className="xsm-text text-capitalize align-middle text-center">
                                             {item.level1_name}
                                           </td>

                                           <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.level2_code}
                                           </td>

                                           <td className="xsm-text text-capitalize align-middle text-center">
                                             {item.level2_name}
                                           </td>

                                           <td className="xsm-text text-capitalize align-middle text-center">
                                           <Switch
                                                checked={item.level2_status==1?true:false}
                                                name="status"
                                                // onChange={(e) => {
                                                //   handleUpdateStatus(item)                                                
                                                // }}
                                                height={22}
                                                width={44}
                                                offColor="#ee5253"
                                                />   
                                           </td>

                                           <td className="xsm-text text-capitalize align-middle text-center">                                                  
                                                  <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() =>
                                                    handleSetEdit(item.id)
                                                  }
                                                  data-toggle="modal"
                                                  data-target="#addAccount"                                             
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={() => {
                                                    handleDeleteConfirmation(
                                                      item.id
                                                    );
                                                  }}                                                   
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                            </td>
                                         </tr>
                                       );
                                     })
                                   ),
                                 ],
                               ]
                             : [
                                 /* searched data, logic === haveData*/
                                 searchedCoa2 && [
                                  searchedCoa2.list.length === 0 ? (
                                     <tr className="align-middle">
                                       <td
                                         scope="row"
                                         colSpan="6"
                                         className="xsm-text align-middle text-center"
                                       >
                                         {_t(t("No data available"))}
                                       </td>
                                     </tr>
                                   ) : (
                                    searchedCoa2.list.map(
                                       (item, index) => {
                                         return (
                                           <tr
                                             className="align-middle"
                                             key={index}
                                           >
                                             <th
                                               scope="row"
                                               className="xsm-text text-capitalize align-middle text-center"
                                             >
                                               {index +
                                                 1 +
                                                 (coa2.current_page -
                                                   1) *
                                                   coa2.per_page}
                                             </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.level1_name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.level2_code}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.level2_name}
                                              </td>

                                             <td className="xsm-text text-capitalize align-middle text-center">
                                             <Switch
                                                checked={item.level2_status==1?true:false}
                                                name="status"
                                                // onChange={(e) => {
                                                //   handleUpdateStatus(item)                                                
                                                // }}
                                                height={22}
                                                width={44}
                                                offColor="#ee5253"
                                                /> 
                                             </td>

                                             <td className="xsm-text text-capitalize align-middle text-center">                                                  
                                                  <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() =>
                                                    handleSetEdit(item.id)
                                                  }
                                                  data-toggle="modal"
                                                  data-target="#addAccount"                                             
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={() => {
                                                    handleDeleteConfirmation(
                                                      item.id
                                                    );
                                                  }}                                                   
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                              </td>
                                           </tr>
                                         );
                                       }
                                     )
                                   ),
                                 ],
                               ]}
                         </tbody>
                       </table>
                     </div>
                   </div>                 
               </div>
             </div>
           </div>

           {/* pagination loading effect */}
           { chartOfAccount.uploading === true || loading === true
             ? paginationLoading()
             : [
                 // logic === !searched
                 !searchedCoa2.searched ? (
                   <div key="fragment4">
                     <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                       <div className="row align-items-center t-pl-15 t-pr-15">
                         <div className="col-md-7 t-mb-15 mb-md-0">
                           {/* pagination function */}
                           {pagination(coa2, setPaginatedCoa2)}
                         </div>
                         <div className="col-md-5">
                           <ul className="t-list d-flex justify-content-md-end align-items-center">
                             <li className="t-list__item">
                               <span className="d-inline-block sm-text">
                                 {showingData(coa2)}
                               </span>
                             </li>
                           </ul>
                         </div>
                       </div>
                     </div>
                   </div>
                 ) : (
                   // if searched
                   <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                     <div className="row align-items-center t-pl-15 t-pr-15">
                       <div className="col-md-7 t-mb-15 mb-md-0">
                         <ul className="t-list d-flex">
                           <li className="t-list__item no-pagination-style">
                             <button
                               className="btn btn-primary btn-sm"
                               onClick={() =>
                                 setSearchedCoa2({
                                   ...searchedCoa2,
                                   searched: false,
                                 })
                               }
                             >
                               {_t(t("Clear Search"))}
                             </button>
                           </li>
                         </ul>
                       </div>
                       <div className="col-md-5">
                         <ul className="t-list d-flex justify-content-md-end align-items-center">
                           <li className="t-list__item">
                             <span className="d-inline-block sm-text">
                               {searchedShowingData(
                                 searchedCoa2,
                                 coa2ForSearch
                               )}
                             </span>
                           </li>
                         </ul>
                       </div>
                     </div>
                   </div>
                 ),
               ]}
         </div>
         {/* Rightbar contents end*/}
       </div>
     </div>
   </main>
       {/* main body ends */}
  </>
  )
}

export default ChartAccount2
