import React, { useContext, useState } from "react";

import "./charts/dashboard.css";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

//pages & includes
import ReportSidebar from "./ReportSidebar";
//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

// graphs
import { ColumnsWidget, DonutWidget, LineCardWidget } from "./charts";
import ListWidget from "./charts/ListWidget";
import VerticalBarWidget from "./charts/VerticalBarWidget";
import BarWidget from "./charts/BarWidget";
import LineWidget from "./charts/LineWidget";
import RadialWidget from "./charts/RadialWidget";
import CardWidget from "./charts/AdminWidget/CardWidget";
import TableList from "./charts/ProductionDashboard/TableList";
import MenuEngTable from "./charts/AdminWidget/MenuEngTable";

const AdminReports = () => {
  const { t } = useTranslation();
  //getting context values here
  let {
    dataPaginating,
    setDataPaginating,
  } = useContext(SettingsContext);

  let { branchForSearch } = useContext(RestaurantContext);
  let { authUserInfo } = useContext(UserContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branch, setBranch] = useState(null);

  const date = {
    startDate: startDate,
    endDate: endDate,
  }

  const [cardData, setCardData] = useState({
    total_sales_report: null,
    total_food_cost_report: null,
    total_sales_insights: null,
    total_sales_payment_wise: null,
    total_day_of_week: null,
    total_sales_party_wise: null,
    total_date_branch_sales:null,
    total_month_sales:null,
    total_sales_summary:null,
    total_branch_wise_sales:null,
    total_item_wise_sales:null,
    total_three_months_sales:null,
    total_top_category_sales:null,
    total_forecasting_report:null,
    total_top_branches_sales:null,
    get_top_items_asc:null,
    get_top_items_desc:null,
  });

  const [loading, setLoading] = useState({
    total_sales_insights: null,
    total_sales_payment_wise: null,
    total_day_of_week: null,
    total_sales_party_wise: null,
    total_date_branch_sales:null,
    total_month_sales:null,
    total_sales_summary:null,
    total_branch_wise_sales:null,
    total_item_wise_sales:null,
    total_three_months_sales:null,
    total_top_category_sales:null,
    total_forecasting_report:null,
    total_top_branches_sales:null,
    get_top_items_asc:null,
    get_top_items_desc:null,
  });

  const handleReq = (params, key=null) =>{
    const keyName = key ? `${params}_${key}` : params;
    if(params === "total_sales_report" || params === "total_food_cost_report"){
      setDataPaginating(true)
    }else{
      setLoading({
        ...loading,
        [keyName]: true,
      })
    }
    let formData = {}
    if(params === "total_sales_summary"){
      formData.range = 12
    }else{
      formData.from = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
      formData.to = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
      formData.branch_id = branch?.id ? branch.id : "";
      key && (formData.type = key);
    }
    const url = BASE_URL + "/widgets/" + params;
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) =>{
        setLoading({
          ...loading,
          [keyName]: false,
        })
        setDataPaginating(false)
        const {message, status, ...rest} = res.data;
        setCardData((curr)=> {
        const newData = {
         ...curr,
         [keyName] : rest.details, 
        }
        return newData
      })})
      .catch((err) =>{
        setLoading({
          ...loading,
          [keyName]: false,
        })
        setDataPaginating(false)
      });
  }
  
  const handleBranch = (branch) => {
    setBranch(branch);
  };

  const handleApplyFilter = () => {
    handleReq("total_sales_report");
    handleReq("total_food_cost_report");
    setCardData({
      ...cardData,
      total_sales_insights: null,
      total_sales_payment_wise: null,
      total_day_of_week: null,
      total_sales_party_wise: null,
      total_date_branch_sales:null,
      total_month_sales:null,
      total_sales_summary:null,
      total_branch_wise_sales:null,
      total_item_wise_sales:null,
      total_three_months_sales:null,
      total_top_category_sales:null,
      total_forecasting_report:null,
      total_top_branches_sales:null,
      get_top_items_asc:null,
      get_top_items_desc:null,
    });
    setLoading({
      total_sales_insights: null,
      total_sales_payment_wise: null,
      total_day_of_week: null,
      total_sales_party_wise: null,
      total_date_branch_sales:null,
      total_month_sales:null,
      total_sales_summary:null,
      total_branch_wise_sales:null,
      total_item_wise_sales:null,
      total_three_months_sales:null,
      total_top_category_sales:null,
      total_forecasting_report:null,
      total_top_branches_sales:null,
      get_top_items_asc:null,
      get_top_items_desc:null,
    })
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Reports"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/*loading === true ? (
                      tableLoading()
                    ) : (*/}
                      <div key="smtp-form reports-dashboard">
                        <div className="row gx-2 mx-1 align-items-center t-pt-15 pb-2">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Dashboard"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* next page data spin loading  <div className={`${dataPaginating && "loading"}`}></div> */}
                       
                        <div className={`${(dataPaginating) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        
                        {/* spin loading ends */}
                        <div className="row mx-1 gx-2 mt-2">
                          <div className="col-3 col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-3 col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <Select
                            options={branchForSearch && branchForSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={branch}
                            classNamePrefix="select"
                            className="xsm-text col-3"
                            onChange={handleBranch}
                            styles={customStyle}
                            maxMenuHeight="180px"                            
                            placeholder={_t(t("Select a branch")) + ".."}
                            isClearable
                            isDisabled={authUserInfo ? (authUserInfo?.details?.user_type === "staff") : false}
                          />
                          <div className="col-1 col-md-3"> </div>
                          <div className="col-2 col-md-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded "
                              onClick={handleApplyFilter}
                            >
                              {_t(t("View Dashboard"))}
                            </button>
                          </div>

                          <div className="col-2 col-md-3 t-mb-15 mb-md-0 d-block d-md-none">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text rounded"
                              onClick={handleApplyFilter}
                            >
                              {_t(t("View Dashboard"))}
                            </button>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2 mb-3">
                          <LineCardWidget data={cardData && cardData.total_sales_report} heading="Total Sales" size="col-6" handleReq={handleReq} url="total_sales_report"/>
                          <LineCardWidget data={cardData && cardData.total_food_cost_report} heading="Avg Food Cost" size="col-6" handleReq={handleReq} url="total_food_cost_report"/>
                         {/* <AreaWidget data={cardData && cardData?.area} heading="Total Sales" size="col-4" handleReq={handleReq} url="area"/>
                          <AreaWidget data={cardData && cardData?.area} heading="Total Sales" size="col-4" handleReq={handleReq} url="area"/>
                          <AreaWidget data={cardData && cardData?.area} heading="Total Sales" size="col-4" handleReq={handleReq} url="area"/>*/}
                          <DonutWidget data={cardData && cardData.total_sales_insights} heading="Insights" size="col-3" handleReq={handleReq} url="total_sales_insights" loading={loading.total_sales_insights}/>
                          <VerticalBarWidget data={cardData && cardData.total_sales_payment_wise} heading="Payment Wise Sale" size="col-3" handleReq={handleReq} url="total_sales_payment_wise" loading={loading.total_sales_payment_wise}/>
                          <DonutWidget data={cardData && cardData.total_sales_party_wise} heading="Party Wise Sale" size="col-3" handleReq={handleReq} url="total_sales_party_wise" loading={loading.total_sales_party_wise}/>
                          <BarWidget data={cardData && cardData.total_day_of_week} heading="Day of the Week" size="col-3" handleReq={handleReq} url="total_day_of_week" loading={loading.total_day_of_week}/>
                          <LineWidget data={cardData && cardData.total_date_branch_sales} heading="Branch Sales" size="col-8" handleReq={handleReq} url="total_date_branch_sales" loading={loading.total_date_branch_sales}/>
                          <RadialWidget data={cardData && cardData.total_month_sales} heading="Last 3 Month Sales" handleReq={handleReq} url="total_month_sales" loading={loading.total_month_sales}/>
                          <ColumnsWidget data={cardData && cardData.total_sales_summary} heading="Sales Summary" handleReq={handleReq} url="total_sales_summary" loading={loading.total_sales_summary}/>
                          <ListWidget data={cardData && cardData.total_branch_wise_sales} heading="Branch Wise Sales" size="col-12" handleReq={handleReq} url="total_branch_wise_sales" loading={loading.total_branch_wise_sales}/>
                         
                          <div className="d-flex p-0">
                            <ListWidget data={cardData && cardData.total_item_wise_sales} heading="Item Wise Sales" size="col-6" handleReq={handleReq} url="total_item_wise_sales" loading={loading.total_item_wise_sales}/>    
                            <ListWidget data={cardData && cardData.total_three_months_sales} heading="Last 3 Month Sales" size="col-6" handleReq={handleReq} url="total_three_months_sales" loading={loading.total_three_months_sales}/>
                          </div>
                          {/* <CardWidget data={cardData && cardData.payment_sales} handleReq={handleReq} url="sales-summary" loading={loading.total_sales_summary}/> */}
                          <TableList data={cardData && cardData.total_top_category_sales} heading="Top 5 Categories" date={date} size="col-12" handleReq={handleReq} url="total_top_category_sales" loading={loading.total_top_category_sales}/>    
                          <TableList data={cardData && cardData.total_top_branches_sales} heading="Top 5 Branches Wise Performance" date={date} size="col-12" handleReq={handleReq} url="total_top_branches_sales" loading={loading.total_top_branches_sales} />
                          <h4 className="mt-4 mx-2 mb-0">
                           Menu Engineering
                          </h4>
                          <MenuEngTable data={cardData && cardData.get_top_items_desc} heading="Top 5 High Profit Items" size="col-6" handleReq={handleReq} url="get_top_items" loading={loading.get_top_items_desc} />
                          <MenuEngTable data={cardData && cardData.get_top_items_asc} heading="Top 5 Low Profit Items" size="col-6" handleReq={handleReq} url="get_top_items" loading={loading.get_top_items_asc} />
                          <TableList data={cardData && cardData.total_forecasting_report} heading="Forecasting" date={date} size="col-12" handleReq={handleReq} url="total_forecasting_report" loading={loading.total_forecasting_report} />
                        </div>
                        
                      </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default AdminReports;
