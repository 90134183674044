// config
// let production = "NO"; //YES ==live NO ==localhost
// export let SAAS_APPLICATION = "NO"; //YES|NO

let production = "YES"; //YES ==live NO ==localhost
export let SAAS_APPLICATION = "YES"; //YES|NO

// default config
export const saas_key =
  "$2a$12$pkLmD5jZdxd6bSNcTg9YT.g2mXz5gS8JTArdIg68c8RK/d7w2y2Gu";
//export const saas_apiUrl = "https://indoljpos.com";
export const saas_apiUrl = "https://indoljpos.pk";

export let BASE_URL = "";
export let saas_apiParams = "";
export let saas_form_data = null;

if (production === "YES") {
  // for production

  BASE_URL =
    window.location.protocol +
    "//" +
    window.location.hostname +
    `${
      window.location.port !== ""
        ? `:${window.location.port}`
        : `${!window.location.href.includes(".test") ? "" : ""}`
    }`;
  // saas config
  saas_apiParams = `saas_key=${saas_key}&domain=${BASE_URL}`;
  saas_form_data = {
    saas_key: saas_key,
    domain: BASE_URL,
  };
} else {
  // for localhost development
  // BASE_URL =git
  //   window.location.protocol +
  //   "//" +
  //   window.location.hostname +
  //   "/khadyo_updated_production_saas/laravel/public";

  BASE_URL =
    //"http://pos.indolj.local";
    //"http://127.0.0.1:8000";
    // 'http://mysticgrill.indoljpos.com/public';
    // 'https://sync.indoljpos.com/public';
    //"http://wrapchick.indoljpos.pk/public";
    //"http://app.indolj.space/public";
    "http://boctest.indoljpos.pk";
  // saas configr-permission-and-disable-item-field
  saas_apiParams = `saas_key=${saas_key}&domain=prince.automatefood.com`;
  saas_form_data = {
    saas_key: saas_key,
    domain: "prince.automatefood.com",
  };
}
