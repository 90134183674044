import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import Moment from "react-moment";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";
const FinishedGoodsReport = () => {
    const { t } = useTranslation();
    //getting context values here
    let { loading, setLoading, dataPaginating, generalSettings } =
      useContext(SettingsContext);
    let { branchForSearch } = useContext(RestaurantContext);
    const { authUserInfo } = useContext(UserContext);
    let { foodForPOS } = useContext(FoodContext);
  
    let [finishedGoodsLedgerReport, setFinishedGoodsLedgerReport] = useState({
      branch:null,
      item:null,
      detail: false,
    });
  
    //all data
    const [reportData, setReportData] = useState(null);
    const [reportSummary, setReportSummary] = useState(null);
    const [allData, setAllData] = useState(null);

  
    const componentRef = useRef();
    const componentRef1 = useRef();
    const componentRef2 = useRef();
    //settle order
    const [startDate, setStartDate] = useState(null);
    const [fromEndDate, setFromEndDate] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    const [generateAll, setGenerateAll] = useState(false);
    const [siteName, setSiteName] = useState("");
    //useEffect == componentDidMount()
    const [branchSearch, setBranchSearch] = useState(null);
  
    useEffect(() => {
      setSiteName(getSystemSettings(generalSettings, "siteName"));
      if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
        setBranchSearch(
          branchForSearch.filter(
            (branch) => branch.id === authUserInfo.details.branch_id
          )
        );
      } else {
        setBranchSearch(branchForSearch);
      }
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }, [generalSettings]);
  
    //Print Report
    const ReportPrint = useRef();
  
    // const handlePrintBtn = useReactToPrint({
    //   content: () => ReportPrint.current,
    // });
  
    const handleCheckboxChange = () =>{
      setFinishedGoodsLedgerReport({
        ...finishedGoodsLedgerReport,
        detail: !finishedGoodsLedgerReport.detail,
      });
    }

    const handleBranch = (branch) => {
      setFinishedGoodsLedgerReport({
        ...finishedGoodsLedgerReport,
        branch,
      });
    };
    const handleItem = (item) => {
        setFinishedGoodsLedgerReport({
          ...finishedGoodsLedgerReport,
          item,
        });
      };
  
    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      // console.log("finishedGoodsLedgerReport?.branch?.id ", finishedGoodsLedgerReport?.branch?.id);
      if (startDate !== null && fromEndDate !== null) {
        setLoading(true);
  
        let toDate = moment(startDate).format("YYYY-MM-DD");
        let fromDate = moment(fromEndDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-fg-stock-ledger-report";
  
        let formData = new FormData();
        formData.append("to_date", toDate);
        formData.append("from_date", fromDate);
        formData.append("branch_id", finishedGoodsLedgerReport?.branch?.id ? finishedGoodsLedgerReport?.branch?.id : "");
        formData.append("item_id", finishedGoodsLedgerReport?.item?.id ? finishedGoodsLedgerReport?.item?.id : "");
  
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            console.log("FinishedGoodsReportResult", res);
            setReportData(res.data[0]);
  
            setLoading(false);
            // console.log("Component ", componentRef);
            setGeneratedReport(true);
            componentRef.current.handleDownload();
            setFinishedGoodsLedgerReport({
              ...finishedGoodsLedgerReport,
              detail: false,
            });
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        toast.error(
          `${_t(t("Please select from date to date"))}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };

    // get all data
    const getAllData  = () =>{
      if (startDate !== null) {
        setLoading(true);
  
        let fromDate = moment(startDate).format("YYYY-MM-DD");
  
        const url = BASE_URL + "/settings/get-fgs-data-report";
  
        let formData = new FormData();
        formData.append("to_date", fromDate);
        formData.append("branch_id", finishedGoodsLedgerReport?.branch?.id ? finishedGoodsLedgerReport?.branch?.id : "");
        formData.append("item_id", finishedGoodsLedgerReport?.item?.id ? finishedGoodsLedgerReport?.item?.id : "");

        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            
            setLoading(false);
            setGenerateAll(true);
            setAllData(res.data[0]);
  
            componentRef2.current.handleDownload();
            setFinishedGoodsLedgerReport({
              ...finishedGoodsLedgerReport,
              detail: false,
            });
          })
          .catch((error) => {
            setLoading(false);
          });
      }else{
        toast.error(
          `${_t(t("Please select date"))}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
      
    }
  
    //get BranchWise summary filter
    const getBranchWiseSelectedSummary = () => {
      // console.log("finishedGoodsLedgerReport?.branch?.id ", finishedGoodsLedgerReport?.branch?.id);
      if (startDate !== null) {
        setLoading(true);
  
        var fromDate = moment(startDate).format("YYYY-MM-DD");
  
        const url = BASE_URL + "/settings/get-fg-stock-summary-report";
  
        let formData = new FormData();
        formData.append("to_date", fromDate);
        formData.append("branch_id", finishedGoodsLedgerReport?.branch?.id ? finishedGoodsLedgerReport?.branch?.id : "");
        formData.append("item_id", finishedGoodsLedgerReport?.item?.id ? finishedGoodsLedgerReport?.item?.id : "");

        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            
            setReportSummary(res.data[0]);
            setLoading(false);
            componentRef1.current.handleDownload();
            setFinishedGoodsLedgerReport({
              ...finishedGoodsLedgerReport,
              detail: false,
            });
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        toast.error(
          `${_t(t("Please select date"))}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };
  
    let stock_inn = 0;
    let stock_out = 0;
    let balance = 0;
    let amount = 0;
    let total_qty = 0;
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Finished Goods Ledger Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Finished Goods Ledger Report"
          sheet="sheet 1"
          buttonText="Sales Detail"
          ref={componentRef}
        />

        <ReactHTMLTableToExcel
        id="summaryTable"
        className="download-table-xls-button btn btn-success ml-1 sm-text px-2 invisible"
        table="summaryTable-xls"
        filename="Finished summary report"
        sheet="sheet 1"
        buttonText="Download as XLS"
        ref={componentRef1}
        />

        <ReactHTMLTableToExcel
        id="allDataTable"
        className="download-table-xls-button btn btn-success ml-1 sm-text px-2 invisible"
        table="allDataTable-xls"
        filename="All data report"
        sheet="sheet 1"
        buttonText="Download as XLS"
        ref={componentRef2}
        />
  
        {/* Print Button */}
        <div className="d-none">
          <div>
            <style type="text/css" media="print">
              {
                "\
            @page { size: landscape; }\
          "
              }
            </style>
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {reportData !== null &&
                      reportData !== undefined &&
                      generatedReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                              <table
                                className="table table-bordered table-hover min-table-height mt-3"
                                id="table-to-xls"
                              >
                                <div className="page-break"></div>
                                <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                    <th colspan="13" style={{ border: "0px" }}>
                                      <div className="row">
                                        <div className="col-12">
                                          <h3 className="text-center mb-0">
                                            {siteName}
                                          </h3>
                                          <h3 className="text-center mt-0">
                                            Finished Goods Ledger Report
                                          </h3>
                                          <p className="text-center">
                                            FROM&nbsp;
                                            <Moment format="LL">
                                              {fromEndDate}
                                            </Moment>
                                            &nbsp;TO&nbsp;
                                            <Moment format="LL">{startDate}</Moment>
                                          </p>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                  <th
                                  scope="col"
                                  >
                                  {_t(t("Branch"))}
                                  </th>
                                    <th
                                      scope="col"
                                    >
                                    {_t(t("Item Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("S/L"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Item Code"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Date"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Document Type"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("document No"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Branch Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Transfer Branch"))}
                                    </th>
  
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Stock Inn"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Stock Out"))}
                                    </th>
                                    {/* <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Avg Rate"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Amount"))}
                                    </th> */}
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Balance Qty"))}
                                    </th>
                                    {
                                      finishedGoodsLedgerReport.detail && (
                                        <>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("Refrence No"))}
                                          </th>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("Customer Name"))}
                                          </th>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("Order Type"))}
                                          </th>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("Party Name"))}
                                          </th>
                                        </>
                                       )
                                     }
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Remarks"))}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="align-middle">
                                  {console.log("309 ", reportData)}
                                  {/* loop here*/}
                                  {
                                    Object.keys(reportData).map(
                                      (group_name, index) => {
                                        // let balanceQty = 0; 
                                        stock_inn = 0;
                                        stock_out = 0;
                                        amount = 0;
                                        
                                        return(
                                        <div key={index}>
                                      
                                        <tr>
                                        <th
                                          colSpan={11}
                                          scope="row"
                                        >
                                          {group_name}
                                        </th>
                                      </tr>
                                      {
                                        Object.keys(reportData[group_name]).map((itemName)=>{
                                          let balanceQty = 0;
                                          return(
                                            <tr>
                                             <th></th>
                                              <th
                                                scope="row"
                                              >
                                                {itemName}
                                              </th>
                                              {reportData[group_name][itemName].map((item, i) => {
                                                balanceQty += parseFloat(item.balance_qty);
                                                stock_inn += parseFloat(item.stock_inn);
                                                stock_out += parseFloat(item.stock_out);
                                                amount += parseFloat(item.amount);
                                                
                                                return (
                                                  <tr key={i}>
                                                    <td></td>
                                                    <td></td>
                                                    <td
                                                      scope="row"
                                                    >
                                                      {i + 1}
                                                    </td>
                                                    <td
                                                      scope="row"
                                                    >
                                                     {item.code}
                                                    </td>
                                                    <td
                                                      scope="row"
                                                    >
                                                      <Moment format="LL">
                                                       {item.document_date}
                                                      </Moment>
                                                    </td>
                                                    <td
                                                      scope="row"
                                                    >
                                                      {
                                                        item.document_type.includes("OP") ?
                                                        <strong>{item.document_type}</strong>
                                                        : item.document_type
                                                      }
                                                    </td>
                                                    <td
                                                      scope="row"
                                                    >
                                                      {
                                                        item.document_no.includes("Opening") ?
                                                        <strong>{item.document_no}</strong>
                                                        : item.document_no
                                                      }
                                                    </td>
                                                    <td
                                                      scope="row"
                                                    >
                                                      {item.branch_name}
                                                    </td>
                                                    <td
                                                    scope="row"
                                                    >
                                                      {item.trans_branch_name}
                                                    </td>
                                                    <td
                                                      scope="row"
                                                    >
                                                      {formatPrice(item.stock_inn)}
                                                    </td>
                                                    <td
                                                      scope="row"
                                                    >
                                                      {formatPrice(item.stock_out)}
                                                    </td>
                                                    <td
                                                      scope="row"
                                                    >
                                                      {formatPrice(balanceQty)}
                                                    </td>
                                                    {
                                                      finishedGoodsLedgerReport.detail && (
                                                        <>
                                                          <td
                                                            scope="row"
                                                          >
                                                            {item.actual_token?.id}
                                                          </td>
                                                          <td
                                                            scope="row"
                                                            >
                                                              {item?.customer_name}
                                                            </td> 
                                                            <td
                                                              scope="row"
                                                            >
                                                              {item?.order_type_name}
                                                            </td>
                                                            <td
                                                              scope="row"
                                                            >
                                                              {item?.party_name}
                                                            </td>
                                                         </>
                                                        )
                                                      }
                                                    <td
                                                      scope="row"
                                                    >
                                                      {item.remarks}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                              <tr>
                                               <th colSpan={10}></th>
                                               <th>Total</th>
                                               <th>{balanceQty}</th>
                                              </tr>
                                            </tr>
                                          )
                                        })
                                      }
                                  </div>
                                  );
                                }
                                )
                               }
                              </tbody>
  
                                <tfoot style={{ border: "0px" }}>
                                  <tr style={{ border: "0px" }}>
                                    <td
                                      colspan="10"
                                      className="text-center"
                                      style={{ border: "0px" }}
                                    >
                                      <h5 className="mt-3">
                                        Powered by indolj.pk
                                      </h5>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* all data Button */}
        <div className="d-none">
          <div>
            <style type="text/css" media="print">
              {
                "\
            @page { size: landscape; }\
          "
              }
            </style>
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {allData !== null &&
                      allData !== undefined &&
                      generateAll === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                              <table
                                className="table table-bordered table-hover min-table-height mt-3"
                                id="allDataTable-xls"
                              >
                                <div className="page-break"></div>
                                <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                    <th colspan="5" style={{ border: "0px" }}>
                                      <div className="row">
                                        <div className="col-12">
                                          <h3 className="text-center mb-0">
                                            {siteName}
                                          </h3>
                                          <h3 className="text-center mt-0">
                                            All Goods Report
                                          </h3>
                                          <p className="text-center">
                                            Till&nbsp;
                                            <Moment format="LL">
                                              {startDate}
                                            </Moment>
                                          </p>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                  <th
                                  scope="col"
                                  >
                                  {_t(t("Item Code"))}
                                  </th>
                                  <th
                                      scope="col"
                                    >
                                    {_t(t("Item Name"))}
                                    </th>
                                  <th
                                  scope="col"
                                  >
                                  {_t(t("Item Group"))}
                                  </th>
                                  <th
                                  scope="col"
                                  >
                                  {_t(t("Shop Name"))}
                                  </th>                                    
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Stock Balance Qty"))}
                                    </th>                                    
                                  </tr>
                                </thead>
                                <tbody className="align-middle">
                                   {
                                    allData && allData?.map((data,index)=>{
                                      total_qty += parseInt(data.stock_qty)
                                      return(
                                        <tr key={index} style={{fontSize:"17px"}}>
                                          <td>{data.sku}</td>
                                          <td>{data.item_name}</td>
                                          <td>{data.food_group_name}</td>
                                          <td>{data.branch_name}</td>
                                          <td>{data.stock_qty}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                  <tr>
                                    <th colSpan={4}></th>
                                    <th>{parseInt(total_qty)}</th>
                                  </tr>
                                </tbody>
  
                                <tfoot style={{ border: "0px" }}>
                                  <tr style={{ border: "0px" }}>
                                    <td
                                      colspan="7"
                                      className="text-center"
                                      style={{ border: "0px" }}
                                    >
                                      <h5 className="mt-3">
                                        Powered by indolj.pk
                                      </h5>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-2 mb-lg-0">
                <div className="t-bg-white p-1">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* spin loading ends */}
  
                      {/* Loading effect */}
                      {/* {loading === true ? (
                        tableLoading()
                      ) : ( */}
                       <>
                        <div key="smtp-form mt-2">
                          <div className="row gx-2 align-items-center t-pt-2 t-pb-2">
                            <div className="col-12 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize mt-3">
                                    {_t(t("Finished Goods Ledger Report"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row mt-3">
                          <div className="col-2">
                            <DatePicker
                              selected={fromEndDate}
                              onChange={(date) => setFromEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              placeholderText={_t(t("From Date"))}
                              shouldCloseOnSelect={false}                                
                            />
                          </div>
                            <div className="col-2">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("To Date"))}
                                shouldCloseOnSelect={false}                                
                              />
                            </div>
                          
                            <div className="col-3">
                              <Select
                                options={branchSearch && branchSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                value={finishedGoodsLedgerReport.branch || ""}
                                isClearable
                                classNamePrefix="select"
                                className="table-text"                               
                                onChange={handleBranch}
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select branch")) + ".."}
                              />
                            </div>
                            <div className="col-3">
                                <Select
                                menuPosition="fixed"
                                options={foodForPOS !== null ? foodForPOS : []}
                                // components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isClearable
                                onChange={handleItem}
                                value={finishedGoodsLedgerReport.item}
                                className="table-text"  
                                classNamePrefix="select"                                                        
                                placeholder={
                                    _t(t("Select item")) + ".."
                                }                                                
                                />
                              </div>
                              <div className="col-2 d-flex justify-content-start align-items-center">
                                {/* Action Button */}
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id="detail"
                                name= "detail"
                                checked={finishedGoodsLedgerReport.detail}
                                onChange={handleCheckboxChange}
                                />
                                <label
                                  className="ml-3 text-primary mr-4"
                                  htmlFor="detail"
                                >
                                  Show Detail
                                </label>
                            
                              </div> 
                            </div>                          
                            <div className="row mt-4">
                              <div className="col-6"></div>
                              <div className="col-2">
                                <button
                                  className="btn btn-primary sm-text text-capitalize col-12 rounded"
                                  onClick={(e) => getBranchWiseSelected(e)}
                                >
                                  GOODS REPORT
                                </button>
                              </div>
                              <div className="col-2">
                                <button
                                  className="btn btn-primary sm-text rounded text-capitalize col-12"
                                  onClick={(e) => getBranchWiseSelectedSummary(e)}
                                >
                                  GOODS SUMMARY
                                </button>
                              </div>
                              <div className="col-2">
                                <button
                                  className="btn btn-primary sm-text rounded text-capitalize col-12"
                                  onClick={(e) => getAllData(e)}
                                >
                                  ALL DATA
                                </button>
                              </div>
                            </div>                       
                        </div>
  
                            {/* summary  */}
  
                            {reportSummary !== null &&
                            reportSummary !== undefined &&
                            Object.keys(reportSummary).length > 0 && (
                              <div className="row gx-2 justify-content-center p-2 mt-2 d-none">
                                {/* Action Button */}
                                
                                <div className="col-12 t-mb-15 mb-md-0 table-responsive"  ref={ReportPrint}>
                                  <table className="table table-bordered table-hover min-table-height mt-3" id="summaryTable-xls">
                                    <thead className="align-middle">
                                    <tr style={{ border: "0px" }}>
                                    <th colspan="6" style={{ border: "0px" }}>
                                      <div className="row">
                                        <div className="col-12">
                                          <h4 className="text-center mb-0 text-dark">
                                            {siteName}
                                          </h4>
                                          <h4 className="text-center mt-0 text-dark">
                                            Finished Goods Summary
                                          </h4>
                                          <p className="text-center">
                                            Till&nbsp;
                                            <Moment format="LL">
                                              {startDate}
                                            </Moment>
                                          </p>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                      <tr>
                                     
                                        <th
                                          scope="col"
                                        >
                                          {_t(t("Branch"))}
                                        </th>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("S/L"))}
                                          </th>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("Item Code"))}
                                          </th>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("Food Group"))}
                                          </th>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("Item Name"))}
                                          </th>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("Stock Quantity"))}
                                          </th>
                                      </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                      {/* loop here*/}
                                     
                                      {Object.keys(reportSummary).map((groupName, index) => {
                                        let stockQty = 0;
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                            >
                                              {groupName}
                                            </th>

                                            {
                                              reportSummary[groupName].map((item,index1)=>{
                                                stockQty += parseFloat(item.stock_qty)
                                                return(
                                                  <tr style={{height:"2rem"}}>
                                                   <td></td>
                                                    <td style={{minWidth:"250px"}}>
                                                      {index1 + 1}
                                                    </td>

                                                    <td style={{minWidth:"300px"}}>
                                                        {item.code}
                                                    </td>
                                                    <td style={{minWidth:"300px"}}>
                                                        {item.food_group_name}
                                                    </td>
  
                                                    <td style={{minWidth:"300px"}}>
                                                        {item.item_name}
                                                    </td>
  
                                                    <td style={{minWidth:"250px"}}>
                                                      {item.stock_qty}
                                                    </td>
                                                   </tr> 
                                                )
                                              })
                                            }
                                            <tr>
                                            <th colSpan={4}>
                                            </th>
                                            <th>
                                              Total
                                            </th>
                                             <th>
                                              {stockQty}
                                             </th>
                                            
                                            </tr>
  
                                          </tr>
                                        );
                                      })}
                                  
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
  
                       </>
                    </div>
  
                  </div>
                    
                </div>
                
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default FinishedGoodsReport
