import React, { useEffect, useContext, useState,useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food"

const DayEndSettingAdd = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {stock ,id} = useParams()
    //getting context values here
    let {
      loading,
      setLoading,
      ingredientItemForSearch,
    } = useContext(RestaurantContext);
    let { foodForPOS } = useContext(FoodContext);

    const toastObj = {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    };

    const [edit,setEdit] = useState(false)

    //item state
    const [ingItems,setIngItems]=useState(null);

    //type state
    const [alltypes,setAllTypes]=useState([
      {id:1,name:"Daily"},
      {id:2,name:"Weekly"},
      {id:3,name:"Monthly"}
    ]);
    const [type,setType]=useState(null);

    //stock type state
    const [stockTypes,setStockTypes]=useState([
      {id:1,name:"Raw Material"},
      {id:2,name:"Finished Goods"}
    ]);
    const [stockType,setStockType]=useState(null);

    //specific item state
    const [editItem,setEditItem]=useState(null);
  
    //useEffect == componentDidMount()
    useEffect(() => {
      if(stock && id){
        setEdit(true)
        getSpecificItem();
      }else{
        setEdit(false)
      }
    }, []);

    useEffect(() => {
      if(editItem && editItem !== null){
        if(stock === 'raw' || stock === 'food'){
          let filterName = stock === 'raw' ? 'Raw Material' : 'Finished Goods';
          let stockType=stockTypes.find((value)=>value.name===filterName);
          let type=alltypes.find((value)=>value.id===parseInt(id));
          setStockType(stockType);
          setType(type);
          let itemArr = [];
          if(stock === 'raw'){
            ingredientItemForSearch.map((value,index)=>{
              editItem.map((item)=>{
                if(item.item_id === value.id){
                  itemArr.push(value);
                }
              })
            }) 
          }else if(stock === 'food'){
            foodForPOS.map((value,index)=>{
              editItem.map((item)=>{
                if(item.item_id === value.id){
                  itemArr.push(value);
                }
              })
            }) 
          }
          setIngItems(itemArr);
        }
      }
    }, [editItem])

    const getSpecificItem = async () => {     
      setLoading(true); 
      if(id && stock){
        const url = BASE_URL + "/settings/get-ingredients-by-type/"+stock+"/"+id;          
        try {
          const res = await axios
            .get(url ,{
              headers: { Authorization: `Bearer ${getCookie()}` },
            });
            if(res.data){
              // console.log("check type data==>",res.data);                    
              setEditItem(res.data.details);
              setLoading(false);         
            }          
        } catch (error) {
          setLoading(false);
          toast.error(
            `${_t(t("Please try again."))}`,
            toastObj                    
          );
        }
      }
    }
    
  
    //select style
    const customStyle = {
        control: (provided) => ({
          ...provided,
          minHeight : '2rem',
          marginTop:"0",
          marginBottom:"0",
        
        }),
       
        singleValue: (provided) =>({
          ...provided,
          alignSelf: "center",
        }),
        input:(provided)=>({
          ...provided,
         margin:"0",
        }),
        valueContainer: (provided) => ({
          ...provided,
          marginTop:"0",
          padding:"0 0.5rem",
         
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding:"0 0.4rem",
          "svg": {
            height: '2rem',
          }
        }),
       
      }
      
    //set items hook
    const handleSetItems = async (item) => {
        setIngItems(item)
    };
    const handleType = (type) => {
        setType(type)
    }
    const handleStockType = (type) => {
      setIngItems(null);    
      setStockType(type)
    }
  
    //post req of food item add
    const DayEndItemAxios = async () => {
      setLoading(true)
      let itemArr=ingItems?.map((item)=>item.id);
      let newType = ''; 
      if(stockType?.name === 'Raw Material'){
        newType = 'raw'
      }else if(stockType?.name === 'Finished Goods'){
        newType = 'food'
      }
      const url = BASE_URL + "/settings/add-ingredient-item-type";
      let formData={
        type_id: type?.id,
        items: JSON.stringify(itemArr),
        stock_type: newType
      }      
      try {
        const res = await axios
          .post(url,formData ,{
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          toast.success(`${_t(t("Data has been added"))}`, toastObj);          
          setLoading(false);
          setType(null);
          setStockType(null);
          setIngItems(null);
          history.push('/dashboard/manage/stock/day-end-setting')
      } catch (error) {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, toastObj);
      }
    };
  
    //send to server
    const handleSubmit = (e) => {
      e.preventDefault();
      if(type !== null && ingItems !== null && stockType !== null){
        DayEndItemAxios();
      }else{
        toast.error(`${_t(t("Please Select All Fields"))}`, toastObj);
      }
    };    
  
    return (
      <>
      <Helmet>
        <title>{_t(t("Add Day End Setting"))}</title>
      </Helmet>
  
      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}
  
            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(loading) && "loadingBlur"}`}></div>
                      <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}
  
                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!edit ? _t(t("Add Day End Setting")) : _t(t("Edit Day End Setting"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>
  
                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <div className="col-12">
                            <div className="row">
                             
                              <div className="form-group col-4 mt-2">
                                <div className="mb-2 ">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Type"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                    <Select
                                    options={alltypes}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={type}
                                    classNamePrefix="select table-text"
                                    onChange={handleType}
                                    //maxMenuHeight="50px"
                                    placeholder={
                                        _t(t("Please select a type")) + ".."
                                    }
                                    styles={customStyle}
                                    required
                                    isClearable
                                    />
                                </div>
                              </div>

                              <div className="form-group col-4 mt-2">
                                <div className="mb-2 ">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Stock Type"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                    <Select
                                    options={stockTypes}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={stockType}
                                    classNamePrefix="select table-text"
                                    onChange={handleStockType}
                                    //maxMenuHeight="50px"
                                    placeholder={
                                        _t(t("Please select a stock")) + ".."
                                    }
                                    styles={customStyle}
                                    required
                                    isClearable
                                    />
                                </div>
                              </div>
  
                              <div className="form-group mt-2 col-4">
                                <div className="mb-2 ">
                                  <label
                                    htmlFor="items"
                                    className="control-label"
                                  >
                                    {_t(t("Items"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <Select
                                    value={ingItems || ""}
                                    options={stockType?.id===1 ? (ingredientItemForSearch ? ingredientItemForSearch : []) : (foodForPOS ? foodForPOS : [])}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.name}
                                    className="basic-multi-select table-text"
                                    classNamePrefix="select"
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    maxMenuHeight="200px"
                                    onChange={handleSetItems}
                                    styles={customStyle}
                                    placeholder={_t(t("Please select items")) + ".."}
                                    isDisabled={stockType ? false : true}
                                    isClearable
                                />
                                </div>                                                           
                          </div>                                                                                                              
                            <div className="form-group mt-4 pb-2">                              
                              <div className="d-flex justify-content-end align-items-center">
                              <div className="col-3 d-flex justify-content-end">
                                  <button
                                  type="button"
                                  className="btn rounded hov-btn mr-3"
                                  onClick={()=>(history.push("/dashboard/manage/stock/day-end-setting"))}                                  
                                  >
                                      <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                  </button>
                                  <button
                                  disabled={loading}
                                  type="submit"
                                  className="btn btn-primary rounded"
                                  >
                                      <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                  </button>                                                    
                              </div>                                                    
                          </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
        {/* main body ends */}
        {/* <StockReport
        name = "Purchase Order"
        purchaseDate={purchaseOrderDate}
        newItem={newItem}
        qtyOfItem={qtyOfItem}
        rateOfItem={rateOfItem}
        handleGetTotal={handleGetTotal}
        handleAmount={handleAmount}
        handleTaxAmount={0}
        handleDisAmount={0}
        handleGetSubTotal={handleGetSubTotal}
        handleGetAmount={handleGetAmount}
        stockPrint={componentRef}
        /> */}
    </>
    )
}

export default DayEndSettingAdd;