import React, { useEffect, useContext, useState, useRef } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";


//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  getSystemSettings
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-datepicker/dist/react-datepicker.css";
import { MdDelete } from 'react-icons/md';

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
// import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";
import { SettingsContext } from "../../../../../contexts/Settings";


const emptyRecipe = {
  item_id: null,
  type: "item",
  variation_id: null,
  qty: 1,
  remarks:"",
  recipeDetails: [],
}

const RecipeEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let { loading, setLoading, ingredientItemForSearch, dataPaginating, getIngredientItem, orderTypeForSearch } =
    useContext(RestaurantContext);
  let {
    foodForPOS,
    propertyItemList,
    getPropertyItem
  } = useContext(FoodContext);
  let {generalSettings} = useContext(SettingsContext);
  // States hook here
  const [radio, setRadio] = useState("Item")
  const [selectedItem, setSelectedItem] = useState(null)
  const [variations, setVariations] = useState('')
  const [recipe, setRecipe] = useState(emptyRecipe)
  const [recipeDetails, setRecipeDetails] = useState([])
  const [recipeEdit, setRecipeEdit] = useState(null)
  const [selectedOrderType, setSelectedOrderType] = useState(null)
  const params = useParams(); 
  const search = useLocation().search;
  const duplicate = new URLSearchParams(search).get('duplicate');
  const [ menuWisePrice , setMenuWisePrice ] = useState(0);

  useEffect(() => {
    getPropertyItem()
    getIngredientItem()
  }, []);

  useEffect(()=>{

    if(getSystemSettings(generalSettings, "menu_wise_rate") == 1){
      setMenuWisePrice(1);
     }
     
     if(params.id){
       getEditRecipe()
     }
  },[generalSettings]);

  useEffect(() => {
    setRecipe({...recipe, recipeDetails: recipeDetails})
  }, [recipeDetails])

  const TypeChangeHandler = ({target}) => {
    setRadio(target.value)
    setRecipe({...recipe, type: target.value, item_id: null, variation_id: null});
    setRecipeEdit({...recipeEdit, type: target.value, item_id: null, variation_id: null})
    setSelectedItem(null);
    setRecipeDetails([])
  }
  console.log("check selected==>",selectedItem)

  const variationChangeHandler = ({target},  item) => {
    setRecipe({...recipe, variation_id: item, remarks: target.value})
    setVariations(target.value)
  }

  const handleItemChange = (item) => {
    setRecipe({...recipe, item_id: item.id})
    setSelectedItem(item)
  }

  const handleChangeRecipe = ({target}) => {
    const {name, value} = target
    setRecipe({...recipe, [name]: value})
  }

  const handleSetOrderType = (ordertype) => {
    setSelectedOrderType(ordertype)
  }

  const handleSetItems = (item) => {
    if(selectedOrderType !== null && selectedOrderType?.length > 0){
      let data =[]
      item.ingredient_id = item.id  
      item.ingredient_name = item.name
      item.orderTypeId = []
      item.orderTypeName = ""
      selectedOrderType.map((orderType)=>{
        item.orderTypeId.push(orderType.id);
        item.orderTypeName += orderType.name + ", "
      });
      data.push(item)
      setRecipeDetails([...recipeDetails, ...data])
    }else{
      toast.error(`${_t(t("Please select order types"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
    // let data =[]
    // item.ingredient_id = item.id  
    // item.ingredient_name = item.name
    // data.push(item)
    // setRecipeDetails([...recipeDetails, ...data])
  }

  const handleChange = ({target}) => {
    const {name, value} = target
    let recipe = recipeDetails.find((item) => {
      return item.id == name
    })
    recipe.ingredient_qty = value
    let temp = [];
    recipeDetails.map((thisItem) => {
      if (thisItem.id !== recipe.id) {
        temp.push(thisItem);
      } else {
        temp.push(recipe);
      }
    });
    setRecipeDetails(temp);
  }
  
  const setRecipeApi = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/recipe/" + params.id;
    const dupUrl = BASE_URL + "/settings/recipe"
    // let formData = new FormData();
    // for (const key in recipe) {
    //   formData.append(key, recipe[key]);        
    //   }
    if(selectedItem === null){
      setLoading(false);
      toast.error(`${_t(t("Please select item"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false
    }

    if(selectedItem?.has_variation === "1" && recipe.variation_id === null ){
      setLoading(false);
      toast.error(`${_t(t("Please select item variation"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false
    }

    if(recipeDetails === null || recipeDetails.length === 0){
      setLoading(false);
      toast.error(`${_t(t("Please add ingredient item"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false
    }

    let formData = JSON.stringify(recipe);
    try {
      let res;
      if(duplicate) {

        res = await axios
        .post(dupUrl, formData, {
          headers: { "Authorization": `Bearer ${getCookie()}`, 'Content-Type': 'application/json'},
        });
      } else {
        res = await axios
        .put(url, formData, {
          headers: { "Authorization": `Bearer ${getCookie()}`, 'Content-Type': 'application/json'},
        });
      }

      setLoading(false);
      setRecipe(emptyRecipe)
      setRecipeDetails([])
      setSelectedItem(null)
      setVariations('')
      setRadio("Item")

      toast.success(`${_t(t("Recipe has been updated"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      history.push("/dashboard/manage/stock/view-recipe")
    } catch (error) {
      setLoading(false);
      if(error.response.data.errors?.item_id[0] === "The item id has already been taken."){
        toast.error(`${_t(t("The item has already been taken"))}`,
          {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
          });
      }else{
        toast.error(`${_t(t("Please try again later " + error))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    }
  };

    //send to server
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (
        recipe.item_id !== null
      ) {
        if(!selectedOrderType || selectedOrderType?.length === 0){
          toast.error(`${_t(t("Please select order type"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          return false;
        }
        await setRecipeApi();
      } else {
        toast.error(`${_t(t("Please fill all the required fields"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    };

    const handleDltHandler = (id) => {
      let data = recipeDetails?.filter(recipe => recipe.id !== id)
      setRecipeDetails(data)
    }

    useEffect(() => {
        setRadio(recipeEdit?.type);
        setTimeout(() => {
          let data;
          if(recipeEdit && (recipeEdit?.type === "Property" || recipeEdit?.type === "property")){
            data = propertyItemList && propertyItemList.find(property => property.id === recipeEdit?.item_id);
          }else if(recipeEdit && (recipeEdit?.type === "sub recipe" || recipeEdit?.type === "Sub Recipe" || recipeEdit?.type === "Sub recipe")){
             data = ingredientItemForSearch && ingredientItemForSearch.find(item => item.id === recipeEdit?.item_id)
          }else{
            data = foodForPOS && foodForPOS?.find(food => food.id === recipeEdit?.item_id)
          }
          
          setSelectedItem(data)
        }, 100);
       
    }, [recipeEdit])
    
    useEffect(() => {
        let veriationName;
        let variationId;
        if (selectedItem && selectedItem.has_variation === "1" && recipeEdit) {
            if(menuWisePrice === 1){
              veriationName = selectedItem?.variation_recipe?.find(variation => variation.variation_id == recipeEdit?.variation_id);
              variationId = veriationName && veriationName.variation_id;
            }else{
              veriationName = selectedItem?.variations?.find(variation => variation.food_with_variation_id == recipeEdit?.variation_id);
              variationId = veriationName.food_with_variation_id
            }
        }
        veriationName && setVariations(veriationName.variation_name);
        if(veriationName && variationId && selectedItem){
          setRecipe({...recipe, variation_id: variationId, item_id : selectedItem.id,remarks: recipeEdit?.remarks, type: recipeEdit?.type, qty: recipeEdit?.qty})
        }else if(selectedItem){
          setRecipe({...recipe, item_id : selectedItem.id, remarks: recipeEdit?.remarks, type: recipeEdit?.type, qty: recipeEdit?.qty});
        }
    }, [recipeEdit, selectedItem])
    

    const getEditRecipe = async () => {
     
        const url =
          BASE_URL +
          "/settings/recipe/" +
          parseInt(params.id);
        try {
          const res = await axios
            .get(url, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            });
            let recipeDetail = res.data.recipeDetail && res.data.recipeDetail.map((recipe)=>{
              const { order_type_id, order_type, ...rest } = recipe;
              return {
                ...rest,
                orderTypeId: recipe.order_type_id && JSON.parse(recipe.order_type_id),
                orderTypeName: recipe.order_type,
              }
            });
            let orderType ;
            if(res.data.recipeDetail[0]){
               
              orderType = orderTypeForSearch.filter((odType)=>{
                 return JSON.parse(res.data.recipeDetail[0].order_type_id).includes(odType.id)
              })
                
            }
          setSelectedOrderType(orderType)
          
          setRecipeDetails(recipeDetail);
          setTimeout(() => {
            setRecipeEdit({...recipe, item_id: res.data.recipe?.item_id, type: res.data.recipe?.type, recipeDetails: res.data.recipeDetail, variation_id: res.data.recipe?.variation_id, remarks: res.data.recipe?.remarks, qty: res.data.recipe?.qty})
          }, 0);
          setLoading(false);
        } catch {
          setLoading(false);
        }
      };
    
    return (
    <>
      <Helmet>
        <title>{_t(t(`${duplicate ? "Create a Recipe" : "Update Recipe"}`))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t(`${duplicate ? "Create a Recipe" : "Update Recipe"}`))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>

                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={(e)=>handleSubmit(e)}
                          autoComplete="off"
                        >
                          <div className="col-12">
                          <div className="d-flex align-items-center w-50 cursor-pointer mt-2">
                          <div class="form-check mx-2">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" value="Item" id="flexRadioDefault1" checked={radio === "Item" || radio === "item"} onChange={TypeChangeHandler} style={{width: "20px", height:"20px"}}/> 
                            <label class="form-check-label mt-1 mx-1" for="flexRadioDefault1"> Item </label>
                          </div>

                          <div class="form-check mx-2">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" value="Property" id="flexRadioDefault2" checked={radio === "Property" || radio === "property"} onChange={TypeChangeHandler} style={{width: "20px", height:"20px"}}/>
                            <label class="form-check-label mt-1 mx-1" for="flexRadioDefault2"> Property </label>
                          </div>

                          <div class="form-check mx-2">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" value="Sub Recipe" id="flexRadioDefault3" checked={radio === "Sub Recipe"} onChange={TypeChangeHandler} style={{width: "20px", height:"20px"}}/>
                            <label class="form-check-label mt-1 mx-1" for="flexRadioDefault3"> Sub Recipe </label>
                          </div>
                          </div>

                          <div className="row">
                          {(foodForPOS && (radio === "Item" || radio === "item")) && (
                              <div className="form-group mt-4 col-4">
                                <div className="mb-2">
                                  <label
                                    htmlFor="items"
                                    className="control-label"
                                  >
                                    {_t(t("Items"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                 <Select
                                  options={foodForPOS}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  classNamePrefix="select"
                                  onChange={handleItemChange}
                                  maxMenuHeight="200px"
                                  value={selectedItem}
                                  id="items"
                                  placeholder={
                                    _t(t("Please Select an Item")) + ".."
                                  }
                                />
                              </div>
                            )}

                          {(propertyItemList && (radio === "Property" || radio === "property")) && (
                              <div className="form-group mt-4 col-4">
                                <div className="mb-2">
                                  <label
                                    htmlFor="items"
                                    className="control-label"
                                  >
                                    {_t(t("Property"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                 <Select
                                  options={propertyItemList}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  classNamePrefix="select"
                                  onChange={handleItemChange}
                                  maxMenuHeight="200px"
                                  id="items"
                                  value={selectedItem}
                                  placeholder={
                                    _t(t("Please Select a Propery")) + ".."
                                  }
                                />
                              </div>
                            )}

                            {(/*filteredItems || */ingredientItemForSearch && radio === "Sub Recipe") && (
                              <div className="col-6 row">
                              <div className="form-group mt-4 col-7">
                              <div className="mb-2">
                                <label
                                  htmlFor="Sub Recipe"
                                  className="control-label"
                                >
                                    {_t(t("Sub Recipe"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <Select
                                  options={/* filteredItems */ingredientItemForSearch}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  classNamePrefix="select"
                                  onChange={handleItemChange}
                                  maxMenuHeight="200px"
                                  id="Sub Recipe"
                                  value={selectedItem}
                                  placeholder={
                                    _t(t("Please Select Sub Recipe")) + ".."
                                  }
                                />
                              </div>
                              <div className="form-group mt-4 col-3">
                               <div className="mb-2">
                                <label
                                  htmlFor="qty"
                                  className="control-label"
                                >
                                    {_t(t("Qty"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <input 
                                 type="number"
                                 className="form-control table-text"
                                 id="qty"
                                 name="qty"
                                 onKeyDown={(evt) =>
                                  ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                  evt.preventDefault()
                                 }
                                 onWheel={(e) => e.target.blur()}
                                 value={recipe.qty}
                                 onChange={handleChangeRecipe}
                                 placeholder="qty"
                                />
                              </div>
                              <div className="form-group mt-4 col-2">
                              <div className="mb-2">
                                <label
                                  htmlFor="unit"
                                  className="control-label"
                                >
                                    {_t(t("Unit"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <input 
                                 type="text"
                                 className="form-control table-text"
                                 id="unit"
                                 name="unit"
                                 value={selectedItem && selectedItem.unit}
                                 readOnly
                                />
                              </div>
                            </div>
                            )}

                            <div className={`form-group mt-4 ${radio === "Sub Recipe" ? "col-6" : "col-8"}`}>
                              <div className="mb-2">
                                <label htmlFor="remarks" className="control-label">
                                  {_t(t("Remarks"))}
                                </label>
                              </div>
                                <input
                                  type="text"
                                  className="form-control table-text"
                                  id="remarks"
                                  name="remarks"
                                  onChange={handleChangeRecipe}
                                  value={recipe.remarks}
                                  placeholder="Remarks"
                                  style={{lineHeight:"1.5rem"}}
                                />
                            </div>
                          </div>
                            
                            {
                             ((radio === "Item" || radio === "item") && selectedItem && selectedItem.has_variation === "1") && (
                             <div className="mt-2">
                              <div className="mb-2">
                                  <label
                                    className="control-label"
                                  >
                                    {_t(t("Select Variation"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                              <div className="row cursor-pointer">
                              {
                                menuWisePrice === 1 ? (
                                  selectedItem && selectedItem.variation_recipe?.map((item) => (
                                    <div class="d-flex align-items-center form-check mt-1 mx-2 col-3" key={item.variation_id}>
                                      <input class="form-check-input" type="radio" name="variations" value={item.variation_name} id={item.variation_id} checked={variations === item.variation_name}
                                      onChange={(e) => variationChangeHandler(e,item.variation_id)} style={{width: "20px", height:"20px"}}/> 
                                      <label class="form-check-label ml-2 mx-1 text-capitalize" htmlFor={item.variation_id}> {item.variation_name} </label>
                                    </div>
                                  ))
                                ) : (
                                  selectedItem && selectedItem.variations?.map((item) => (
                                    <div class="d-flex align-items-center form-check mx-2 col-3" key={item.food_with_variation_id}>
                                      <input class="form-check-input" type="radio" name="variations" value={item.variation_name} id={item.food_with_variation_id} checked={variations === item.variation_name}
                                      onChange={(e) => variationChangeHandler(e,item.food_with_variation_id)} style={{width: "20px", height:"20px"}}/> 
                                      <label class="form-check-label ml-2 mx-1 text-capitalize" htmlFor={item.food_with_variation_id}> {item.variation_name} </label>
                                    </div>
                                  ))
                                )
                              }
                             </div>
                             </div>
                             )
                            }

                            <div className="row">

                            <div className="form-group mt-3 col-4">
                              <div className="mb-2">
                                <label
                                  className="control-label"
                                >
                                  {_t(t("Select Order Types"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <Select
                                options={/* filteredItems */orderTypeForSearch ? orderTypeForSearch : []}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                classNamePrefix="select"
                                onChange={handleSetOrderType}
                                value={selectedOrderType}
                                maxMenuHeight="200px"
                                isDisabled={!selectedItem}
                                closeMenuOnSelect={false}
                                // isOptionDisabled={(option) => option.isdisabled}
                                isMulti
                                // isDisabled={newItem.branch === null}
                                placeholder={
                                  _t(t("Please order types")) + ".."
                                }
                              />
                          </div>
                          {(/*filteredItems || */ingredientItemForSearch) && (
                           <div className="form-group mt-3 col-4">
                            <div className="mb-2">
                              <label
                                className="control-label"
                              >
                                {_t(t("Select Ingredients"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <Select
                              options={/* filteredItems */ingredientItemForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="select"
                              onChange={handleSetItems}
                              maxMenuHeight="200px"
                              isDisabled={!selectedItem}
                              // isOptionDisabled={(option) => option.isdisabled}
                              // isMulti
                              // isDisabled={newItem.branch === null}
                              placeholder={
                                _t(t("Please Ingredient Items")) + ".."
                              }
                            />
                          </div>
                          )}
                        </div> 

                            <div className="form-group mt-3">
                              <div className="mb-2">
                                <label
                                  htmlFor="payment_type"
                                  className="control-label"
                                >
                                  {_t(t("Ingredient Items"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>
                                <div className="table-responsive mt-2">
                                  <table className="table table-bordered table-hover">
                                    <thead className="align-middle">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"50%"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Name"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"20%"}}
                                         >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Order Type"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"20%"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Qty"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"20%"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Unit"))}
                                          </span>
                                        </th>
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"10%"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                            {_t(t("Actions"))}
                                          </span>
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody className="align-middle">
                                      {recipeDetails?.map(
                                        (ingredientItem, index) => {
                                          return (
                                            <tr
                                              className="align-middle table_row_dlt"
                                              key={index}
                                              style={{width: '100%'}}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center show_dlt" style={{width:"50%"}}>
                                                {/* <span className="hide_dlt" onClick={()=> itemDltHandler(ingredientItem.ingredient_id)}>
                                                  <MdDelete className="text-danger"/>
                                                </span> */}
                                                {ingredientItem.ingredient_name || ingredientItem.name }
                                              </td>

                                              <td className="sm-text text-capitalize align-middle text-center show_dlt" style={{width:"50%"}}>
                                                {ingredientItem.orderTypeName}
                                              </td>

                                              <td className="sm-text text-capitalize align-middle text-center sm-text" style={{width:"20%"}}>
                                                <input
                                                  type="number"
                                                  step="0.0001"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center"
                                                  id={ingredientItem.id}
                                                  name={ingredientItem.id}
                                                  onChange={handleChange}
                                                  value={ingredientItem.ingredient_qty}
                                                  placeholder="Qty"
                                                  required
                                                />
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center" style={{width:"20%"}}>
                                                {ingredientItem.unit_code || ingredientItem.unit}
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center" style={{width:"10%"}}>
                                                <MdDelete className="text-danger" style={{cursor: "pointer", fontSize: "20px"}} onClick={()=>handleDltHandler(ingredientItem.id)}/>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                             
                            <div className="form-group mt-4 pb-2">
                              {/* <div className="col-lg-12">
                                <button
                                  className="btn btn-primary px-5"
                                  type="submit"
                                >
                                  {_t(t(`${duplicate ? "Create" : "Update"}`))}
                                </button>
                              </div> */}
                              <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/view-recipe"))}
                                //   data-dismiss="modal"
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t(`${duplicate ? "Create" : "Update"}`))}
                                </button>                                                    
                            </div>                                                    
                           </div>
                           </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default RecipeEdit;
