import React, { useEffect, useContext, useState } from "react";

import "./charts/dashboard.css";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

//pages & includes
import ReportSidebar from "./ReportSidebar";
//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

// graphs
import PieWidget from "./charts/PieWidget";
import CardWidget from "./charts/ProductionDashboard/ProductionCardWidget";
import TableList from "./charts/ProductionDashboard/TableList"; 
import VerticalBarWidget from "./charts/VerticalBarWidget"


const ProductionDashboard = () => {
  const { t } = useTranslation();
  //getting context values here
  let {
    loading,
    setLoading,
    dataPaginating,
    setDataPaginating,
  } = useContext(SettingsContext);

  let { branchForSearch } = useContext(RestaurantContext);
  let { authUserInfo } = useContext(UserContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branch, setBranch] = useState(null);
  const date = {
    startDate: startDate,
    endDate: endDate,
  }

  const [cardData, setCardData] = useState({
    card_data: null,
    production_item: null,
    production_category: null,
    production_discard: null,
    production_data: null,
    remaining_stock: null,
    pie_item: null,
    pie_category: null,
  });

  // data fetching
  useEffect(() => {
    getReport(() => {
      setLoading(false);
    });
    getReport("/widgets/production_dashboard");
  }, []);


  const handleBranch = (branch) => {
    setBranch(branch);
  };

  //get reports
  const getReport = (url) => {
    const formData = {};
    if (startDate) formData.from = moment(startDate).format("YYYY-MM-DD");
    if (endDate) formData.to = moment(endDate).format("YYYY-MM-DD");
    if (branch && branch.id) formData.branch_id = branch.id;
    const apiUrl = BASE_URL + url;
    setDataPaginating(true);
    return axios
      .post(apiUrl,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDataPaginating(false);
        setCardData({
            card_data: res.data[0],
            production_item: res.data[1],
            production_discard:res.data[2],
            production_category: null,
            remaining_stock: res.data[3],
            production_data: res.data[4],
            pie_item: res.data[5],
            pie_category: res.data[6],
        })
      })
      .catch((error) => {  setDataPaginating(false);})
  };

  const handleApplyFilter = () => {
   
    getReport("/widgets/production_dashboard");
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Production Dashboard"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form reports-dashboard">
                        <div className="row gx-2 mx-1 align-items-center t-pt-15 pb-2">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Production Dashboard"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* next page data spin loading  <div className={`${dataPaginating && "loading"}`}></div> */}
                       
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        
                        {/* spin loading ends */}
                        <div className="row mx-1 gx-2 mt-2">
                          <div className="col-3 col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-3 col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <Select
                            options={branchForSearch ? branchForSearch : []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={branch}
                            classNamePrefix="select"
                            className="xsm-text col-3"
                            onChange={handleBranch}
                            styles={customStyle}
                            maxMenuHeight="180px"                            
                            placeholder={_t(t("Select a branch")) + ".."}
                            isClearable
                            isDisabled={authUserInfo ? (authUserInfo?.details?.user_type === "staff") : false}
                          />
                          <div className="col-1 col-md-3"> </div>
                          <div className="col-2 col-md-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded "
                              onClick={handleApplyFilter}
                            >
                              {_t(t("View Dashboard"))}
                            </button>
                          </div>

                          <div className="col-2 col-md-3 t-mb-15 mb-md-0 d-block d-md-none">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text rounded"
                              onClick={handleApplyFilter}
                            >
                              {_t(t("View Dashboard"))}
                            </button>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2 mb-3">
                          <CardWidget data={cardData.card_data} />
                          <VerticalBarWidget data={cardData.production_data} heading="Production" size="col-12"/>
                          <PieWidget data={cardData.pie_item} heading="Top 10 Selling Item" size="col-6"/>
                          <PieWidget data={cardData.pie_category} heading="Top 10 Selling Category" size="col-6"/>
                          <div>
                            <TableList data={cardData.remaining_stock} heading="Total Stock In Hand" date={date}/>
                            <TableList data={cardData.production_item} heading="Total Production By Item" date={date} size="col-12"/>   
                            <TableList data={cardData.production_discard} heading="Total Discard" date={date} size="col-12"/> 
                          </div>  
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default ProductionDashboard;
