import React, { useContext } from "react";
import {
  _t,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";
import "./stock.css"

const FgPakingPrint = ({
    name,
    print,
    printData,
    documentDate,
    newRow
}) => {
    const { t } = useTranslation();
    const {
        //common
        generalSettings,
      } = useContext(SettingsContext);
      let totalQty = 0
      let totalTransferQty = 0
  return (
    
    <>
       {/* print */}
       <div className="d-none">
       <div ref={print}>
         {printData && (
           <div className="fk-print" style={{margin:"20px 30px"}}>
             <div>
               <div className="row">
                 <div className="col-12">
                   <div className="mx-auto" style={{ width: "90px" }}>
                     <img
                       src={`${getSystemSettings(
                         generalSettings,
                         "type_logo"
                       )}`}
                       style={{ width: "100%" }}
                     />
                   </div>
                   <div className="my-2 xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                     branch: {printData.branch && printData.branch?.name}
                   </div>
                   <div className="my-2 xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                     {name}
                   </div>

                   <div className="mt-4 my-2 d-flex print-text-stock justify-content-between text-capitalize">
                     <div className="print-text-stock--bold w-50">Document No: {printData.identity}</div>
                     <div className="print-text-stock--bold w-50">Document Date : {documentDate && documentDate?.toDateString()}</div>
                   </div>

                   <div className="mb-2 my-2 print-text-stock text-capitalize print-text-stock--bold d-flex justify-content-between">

                      <div className="print-text-stock--bold w-50">
                        Food Item :{" "}
                        {printData.item && printData.item.name}
                      </div>
  
                        <div className="print-text-stock--bold w-50">
                          Qty :{" "}
                          {printData.qty}
                        </div>
                   </div> 
                   <div className="mb-4 my-2 print-text-stock text-capitalize print-text-stock--bold d-flex justify-content-between">
                       <div className="print-text-stock--bold w-50">
                        Rate :{" "}
                        {printData.rate}
                       </div>
             
                       <div className="print-text-stock--bold w-50">
                        Amount :{" "}
                        {printData.amount}
                       </div>
                    </div>  
                    
                    <div className="print-text-stock--bold">
                      Remarks :{" "}
                      {printData.remarks && printData.remarks}
                    </div>               
                </div>

                    {newRow !== null && [
                     newRow.length > 0 && (
                       <div className=" mt-3">
                         <table className="table table-bordered-sm table-hover ">
                           <thead className="align-middle">
                             <tr>
                              {
                                name === "Gift Hampers" && (
                                  <th
                                  scope="col"
                                  className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                  >
                                  {_t(t("Type"))}
                                  </th>
                                )
                              }
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Name"))}
                               </th>
                                <th
                                scope="col"
                                className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Qty"))}
                              </th>
                     
                              {
                                name === "Finished Goods Packaging" && (
                                  <th
                                    scope="col"
                                    className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                  >
                                    {_t(t("Unit"))}
                                  </th>
                                )
                              }
                               
                                <th
                                  scope="col"
                                  className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                >
                                    {_t(t("Cost Price"))}
                                </th>

                                <th
                                scope="col"
                                className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                   {_t(t("Total Qty"))}
                               </th>
                                
                                <th
                                scope="col"
                                className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                >
                                    {_t(t("Amount"))}
                                </th>
                                                                                            
                             </tr>
                           </thead>

                           <tbody className="align-middle">
                             {newRow?.map((item, index) => {
                               
                               return (
                                 <tr className="align-middle" key={index}>

                                  {
                                    name === "Gift Hampers" && (
                                      <th
                                      scope="col"
                                      className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                      >
                                      {item.item_type}
                                      </th>
                                    )
                                  }

                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {item?.item_name}
                                   </td>

                                    <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                    {parseInt(item.qty)}
                                    </td>

                                    {
                                      name === "Finished Goods Packaging" && (
                                        <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                         {item?.unit_name}
                                        </td>
                                      )
                                    }
                                    
                                    <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {parseFloat(item.cost)?.toFixed(3)}
                                    </td>

                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">                                    
                                      {(item.qty && printData.qty) ? (parseInt(item.qty) * Number(printData.qty)) : 0}
                                   </td>

                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {(item.qty && printData.qty) ? ((parseInt(item.qty) * parseFloat(item.cost) * Number(printData.qty)).toFixed(2)) : 0}
                                   </td>

                                 </tr>
                               );
                             })}
                           </tbody>

                           <tfoot className="align-middle">                           
                             <tr>                                
                               <th
                                 scope="col"
                                 colSpan={ name === "Gift Hampers" ? 2 : 1 }
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Total"))}
                               </th>

                                <th
                                    scope="col"
                                    className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                                >
                                  {printData.total_item_qty ? printData.total_item_qty : 0}
                                </th>
                               <th className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               ></th>

                               {name === "Finished Goods Packaging" && (
                               <th className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               ></th>)}
                               
                               <th
                                scope="col"
                                className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {printData.total_consume_qty ? printData.total_consume_qty : 0}                        
                              </th>
                               <th className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border">
                                  {printData.amount}
                               </th>
                               
                              </tr>                                                                                   
                           </tfoot>
                         </table>
                       </div>
                     ),
                   ]}                                      
                 </div>
              </div>
            </div>
         )}
       </div>
     </div>
    </>
  )
}

export default FgPakingPrint;
