import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import makeAnimated from "react-select/animated";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
import Select from "react-select";


//functions
import {
  _t,
  getCookie,
  tableLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//context consumer
import { FinanceContext } from "../../../../../contexts/Finance";

const AccountSettings = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //finance context
    const {
      loading,
      setLoading,

      // account module
      saveData,
      setSaveData,
      cashAcc,
      setCashAcc,
      whtAcc,
      setWhtAcc,
      otherTaxAcc,
      setOtherTaxAcc,
      bankAcc,
      setBankAcc,
      invAcc,
      setInvAcc,
      revAcc,
      setRevAcc,
      cogs,
      setCogs,
      purchaseDis,
      setPurchaseDis,
      salesDis,
      setSalesDis,
      purchaseTax,
      setPurchaseTax,
      salesTax,
      setSalesTax,
      suppCashAcc,
      setSuppCashAcc,
      suppPayableAcc,
      setSuppPayableAcc,

        //coa3
        coa3, 
        setCoa3,
        coa3ForSearch,
        setCoa3ForSearch,
        getCoaL3,
        setPaginatedCoa3,

        //pagination
        dataPaginating,
        setDataPaginating,
    } = useContext(FinanceContext);
    
    // States hook here
    // const [saveData, setSaveData] = useState([
    //   {name:"cash_account_id",value:""},
    //   {name:"withholding_tax_account_id",value:""},
    //   {name:"other_tax_account_id",value:""},
    //   {name:"bank_account_id",value:""},
    //   {name:"inventory_account_id",value:""},
    //   {name:"revenue_account_id",value:""},
    //   {name:"cogs_id",value:""},
    //   {name:"purchase_discount_id",value:""},
    //   {name:"sales_discount_id",value:""},
    //   {name:"purchase_tax_id",value:""},
    //   {name:"sales_tax_id",value:""}
    // ]);

    // const [cashAcc,setCashAcc]=useState([]);
    // const [whtAcc,setWhtAcc]=useState("");
    // const [otherTaxAcc,setOtherTaxAcc]=useState("");
    // const [bankAcc,setBankAcc]=useState([]);
    // const [invAcc,setInvAcc]=useState("");
    // const [revAcc,setRevAcc]=useState("");
    // const [cogs,setCogs]=useState("");
    // const [purchaseDis,setPurchaseDis]=useState("");
    // const [salesDis,setSalesDis]=useState("");
    // const [purchaseTax,setPurchaseTax]=useState("");
    // const [salesTax,setSalesTax]=useState("");
    

    // const getModuleSetting=async()=>{
    //  // setLoading(true);
    //   const moduleUrl = BASE_URL + "/settings/get-module-setting";
    //   try {
    //     const res = await axios.get(moduleUrl, {
    //       headers: { Authorization: `Bearer ${getCookie()}` },
    //     });
    //     // console.log("check get data==>",res.data);
    //     res.data.map((acc)=>{
    //       if(acc.field === "cash_account_id"){
    //         //set state for dropdown default values
    //        let cashAccId =  JSON.parse(acc.value)
    //        let cash =  cashAccId.map((cash)=>{
    //         return coa3ForSearch.find((coa3)=> coa3.id == cash)
    //        });
    //        setCashAcc(cash);
    //        //set savedata values for not empty on load
    //        saveData[0].value = cashAccId
    //        setSaveData([...saveData]);

    //       }
    //       else if(acc.field === "withholding_tax_account_id"){
    //         //set state for dropdown default values
    //         let withTax = coa3ForSearch.find((coa3)=> coa3.id == acc.value);
    //         setWhtAcc(withTax)
    //         //set savedata values for not empty on load
    //         saveData[1].value = withTax == "" ? "" :  acc.value
    //         setSaveData([...saveData]);
    //       }
    //       else if(acc.field === "other_tax_account_id"){
    //         //set state for dropdown default values
    //         let otherTax = coa3ForSearch.find((coa3)=> coa3.id == acc.value);
    //         setOtherTaxAcc(otherTax)
    //         //set savedata values for not empty on load
    //         saveData[2].value = otherTax == "" ? "" : acc.value
    //         setSaveData([...saveData]);
    //       }else if(acc.field === "bank_account_id"){
    //         //set state for dropdown default values
    //         let bankAccId =  JSON.parse(acc.value)
    //        let bankIdArr =  bankAccId.map((bank)=>{
    //         return coa3ForSearch.find((coa3)=> coa3.id == bank)
    //        });
    //        setBankAcc(bankIdArr);
    //        //set savedata values for not empty on load
    //        saveData[3].value = bankAccId?.length == 0 ? "" : bankAccId
    //        setSaveData([...saveData]);
            
    //       }else if(acc.field === "inventory_account_id"){
    //         //set state for dropdown default values
    //         let inventoryAcc = coa3ForSearch.find((coa3)=> coa3.id == acc.value);
    //         setInvAcc(inventoryAcc);
    //         //set savedata values for not empty on load
    //         saveData[4].value = inventoryAcc == "" ? "" : acc.value
    //         setSaveData([...saveData]);
            
    //       }else if(acc.field === "revenue_account_id"){
    //         //set state for dropdown default values
    //         let revenueAcc = coa3ForSearch.find((coa3)=> coa3.id == acc.value);
    //         setRevAcc(revenueAcc);
    //         //set savedata values for not empty on load
    //         saveData[5].value = revenueAcc == "" ? "" : acc.value
    //         setSaveData([...saveData]);
            
    //       }else if(acc.field === "cogs_id"){
    //         //set state for dropdown default values
    //         let costOfGoods = coa3ForSearch.find((coa3)=> coa3.id == acc.value);
    //         setCogs(costOfGoods);
    //         //set savedata values for not empty on load
    //         saveData[6].value = costOfGoods == "" ? "" : acc.value
    //         setSaveData([...saveData]);
            
    //       }else if(acc.field === "purchase_discount_id"){
    //         //set state for dropdown default values
    //         let purchaseDis = coa3ForSearch.find((coa3)=> coa3.id == acc.value);
    //         setPurchaseDis(purchaseDis);
    //         //set savedata values for not empty on load
    //         saveData[7].value = purchaseDis == "" ? "" : acc.value
    //         setSaveData([...saveData]);
            
    //       }else if(acc.field === "sales_discount_id"){
    //         //set state for dropdown default values
    //         let salesDis = coa3ForSearch.find((coa3)=> coa3.id == acc.value);
    //         setSalesDis(salesDis);
    //         //set savedata values for not empty on load
    //         saveData[8].value = salesDis == "" ? "" : acc.value
    //         setSaveData([...saveData]);
            
    //       }else if(acc.field === "purchase_tax_id"){
    //         //set state for dropdown default values
    //         let purchaseTax = coa3ForSearch.find((coa3)=> coa3.id == acc.value);
    //         setPurchaseTax(purchaseTax);
    //         //set savedata values for not empty on load
    //         saveData[9].value = purchaseTax == "" ? "" : acc.value
    //         setSaveData([...saveData]);
            
    //       }else if(acc.field === "sales_tax_id"){
    //         //set state for dropdown default values
    //         let salesTax = coa3ForSearch.find((coa3)=> coa3.id == acc.value);
    //         setSalesTax(salesTax);
    //         //set savedata values for not empty on load
    //         saveData[10].value = salesTax == "" ? "" : acc.value
    //         setSaveData([...saveData]);
    //       }
    //     })
    //     setLoading(false)
    //   } catch (error) {
    //      setLoading(false)
    //      console.log("error==>",error)
    //   }
    // }
    //on load
    // useEffect(()=>{
    //  // getModuleSetting()
    // },[]);

  // handle dropdown functions
  const handleCashAcc = (account) => {
    //for onChange Values
    let cashAccId =  account
    let cashArr =  cashAccId.map((cash)=>{
      return coa3ForSearch.find((coa3)=> coa3.id == cash.id)
    });
    setCashAcc(cashArr)
    //for set values in saveData
    let valueIds=account?.map((item)=>{
      return item.id;
    })
    saveData[0].value = valueIds?.length == 0 ? "" : valueIds
    setSaveData([...saveData]);
  };

  const handleWHT=(account)=>{
    //for onChange Values
    let whtAcc = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setWhtAcc(whtAcc);
    //for set values in saveData
    saveData[1].value=account.id
    setSaveData([...saveData]);
  }
  const handleOtherTax=(account)=>{
    //for onChange Values
    let otherTax = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setOtherTaxAcc(otherTax);
    //for set values in saveData
    saveData[2].value=account.id
    setSaveData([...saveData]);
  }
  const handleBankAcc=(account)=>{
    //for onChange Values
    let bankAccId =  account
    let bankIdArr =  bankAccId.map((bank)=>{
      return coa3ForSearch.find((coa3)=> coa3.id == bank.id)
    });
    setBankAcc(bankIdArr)
    //for set values in saveData
    let valueIds=account?.map((item)=>{
      return item.id;
    })
    saveData[3].value = valueIds?.length == 0 ? "" : valueIds
    setSaveData([...saveData]);
  }
  const handleInventoryAcc=(account)=>{
    //for onChange Values
    let invAccId = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setInvAcc(invAccId);
    //for set values in saveData
    saveData[4].value=account.id
    setSaveData([...saveData]);
  }
  const handleRevAcc=(account)=>{
    //for onChange Values
    let revAccId = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setRevAcc(revAccId);
    //for set values in saveData
    saveData[5].value=account.id
    setSaveData([...saveData]);
  }
  const handleCOGS=(account)=>{
    //for onChange Values
    let cogsAccId = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setCogs(cogsAccId);
    //for set values in saveData
    saveData[6].value=account.id
    setSaveData([...saveData]);
  }
  const handlePurchaseDis=(account)=>{
    //for onChange Values
    let purchaseDisId = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setPurchaseDis(purchaseDisId);
    //for set values in saveData
    saveData[7].value=account.id
    setSaveData([...saveData]);
  }
  const handleSaleDis=(account)=>{
    //for onChange Values
    let saleDisId = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setSalesDis(saleDisId);
    //for set values in saveData
    saveData[8].value=account.id
    setSaveData([...saveData]);
  }
  const handlePurchaseTax=(account)=>{
    //for onChange Values
    let purchaseTaxId = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setPurchaseTax(purchaseTaxId);
    //for set values in saveData
    saveData[9].value=account.id
    setSaveData([...saveData]);
  }
  const handleSaleTax=(account)=>{
    //for onChange Values
    let saleTaxId = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setSalesTax(saleTaxId);
    //for set values in saveData
    saveData[10].value=account.id
    setSaveData([...saveData]);
  }
  const handleSuppCashAcc=(account)=>{
    //for onChange Values
    let suppCashAcc = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setSuppCashAcc(suppCashAcc);
    //for set values in saveData
    saveData[11].value=account.id
    setSaveData([...saveData]);
  }
  const handleSuppPayableAcc=(account)=>{
    //for onChange Values
    let suppPayableAcc = coa3ForSearch.find((coa3)=> coa3.id == account.value);
    setSuppPayableAcc(suppPayableAcc);
    //for set values in saveData
    saveData[12].value=account.id
    setSaveData([...saveData]);
  }

  const saveAccounts=(e)=>{
    e.preventDefault();
    let bodyData={
      module_settings:saveData
    }
    const moduleUrl = BASE_URL + `/settings/create-module-setting`;
    // console.log("create data==>",bodyData);
    // validate all dropdowns
    if(saveData[0].value != "" && saveData[1].value != "" &&
      saveData[2].value != "" && saveData[3].value != "" &&
      saveData[4].value != "" && saveData[5].value != "" &&
      saveData[6].value != "" && saveData[7].value != "" &&
      saveData[8].value != "" && saveData[9].value != "" &&
      saveData[10].value != ""){

      return axios
      .post(moduleUrl, bodyData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setSaveData([
          {name:"cash_account_id",value:""},
          {name:"withholding_tax_account_id",value:""},
          {name:"other_tax_account_id",value:""},
          {name:"bank_account_id",value:""},
          {name:"inventory_account_id",value:""},
          {name:"revenue_account_id",value:""},
          {name:"cogs_id",value:""},
          {name:"purchase_discount_id",value:""},
          {name:"sales_discount_id",value:""},
          {name:"purchase_tax_id",value:""},
          {name:"sales_tax_id",value:""}
        ]);
        //getModuleSetting()
        toast.success(`${_t(t("Module settings has been saved"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        console.log("check error==>",error);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
          
      });
    }
    else{
      toast.error(`${_t(t("Please select all dropdowns"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  }
  const customSelectStyle={
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize:"14px"
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px',
    })
  }
  return (
    <>
    <Helmet>
      <title>{_t(t("Module Settings"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <AccountsSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                  <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}

                  {/* Loading effect
                  {loading === true ? (
                    tableLoading()
                  ) : (
                     */}
                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Module Settings"))}
                              </span>
                            </li>
                          </ul>
                        </div>

                      {/* Form starts here */}
                      <form
                        className="row card p-2 mx-3 sm-text my-2"
                        onSubmit={(e)=>saveAccounts(e)}
                      >
                        <div className="col-12">
                         
                          <div className="form-group mt-3 d-flex align-items-center">
                          <div className="col mr-4">
                          <div className="genAcc-select mb-1">Cash Accounts <span className="text-danger">*</span></div>
                          <Select
                          menuPlacement="auto"
                          minMenuHeight={300}
                          options={coa3ForSearch && coa3ForSearch}
                          components={makeAnimated()}
                          getOptionLabel={(option) =>
                            option.level3_name
                          }
                          getOptionValue={(option) =>
                            option.id
                          }
                          value={cashAcc}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isMulti
                          isClearable
                          closeMenuOnSelect={false}
                          maxMenuHeight="200px"
                          onChange={handleCashAcc}
                          placeholder={
                            _t(
                              t("Please select cash accounts")
                            ) + ".."
                          }
                          styles={customSelectStyle}
                          
                        />
                          </div>
                          <div className="col">
                          <div className="genAcc-select mb-1">Withholding Tax Account <span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleWHT}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select withholding tax account")
                                ) + ".."
                              }
                              value={whtAcc}
                              styles={customSelectStyle}                 
                            />
                          </div>
                          </div>
                          <div className="form-group mt-3 d-flex align-items-center">
                          <div className="col mr-4">
                          <div className="genAcc-select mb-1">Other Tax Account <span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleOtherTax}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select other tax account")
                                ) + ".."
                              }
                              value={otherTaxAcc}
                              styles={customSelectStyle}
                            />
                          </div>
                          <div className="col">
                          <div className="genAcc-select mb-1">Bank Accounts <span className="text-danger">*</span></div>
                          <Select
                          menuPlacement="auto"
                          minMenuHeight={300}
                          options={coa3ForSearch && coa3ForSearch}
                          components={makeAnimated()}
                          getOptionLabel={(option) =>
                            option.level3_name
                          }
                          getOptionValue={(option) =>
                            option.id
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isMulti
                          isClearable
                          closeMenuOnSelect={false}
                          maxMenuHeight="200px"
                          onChange={handleBankAcc}
                          placeholder={
                            _t(
                              t("Please select bank accounts")
                            ) + ".."
                          }
                          value={bankAcc}
                          styles={customSelectStyle}
                        />
                          </div>
                          </div>
                          <div className="form-group mt-3 d-flex align-items-center">
                          <div className="col mr-4">
                          <div className="genAcc-select mb-1">Inventory Account<span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleInventoryAcc}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select inventory account")
                                ) + ".."
                              }
                              value={invAcc}
                              styles={customSelectStyle}
                            />
                          </div>
                          <div className="col">
                          <div className="genAcc-select mb-1">Revenue Account <span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleRevAcc}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select revenue account")
                                ) + ".."
                              }
                              value={revAcc}
                              styles={customSelectStyle}                   
                            />
                          </div>
                          </div>
                          <div className="form-group mt-3 d-flex align-items-center">
                          <div className="col mr-4">
                          <div className="genAcc-select mb-1">Cost of good sold <span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleCOGS}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select cost of good sold")
                                ) + ".."
                              }
                              value={cogs}    
                              styles={customSelectStyle}               
                            />
                          </div>
                          <div className="col">
                          <div className="genAcc-select mb-1">Purchase Discount <span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="auto"
                              minMenuHeight={300}
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handlePurchaseDis}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select purchase discount")
                                ) + ".."
                              }
                              value={purchaseDis}   
                              styles={customSelectStyle}                
                            />
                          </div>
                          </div>
                         
                          <div className="form-group mt-3 d-flex align-items-center">
                          <div className="col mr-4">
                          <div className="genAcc-select mb-1">Sales Discount <span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="top"                              
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleSaleDis}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select sales discount")
                                ) + ".."
                              }
                              value={salesDis}    
                              styles={customSelectStyle}               
                            />
                          </div>
                          <div className="col">
                          <div className="genAcc-select mb-1">Purchase Tax <span className="text-danger">*</span></div>
                          <Select
                              menuPlacement="top"                              
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handlePurchaseTax}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select purchase tax")
                                ) + ".."
                              }
                              value={purchaseTax} 
                              styles={customSelectStyle}                  
                            />
                          </div>
                          </div>
                          <div className="form-group mt-3 d-flex align-items-center">
                          <div className="col mr-4">
                          <div className="genAcc-select mb-1">Sales Tax <span className="text-danger">*</span></div>
                          <Select
                               menuPlacement="top"                               
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleSaleTax}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select sales tax")
                                ) + ".."
                              }
                              value={salesTax}   
                              styles={customSelectStyle}                
                            />
                          </div>
                          <div className="col">
                          <div className="genAcc-select mb-1">Supplier Cash Account <span className="text-danger">*</span></div>
                          <Select
                               menuPlacement="top"                               
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleSuppCashAcc}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select cash account")
                                ) + ".."
                              }
                              value={suppCashAcc}   
                              styles={customSelectStyle}                
                            />
                          </div>                          
                          </div>

                          <div className="form-group mt-3 d-flex align-items-center">
                          <div className="col mr-4">
                          <div className="genAcc-select mb-1">Supplier Payable Account Id <span className="text-danger">*</span></div>
                          <Select
                               menuPlacement="top"                               
                              options={coa3ForSearch && coa3ForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.level3_name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="account"
                              className="xsm-text"
                              onChange={handleSuppPayableAcc}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(
                                  t("Please select payable account")
                                ) + ".."
                              }
                              value={suppPayableAcc}   
                              styles={customSelectStyle}                
                            />
                          </div>
                          <div className="col"></div>                                                   
                          </div>
                         
                          <div className="form-group mt-4 pb-2">
                            <div className="col-lg-12">
                              <button
                                className="btn btn-primary px-5 rounded"
                                type="submit"
                              >
                                {_t(t("Save"))}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                 </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>
  )
}

export default AccountSettings
