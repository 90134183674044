import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap"; 
import "./dashboard.css";
import { ReactComponent as Triangle } from "../../../public/caret-up.svg";
import ReactHTMLTableToExcel from "react-html-table-to-excel"

const ListWidget = ({data, heading, size, handleReq = null, url=null, loading=false }) => {

  const headingsToCheck = [
    "branch wise sales",
    "item wise sales",
    "category wise sales",
    "brand wise sales",
    "last 3 month sales"
  ];

  const [loadAll,setLoadAll] = useState(false);
  const [data1,setData1] = useState(null);
  const [tableLoading,setTableLoading] = useState(false);
  const componentRef = useRef();

  const getReport= (e)=>{
    componentRef.current.handleDownload();
  }

  const handleLoad = () =>{
    setTableLoading(true);
    if(loadAll === true){
      setData1(data.tbody);
    }else{
      setData1(data.tbody.slice(0, 5));
    }
    setTimeout(() => {    
      setTableLoading(false);
    }, 300);
  }
 
  useEffect(()=>{
    const loadData = headingsToCheck.includes(heading?.toLowerCase());
    if(data){
      if(loadData){
        handleLoad();
      }
    }
 },[loadAll,data])

  return (
    <div className={`col mt-3 mt-1 px-2 position-relative ${size}`}>
     <div className="dashboard__card_list">
      <ReactHTMLTableToExcel
      id="table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table={heading}
      filename={heading}
      sheet="sheet 1"
      buttonText="Sales Detail"
      ref={componentRef}
      />
      <div className="d-flex align-items-center justify-content-between px-0 pr-2 mb-2">
      <div className="list-heading font-weight-bold">
        <h5 className="mt-1">{heading}</h5>
      </div>
    
      <div>
      {
        data && data?.tbody && data.tbody?.length > 5 && (
        <button className="btn btn-primary btn-sm table-text rounded px-2 mr-2"
        disabled={tableLoading}
        onClick={()=> setLoadAll(!loadAll)}
        >
          {loadAll ? "Load less" : "Load All"}
        </button>)
      }
      {
        data && data?.tbody && data.tbody?.length > 0 && (
          <button
          className="btn btn-primary text-uppercase btn-sm table-text px-2 rounded"
          onClick={(e) => getReport(e)}
          >
          <i class="fa fa-table mr-1" aria-hidden="true"></i>
           download 
          </button>
        )
      }
      </div>
      </div>
      {data1 && data ? (
      
        <div className="col-12">
          <div className="list-table-view" data-simplebar style={{height:"14.5rem"}}>
          <table id={heading}  className="table mb-0 table-borderless akash-table-for-print-padding">
          <thead>
            <tr>
              <th
                scope="col"
                className="list-head-text text-capitalize dashboard_border border-1 p-2"
              >
                {data.thead[0]}
              </th>
              <th
                scope="col"
                className="list-head-text text-capitalize dashboard_border border-1 p-2"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-capitalize">
                    {data.thead[1]}
                  </div>
                  <div className="ml-2" onClick={()=>{
                    setTableLoading(true)
                    let Data =  [...data1].sort((a,b)=>{
                      return parseFloat(b[1]) - parseFloat(a[1])
                    });
                    setTimeout(() => {
                      setData1(Data);
                      setTableLoading(false)
                    }, 300);
                  }}>
                  <Triangle className = "dashboard_card_up cursor-pointer"/>
                  </div>
                </div>
              </th>
              <th
                scope="col"
                className=" list-head-text text-capitalize dashboard_border border-1 p-2"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-capitalize">
                    {data.thead[2]}
                  </div>
                  <div className="ml-2" onClick={()=>{
                    setTableLoading(true)
                    let Data = [...data1].sort((a,b)=>{
                      return parseFloat(b[2]) - parseFloat(a[2])
                    });
                    setTimeout(() => {
                      setData1(Data);
                      setTableLoading(false)
                    }, 300);
                  }}>
                  <Triangle className = "dashboard_card_up cursor-pointer"/>
                  </div>
                </div>
              </th>
              <th
                scope="col"
                className=" list-head-text text-capitalize dashboard_border border-1 p-2"
              >
              {data.thead[3]}
              </th>
            </tr>
          </thead>
          <tbody>
            {data1?.map((dataChild) => {
              let percent = ((parseFloat(dataChild[1]) / parseFloat(data.total))*100)?.toFixed(2);
              return (
                  <tr>
                    <td
                      scope="col"
                      className=" list-text text-capitalize dashboard_border border-1 p-2"
                    >
                      {dataChild[0]}
                    </td>
                    <td
                      scope="col"
                      className=" list-text text-capitalize dashboard_border border-1 p-2"
                    >
                      {parseFloat(dataChild[1])?.toFixed(2)}
                    </td>
                    <td
                      scope="col"
                      className=" list-text text-capitalize dashboard_border border-1 p-2"
                    >
                      {dataChild[2]}
                    </td>
                    <td
                    scope="col"
                    className=" list-text text-capitalize dashboard_border border-1 p-2"
                    >
                      <div className="d-flex align-items-center" style={{width:"100%"}}>
                      <span className = "mr-2" style={{width:"42%",fontSize:"0.75rem"}}>
                      {percent}%
                      </span>
                        <ProgressBar style={{width:"100%", height:"0.6rem"}} now = {percent} />
                      </div>
                    </td>
                  </tr>
                  );
                })}
            </tbody>
            </table>
          </div>
        </div>
    
      ):(
        <div className="btn_load btn_load_height">
        {
          loading ? (
            <>
            {/*loading */}
            <div className={`${(loading) && "loadingBlur"}`}></div>
            <div className={`${(loading) && "lds-roller ldr-height"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </>
          ) : (
            <button className="btn btn-sm btn-primary rounded-md" onClick={()=> url && handleReq(url)}>Load Data</button>
          )}
        </div>
      )}
     </div>
    </div>
  );
};

export default ListWidget;
