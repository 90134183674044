import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
  getDate
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";

const SettlementAdd = () => {
  const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
      loading,
      setLoading,      
      paymentTypeForSearch,
      branchForSearch
    } = useContext(RestaurantContext);
  
    let {
      //settlement list
      getSettlementList,
      settlementList, 
      setSettlementList,
      setPaginatedSettlementList,
      settlementListForSearch, 
      setSettlementListForSearch,

      partyForSearch
    } = useContext(UserContext);

    // States hook here
    //new item
    let [newSettlement, setNewSettlement] = useState({
      from_date: "",
      to_date: "",
      payment_type: null,
      amount: 0,
      branch: null,
      party: null
    });

    let common_toast = {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }
  
    //select style
    const customStyle = {
      control: (provided) => ({
        ...provided,
        minHeight: "2rem",
        marginTop: "0",
        marginBottom: "0",
      }),
  
      singleValue: (provided) => ({
        ...provided,
        alignSelf: "center",
      }),
      input: (provided) => ({
        ...provided,
        margin: "0",
      }),
      valueContainer: (provided) => ({
        ...provided,
        marginTop: "0",
        padding: "0 0.5rem",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0 0.4rem",
        svg: {
          height: "2rem",
        },
      }),
    };
  
    //handle Set branch hook
    const handleSetPayment = (type) => {
        setNewSettlement({ ...newSettlement, payment_type:type });
    };

    //handle Set branch hook
    const handleSetBranch = (branch) => {
      setNewSettlement({ ...newSettlement, branch });
      if(newSettlement.from_date !== null && newSettlement.to_date !== null && newSettlement.party){
        getTotalAmount(newSettlement.from_date,newSettlement.to_date, branch, newSettlement.party);
      }
    };

    const handleSetParty = (party) => {
      setNewSettlement({ ...newSettlement, party });
      if(newSettlement.from_date !== null && newSettlement.to_date !== null && newSettlement.branch !== null){
        getTotalAmount(newSettlement.from_date,newSettlement.to_date, newSettlement.branch, party);
      }
    };

    const handleAmount=(amount)=>{
        setNewSettlement({ ...newSettlement, amount });
    }
  
    //post req of stock item add
    const settlementAxios = async () => {
      setLoading(true);
      let fdate = getDate(newSettlement.from_date);
      let tdate = getDate(newSettlement.to_date);

        let formData = {
          payment_type: newSettlement.payment_type.id,     
          from_date: fdate,
          to_date: tdate,
          branch_id: newSettlement.branch?.id,
          party_id: newSettlement.party?.id,          
          amount: newSettlement.amount,
        };
  
      const url = BASE_URL + "/settings/update-order-payment";
      try {
        const res = await axios.post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });        
        // console.log("check data==>",res.data);
        setSettlementList(res.data[0]);
        setSettlementListForSearch(res.data[1]);
        setLoading(false);
        toast.success(`${_t(t("Settlement has been added"))}`, common_toast);
      history.push(
        "/dashboard/manage/restaurant/settlement"
      )
      } catch (error) {        
        console.log("check error==>",error);
        setLoading(false);
        toast.error(`${_t(t("Please try again later"))}`, common_toast);
      }
    
  };
  
    //send to server
    const handleSubmit = (e) => {
      e.preventDefault();
      if (
        newSettlement.from_date !== null &&
        newSettlement.to_date !== null &&
        newSettlement.payment_type !== null &&
        newSettlement.amount !== null &&
        newSettlement.branch !== null
      ) {        
          settlementAxios();        
      } else {
        toast.error(`${_t(t("Please fill all the required fields"))}`,common_toast);
      }
    };

    const getTotalAmount = async (fromDate,toDate,branch,party) => {
      setLoading(true);
      let fdate = getDate(fromDate);
      let tdate = getDate(toDate);
      const url = BASE_URL + "/settings/settlement-amount";
      let formData = {        
        from_date: fdate,
        to_date: tdate,
        branch_id: branch.id,
        party_id: party.id
      };      
      try {
        const res = await axios.post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });                
        setNewSettlement({
          ...newSettlement,
          from_date: fromDate,
          to_date: toDate,
          branch:branch,
          amount:res.data > 0 ? res.data : 0,
          party: party
        }) 
        setLoading(false);      
      } catch (error) {        
        setLoading(false);
        toast.error(`${_t(t("Please try again later"))}`, common_toast);
      }
    }
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Add Settlements"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                    <div className={`${(loading) && "loadingBlur"}`}></div>
                      <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* Loading effect */}
                      {/* {loading === true ? (
                        tableLoading()
                      ) : ( */}
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Add Settlement"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"
                            onSubmit={(e)=>{
                              e.preventDefault();
                              newSettlement.amount!=0 ? handleSubmit(e) : toast.error(`${_t(t("Amount Can't be Empty or 0"))}`, common_toast);
                            }}
                            autoComplete="off"
                          >
                            <div className="col-12">
                            <div className="row">
                              <div className="form-group col-3 text-right mt-3">
                                  <div className="mb-2 text-left">
                                    <label
                                      htmlFor="from_date"
                                      className="control-label"
                                    >
                                      {_t(t("From Date"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <DatePicker
                                      selected={newSettlement.from_date}
                                      onChange={(date) => {
                                        setNewSettlement({...newSettlement,from_date:date});
                                        if(newSettlement.to_date && newSettlement.branch && newSettlement.party){
                                          getTotalAmount(date,newSettlement.to_date,newSettlement.branch,newSettlement.party);
                                        }
                                        // else{
                                        //   toast.error("Please select both date",common_toast);
                                        // }
                                      }}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control table-text"
                                      placeholderText={_t(t("from date"))}
                                      shouldCloseOnSelect={false}
                                      required
                                    />
                                  </div>
                                </div>
  
                                <div className="form-group col-3 text-right mt-3">
                                  <div className="mb-2 text-left">
                                    <label
                                      htmlFor="to_date"
                                      className="control-label"
                                    >
                                      {_t(t("To Date"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <DatePicker
                                      selected={newSettlement.to_date}
                                      onChange={(date) => {
                                        setNewSettlement({...newSettlement,to_date:date});
                                        if(newSettlement.from_date && newSettlement.branch && newSettlement.party){
                                          getTotalAmount(newSettlement.from_date,date,newSettlement.branch,newSettlement.party);
                                        }
                                        // else{
                                        //   toast.error("Please select both date",common_toast);
                                        // }
                                      }}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control table-text"
                                      placeholderText={_t(t("to date"))}
                                      shouldCloseOnSelect={false}
                                      required
                                    />
                                  </div>
                                </div>

                                <div className="form-group mt-3 col-3">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("Select Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchForSearch ? branchForSearch : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={newSettlement.branch || null}
                                      classNamePrefix="select"
                                      onChange={handleSetBranch}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select branch")) + ".."
                                      }
                                    />
                                </div>

                                <div className="form-group mt-3 col-3">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("Select Party"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={partyForSearch ? partyForSearch : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={newSettlement.party || null}
                                      classNamePrefix="select"
                                      onChange={handleSetParty}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select party")) + ".."
                                      }
                                    />
                                </div>                                                                  

                                <div className="form-group mt-3 col-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="amount"
                                      className="control-label"
                                    >
                                      {_t(t("Amount"))}
                                      <span className="text-danger">*</span>                                        
                                    </label>
                                  </div>
                                <input
                                  readOnly
                                  type="number"
                                  step="0.1"
                                  min="0"
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  className={`form-control table-text`}
                                  id='amount'
                                  name='amount'
                                  onChange={(e)=>handleAmount(e.target.value)}
                                  value={                                                    
                                    newSettlement.amount || ""
                                  }
                                  required
                                  placeholder="Amount"
                                                  
                                /> 
                                </div>

                                <div className="form-group mt-3 col-3">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="payment_type"
                                        className="control-label"
                                      >
                                        {_t(t("Payment Type"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={paymentTypeForSearch ? paymentTypeForSearch : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={newSettlement.payment_type || null}
                                      classNamePrefix="select"
                                      onChange={handleSetPayment}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select payment")) + ".."
                                      }
                                    />
                                </div>
                              </div>                                                            
  
                              <div className="form-group mt-4 pb-2">                                
                                <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/restaurant/settlement"))}
                                //   data-dismiss="modal"
                                >
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i className="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      {/* // )} */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default SettlementAdd;