import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
// import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";

const AdminReport = () => {
    const { t } = useTranslation();
    // const history = useHistory();
    //getting context values here
    let { loading, setLoading, dataPaginating, generalSettings } =
      useContext(SettingsContext);
    let { branchForSearch, orderTypeList, paymentTypeForSearch } =
      useContext(RestaurantContext);
    // const { foodForSearch, foodGroupForSearch } = useContext(FoodContext);
    const { partyForSearch,authUserInfo } = useContext(UserContext);
  
    let [newAdminReport, setNewAdminReport] = useState(null);
  
    //all data
    const [reportData, setReportData] = useState(null);
  
    // paidMoney
    // const [paidMoney, setPaidMoney] = useState(0);
    //return
    // const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);
    const componentRef = useRef();
    //settle order
    // const [checkOrderDetails, setCheckOrderDetails] = useState({
    //   item: null,
    //   settle: false,
    //   uploading: false,
    //   payment_type: null,
    //   payment_amount: null,
    // });
  
    // const groupByArray = [
    //   { id: "Branch", name: "Branch" },
    //   { id: "Order Types", name: "Order Types" },
    //   { id: "Payment Types", name: "Payment Types" },
    // ];
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    // const [sumPrice, setSumPrice] = useState(0);
    // const [sumTotalPrice, setSumTotalPrice] = useState(0);
    const [siteName, setSiteName] = useState("");
    const [password,setPassword]=useState("");
    //useEffect == componentDidMount()
    const [branchSearch, setBranchSearch] = useState(null);
  
    useEffect(() => {
      setSiteName(getSystemSettings(generalSettings, "siteName"));
      if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
        setBranchSearch(
          branchForSearch.filter(
            (branch) => branch.id === authUserInfo.details.branch_id
          )
        );
      } else {
        setBranchSearch(branchForSearch);
      }
  
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }, [generalSettings]);
  
    //Print Report
    const ReportPrint = useRef();
  
    const handlePrintBtn = useReactToPrint({
      content: () => ReportPrint.current,
    });
  
    const handleBranch = (branch) => {
      setNewAdminReport({ ...newAdminReport, branch });
      // setBranch(branch);
    };
    // const handleGroup = (group) => {
    //   setNewSourceWiseReport({ ...newSourceWiseReport, group });
    //   //setGroup(group);
    // };
    // const handleItem = (item) => {
    //   setNewSourceWiseReport({ ...newSourceWiseReport, item });
    //   //setItem(item);
    // };
    // const handleParty = (party) => {
    //   setNewSourceWiseReport({ ...newSourceWiseReport, party });
    //   //setParty(party);
    // };
    // const handleOrderTypes = (orderTypes) => {
    //   setNewSourceWiseReport({ ...newSourceWiseReport, orderTypes });
    //   //setOrderTypes(orderTypes);
    // };
    // const handlePaymentTypes = (paymentTypes) => {
    //   setNewSourceWiseReport({ ...newSourceWiseReport, paymentTypes });
    //   //setPaymentTypes(paymentTypes);
    // };
    // const handleGroupBy = (groupBy) => {
    //   setNewSourceWiseReport({ ...newSourceWiseReport, groupBy });
    //   //setGroupBy(groupBy);
    // };

    const checkForAdmin=(e)=>{
        e.preventDefault();        
        if(password === "dataverify"){
            getBranchWiseSelected()            
        }else{
            toast.error(`${_t(t("Wrong Password!"))}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
              setPassword("");
        }
    } 
  
    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
    //   console.log("newSourceWiseReport ", newSourceWiseReport);
    if (
        // newSourceWiseReport?.branch?.id !== null &&
        startDate !== null &&
        endDate !== null &&
        newAdminReport?.branch?.id
        ) {
            document.getElementById('close-btn').click();
            setLoading(true);
        var fromDate = moment(startDate).format("YYYY-MM-DD");
        var toDate =  moment(endDate).format("YYYY-MM-DD");
  
        const url = BASE_URL + "/settings/get-source-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", newAdminReport?.branch?.id);
  
        console.log("formData ", formData);
  
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            console.log("SourceWiseReportResult", res);
            setReportData(res.data[0]);
  
            setLoading(false);
            console.log("Component ", componentRef);
            setGeneratedReport(true);
            componentRef.current.handleDownload();
            setNewAdminReport(null);
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        document.getElementById('close-btn').click();
        toast.error(
          `${_t(
            t(
              "Please select from date, to date and branch to generate report"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };
    let gross_sale = 0;
    let net_sale = 0;
    let commission = 0;
    let total_tax = 0;
    let total_commission = 0;
    let net_amount = 0;
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Report For Admin"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Source Wise Report"
          sheet="sheet 1"
          buttonText="Sales Detail"
          ref={componentRef}
        />

        {/* Password modal */}
      <div className="modal fade" id="addvariation" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  Password Protected (Only for admin)
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}              
                <div key="fragment-property-group-1">
                  <form
                    onSubmit={(e)=>checkForAdmin(e)}
                    autoComplete="off"
                  >
                    <div>
                      <label htmlFor="name" className="form-label">
                        {_t(t("Password"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter password"
                        value={password}                        
                        onChange={(e)=>setPassword(e.target.value)}
                      />
                    </div>                    

                    <div className="mt-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-3 ">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                             {_t(t("Verify"))}
                          </button>
                        </div>                        
                      </div>
                    </div>
                  </form>
                </div>              
            </div>
          </div>
        </div>
      </div>
  
        {/* Print Button */}
        <div className="d-none">
          <div>
            <style type="text/css" media="print">
              {"\
            @page { size: landscape; }\
          "}
            </style>
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {reportData !== null &&
                      reportData !== undefined &&
                      generatedReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            {console.log("reportData", reportData)}
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                              <table
                                className="table table-bordered table-hover min-table-height mt-3"
                                id="table-to-xls"
                              >
                                <div className="page-break"></div>
                                <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                    <th colspan="25" style={{ border: "0px" }}>
                                      <div className="row">
                                        <div className="col-12">
                                          <h3 className="text-center mb-0">
                                            {siteName}
                                          </h3>
                                          <h3 className="text-center mt-0">
                                            Source Wise Report
                                          </h3>
                                          <p className="text-center">
                                            FROM&nbsp;
                                            <Moment format="LL">
                                              {startDate}
                                            </Moment>
                                            &nbsp;TO&nbsp;
                                            <Moment format="LL">{endDate}</Moment>
                                          </p>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("S/L"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Date"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Branch"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Call Centre Sq #"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Order Sq #"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Outlet Sq #"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Cust Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Cust Contact#"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Address"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Order Type"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Order Status"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Order Punch Time"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Rider Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Source"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Ref #"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Method of Payment"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Gross Sale"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Net Sale"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Claim Discount"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Unclaim Discount"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Commission"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Tax 13%"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Total Commission"))}
                                    </th>
                                    <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {_t(t("Net Amount"))}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="align-middle">
                                  {console.log("309 ", reportData)}
                                  {/* loop here*/}
                                  {reportData.map((item, i) => {
                                    console.log("309", item);
                                    gross_sale += parseFloat(item.order_bill);
                                    net_sale += parseFloat(item.total_payable);
                                    net_amount += parseFloat(item.total_payable);
                                    return (
                                      <div key={i}>
                                        <tr>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {i + 1}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            <Moment format="LL">
                                              {item.created_at}
                                            </Moment>
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.branch_name}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.call_center}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          ></td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.token?.id}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.customer_name}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.customer_phn_no}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.customer_address}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.order_type_name}
                                          </td>
                                          <td class="xsm-text text-capitalize align-middle text-center">
                                            {parseInt(item.is_cancelled) === 0 ? (
                                              [
                                                parseInt(item.is_ready) === 0 ? (
                                                  <span class="btn btn-transparent btn-secondary xsm-text text-capitalize">
                                                    {_t(t("processing"))}
                                                  </span>
                                                ) : (
                                                  <span class="btn btn-transparent btn-success xsm-text text-capitalize px-4">
                                                    {_t(t("Ready"))}
                                                  </span>
                                                ),
                                              ]
                                            ) : (
                                              <span class="btn btn-transparent btn-primary xsm-text text-capitalize px-3">
                                                {_t(t("Cancelled"))}
                                              </span>
                                            )}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.punching_time}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.rider_name}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.party_name}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.ref_no}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.bill_distribution !== null
                                              ? item.bill_distribution[0]
                                                  .payment_type
                                              : ""}{" "}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.order_bill}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.total_payable}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.claimable_amount}{" "}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.unclaimable_amount}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            0
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            0
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            0
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.total_payable}
                                          </td>
                                        </tr>
                                      </div>
                                    );
                                  })}
                                  <tr>
                                    <th colspan="15"></th>
                                    <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                      Total
                                    </th>
                                    <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                      {formatPrice(gross_sale)}
                                    </td>
                                    <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                      {formatPrice(net_sale)}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                      {formatPrice(commission)}
                                    </td>
                                    <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                      {formatPrice(total_tax)}
                                    </td>
                                    <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                      {formatPrice(total_commission)}
                                    </td>
                                    <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                      {formatPrice(net_amount)}
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot style={{ border: "0px" }}>
                                  <tr style={{ border: "0px" }}>
                                    <td
                                      colspan="7"
                                      className="text-center"
                                      style={{ border: "0px" }}
                                    >
                                      <h5 className="mt-3">
                                        Powered by indolj.pk
                                      </h5>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* spin loading ends */}
  
                      {/* Loading effect
                      {loading === true ? (
                        tableLoading()
                      ) : (
                         */}
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-12 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Report For Admin"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-3">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={true}
                              />
                            </div>
                            <div className="col-3">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={true}
                              />
                            </div>
                            <div className="col-3">
                              <Select
                                options={branchSearch && branchSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                //value={branch}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleBranch}
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a branch")) + ".."}
                              />
                            </div>
                            <div className="col-3">
                            <button
                                type="button"
                                // className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"                                
                                data-toggle={password === "" && "modal"}
                                data-target={password === "" && "#addvariation"}
                              className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => {
                                password !== "" && getBranchWiseSelected()
                              }}
                            >
                              Print Report
                            </button>
                          </div>                            
                          </div>                          
                        </div>
                     
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default AdminReport
