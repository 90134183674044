import React from 'react';
import {
    _t,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next"; 

const CardModal = ({cardAdd,handleCardSubmit,inputRefs,cardNumber,handleCardChange}) => {
  const { t } = useTranslation();

  return (
    <>
        <div
        className="modal fade"
        ref={cardAdd}
        id="cardAdd"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content" style={{ borderRadius: "1rem" }}>
            {/* <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> */}
            <div className="modal-body py-4">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form autoComplete="off" 
                 onSubmit={(e)=>{
                  handleCardSubmit(e)
                 }}
                >
                  <div>
                    <div className="addons-list__item mt-1 mx-1">
                      <label
                        htmlFor="name"
                        className="form-label d-flex justify-content-between"
                      >
                        <span>{_t(t("Enter Card Number"))} </span>
                        <span
                          onClick={(e) => {
                            cardAdd.current.classList.remove("showing")
                          }}
                        >
                          <i className="fa fa-times-circle close-1 pointer-cursor"></i>
                        </span>
                      </label>
                      {
                        /*
                      <input
                        type="text"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-","ArrowUp","ArrowDown","ArrowLeft", "ArrowRight"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}  
                        min="0"
                        className="form-control-card"
                        placeholder="Card first 6 number"
                        autoComplete="off"
                        maxLength={6}
                        ref={inputRefs.first}
                        value={cardNumber.first}
                        onChange={(e) => handleCardChange('first', e.target.value)}                      
                        required
                      />
                      */ }

                      <input
                      type="text"
                      min="0"
                      className="form-control-card mt-1"
                      placeholder="Card last 6 number"
                      autoComplete="off"
                      maxLength={6}
                      ref={inputRefs.last}
                      value={cardNumber.last}
                      onChange={(e) => handleCardChange('last', e.target.value)}                     
                      required
                    />
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="row">
                    <div className="col-8">
                    </div>
                      <div className="col-4">
                        <button
                          type="submit"
                          className="btn btn-success font-weight-bold w-100 sm-text text-uppercase t-width-max px-2"
                          
                        >
                          {_t(t("Add Card"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CardModal
