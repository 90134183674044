import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';
import { BASE_URL } from '../../../../BaseUrl';
import Moment from "react-moment";

const OrderDelivery = () => {
    const [orderDetails, setOrderDetails] = useState(null);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("Processing...");

    const toastObj = {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
    };

    const getDetails = async() =>{
        setLoading(true);
        try {
          const url = BASE_URL + "/order-details/" + id;
          const res = await axios.get(url);
          if(res.data[1]){
            const data = res.data[1]?.delivery_time ? res.data[1] : res.data[0];
            setOrderDetails(data);
            setMsg("The order not placed!")
          }else{
            setMsg("Order can't be fetched before rider pickup");
          }
          setLoading(false);
        } catch (error) {
            setLoading(false)
            setMsg("The order not placed!")
            toast.error("Unable to retrieve your order",toastObj);
        }
    }

    useEffect(()=>{
        getDetails();
    },[]);

    const getGeolocation = (callback) => {
        navigator.geolocation.getCurrentPosition((position)=> {
            const { latitude, longitude } = position.coords;
            const data = { latitude, longitude };
            callback(data); // Call the callback with the data
        },
        (error) => {
            console.error("Error getting geolocation:", error);
        }
      )
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoading(true);
        getGeolocation(async(data)=>{
            const destination = data.latitude + "," + data.longitude;
            const status = 'delivered';
            if(orderDetails?.id){
                const endPoint = `/delivered-order/${orderDetails.id}/${status}/${destination}`;
                const url = BASE_URL + endPoint;
                try {
                  const res = await axios.get(url);
                  if(res){
                      setOrderDetails({
                        ...res.data,
                        delivery_time: new Date().getTime(),
                      });
                      toast.success("Your order has been delivered",toastObj);
                  }
                  setLoading(false)
                } catch (error) {
                    setLoading(false);
                    toast.error("Please try again",toastObj);
                }
            }
        })
    }


  return (
    <div className='kitchen_order position-relative vh-100 d-flex align-items-center'>
        <div className={`${(loading) && "loadingBlur"}`}></div>
        <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

        <div className="container my-3">
            <div className="row justify-content-center">
                <div className="col-11 text-center">
                    <h3 className="my-3">Order Details</h3>
                </div>
                <div className="col-md-6 col-11 lg-text">
                    <div className="card border-0" style={{background: "aliceblue"}}>
                        {
                            loading ? (
                                <div className="card-body text-center mt-1">
                                 <p className='mb-1'><strong>Processing...</strong></p>
                                 </div>
                            ) :
                            orderDetails?.delivery_time ? (
                                <div>
                                     <img
                                        src="/assets/img/delivery.gif"
                                        alt="no order found"
                                        className="img-fluid d-block w-50 m-auto mt-4"
                                    />
                                    <div className="card-body text-center mt-1">
                                        <p className='mb-1'><strong>The Order has been delivered!</strong></p>
                                    </div>
                                </div>
                            ) : (
                                orderDetails && orderDetails?.id ? (
                                    <div>
                                        {/* <img
                                            src="/assets/img/on-way.gif"
                                            alt="no order found"
                                            className="img-fluid d-block w-25 m-auto mt-3"
                                        /> */}
                                        <div className="card-body">
                                            <p><strong>Order No: </strong> {orderDetails.id} </p>
                                            <p><strong>Customer Name: </strong> {orderDetails.customer_name} </p>
                                            <p><strong>Restaurant Name: </strong> {orderDetails.branch_name} </p>
                                            <p><strong>Address:</strong> {orderDetails.delivery_address} </p>
                                            <p><strong>Delivery Phone:</strong> {orderDetails.delivery_phn_no} </p>
                                            {/* <p><strong>Alternate Phone:</strong> {orderDetails.delivery_phn_no} </p> */}
                                            <p><strong>Note by Customer:</strong> {orderDetails.note_to_rider || ""} </p>
                                            <p><strong>Order Placing Time:</strong> <Moment format="DD-MMM-YYYY hh:mm A">{orderDetails.created_at}</Moment> </p>
                                            <p><strong>Amount to collect:</strong> {orderDetails.total_payable} </p>
                                            {/* <p><strong>Receipt Link:</strong> <Link to="https://stage.indolj.io/receipt?id=GY2Egt">https://stage.indolj.io/receipt?id=GY2Egt</Link></p>  */}
                                            <div className="border-bottom border-2"></div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <button type="submit" className="btn btn-primary btn-md rounded" onClick={(e)=>handleSubmit(e)}>Delivered</button>
                                                <button className="btn btn-danger btn-md rounded" >Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body text-center mt-1">
                                        <p className='mb-1'><strong>{msg}</strong></p>
                                    </div>
                                )
                            )
                        }
    
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OrderDelivery;
