import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";

const CustomerReport = () => {
    const { t } = useTranslation();
    //getting context values here
    let { loading, setLoading, dataPaginating, generalSettings } =
      useContext(SettingsContext);
    const { partyForSearch } = useContext(UserContext);
  
    let [newCustomerReport, setNewCustomerReport] =
      useState(null);
  
    //all data
    const [reportData, setReportData] = useState(null);
  
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    //useEffect == componentDidMount()
    const componentRef = useRef();
  
    useEffect(() => {
      setSiteName(getSystemSettings(generalSettings, "siteName"));
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }, [generalSettings]);
  

    const handleParty = (party) => {
      setNewCustomerReport({
        ...newCustomerReport,
        party,
      });
    };

    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      if (
        startDate !== null &&
        endDate !== null
      ) {
        setLoading(true);
        let fromDate = moment(startDate).format("YYYY-MM-DD");
        let toDate =  moment(endDate).format("YYYY-MM-DD");
  
        const url = BASE_URL + "/settings/get-customer-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("party_id", newCustomerReport?.party ? newCustomerReport.party?.id : "");
  
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setReportData(res.data[0]);
            setLoading(false);
            setGeneratedReport(true);
            componentRef.current.handleDownload();
            setNewCustomerReport(null);
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        toast.error(
          `${_t(t("Please select from date and to date"))}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Customer Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Customer Report"
          sheet="sheet 1"
          buttonText="Sales Detail"
          ref={componentRef}
        />
  
        {/* Print Button */}
        <div className="d-none">
          <div>
            <style type="text/css" media="print">
              {"\
              @page { size: landscape; }\
            "}
            </style>
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {reportData !== null &&
                      reportData !== undefined &&
                      generatedReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                              <table
                                className="table table-bordered table-hover min-table-height mt-3"
                                id="table-to-xls"
                              >
                                <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                    <th colspan="5" style={{ border: "0px" }}>
                                      <div className="row">
                                        <div className="col-12">
                                          <h3 className="text-center mb-0">
                                            {siteName}
                                          </h3>
                                          <h3 className="text-center mt-0">
                                            Customer Report
                                          </h3>
                                          <p className="text-center">
                                            FROM&nbsp;
                                            <Moment format="LL">
                                              {startDate}
                                            </Moment>
                                            &nbsp;TO&nbsp;
                                            <Moment format="LL">{endDate}</Moment>
                                          </p>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Party"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Customer Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Phone Number"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Email"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Address"))}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="align-middle">
                                  {/* loop here*/}
                                  {Object.keys(reportData).map(
                                    (group_name, index) => {
                                      return (
                                        <div key={index}>
                                          <tr>
                                            <th
                                              scope="row"
                                              className="lg-text text-capitalize align-middle text-left"
                                            >
                                              {group_name}
                                            </th>
                                          </tr>
                                          {reportData[group_name].map(
                                            (item, i) => {
                                              return (
                                                <tr key={i}>
                                                 <td></td>
                                                  <td
                                                    scope="row"
                                                  >
                                                    {item.customer_name}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                  >
                                                    {item.customer_phone ? item.customer_phone : "-"}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                  >
                                                    {item.email ? item.email : "-"}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                  >
                                                    {item.address ? item.address : "-"}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </tbody>
                                <tfoot style={{ border: "0px" }}>
                                  <tr style={{ border: "0px" }}>
                                    <td
                                      colspan="7"
                                      className="text-center"
                                      style={{ border: "0px" }}
                                    >
                                      <h5 className="mt-3">
                                        Powered by indolj.pk
                                      </h5>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  
                      {/* spin loading ends */}
  
                      {/* Loading effect  {loading === true ? (
                        tableLoading()
                      ) : ( */}
                     
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-12 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Customer Report"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="row gx-2 mt-2">
                            <div className="col-2">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={true}
                              />
                            </div>
                            <div className="col-2">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={true}
                              />
                            </div>
                            <div className="col-2">
                                <Select
                                    options={partyForSearch ? partyForSearch : []}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={newCustomerReport?.party}
                                    isClearable
                                    classNamePrefix="select"
                                    className="table-text"
                                    onChange={handleParty}                                
                                    maxMenuHeight="200px"
                                    placeholder={_t(t("Select a party")) + ".."}
                                />
                            </div>
                            <div className="col-4"></div>
                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              {/* Action Button */}
                              <button
                                className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                                onClick={(e) => getBranchWiseSelected(e)}
                              >
                                Print Customer Report
                              </button>
                            </div>
                          </div>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
  };

export default CustomerReport
