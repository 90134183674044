import React, { useState } from 'react'
import { _t } from "../../../../functions/Functions";

const SettledAddReason = ({
  passcodeApplied,
  correctPasscode,
  setShow,
  reason,
  setReason,
  onSuccess,
}) => {
  const [passcodeEntered, setPasscodeEntered] = useState('')
  const [passcodeError, setPasscodeError] = useState(false)

  const checkPasscode = () => {
    if (!passcodeApplied) return true
    if (passcodeEntered) {
      if (passcodeEntered === correctPasscode) {
        setPasscodeError('')
        return true
      } else {
        setPasscodeError('Incorrect password. Try again.')
        setPasscodeEntered('')
        return false
      }
    }
    else {
      setPasscodeError('Please enter password to submit.')
      return false
    }
  }

  const onSubmit = () => {
    if (!reason) {
      setPasscodeError('Please enter reason first.')
      return
    }
    if (!checkPasscode()) return
    setShow(false)
    onSuccess()
  }

  return (
    <div className="modal fade showing" id="addReson" aria-hidden="false">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShow(false)}
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form autoComplete='off' onSubmit={(e) => {
                  e.preventDefault()
                  onSubmit();
                }}>
                  <div>
                    <label htmlFor="name" className="form-label">
                      {_t(("Reason"))}{" "}
                    </label>
                    <textarea
                      style={{ height: "120px" }}
                      type="text"
                      className="form-control"
                      id="reason"
                      name="reason"
                      placeholder="e.g. It tasted bad"
                      value={reason}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                      required
                    ></textarea>
                  </div>

                  {passcodeApplied ?
                    <div className="mt-4">
                      <label htmlFor="passcode" className="form-label">
                        Password
                      </label>
                      <input
                        type="text"
                        name="passcode"
                        className="form-control secure-password-field"
                        id="passcode"
                        placeholder="Password"
                        value={passcodeEntered}
                        onChange={(e) => setPasscodeEntered(e.target.value)}
                      />
                      {passcodeError ?
                        <p className="text-danger">{passcodeError}</p>
                        : null
                      }
                    </div>
                    : null
                  }

                  <div className="mt-4">
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-block btn-success w-100 xsm-text text-uppercase t-width-max"
                          onClick={onSubmit}
                        >
                          {_t(("Submit"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default SettledAddReason