import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartThemeColorsDonut } from ".";

const parseData = (data) => {
  let newData = Array.isArray(data)
    ? data.map((item) => {
        if (item.name) return item;
        else
          return {
            name: item.option_name,
            value: item.option_value,
          };
      })
    : data.donut;
  return newData.filter((data) => data.value > 0);
};

const tooltipFunc = ({ series, seriesIndex, dataPointIndex, w }) => {
  let total = series && series.reduce((acc, item) => acc + parseFloat(item), 0)
  let selected = series && series[seriesIndex];
  return (
    `<div style="color:white;background-color:${chartThemeColorsDonut[seriesIndex]};padding-inline: 0.2rem;" >` +
    w.config.labels[seriesIndex] +
    ":" +
    "<br />" +
    `<span className='ml-2 donut-text'>${selected}</span>` +
    "<br />" +
    `<span className='ml-2 donut-text'>${
      ((selected / total) * 100).toFixed(2) + "%"
    }</span>` +
    "</div>"
  );
};

const DonutWidget = ({ data, heading, size, handleReq = null, url=null, loading=false }) => {
  const [formattedData, setFormattedData] = useState(null);

  useEffect(() => {
    if(data){
      const newData = parseData(data);
      setFormattedData(newData);
    }else{
      setFormattedData(null);
    }
  }, [data]);
 
  return (
    <div className={`col ${size} row mx-0 mt-3 px-2 position-relative`}>
      <div className= "row mx-0 justify-content-between col col-12 px-0 ">
       <div className="dashboard__card col col-12 w-100 flex-basis-100 d-flex flex-column justify-content-between">
        <div>{heading ? <h5 className="mb-1 mt-0">{heading}</h5> : ""}</div>
          <div>
           {
            formattedData ? (
                <Chart
                  width="100%"
                  height="270px"
                  type="donut"
                  series={formattedData.map((x) => parseFloat(parseFloat(x.value)?.toFixed(2)))}
                  options={{
                    dataLabels: {
                      enabled: false,
                    },
                    
                    tooltip: {
                      custom: (p) => tooltipFunc(p),
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: "80%",
                          labels: {
                            show: true,
                            offsetY: 5,
                            total: {
                              fontWeight : 600,
                              fontSize: "1.15rem",
                              show: true,
                              formatter: (w) => (heading?.match(/insights/i) ? ((w.globals.seriesTotals.reduce((a, b) => a + b, 0))) : ((w.globals.seriesTotals.reduce((a, b) => a + b, 0).toFixed(2)))),
                            },
                            value:{
                              fontWeight : 600,
                              fontSize: "1.1rem",
                              offsetY: 2,
                            },
                          },
                        },
                      },
                    },
                    chart: {
                      type: "donut",
                      width: "200px",
                      height: "220px",
                    },
                    colors: chartThemeColorsDonut,
                    labels: formattedData.map((x) => x.name),
                    legend: {
                      position: "bottom",
                      fontSize:"11px",
                    },
                  }}
                />
              ): (
                <div className="btn_load">
                  {
                    loading ? (
                      <>
                        {/*loading */}
                        <div className={`${(loading) && "loadingBlur"}`}></div>
                        <div className={`${(loading) && "lds-roller"}`} style={{top:"38%",left:"45%"}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      </>
                    ) : (
                      <button className="btn btn-sm btn-primary rounded-md" onClick={()=>url && handleReq(url)}>Load Data</button>
                    )
                  }
                </div>
              )
           }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonutWidget;
