import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
    _t,
  } from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { UserContext } from '../../../../contexts/User';

const RestaurantModal = ({
    kdsList,
    kdsModal,
    kitchenModal
}) => {
    const { t } = useTranslation();
    const { authUserInfo } = useContext(UserContext);

    let isStatus = kitchenModal.kitchen?.status==1 || kitchenModal.takeaway?.status==1 || kitchenModal.dinein?.status==1;
    let isPermission = authUserInfo.permissions !== null && (authUserInfo.permissions.includes("Show Kitchen") || authUserInfo.permissions.includes("Show Takeaway") || authUserInfo.permissions.includes("Show Dine In"))

  return (
    <>

    {/* kds Modal */}
    <div
    className="modal fade"
    ref={kdsModal}
    id="kdsModal"
    aria-hidden="true"
    >
    <div className="modal-dialog modal-md">
        <div className="modal-content" style={{ borderRadius: "1rem" }}>
        <div className="modal-body py-3">
            {/* show form or show saving loading */}
            <div key="fragment-food-group-1">
            <form autoComplete="off">
                <div>
                <div className="addons-list__item mt-1 mx-1 row">
                <label
                    htmlFor="name"
                    className="form-label d-flex justify-content-between font-weight-md xslg-text mb-3"
                >
                    <span className="mt-2">{kdsList !== null && kdsList?.length > 0 && (isStatus) && (isPermission) ? _t(t("Kitchen Display Screen")) : _t(t("KDS Setting Empty / You don't have rights"))} </span>
                    <span
                    onClick={(e) => {
                        kdsModal.current.classList.remove("showing");
                    }}
                    >
                    <i className="fa fa-times-circle close-1 pointer-cursor"></i>
                    </span>
                </label>
                    {authUserInfo.permissions !== null && authUserInfo.permissions.includes("Show Kitchen") && 
                kitchenModal.kitchen?.status==1 && <NavLink to="/dashboard/kitchen" className="col-4 t-link" style={{color:" #121053"}}>
                    <img
                    src="/assets/img/kds.png"
                    alt={_t(t("kds"))}
                    className="img-fluid"
                    />
                    <div className="text-center font-weight-md mt-2">Kitchen</div>
                    </NavLink>}
                    {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Show Takeaway") && kitchenModal.takeaway?.status==1 && <NavLink to="/dashboard/kitchen/takeaway" className="col-4 t-link" style={{color:" #121053"}}>
                    <img
                        src="/assets/img/kds1.png"
                        alt={_t(t("kds"))}
                        className="img-fluid"
                    />
                    <div className="text-center font-weight-md mt-2">Takeaway Delivery</div>
                    </NavLink>}
                    {authUserInfo.permissions !== null && 
                    authUserInfo.permissions.includes("Show Dine In") && kitchenModal.dinein?.status==1 && <NavLink to="/dashboard/kitchen/dinein" className="col-4 t-link" style={{color:" #121053"}}>
                    <img
                    src="/assets/img/kds2.png"
                    alt={_t(t("kds"))}
                    className="img-fluid"
                    />
                    <div className="text-center font-weight-md mt-2">Dine-In</div>
                    </NavLink>}
                </div>
                </div>

            </form>
            </div>
        </div>
        </div>
    </div>
    </div>

    </>
  )
}

export default RestaurantModal
