import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";

const GiftHampersAction = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {id} = useParams();
    //getting context values here
    let {
      loading,
      setLoading,
      branchForSearch,
      ingredientItemForSearch
    } = useContext(RestaurantContext);

    let { foodForPOS } = useContext(FoodContext)

    // States hook here
    //new item
    let [giftHampers, setGiftHampers] = useState({
      document_no: "",
      branch: null,      
      remarks: "",
      item: null,
      qty: 1,
      item_amount: 0,
      edit: false,
    });
    //new Row
    const [newRow,setNewRow]=useState([]);

    let common_toast = {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }

   //edit
   const handleEdit = async() =>{
    const url = BASE_URL + "/settings/get-gift_hamper-edit/" + id;
      
    try {
      const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${getCookie()}` },
      });   
      const date = new Date(res.data.giftHamper.document_date)
      setDocumentDate(date)
      setGiftHampers({
        document_no: res.data.giftHamper.document_identity,
        branch: res.data.giftHamper.branch,      
        remarks: res.data.giftHamper.remarks,
        item: res.data.giftHamper.item,
        qty: res.data.giftHamper.food_qty,
        item_amount: res.data.giftHamper,
        edit:true,
      });     
      const newRes = [];
      res.data.giftHamper.gift_hamper_detail && res.data.giftHamper.gift_hamper_detail.forEach(item =>{
        const {qty, item_type, cost, ...rest}  = item;
        let itm = {
          item_name:{
            ...rest
          },
          cost: parseFloat(cost),
          qty: qty,
          item_type: item_type,
       }
       newRes.push(itm)
      })
      setNewRow(newRes);
      setLoading(false);

    } catch (error) {              
      setLoading(false);
      toast.error(`${_t(t("Please try again later"))}`, common_toast);
    }
   }

    useEffect(()=>{
      if(id){
        handleEdit(id)
      }
    },[id]);
  
    //datepicker
    const [documentDate, setDocumentDate] = useState(new Date());

     //item cost price 
     const handleCostPrice = async (index,item) =>{
      const url = BASE_URL + "/settings/get-qty-avgrate";
      if(!giftHampers.branch?.id){
        toast.error(`${_t(t("Please select branch"))}`, common_toast);
        return false;
      }

      const formData = {
        branch_id: giftHampers.branch.id,
        item_id: item.id,
        unit_id: item.unit_id,
      }
      
      try {
          const res = await axios.post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
          });        
          // newRow[index].item_name = item;
          const { id , name, unit, item_code, unit_id, price } = item;
          newRow[index].item_name = {
              item_id: id,
              item_name: name,
              unit_name: unit,
              unit_id: unit_id, 
              item_code: item_code,
              price: price,
          };
          newRow[index].cost = res.data.avg_rate ? parseFloat((res.data.avg_rate * 1).toFixed(3)) : 0;

          setNewRow([...newRow])
          setLoading(false);
      } catch (error) {              
        setLoading(false);
        toast.error(`${_t(t("Please try again later"))}`, common_toast);
      }
        
    }

    //set item avg rate
    const handleSetItems = async (index,item) => {
      // newRow[index].item_name = item;
      // setNewRow([...newRow])
      const url = BASE_URL + "/settings/get-item-cost-gift_hamper/" + item.id;
      if(!giftHampers.branch?.id){
        toast.error(`${_t(t("Please select branch"))}`, common_toast);
        return false;
      }
      
      try {
          const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
          });        
          // newRow[index].item_name = item;
          const { id, name, unit, sku, unit_id } = item;
          newRow[index].item_name = {
              item_id: id,
              item_name: name,
              unit_name: unit,
              unit_id: unit_id, 
              item_code: sku,
          };
          newRow[index].cost = res.data.avg_rate ? parseFloat((res.data.avg_rate * 1).toFixed(3)) : 0;

          setNewRow([...newRow])
          setLoading(false);

      } catch (error) {              
        setLoading(false);
        toast.error(`${_t(t("Please try again later"))}`, common_toast);
      }
    };
  
    //total qty
    const totalQty = () =>{
      const qty = newRow && newRow.reduce((acc,item) => {
        return acc + ((item.qty) ? parseInt(item.qty) : 0)
      },0);
      return qty;
    }

    //total amount
    const totalAmount = () =>{
      const total = newRow && newRow.reduce((acc,item) => {
        return acc + ((item.qty && giftHampers.qty) ? (parseInt(item.qty) * item.cost * Number(giftHampers.qty)) : 0)
      },0);
      return total.toFixed(2);
    }
  
    //total rate
    const totalRate = () =>{
      let total = totalAmount();
      return giftHampers.qty ? (Number(total)/parseFloat(giftHampers.qty)).toFixed(2) : total;
    }
  
    const totalItemQty = () =>{
      const qty = newRow && newRow.reduce((acc,item)=>{
        return acc + (item.qty && giftHampers.qty ? (parseInt(item.qty) * Number(giftHampers.qty)) : 0);
      },0);
      return qty;
    }

     const addNewRow=()=>{
      if(giftHampers.branch !== null && giftHampers.item !== null){
        setNewRow((pre)=>[...pre,{
          item_name:"",
          qty:1,
          cost:0,
          item_type:"food",
        }])
      }else{
        toast.error(`${_t(t("Please select branch and Item"))}`, common_toast);
      }   
    }

    const delRow=(id)=>{
      let row=[...newRow];
      if(id > -1){
        row.splice(id, 1);
      }
      setNewRow(row);
    }
  
    //set each item qty
    const handleItemQty = (index,e) => {
      newRow[index].qty = e.target.value;
      setNewRow([...newRow]);
    };

    //select food
    const handleSetItem = (item) => {
      setGiftHampers({ ...giftHampers, item });
    };

    //change type
    const TypeChangeHandler = (index,e) =>{
      newRow[index] = {
        item_name: "",
        qty: 1,
        cost: 0,
        item_type: e.target.value,
      }
      setNewRow([...newRow]);
    }

    //handle Set branch hook
    const handleSetBranch = (branch) => {
        setGiftHampers({ ...giftHampers, branch });
        setNewRow([]);
    };

    const handleChange=(e)=>{
        setGiftHampers({ ...giftHampers, [e.target.name]:e.target.value });
    }
    //post req edit item
    const FgGiftAxios = async () => {
      let date =
        new Date(documentDate).getFullYear() +
        "-" +
        (new Date(documentDate).getMonth() + 1) +
        "-" +
        new Date(documentDate).getDate();
  
      let slugArray=[];
      let isDuplicate=false;
      newRow.map((gift,index) => {
        if(gift.item_name === ""){
          slugArray.push();
        }
        else{
          isDuplicate = slugArray.some((item) => item.item_id === gift.item_name.item_id);
            const totalQty = parseInt(gift.qty) * Number(giftHampers.qty);
            slugArray.push({
              qty: parseInt(gift.qty),
              item_type : gift.item_type,
              item_id: gift.item_name.item_id,
              item_name: gift.item_name.item_name,
              unit_name:  gift.item_name.unit_name || "Pcs",
              unit_id: gift.item_name.unit_id || 1, 
              item_code: gift.item_name.item_code ? gift.item_name.item_code :"",
              cost: gift.cost.toFixed(3),
              cost_amount: (gift.cost * totalQty).toFixed(2),
              total_qty: (totalQty),
            });          
         }
        });
        
        if(newRow.length === slugArray.length){          
          if(isDuplicate){
            toast.error(`${_t(t("Can't select same item"))}`, common_toast);
          }else{
            setLoading(true);
          
          let formData = {
            ...((id && {id:id})),
            branch_id: giftHampers.branch.id,
            food_id: giftHampers.item.id, 
            food_qty: giftHampers.qty ? parseInt(giftHampers.qty) : 0,
            document_date: date,
            remarks: giftHampers.remarks,
            total_item_qty: totalQty(),
            total_consume_qty: totalItemQty(),
            item: JSON.stringify(slugArray),
            rate: totalRate(),
            amount: totalAmount(),
          };
        const endpoint =  giftHampers.edit && id ? "/settings/gift_hamper-update" : "/settings/gift_hamper-store";
        const url = BASE_URL + endpoint;
        
        try {
            const res = await axios.post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
            });        
            if(id){
              toast.success(`${_t(t("Gift Hampers has been updated"))}`,common_toast);
            }else{
              toast.success(`${_t(t("Gift Hampers has been added"))}`,common_toast);
            }
            history.push("/dashboard/manage/stock/gift-hampers")
            } catch (error) {              
              setLoading(false);
              toast.error(`${_t(t("Please try again later"))}`, common_toast);
            }
          }
          }else{
            toast.error(`${_t(t("Please select all dropdowns"))}`, common_toast);
          }
    };

      //send to server
      const handleSubmit = (e) => {
      e.preventDefault();
      if (
        giftHampers.branch !== null &&
        giftHampers.document_no !== null &&
        giftHampers.item !== null &&
        documentDate !== null
      ) {
        if(newRow.length > 0 && !isNaN(totalRate()) && !isNaN(totalAmount())){
            FgGiftAxios();
        }
        else{
          toast.error(`${_t(t("Please add gift hampers / rate or amount issue"))}`, common_toast);
        }
      } else {
        toast.error(`${_t(t("Please fill all the required fields"))}`,common_toast);
      }
    };
    
    return (
      <>
        <Helmet>
          <title>{_t(t("Gift Hampers"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
  
                      {/* Loading effect */}
                      {loading === true ? (
                        tableLoading()
                      ) : (
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {giftHampers.edit ? _t(t("Update Gift Hampers")) : _t(t("Add Gift Hampers"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"
                            onSubmit={(e) => handleSubmit(e)}
                            autoComplete="off"
                          >
                            <div className="col-12">
                            <div className="row">
                                <div className="form-group col-4 mt-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="document no"
                                      className="control-label"
                                    >
                                      {_t(t("Document no"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control table-text"
                                      id="document_no"
                                      name="document_no"                                      
                                      placeholder="Auto no"
                                      required
                                    />
                                  </div>
                                </div>
  
                                <div className="form-group col-4 text-right mt-3">
                                  <div className="mb-2 text-left">
                                    <label
                                      htmlFor="transfer_date"
                                      className="control-label"
                                    >
                                      {_t(t("Document Date"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <DatePicker
                                      selected={documentDate}
                                      onChange={(date) => setDocumentDate(date)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control table-text"
                                      placeholderText={_t(t("Document date"))}
                                      shouldCloseOnSelect={false}
                                      required
                                    />
                                  </div>
                                </div>
                                
                                <div className="form-group mt-3 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchForSearch ? branchForSearch : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      value={giftHampers.branch || null}
                                      classNamePrefix="select"
                                      isClearable
                                      onChange={handleSetBranch}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                    />
                                </div> 
                              </div>
  
                              <div className="row">
                               <div className="form-group mt-2 col-4">
                                <div className="mb-2">
                                  <label
                                    htmlFor="item"
                                    className="control-label"
                                  >
                                    {_t(t("Select Item"))}
                                    <span className="text-danger">*</span> 
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <Select
                                  options={foodForPOS ? foodForPOS : []}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  value={giftHampers.item || null}
                                  classNamePrefix="select"
                                  isClearable
                                  onChange={handleSetItem}
                                  maxMenuHeight="200px"
                                  styles={customStyle}
                                  placeholder={
                                    _t(t("Please select a item")) + ".."
                                  }
                                  />
                                </div>
                                </div> 
                                <div className="form-group mt-2 col-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="item_qty"
                                    className="control-label"
                                  >
                                    {_t(t("Qty"))}
                                    <span className="text-danger">*</span> 
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                   type="number"
                                   min="1"
                                   onKeyDown={(evt) =>
                                      ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    onWheel={(e) => e.target.blur()}
                                    className="form-control"
                                    id="item_qty"
                                    name="qty"
                                    onChange={handleChange}
                                    value={giftHampers.qty || ""}
                                    placeholder="Item Qty"
                                    required          
                                  />
                                </div>
                                </div>  
                                <div className="form-group mt-2 col-3">
                                <div className="mb-2">
                                  <label
                                    htmlFor="rate"
                                    className="control-label"
                                  >
                                    {_t(t("Rate Per Unit"))}
                                    <span className="text-danger">*</span> 
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                   type="number"
                                    className="form-control"
                                    id="rate"
                                    name="rate"
                                    value={totalRate()}
                                    placeholder="Rate"
                                    readOnly          
                                  />
                                </div>
                                </div>  
                                <div className="form-group mt-2 col-3">
                                <div className="mb-2">
                                  <label
                                    htmlFor="amount"
                                    className="control-label"
                                  >
                                    {_t(t("Amount"))}
                                    <span className="text-danger">*</span> 
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                   type="number"
                                    className="form-control"
                                    id="amount"
                                    name="amount"
                                    value={totalAmount()}
                                    placeholder="Amount"
                                    readOnly          
                                  />
                                </div>
                                </div>                                                         
                               </div>

                               <div className="row">
                                <div className="form-group mt-2 col-12">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="remarks"
                                      className="control-label"
                                    >
                                      {_t(t("Remarks"))}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <textarea
                                      type="text"
                                      className="form-control table-text"
                                      id="remarks"
                                      name="remarks"
                                      onChange={handleChange}
                                      value={giftHampers.remarks || ""}
                                      placeholder="Remarks"
                                      rows="1"
                                    />
                                  </div>
                                </div>
                               </div>                            
                                
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>
                                      {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"20rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Type"))}
                                          </span>
                                        </th>  
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"32rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item Name"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"18rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"18rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Cost"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"18rem"}}
                                        >
                                            <span style={{fontWeight:500}}>
                                            {_t(t("Total Qty"))}
                                            </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"18rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Amount"))}
                                          </span>
                                        </th>
                                        

                                      </tr>
                                    </thead>
                                  {newRow && newRow.length > 0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (gifts, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                    <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}&nbsp;
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                                onClick={()=>{delRow(index)}}
                                                ></i>
                                              </td>

                                              <td className="table-text text-capitalize d-flex align-items-center justify-content-center" style={{height:"4rem"}}>
                                                <div className="form-check mx-2 d-flex align-items-center">
                                                  <input className="form-check-input" type="radio" name={`type_${index}`}  value="food" id={`food_${index}`} checked={gifts.item_type === "food"} onChange={(e)=>TypeChangeHandler(index,e)} style={{width: "1rem", height:"1rem"}}/> 
                                                  <label className="form-check-label mx-1" htmlFor={`food_${index}`}> Food </label>
                                                </div>
                                                <div className="form-check mx-2 d-flex align-items-center">
                                                  <input className="form-check-input" type="radio" name={`type_${index}`} value="raw" id={`raw_${index}`} checked={gifts.item_type === "raw"} onChange={(e)=>TypeChangeHandler(index,e)} style={{width: "1rem", height:"1rem"}}/> 
                                                  <label className="form-check-label mx-1" htmlFor={`raw_${index}`}> Raw </label>
                                                </div>
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <Select
                                                  menuPosition="fixed"
                                                  options={gifts.item_type === "raw" ? (ingredientItemForSearch ? ingredientItemForSearch : []) : (foodForPOS ? foodForPOS : [])}                                                  
                                                  getOptionLabel={(option) => option.item_name || option.name }
                                                  getOptionValue={(option) => option.item_id || option.id }
                                                  onChange={(e)=> gifts.item_type === "raw" ? handleCostPrice(index,e) : handleSetItems(index,e)}
                                                  value={gifts.item_name}                                                  
                                                  styles={customStyle}
                                                  placeholder={
                                                  _t(t("Please select item")) + ".."
                                                  }                                                
                                                />
                                              </td>                                              

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="1"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={gifts.item_name.item_id}
                                                  name={gifts.item_name.item_id}
                                                  onChange={(e)=>handleItemQty(index,e)}
                                                  value={gifts.qty || ""}
                                                  placeholder="Qty"
                                                  required                                                  
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                    type="number"
                                                    step="0.1"
                                                    min="0"
                                                    className="form-control text-center table-Input table-text"
                                                    id={gifts.item_name.item_id}
                                                    name={gifts.item_name.item_id}
                                                    value={gifts.cost?.toFixed(3) || 0}
                                                    readOnly                                                  
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                    type="number"
                                                    step="0.1"
                                                    min="0"
                                                    className="form-control text-center table-Input table-text"
                                                    id={gifts.item_name.item_id}
                                                    name={gifts.item_name.item_id}
                                                    value={gifts.qty && giftHampers.qty ? (parseInt(gifts.qty) * Number(giftHampers.qty)) : 0}
                                                    readOnly                                                  
                                                />
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                    type="number"
                                                    step="0.1"
                                                    min="0"
                                                    className="form-control text-center table-Input table-text"
                                                    id={gifts.item_name.item_id}
                                                    name={gifts.item_name.item_id}
                                                    value={gifts.qty && giftHampers.qty ? (parseInt(gifts.qty) * gifts.cost * Number(giftHampers.qty)).toFixed(2) : 0}
                                                    readOnly                                                  
                                                />
                                              </td>
                                              
                                            </tr>
                                          );
                                        }
                                      )}
                                      <tr>
                                       <th></th>
                                       <th></th>
                                       <th className="text-capitalize align-middle text-center">Total</th>
                                       <th className="text-capitalize align-middle text-center">{totalQty()}</th>
                                       <th></th>
                                       <th className="text-capitalize align-middle text-center">{totalItemQty()}</th>
                                       <th className="text-capitalize align-middle text-center">{totalAmount()}</th>
                                      </tr>
                                    </tbody>
                                    :null}
                                  </table>
                                </div>                                
  
                              <div className="form-group mt-4 pb-2">                                
                                <div className="d-flex justify-content-end align-items-center">
                                  <div className="col-3 d-flex justify-content-end">
                                      <button
                                      type="button"
                                      className="btn rounded hov-btn mr-3"
                                      onClick={()=>(history.push("/dashboard/manage/stock/gift-hampers"))}                                
                                      >
                                          <i className="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                      </button>
                                      <button
                                      disabled={loading}
                                      type="submit"
                                      className="btn btn-primary rounded"
                                      >
                                          <i className="fa fa-floppy-o" aria-hidden="true"></i> {giftHampers.edit ? _t(t("Update")) : _t(t("Save"))}
                                      </button>                                                    
                                  </div>                                                    
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default GiftHampersAction;