import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import moment from 'moment';

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";


const ConsolitedReport = () => {
    const { t } = useTranslation();
    //getting context values here
    let { loading, setLoading, dataPaginating, generalSettings } =
      useContext(SettingsContext);
    let { branchForSearch } =
      useContext(RestaurantContext);
    const {  authUserInfo } = useContext(UserContext);
  
    let [newConsolidateReport, setnewConsolidateReport] = useState(null);
  
    //all data
    const [reportData, setReportData] = useState(null);
    const [report,setReport] = useState(null)
    const componentRef = useRef();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");
  
    //useEffect == componentDidMount()
    const [branchSearch, setBranchSearch] = useState(null);
  
    useEffect(() => {
      setSiteName(getSystemSettings(generalSettings, "siteName"));
      if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
        setBranchSearch(
          branchForSearch.filter(
            (branch) => branch.id === authUserInfo.details.branch_id
          )
        );
      } else {
        setBranchSearch(branchForSearch);
      }
  
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }, [generalSettings]);
  
    const handleBranch = (branch) => {
      setnewConsolidateReport({ ...newConsolidateReport, branch });
      // setBranch(branch);
    };

    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      setReport(null)
      if (
        (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") &&
        newConsolidateReport?.branch?.id === undefined
      ) {
        toast.error(`${_t(t("Please select Branch to generate report"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        return;
      }
  
      if (
        // newConsolidateReport?.branch?.id !== null &&
        startDate !== null &&
        endDate !== null
      ) {
        setLoading(true);
        var fromDate = moment(startDate).format("YYYY-MM-DD");
        var toDate =  moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-consolidate-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", newConsolidateReport?.branch?.id);
  
        console.log("formData ", formData);
  
        return axios
          .post(url, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            console.log("SalesSummaryReportResult", res);
            setReportData(res.data[0]);
            setReport(res.data[1]);
            setLoading(false);
            setGeneratedReport(true);
            componentRef.current.handleDownload();
            setnewConsolidateReport(null);
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date, to date, branch and report group to generate report"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    let no_of_days = endDate && startMoment && endMoment && endMoment?.diff(startMoment, 'days')

  return (
    <>
    <Helmet>
      <title>{_t(t("Consolidate Report"))}</title>
    </Helmet>
    <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-xls"
      filename="Consolidate Report"
      sheet="sheet 1"
      buttonText="Consolidate Detail"
      ref={componentRef}
    />

    {/* Print Button */}
    <div className="d-none">
      <div>
        <style type="text/css" media="print">
          {
            "\
        @page { size: landscape; }\
      "
          }
        </style>
        <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {reportData !== null &&
                  reportData !== undefined &&
                  generatedReport === true && (
                    <>
                      <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        {console.log("reportData", reportData)}
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                          <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="table-to-xls"
                          >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                              <tr style={{ border: "0px" }}>
                                <th colspan="12" style={{ border: "0px" }}>
                                  <div className="row">
                                    <div className="col-13">
                                      <h3 className="text-center mb-0">
                                        {siteName}
                                      </h3>
                                      <h3 className="text-center mt-0">
                                        Consolidate Report
                                      </h3>
                                      <p className="text-center">
                                        FROM&nbsp;
                                        <Moment format="LL">
                                          {startDate}
                                        </Moment>
                                        &nbsp;TO&nbsp;
                                        <Moment format="LL">{endDate}</Moment>
                                      </p>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"   
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                ></th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>
                                <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                    {_t(t("Branch"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Date"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Sales"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Trans"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("GCA"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Month Total"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Month Total Trans"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Till Date GCA"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Month Avg"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Projected"))}
                                </th>
                                <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Payments"))}
                              </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {console.log("309 ", reportData)}
                              {/* loop here*/}
                              {Object.keys(reportData).map(
                                (group_name, index) => {
                                    let net_month = 0;
                                    let net_month_trans = 0;
                                    let net__month_gca = 0
                                    let net_mont_avg = 0;
                                    let net_day = 0;
                                    let net_day_trans = 0;
                                    let net_day_gca = 0
                                    let net_projected = 0;
                                  return (
                                    <div key={index}>
                                      <tr>
                                        <th
                                          scope="row"
                                          className="lg-text text-capitalize align-middle text-center"
                                        >
                                          {group_name}
                                        </th>
                                      </tr>
                                      {reportData[group_name].map(
                                        (item, i) => {
                                            let paymentType = "";
                                            let abc = Object.keys(item?.payments)?.map((payment)=>{
                                              paymentType += `${payment} : ${formatPrice(item.payments[payment])},  `
                                            });
                                            let day_report = report[item?.branch_name] ? report[item.branch_name][0] : "";
                                            let month_avg = (parseFloat(item.gross_sales) / no_of_days);
                                            let month_gca = item.no_of_orders == 0  ? 0 : (parseFloat(item.gross_sales) / parseFloat(item.no_of_orders));
                                            let projected = (parseFloat(item.gross_sales) + parseFloat(month_avg));
                                            let day_gca = (day_report?.no_of_orders == 0 || !day_report?.no_of_orders) ? 0 : (parseFloat(day_report?.gross_sales) / parseFloat(day_report?.no_of_orders));
                                             net_month += parseFloat(item.gross_sales);
                                             net_month_trans += parseFloat(item.no_of_orders);
                                             net__month_gca += parseFloat(month_gca);
                                             net_mont_avg += parseFloat(month_avg);
                                             net_day += day_report?.gross_sales ? parseFloat(day_report?.gross_sales) : 0;
                                             net_day_trans += day_report?.no_of_orders ? parseFloat(day_report?.no_of_orders) : 0;
                                             net_day_gca += day_gca ? parseFloat(day_gca) : 0;
                                             net_projected += parseFloat(projected);
                                          return (
                                            <tr>
                                              <td></td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center "
                                              >
                                                {i + 1}
                                              </td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                    {item.branch_name}
                                                </td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                <Moment format="LL">
                                                  {day_report?.order_date}
                                                </Moment>
                                              </td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {day_report?.gross_sales ? formatPrice(parseFloat(day_report?.gross_sales)) : 0}
                                              </td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {day_report?.no_of_orders ? formatPrice(parseFloat(day_report?.no_of_orders)) : 0}
                                              </td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {formatPrice(parseFloat(day_gca))}
                                              </td>
                                              
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {formatPrice(parseFloat(item?.gross_sales))}
                                              </td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {formatPrice(parseFloat(item?.no_of_orders))}
                                              </td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {formatPrice(month_gca)}
                                              </td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {formatPrice(month_avg)}
                                              </td>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {formatPrice(projected)}
                                              </td>
                                              <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                                {paymentType}
                                              </td>
                                            
                                            </tr>
                                          );
                                        }
                                      )}
                                      <tr>
                                        <th colspan="3"></th>
                                        <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                          Total
                                        </th>
                                        <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                          {formatPrice(net_day)}
                                        </td>
                                        <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                        {formatPrice(net_day_trans)}
                                       </td>
                                        <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                        {formatPrice(net_day_gca)}
                                        </td>
                                        <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                        {formatPrice(net_month)}
                                        </td>
                                        <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                        {formatPrice(net_month_trans)}
                                        </td>
                                        <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                        {formatPrice(net__month_gca)}
                                       </td>
                                       <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                       {formatPrice(net_mont_avg)}
                                      </td>
                                      <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                      {formatPrice(net_projected)}
                                      </td>
                                        
                                      </tr>
                                    </div>
                                  );
                                }
                              )}
                              {/*
                                <tr>
                                  <th colspan="13"></th>
                                  <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                    Grand Total
                                  </th>
                                  <td className="xsm-text align-middle text-center">
                                    {formatPrice(total_price)}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="xsm-text align-middle text-center">
                                    {formatPrice(grand_total)}
                                  </td>
                                </tr>
                            */}
                            </tbody>
                            <tfoot style={{ border: "0px" }}>
                              <tr style={{ border: "0px" }}>
                                <td
                                  colspan="7"
                                  className="text-center"
                                  style={{ border: "0px" }}
                                >
                                  <h5 className="mt-3">
                                    Powered by indolj.pk
                                  </h5>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ReportSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {loading === true ? (
                    tableLoading()
                  ) : (
                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-12 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Consolidate Report"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row gx-2 mt-2">
                        <div className="col-md-2 d-md-block">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control sm-text py-2 t-mb-15 mb-md-0"
                            placeholderText={_t(t("From date"))}
                            shouldCloseOnSelect={true}
                          />
                        </div>
                        <div className="col-md-2 t-mb-15 mb-md-0">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control sm-text py-2"
                            placeholderText={_t(t("To date"))}
                            shouldCloseOnSelect={true}
                          />
                        </div>
                        <div className="col-md-4 t-mb-15 mb-md-0">
                          <Select
                            options={branchSearch && branchSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            //value={branch}
                            classNamePrefix="select"
                            className="sm-text "
                            onChange={handleBranch}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select a brand")) + ".."}
                          />
                        </div>
                          {/* Action Button */}
                          <div className=" col-4 px-1 gx-2 justify-content-end t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                                  className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                                  onClick={(e) => getBranchWiseSelected(e)}
                              >
                                  Print Consolidate Report
                              </button>
                          </div>
                      </div>
                      <>
                        
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>
  )
}

export default ConsolitedReport
