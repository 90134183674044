import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const reportType  = [{id: 1, name:"SRB"},{id: 2, name:"FBR"},{id: 3, name:"PRA"}];

const SalesSyncReport = () => {
    const { t } = useTranslation();
    //getting context values here
    let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
    const { authUserInfo } = useContext(UserContext);
    let { branchForSearch } = useContext(RestaurantContext);
  
    //all data
    const [reportData, setReportData] = useState(null);
    const componentRef = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [branch, setBranch] = useState(null);
    const [type, setType] = useState(null); 
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");

    const [branchSearch, setBranchSearch] = useState(null);
  
    useEffect(() => {
        setSiteName(getSystemSettings(generalSettings, "siteName"));
        if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
            setBranchSearch(
            branchForSearch.filter(
                (branch) => branch.id === authUserInfo.details.branch_id
            )
            );
        } else {
            setBranchSearch(branchForSearch);
        }
        
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [generalSettings]);

    const handleType = (type) =>{
      setType(type)
    }

    const handleBranch=(branch)=>{
     setBranch(branch)
    }
  
    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      setGeneratedReport(false)   
      if (        
        startDate !== null &&
        endDate !== null &&
        type
      ) {
        setDataPaginating(true);
        let fromDate = moment(startDate).format("YYYY-MM-DD");
        let toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/get-srb-fbr-pra-reports";
        const formData = new FormData();
        formData.append("from_date", fromDate);
        formData.append("to_date", toDate);
        formData.append("branch_id", branch?.id ? branch?.id : "");
        formData.append("report_type", type ? type?.name : "");
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setReportData(res.data)
            setGeneratedReport(true);
            setDataPaginating(false);
            componentRef.current.handleDownload();
          
          })
          .catch((error) => {
            setDataPaginating(false);
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date, to date and type"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };


    let netAmount = 0;
    let g_netAmount = 0;
    let tax = 0;
    let g_tax = 0;
    let gross = 0;
    let g_gross = 0;
    let discount = 0;
    let g_discount = 0;

  
  return (
    <>
    <Helmet>
      <title>{_t(t("Tax Report"))}</title>
    </Helmet>
    <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-xls"
      filename="Tax Report"
      sheet="sheet 1"
      buttonText="Gross Detail"
      ref={componentRef}
    />   
 

     {/* Excel Report*/}
     <div className="d-none">
        <div>           
        <div className="fk-print">
            <div className="container">
            <div className="row">
                <div className="col-12">
                {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                        <table
                          className="table table-bordered table-hover min-table-height mt-3"
                          id="table-to-xls"
                        >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                            <tr style={{ border: "0px" }}>
                                <th colspan="15" style={{ border: "0px" }}>
                                <div className="row">
                                    <div className="col-12">
                                    <h3 className="text-center mb-0">
                                        {siteName}
                                    </h3>
                                    <h3 className="text-center mt-0">
                                        {type && type?.name} Tax Report
                                    </h3>
                                    <p className="text-center">
                                        FROM&nbsp;
                                        <Moment format="LL">
                                        {startDate}
                                        </Moment>
                                        &nbsp;TO&nbsp;
                                        <Moment format="LL">{endDate}</Moment>
                                    </p>
                                    </div>
                                </div>
                                </th>
                            </tr>
                            <tr>
                                <th
                                scope="col"
                                >
                                  {_t(t("Branch"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("S.No"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Order No"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Table Name"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Invoice No"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Invoice Date"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Invoice Time"))}
                                </th>

                                <th
                                scope="col"
                                >
                                  {_t(t("Customer Type"))}
                                </th>
                                <th
                                scope="col"
                                >
                                    {_t(t("Payment Type"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Gross Amount"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Discount"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Tax Amount"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Service Charges"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Delivery Charges"))}
                                </th>
                                <th
                                scope="col"
                                >
                                  {_t(t("Net Amount"))}
                                </th>
                            </tr>
                            </thead>
                            <tbody className="align-middle"> 
                                <tr>
                                 {
                                    reportData && Object.keys(reportData).map((group_name)=>{
                                      gross = 0;
                                      netAmount = 0;
                                      tax = 0;
                                      discount = 0;

                                      return(
                                        <>
                                         <tr>
                                            <td>
                                              {group_name}
                                            </td>
                                         </tr>    
                                        {
                                          reportData[group_name].map((item, index) => {
                                            netAmount += parseFloat(item.total_payable);
                                            g_netAmount += parseFloat(item.total_payable);
                                            tax += parseFloat(item.vat);
                                            g_tax += parseFloat(item.vat);
                                            gross += parseFloat(item.order_bill);
                                            g_gross += parseFloat(item.order_bill);
                                            discount += parseFloat(item.discount_amount);
                                            g_discount += parseFloat(item.discount_amount);

                                            return (
                                              <tr>
                                                  <td>
                                                  </td>
                                                  <td>
                                                    {index+1}
                                                  </td>
                                                  <td>
                                                    {item.order_no}
                                                  </td>
                                                  <td>
                                                    {item.table_name}
                                                  </td>
                                                  <td>
                                                    {`'${item.invoice_no}`}
                                                  </td>
                                                  <td>
                                                    {item.order_date}
                                                  </td>
                                                  <td>
                                                    {item.invoice_time}
                                                  </td>
                                                  <td>
                                                    {item.order_type_name}
                                                  </td>
                                                  <td>
                                                      {item.payment_type}
                                                  </td>
                                                  <td>
                                                    {item.order_bill}
                                                  </td>
                                                  <td>
                                                    {item.discount_amount}
                                                  </td>
                                                  <td>
                                                    {item.vat}
                                                  </td>
                                                  <td>
                                                    {item.service_charges}
                                                  </td>
                                                  <td>
                                                    {item.delivery_charges}
                                                  </td>
                                                  <td>
                                                    {item.total_payable}
                                                  </td>
                                              </tr>
                                            )
                                          })
                                        }
                                        <tr>
                                          <th colspan="8">
                                          </th>
                                          <th>
                                            Total
                                          </th>
                                          <th>
                                            {gross}
                                          </th>
                                          <th>
                                            {discount}
                                          </th>
                                          <th>
                                            {tax}
                                          </th>
                                          <th>
                                          </th>
                                          <th>
                                          </th>
                                          <th>
                                            {netAmount}
                                          </th>
                                        </tr>
                                       </>
                                      )
                                   })
                                 }                               
                                </tr>
                                <tr>
                                  <th
                                  colspan="8"
                                  >
                                  </th>
                                  <th>
                                    Grand Total
                                  </th>
                                  <th>
                                    {g_gross}
                                  </th>
                                  <th>
                                    {g_discount}
                                  </th>
                                  <th>
                                    {g_tax}
                                  </th>
                                  <th>
                                  </th>
                                  <th>
                                  </th>
                                  <th>
                                    {g_netAmount}
                                  </th>
                                </tr>
                            </tbody>

                            <tfoot style={{ border: "0px" }}>
                            <tr style={{ border: "0px" }}>
                                <td
                                colspan="7"
                                className="text-center"
                                style={{ border: "0px" }}
                                >
                                <h5 className="mt-3">
                                    Powered by indolj.pk
                                </h5>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                        </div>
                        </div>
                    )}
                </div>
            </div>
            </div>
        </div>
        </div>
     </div>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ReportSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                  <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                 
                  {/* spin loading ends */}

                  {/* Loading effect {loading === true ? (
                    tableLoading()
                  ) : ( */}
                  
                    <div key="smtp-form">
                      <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="row t-mb-15 mb-md-0" >
                          <ul className="t-list fk-breadcrumb col-12">
                            <li className="fk-breadcrumb__list">
                              <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Tax Report"))}
                              </span>
                            </li>
                          </ul>
                          
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-2">
                          <Select
                            options={reportType ? reportType : []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={type}
                            isClearable
                            classNamePrefix="select"
                            className="table-text"
                            onChange={handleType}                                
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select type")) + ".."}
                          />
                        </div>
                        <div className="col-2 d-md-block">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control t-mb-15 mb-md-0"
                            placeholderText={_t(t("From date"))}
                            shouldCloseOnSelect={false}
                          />
                        </div>

                        <div className="col-2 d-md-block">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control t-mb-15 mb-md-0"
                            placeholderText={_t(t("To date"))}
                            shouldCloseOnSelect={false}
                          />
                        </div>                                                                                 

                        <div className="col-3">
                          <Select
                            options={branchSearch && branchSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={branch}
                            isClearable
                            classNamePrefix="select"
                            className="table-text"
                            onChange={handleBranch}                                
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select a branch")) + ".."}
                          />
                        </div>

                        <div className="col-1">
                       
                        </div> 

                        <div className="col-2 t-mb-15 mb-md-0 text-right">
                          <button
                          className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                          onClick={(e) => getBranchWiseSelected()}
                          >
                           SALES SYNC REPORT
                          </button>
                        </div>  
                      </div>                   
                      
                    </div>                                          
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>

  </>
  )
}

export default SalesSyncReport;

