import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const RawStockTransferReport = () => {
  const { t } = useTranslation();
  // const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
  const { authUserInfo, getSupplier } = useContext(UserContext);
  let { branchForSearch } = useContext(RestaurantContext);

  //all data
  const [reportData, setReportData] = useState(null);
  const componentRef = useRef();
  const componentRef1 = useRef();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branch, setBranch] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [option, setOption] = useState("print");
  const [group, setGroup] = useState(null);

  const [branchSearch, setBranchSearch] = useState(null);
  useEffect(() => {
    getSupplier();
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  let common_toast = {
    position: "bottom-center",
    closeButton: false,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  }

  const handleBranch = (branch) => {
    setBranch(branch)
  }

  //get excel reports filter
  const getExcelReport = () => {
    setReportData(null);
    setGeneratedReport(false)
    if (
      startDate !== null &&
      endDate !== null
    ) {
        getSummaryReport()
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date and branch"
          )
        )}`,
        common_toast
      );
    }
  };

 //summary report
  const getSummaryReport = async () => {
    setDataPaginating(true);
    var fromDate = moment(startDate).format("YYYY-MM-DD");
    var toDate = moment(endDate).format("YYYY-MM-DD");
    const url = BASE_URL + "/settings/get-raw-stock-transfer-report";
    let formData = new FormData();
    formData.append("fromDate", fromDate);
    formData.append("toDate", toDate);
    formData.append("branch_id", branch?.id ?? "");

    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log("ReportResult", res);
        setGeneratedReport(true);
        setReportData(res.data[0])
        setDataPaginating(false);
        componentRef.current.handleDownload();
      })
      .catch((error) => {
        setDataPaginating(false);
        toast.error(
          `${_t(
            t(
              "Please try again"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      });
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Stock Transfer Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Stock Transfer Report"
        sheet="sheet 1"
        buttonText="Stock Detail"
        ref={componentRef}
      />

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xlss"
        filename="Stock Transfer Report"
        sheet="sheet 1"
        buttonText="Stock Detail"
        ref={componentRef1}
      />

      {/* Excel Report Details*/}
      <div className="d-none">
        <div>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && option === "print" && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="8" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Stock Transfer Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Branch"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                   {_t(t("Transfer No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Transfer Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("To Branch"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Item"))}
                                </th>
                                 <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Unit"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Qty"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Remarks"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* {console.log("309 ", reportData)} */}
                                {/* loop here*/}
                                {
                                  Object.keys(reportData).length > 0 && Object.keys(reportData).map(
                                    (group_name, index) => {
                                      return (
                                        <div key={index}>

                                          <tr>
                                            <th
                                              scope="row"
                                              className="lg-text text-capitalize align-middle text-center"
                                            >
                                              {group_name}
                                            </th>
                                          </tr>
                                          {reportData[group_name]?.length > 0 && reportData[group_name].map((item, i) => {

                                            return (
                                              <tr>
                                                <td></td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >
                                                  {item.transfer_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  <Moment format="LL">
                                                    {item.transfer_date}
                                                  </Moment>
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.to_branch}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.item_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.unit_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.qty}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.remarks}
                                                </td>
                                               
                                              </tr>
                                            );
                                          })}
                                        </div>
                                      );
                                    }
                                  )
                                }
                              </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Excel Report Summary*/}
      <div className="d-none">
        <div>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && option !== "print" && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xlss"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="9" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Purchase Invoice Summary Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Group Name"))}
                                  </th>
                                  {group?.name !== "Document No" && <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Invoice No"))}
                                  </th>}
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Manual Invoice No"))}
                                  </th>
                                  {group?.name !== "Document Date" && <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>}
                                  {group?.name !== "Branch" && <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Branch Name"))}
                                  </th>}
                                  {group?.name !== "Supplier" && <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Supplier Name"))}
                                  </th>}
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Discount Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Tax Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Net Total"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* {console.log("309 ", reportData)} */}
                                {/* loop here*/}
                                {
                                  Object.keys(reportData).length > 0 && Object.keys(reportData).map(
                                    (group_name, index) => {
                                      let totalAmount = 0;
                                      let totalDisAmount = 0;
                                      let totalTaxAmount = 0;
                                      let totalNetAmount = 0;
                                      return (
                                        <div key={index}>

                                          <tr>
                                            <th
                                              scope="row"
                                              className="lg-text text-capitalize align-middle text-center"
                                            >
                                              {group_name}
                                            </th>
                                          </tr>
                                          {reportData[group_name]?.length > 0 && reportData[group_name].map((item, i) => {
                                            totalAmount += item.amount ? parseFloat(item.amount) : 0;
                                            totalDisAmount += item.discount ? parseFloat(item.discount) : 0;
                                            totalTaxAmount += item.tax ? parseFloat(item.tax) : 0;
                                            totalNetAmount += item.total ? parseFloat(item.total) : 0;

                                            return (
                                              <tr>
                                                <td></td>
                                                {group?.name !== "Document No" && <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >
                                                  {item.invoice_number}
                                                </td>}
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >
                                                  {item.manual_invoice_no}
                                                </td>
                                                {group?.name !== "Document Date" && <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  <Moment format="LL">
                                                    {item.purchase_date}
                                                  </Moment>
                                                </td>}
                                                {group?.name !== "Branch" && <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.branch_name}
                                                </td>}
                                                {group?.name !== "Supplier" && <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.supplier_name}
                                                </td>}
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.amount && parseFloat(item.amount).toFixed(2)}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.discount && parseFloat(item.discount).toFixed(2)}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.tax && parseFloat(item.tax).toFixed(2)}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.total && parseFloat(item.total).toFixed(2)}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              Total
                                            </th>
                                            <td
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              <strong>
                                                {formatPrice(totalAmount)}
                                              </strong>
                                            </td>
                                            <td
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              <strong>
                                                {formatPrice(totalDisAmount)}
                                              </strong>
                                            </td>
                                            <td
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              <strong>
                                                {formatPrice(totalTaxAmount)}
                                              </strong>
                                            </td>
                                            <td
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              <strong>
                                                {formatPrice(totalNetAmount)}
                                              </strong>
                                            </td>
                                          </tr>
                                        </div>
                                      );
                                    }
                                  )
                                }
                              </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                    {/* spin loading ends */}

                    {/* Loading effect {loading === true ? (
                        tableLoading()
                      ) : ( */}

                    <div key="smtp-form">
                      <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="row t-mb-15 mb-md-0" >
                          <ul className="t-list fk-breadcrumb col-12">
                            <li className="fk-breadcrumb__list">
                              <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Stock Transfer Report"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-2 d-md-block">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control"
                            placeholderText={_t(t("From date"))}
                            shouldCloseOnSelect={false}
                          />
                        </div>

                        <div className="col-2 d-md-block">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control"
                            placeholderText={_t(t("To date"))}
                            shouldCloseOnSelect={false}
                          />
                        </div>

                        <div className="col-3">
                        <Select
                            options={branchSearch && branchSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={branch}
                            classNamePrefix="select"
                            isClearable
                            className="table-text"
                            onChange={handleBranch}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select from branch")) + ".."}
                           />
                        </div>

                        <div className="col-3"></div>
                           
                        <div className="col-2 t-mb-15 mb-md-0 text-right">
                          <button
                            className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                            onClick={(e) => getExcelReport()}
                          >
                            GENERATE REPORT
                          </button>
                        </div>
                        
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>

    </>
  );
}

export default RawStockTransferReport;