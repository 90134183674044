import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";

const OpeningStockEdit = () => {
  const { t } = useTranslation();
    const history = useHistory();
    const params = useParams()
    //getting context values here
    let {
      loading,
      setLoading,
      branchForSearch,
      ingredientItemForSearch,
    } = useContext(RestaurantContext);

    //row id
    let rowId=useRef(0);
    let {
      //opening stock
      setOpeningStockList,
      setOpeningStockForSearch,
    } = useContext(UserContext);

    // States hook here
    //new item
    let [newOpeningStock, setNewOpeningStock] = useState({
      document_no: "",
      branch: null,      
      remarks: "",
      item_amount: 0,
    });
    //new Row
    const [newRow,setNewRow]=useState([]);

    //edit stock
    const [editStock,setEditStock]=useState(null);
  
    //select style
    const customStyle = {
      control: (provided) => ({
        ...provided,
        minHeight: "2rem",
        marginTop: "0",
        marginBottom: "0",
      }),
  
      singleValue: (provided) => ({
        ...provided,
        alignSelf: "center",
      }),
      input: (provided) => ({
        ...provided,
        margin: "0",
      }),
      valueContainer: (provided) => ({
        ...provided,
        marginTop: "0",
        padding: "0 0.5rem",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0 0.4rem",
        svg: {
          height: "2rem",
        },
      }),
    };
  
    //datepicker
    const [documentDate, setDocumentDate] = useState(new Date());

    //Get Specific item for edit
  const getOpeningStockItem = async () => {
    setLoading(true);
    const url =
    BASE_URL +
      "/settings/get-opening-stock-edit/" +
      parseInt(params.id);
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res){
            setEditStock(res.data)
          }
          setLoading(false);
        } catch {
          setLoading(false);
        }
      };
  
    //useEffect == componentDidMount()
    useEffect(() => {
      getOpeningStockItem()
    }, []);

    useEffect(()=>{
      if(editStock && editStock !== null){
        let docDate=new Date(editStock.stock.document_date);
        let branch=branchForSearch?.find((item)=>editStock.stock.branch_id === item.id)
        setNewOpeningStock({
          ...newOpeningStock,
          document_no:editStock.stock.document_identity,
          branch,
          remarks:editStock.stock.remarks,
        })
        setDocumentDate(docDate);
        let rowArr=[];
        if(editStock.stockDetails && editStock.stockDetails.length > 0){
          editStock.stockDetails.map((item,index)=>{
            let ing_item=ingredientItemForSearch?.find((v)=>v.id===item.item_id);            
            rowArr.push({
              id: rowId.current,
              item_name:ing_item,
              qty:parseInt(item.qty),
              unit_id:item.unit_id,
              unit:item.unit_name,
              rate:parseFloat(item.rate).toFixed(2),
              amount:item.amount,
              base_unit:item.base_unit,
              base_qty:item.base_qty,
              conversion_qty:parseFloat(item.conversion_qty).toFixed(2),              
              isEdit:true,
            })
            rowId.current += 1
          })
          setNewRow([...rowArr]);
        }
      }
      },[editStock,branchForSearch])


    //set items hook
    const handleSetItems = async (index,item) => {
      newRow[index].unit="";
      newRow[index].base_unit="";
      newRow[index].base_qty="";
      newRow[index].conversion_qty="";
      newRow[index].item_name=item;
      newRow[index].base_unit=item.unit_id;
      newRow[index].item_units="";
      setNewRow([...newRow]);
      getUnits(item.id,index)
    };

    const getUnits=async(item,index)=>{
      const url = BASE_URL + "/settings/get-unit_item/"+item;
      await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
      .then((res) => {
        if(res.data){
          newRow[index].item_units = res.data;
          setNewRow([...newRow]);
        }      
      }).catch((error) =>{
        console.log("check error",error);
      })
    }
  
    const handleSetUnit=(index,unit)=>{
      newRow[index].unit=unit;
      newRow[index].conversion_qty=parseFloat(unit.qty).toFixed(2);
      setNewRow([...newRow]);
    }
  
    const calBaseQty=(index)=>{
      let qty=newRow[index].qty;
      let conversion_qty=newRow[index].conversion_qty
      if(qty && conversion_qty){
        return qty * conversion_qty;
      }else{
        return "";
      }
    }
  
    const addNewRow=()=>{  
      let newID=rowId.current     
      setNewRow((pre)=>[...pre,{
        id:newID,
        item_name:"",
        qty:"",
        unit:"",
        item_units:"",
        rate:"",
        amount:"",
        total:"",
        base_unit:"",
        base_qty:"",
        conversion_qty:"",
        isEdit:false
      }])
      rowId.current = rowId.current + 1;
    }
  
    const delRow=(id)=>{
      let row=[...newRow]
      row= row.filter((item,i)=>id!==item.id);
      setNewRow(row);
    }
  
    // total amount
    const handleAmount = () => {
      let total = 0;
      if (newRow !== null && newRow.length>0) {
        newRow.map((item) => {        
          if (parseFloat(item.qty) && parseFloat(item.rate)) {
            total = total +  parseFloat(item.qty) * parseFloat(item.rate);
          }
        });
      }
      return total;
    };
  
    //subtotal
    const handleGetAmount = (index) => {
      let qty = 0;
      let rate = 0;
      if (newRow !== null && newRow[index].qty !== "") {
        qty = parseFloat(newRow[index].qty);
      }
      if (newRow !== null && newRow[index].rate !== "") {
        rate = parseFloat(newRow[index].rate);
      }
      return rate * qty;
    };
  
    //set each item qty
    const handleItemQty = (index,e) => {
      newRow[index].qty=e.target.value;
      setNewRow([...newRow]);
    };
  
    //set each item qty
    const handleItemRate = (index,e) => {
      newRow[index].rate=e.target.value;
      setNewRow([...newRow]);
    };
  
    //handle Set branch hook
    const handleSetBranch = (branch) => {
        setNewOpeningStock({ ...newOpeningStock, branch });
    };

    const handleRemarks=(e)=>{
        setNewOpeningStock({ ...newOpeningStock, remarks:e.target.value });
    }
  
    //post req of stock item update
    const openingStockItemAxios = async () => {
      let date =
        new Date(documentDate).getFullYear() +
        "-" +
        (new Date(documentDate).getMonth() + 1) +
        "-" +
        new Date(documentDate).getDate();

      let slugArray=[];
      newRow.map((newStockItem,index) => {
        if(newStockItem.item_name === "" || newStockItem.unit===""){
          toast.error(`${_t(t("Please select all dropdowns"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          
        }
        else{
          slugArray.push({
            qty: parseInt(newStockItem.qty),
            price: parseFloat(newStockItem.rate),
            item_id: newStockItem.item_name.id,
            item_name: newStockItem.item_name.name,          
            unit_id: newStockItem.isEdit ? newStockItem.unit_id : newStockItem.unit.id,
            unit_name: newStockItem.isEdit ? newStockItem.unit : newStockItem.unit.name,
            amount: handleGetAmount(index),
            base_unit: newStockItem.base_unit,
            base_qty: calBaseQty(index),
            conversion_qty: parseFloat(newStockItem.conversion_qty)
          });
        }
        });
        
        if(newRow.length === slugArray.length){
          setLoading(true);
          let formData = {
            opening_stock_id:params.id,
            branch_id: newOpeningStock.branch.id,     
            document_date: date,
            remarks: newOpeningStock.remarks,
            item: slugArray,
            total_amount: handleAmount(),
          };      
      const url = BASE_URL + "/settings/update-opening-stock";
      try {
          const res = await axios.post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setOpeningStockList(res.data[0]);
        setOpeningStockForSearch(res.data[1]);
        setLoading(false);
        toast.success(`${_t(t("Opening stock has been updated"))}`, {
            position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      history.push(
        "/dashboard/manage/stock/opening-stock"
      )
      } catch (error) {
        console.log("check error==>",error);
        setLoading(false);
        toast.error(`${_t(t("Please try again later"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    }
  };
  
    //send to server
    const handleSubmit = (e) => {
      e.preventDefault();
      if (
        newOpeningStock.branch !== null &&
        newOpeningStock.document_no !== null &&
        documentDate !== null
      ) {
        if(newRow.length > 0){
          openingStockItemAxios();
        }
        else{
          toast.error(`${_t(t("Please add stocks"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      } else {
        toast.error(`${_t(t("Please fill all the required fields"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Edit Opening Stock"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      {/* <div className={`${dataPaginating && "loading"}`}></div> */}
                      {/* spin loading ends */}
  
                      {/* Loading effect */}
                      {loading === true ? (
                        tableLoading()
                      ) : (
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Edit Opening Stock"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"
                            onSubmit={handleSubmit}
                            autoComplete="off"
                          >
                            <div className="col-12">
                            <div className="row">
                                <div className="form-group col-4 mt-3">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="document no"
                                      className="control-label"
                                    >
                                      {_t(t("Document no"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      readOnly
                                      type="text"
                                      className="form-control table-text"
                                      id="document_no"
                                      name="document_no"                                      
                                      value={newOpeningStock.document_no}
                                      placeholder="Auto no"
                                      required
                                    />
                                  </div>
                                </div>
  
                                <div className="form-group col-4 text-right mt-3">
                                  <div className="mb-2 text-left">
                                    <label
                                      htmlFor="transfer_date"
                                      className="control-label"
                                    >
                                      {_t(t("Document Date"))}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <DatePicker
                                      selected={documentDate}
                                      onChange={(date) => setDocumentDate(date)}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      className="form-control table-text"
                                      placeholderText={_t(t("Document date"))}
                                      shouldCloseOnSelect={false}
                                    />
                                  </div>
                                </div>
                                  
                                <div className="form-group mt-3 col-4">
                                    <div className="mb-2">
                                      <label
                                        htmlFor="branch"
                                        className="control-label"
                                      >
                                        {_t(t("Branch"))}
                                        <span className="text-danger">*</span>                                        
                                      </label>
                                    </div>
                                    <Select
                                      options={branchForSearch}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={newOpeningStock.branch || null}
                                      classNamePrefix="select"
                                      onChange={handleSetBranch}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                    />
                                </div> 
                              </div>
  
                              <div className="row">                                                            
                                <div className="form-group mt-2 col-12">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="remarks"
                                      className="control-label"
                                    >
                                      {_t(t("Remarks"))}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <textarea
                                      type="text"
                                      className="form-control table-text"
                                      id="remarks"
                                      name="remarks"
                                      onChange={handleRemarks}
                                      value={newOpeningStock.remarks || ""}
                                      placeholder="Remarks"
                                      style={{height:"33px"}}
                                    />
                                  </div>
                                </div>
                              </div>                            
                              
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>
                                      {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border table-Child"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item Name"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Unit"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Rate"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Amount"))}
                                          </span>
                                        </th>                                        
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base unit"))}
                                          </span>
                                        </th>
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Base qty"))}
                                          </span>
                                        </th>
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{minWidth:"9rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Conversion unit"))}
                                          </span>
                                        </th>
                                        {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (stockItem, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                          {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>}&nbsp;
                                          <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                          onClick={()=>{delRow(stockItem.id)}}
                                          ></i>
                                          </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                                {stockItem.isEdit ? 
                                                <input
                                                readOnly
                                                type="text"
                                                className={`form-control text-center table-Input table-text`}
                                                value={stockItem.item_name?.name || ""}
                                                />:
                                              <Select
                                                options={ingredientItemForSearch !== null ? ingredientItemForSearch : []}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e)=>handleSetItems(index,e)}
                                                maxMenuHeight="12.8rem"                                                
                                                value={stockItem.item_name}
                                                styles={customStyle}
                                                isMenuOpen={true}
                                                menuPosition="fixed"
                                                className="bank-table-input"                                              
                                                placeholder={
                                                _t(t("Please select items")) + ".."
                                                }                                                
                                              />}
                                              </td>                                              

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={stockItem.id}
                                                  name={stockItem.id}
                                                  onChange={(e)=>handleItemQty(index,e)}
                                                  value={                                                    
                                                    stockItem.qty || ""
                                                  }
                                                  placeholder="Qty"
                                                  required                                                  
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                {stockItem.isEdit ? 
                                                <input
                                                readOnly
                                                type="text"
                                                className={`form-control text-center table-Input table-text`}
                                                value={stockItem.unit || ""}
                                                /> :                                                 
                                                <Select
                                                  menuPosition="fixed"
                                                  options={stockItem.item_units ? stockItem.item_units : []}
                                                  components={makeAnimated()}
                                                  getOptionLabel={(option) => option.name}
                                                  getOptionValue={(option) => option.id}
                                                  value={stockItem.unit}
                                                  classNamePrefix="select"
                                                  onChange={(e)=>handleSetUnit(index,e)}
                                                  className="bank-table-input"
                                                  maxMenuHeight="200px"
                                                  styles={customStyle}                                                  
                                                  placeholder={
                                                  _t(t("please select unit")) + ".."
                                                  }  
                                                  isDisabled={stockItem.item_units?false:true}                                                   
                                                />}
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={stockItem.id}
                                                  name={stockItem.id}
                                                  onChange={(e)=>handleItemRate(index,e)}
                                                  value={                                                    
                                                    stockItem.rate || ""
                                                  }
                                                  placeholder="Price"
                                                  required                                                  
                                                />
                                              </td>
                                              <td className="table-text align-middle text-center">
                                                {formatPrice(
                                                  handleGetAmount(                                                    
                                                    index
                                                  )
                                                )}
                                              </td>                                            

                                              {/* hidden columns */}
                                              
                                              <td className="table-text align-middle text-center">
                                                {stockItem.base_unit}
                                              </td> 
                                              
                                              <td className="table-text align-middle text-center">                                                
                                                {formatPrice(calBaseQty(index))}
                                              </td>
                                              
                                              <td className="table-text align-middle text-center">
                                                {stockItem.conversion_qty}
                                              </td> 
                                              
                                              <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                                <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(stockItem.id)}}></i>&nbsp;
                                                {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                  <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                                </span>}                                             
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}
                                    {newRow && newRow.length>0 ?
                                    <tfoot className="align-middle">
                                      <tr>
                                        <th
                                          scope="col"
                                          colSpan="1"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        ></th>

                                        <th
                                          scope="col"
                                          colSpan="3"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        ></th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                              {_t(t("Total"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:"bold"}}>
                                            {formatPrice(handleAmount())}
                                          </span>
                                        </th>                                     

                                        <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      
                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      
                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      
                                      <th                                      
                                        scope="col"                                        
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>
                                      </tr>
                                    </tfoot>
                                    :null}
                                  </table>
                              </div>
                                
  
                              <div className="form-group mt-4 pb-2">
                                {/* <div className="col-lg-12">
                                  <button
                                    className="btn btn-primary px-5 rounded"
                                    type="submit"                                    
                                  >
                                    {_t(t("Save"))}
                                  </button>
                                  <button
                                    className="btn btn-primary px-5 mx-2 rounded"
                                    onClick={() =>
                                      history.push(
                                        "/dashboard/manage/stock/opening-stock"
                                      )
                                    }
                                  >
                                    {_t(t("Back"))}
                                  </button>
                                </div> */}
                                <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/opening-stock"))}
                                //   data-dismiss="modal"
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}     
      </>
    );
}

export default OpeningStockEdit;