import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartThemeColors, chartThemeColorsLine } from "../index";
import { ReactComponent as Triangle } from "../../../../public/caret-up.svg";
import { ReactComponent as TriangleDown } from "../../../../public/caret-down.svg";
import { ReactComponent as Report } from "../../../../../restaurant/public/report.svg";
import Expense from "../../../../public/expense-icon.png";
import moment from "moment";
import { NavLink } from "react-router-dom";

const LineCardWidget = ({ data, heading, size, handleReq, url = null }) => {
    const [ cards, setCards ] = useState([]);
    const [ previousCards, setPreviousCards ] = useState([]);
    const [chart,setCharts]= useState(null);

    useEffect(()=>{
        if(url){
            handleReq(url);
        }
    },[])
  
    useEffect(() => {
        if(data){
            let currentData = [];
            let previousData = [];
            let dates = [];
            if(data?.currentDateWiseSales){
                data.currentDateWiseSales.forEach((item)=>{
                    currentData.push(item.sale_amount);
                    dates.push(moment(item.date, "YYYY-MM-DD").format("DD MMMM"));
                })
                previousData = data.previousDateWiseSales.map((item)=>item.sale_amount)
            }
            setCards(data.currentDetails);
            setPreviousCards(data.previousDetails)
            setCharts({
                details:{
                    previousMainDetails: data.previousMainDetails,
                    currentMainDetails: data.currentMainDetails,
                    ...( heading === "Avg Food Cost" && { ideal_food_cost: data?.ideal_food_cost, food_cost_percent: data.food_cost_percent })
                },
                line_chart:{
                    yaxis: [
                        {
                            name: heading === "Avg Food Cost" ? 'Current Cost' : 'Current Sales',
                            data: currentData
                        },
                        {
                            name: heading === "Avg Food Cost" ? 'Previous Cost' : 'Previous Sales',
                            data: previousData,
                        },
                    ],
                    xaxis: dates,
               }
            })
        }
    }, [data]);
  
  return (
    <div className={`col row mx-0 mt-3 ${size} px-2`}>
        <div className="row px-0 mx-0 justify-content-between col col-12 ">
            <div className="dashboard__card col col-12 w-100 flex-basis-100 widget_border">
                {heading ?
                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <div className="mr-3 font-weight-bold xlg-text t-text-heading">
                         <h6 className="mb-0 mt-0 xxxslg-text">{heading}</h6>
                        </div>
                        <div className="py-1 px-2 rounded table-text" style={{background:"white",fontWeight:"500"}}>
                         <NavLink to="/dashboard/sales-summary-reports" className="text-decoration-none text-dark">
                          <Report style={{width:"1.4rem", height:"1.4rem"}} className="mr-2"/> Report
                         </NavLink>
                        </div>
                    </div>
                : ""}
                {
                (heading === "Avg Food Cost" && chart) ? (
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center my-2">
                            <div className="mr-3 font-weight-bold xxxslg-text t-text-heading">
                            {chart && (chart.details.food_cost_percent * 1).toFixed(2)}% {" "}
                            ({chart.details.currentMainDetails.value})
                            </div>
                            <div className="rounded table-text mr-2 px-1 font-weight-md" style={{backgroundColor: chartThemeColorsLine[0], color:"white"}}>{chart && chart.details.previousMainDetails.value > 0 ? (((chart.details.currentMainDetails.value - chart.details.previousMainDetails.value)/chart.details.previousMainDetails.value) * 100).toFixed(1) : 0}% {chart && (chart.details.currentMainDetails.value > chart.details.previousMainDetails.value) ? <i className="fa fa-arrow-up"></i> : <i className="fa fa-arrow-down"></i>} </div>
                            <div className="table-text dashboard_text_secondary"> VS Last Month</div>
                        </div>
                        <div className="d-flex align-items-end flex-column">
                            <div className="genAcc-select">Ideal Cost</div>
                            <div className="genAcc-select" style={{margin:"-0.3rem"}}>{chart.details.ideal_food_cost && chart.details.ideal_food_cost.cost_percent} % {" "} ({chart.details.ideal_food_cost && chart.details.ideal_food_cost.cost_amount})</div>
                        </div>
                    </div>  
                ) : (
                    <div className="d-flex align-items-center my-2">
                        <div className="mr-3 font-weight-bold xxxslg-text t-text-heading">
                            {chart && chart.details.currentMainDetails.value}
                        </div>
                        <div className="rounded table-text mr-2 px-1 font-weight-md" style={{backgroundColor: chartThemeColors[0], color:"white"}}>{chart && chart.details.previousMainDetails.value > 0 ? (((chart.details.currentMainDetails.value - chart.details.previousMainDetails.value)/chart.details.previousMainDetails.value) * 100).toFixed(1) : 0}% {chart && (chart.details.currentMainDetails.value > chart.details.previousMainDetails.value) ? <i className="fa fa-arrow-up"></i> : <i className="fa fa-arrow-down"></i>} </div>
                        <div className="table-text dashboard_text_secondary"> VS Last Month</div>
                    </div>
                )
                }
          
                <div className="d-flex flex-col mx-3 my-2" style={{ gap: "1rem" }}>
                </div>
                {
                    chart && chart.line_chart && (
                        <Chart
                        width="100%"
                        height="230px"
                        type="line"
                        series={chart.line_chart.yaxis}
                        options={{
                            chart: {
                            type: 'line',
                            toolbar: {
                                show: false
                            }
                            },
                            stroke: {
                                curve: 'smooth',
                                dashArray: [0, 8]
                            },
                            plotOptions: {
                            bar: {
                                distributed: true,
                                borderRadius: 10,
                            },
                            },
                            colors: heading === "Avg Food Cost" ?  chartThemeColorsLine : chartThemeColors,
                            dataLabels: {
                            enabled: false,
                            },
                            
                            xaxis: {
                            categories: chart.line_chart.xaxis,
                            labels: { 
                                // show: false,
                                style:{
                                fontSize:"10.5px"
                                }
                            },
                            },
                            yaxis: {
                            labels: {
                                formatter: function (value) {
                                return value ? value?.toFixed(0) : value;
                                },
                                style:{
                                fontSize:"10.5px"
                                }
                            },
                            tickAmount: 4,
                            },
                            legend:{
                            position:"top",
                            fontSize:"10.5px",
                            offsetY:0,
                            }
                        }}
                        />
                    )
                }

                <div className="col col-12 row mx-0 mt-3 px-1">
                    <div className='row mx-0 px-0 flex-wrap dashboard__card-container'>
                {cards && cards.map((card,index) => (
                    <div key={card.name} className='col-4 p-0' >
                    <div className='col p-0 flex-nowrap adminDashboard__card dashboard__card_view p-2 widget_border' style={{margin:"0 0.4rem"}} >
                    <div className='col m-0 p-0'>
                        <div className='d-flex align-items-center justify-content-between'>
                        <div className='text-capitalize table-text mb-0'>{card.name}</div>
                        </div>
                        <>
                            <div className='d-block'>
                             <p className='font-weight-bold xslg-text mb-0'>{card.value?.toLocaleString()}</p>
                            {
                                previousCards && (
                                    <div className='table-text d-flex align-items-end justify-content-between'>
                                        {card.value > previousCards[index]?.value ? <span className='text-primary dashboard_percent'><Triangle className = "dashboard_card_up"/> {previousCards[index]?.value > 0 ? (((card.value - previousCards[index]?.value)/previousCards[index]?.value)*100).toFixed(1) : 0 }% <span>{previousCards[index]?.value}</span>  </span> : <span className='text-danger dashboard_percent'><TriangleDown className="dashboard_card_down"/>  {card.value > 0 ? (100 - ((card.value/previousCards[index]?.value)*100)).toFixed(1) : 0 }% <span>{previousCards[index]?.value}</span> </span>}
                                        <span className="ml-1 mb-1"><img style={{height:"1.4rem"}} src={Expense} alt=""/></span>
                                    </div>
                                )
                            }
                            </div>
                        </>
                    </div>
                    
                    </div>
                    </div>
                ))}
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LineCardWidget
