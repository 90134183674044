import React from 'react';
import {
    _t,
    currencySymbolLeft,
    currencySymbolRight,
    // restaurantMenuLink,
  } from "../../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

const CustomPosModal = ({
    addReasonOnSubmit,
    deleteReason,
    setDeleteReason,
    setAddReasonModalShowing,
    addReasonModalShowing,
    paymentTypeModalState,
    setPaymentTypeModalState,
    callCenterPayment,
    setCallCenterPayment,
}) => {
    const { t } = useTranslation();
    const focusPopUpInputField = (input) => {
        if (input) {
          input.focus()
        }
    };
    //close tax calculation
    const closePaymentTypeTax = () => {
        setPaymentTypeModalState({
            showModal: false,
            taxCalSelect:false,
            subTotal: 0,
            vat: 0,
            vatAmount: 0,
            serviceCharge: 0,
            serviceChargeAmount: 0,
            deliveryCharges: 0,
            discount: 0,
            totalPayable: 0,
            payment_type_name: "",
        });
    };

    //transaction type id
    const handleOnTransaction = (e) =>{
        setCallCenterPayment({
            ...callCenterPayment,
            transaction_no: e.target.value,
        })
    }

    //transaction type id
    const handleTransactionSubmit = (e) =>{
        e.preventDefault();
        setCallCenterPayment({
            ...callCenterPayment,
            transactionModal: false,
        })
    }

  return (
    <>
        {/* call center transaction Modal */}
        <div
          className={`modal fade ${callCenterPayment.transactionModal ? "showing" : ""}`}
          aria-hidden="true"
        >
            <div className="modal-dialog modal-md">
                <div className="modal-content" style={{ borderRadius: "1rem" }}>
                
                    <div className="modal-body py-4">
                    {/* show form or show saving loading */}
                    <div key="fragment-food-group-1">
                        <form autoComplete="off">
                        <div>
                            <div className="addons-list__item mt-1 mx-1">
                            <label
                                htmlFor="name"
                                className="form-label d-flex justify-content-between"
                            >
                                <span>{_t(t("Enter Transaction Number"))} </span>
                                <span
                                onClick={(e) => {
                                    handleTransactionSubmit(e)
                                }}
                                >
                                <i className="fa fa-times-circle close-1 pointer-cursor"></i>
                                </span>
                            </label>
                            <input
                                ref={focusPopUpInputField}
                                type="number"
                                onKeyDown={(evt) =>
                                ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                evt.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}  
                                min="0"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Enter Transaction Id"
                                autoComplete="off"
                                maxLength={16}
                                value={callCenterPayment.transaction_no}
                                required
                                onChange={(e) => {
                                    handleOnTransaction(e)
                                }}
                            />
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className="row">
                                <div className='col-4'></div>
                                <div className="col-4">
                                    <button
                                    type="submit"
                                    className="btn btn-success font-weight-bold w-100 rounded table-text text-uppercase t-width-max"
                                    onClick={(e) => {
                                        handleTransactionSubmit(e);
                                    }}
                                    >
                                    {_t(t("Submit"))}
                                    </button>
                                </div>
                                <div className='col-4'></div>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        {/*Reason Modal*/}
        <div
            className={`modal fade ${
            addReasonModalShowing !== null ? "showing" : ""
            }`}
            id="addReason"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-md">
            <div className="modal-content">
                <div className="modal-header align-items-center">
                <div className="fk-sm-card__content"></div>
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => setAddReasonModalShowing(null)}
                ></button>
                </div>
                <div className="modal-body">
                {/* show form or show saving loading */}
                <div key="fragment-food-group-1">
                    <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
                    <div>
                        <label htmlFor="name" className="form-label">
                        {_t(t("Reason"))}{" "}
                        <small style={{ color: "red" }}>*</small>
                        </label>
                        <input
                        ref={focusPopUpInputField}
                        type="text"
                        className="form-control"
                        id="modal_reasons"
                        name="modal_reasons"
                        placeholder="e.g. Reason"
                        required
                        value={deleteReason}
                        onChange={(e) => setDeleteReason(e.target.value)}
                        />
                    </div>

                    <div className="mt-4">
                        <div className="row">
                        <div className="col-6">
                            <button
                            type="button"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                            data-dismiss="modal"
                            onClick={(e) => {
                                addReasonOnSubmit(true);
                            }}
                            >
                            {_t(t("Made"))}
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                            data-dismiss="modal"
                            onClick={(e) => {
                                addReasonOnSubmit(false);
                            }}
                            >
                            {_t(t("Unmade"))}
                            </button>
                        </div>
                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </div>

        {/* payment type tax modal */}
        <div
            className={`modal fade ${
                paymentTypeModalState.showModal ? "showing" : ""
            }`}
            id="addPayment"
            aria-hidden="true"
            >
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                    <div className="modal-header align-items-center py-1">
                        <div className="fk-sm-card__content">
                        <h4>{paymentTypeModalState.payment_type_name}</h4>
                        </div>
                        <button
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={closePaymentTypeTax}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {/* show form or show saving loading */}
                        <div key="fragment-food-group-1">
                        <div>
                            <table className="table mb-4 table-bordered table-striped">
                            <tbody>
                                <tr>
                                <th>{_t(t("Sub Total"))}</th>
                                <td className="font-bold text-center">
                                    {currencySymbolLeft()}
                                    {parseFloat(paymentTypeModalState.subTotal).toFixed(
                                    2
                                    )}
                                    {currencySymbolRight()}
                                </td>
                                </tr>
                                <tr>
                                <th>
                                    {_t(t("VAT"))} {`(${paymentTypeModalState.vat}%)`}
                                </th>
                                <td className="font-bold text-center">
                                    {currencySymbolLeft()}
                                    {parseFloat(paymentTypeModalState.vatAmount).toFixed(
                                    2
                                    )}
                                    {currencySymbolRight()}
                                </td>
                                </tr>
                                <tr>
                                <th>
                                    {_t(t("Service Charges"))}{" "}
                                    {`(${paymentTypeModalState.serviceCharge})%`}
                                </th>
                                <td className="font-bold text-center">
                                    {currencySymbolLeft()}
                                    {parseFloat(
                                    paymentTypeModalState.serviceChargeAmount
                                    ).toFixed(2)}
                                    {currencySymbolRight()}
                                </td>
                                </tr>
                                <tr>
                                <th>{_t(t("Discount"))}</th>
                                <td className="font-bold text-center">
                                    {currencySymbolLeft()}
                                    {parseFloat(paymentTypeModalState.discount).toFixed(
                                    2
                                    )}
                                    {currencySymbolRight()}
                                </td>
                                </tr>
                                <tr>
                                <th>{_t(t("Delivery Charges"))}</th>
                                <td className="font-bold text-center">
                                    {currencySymbolLeft()}
                                    {paymentTypeModalState.deliveryCharges
                                    ? parseFloat(
                                        paymentTypeModalState.deliveryCharges
                                        ).toFixed(2)
                                    : 0}
                                    {currencySymbolRight()}
                                </td>
                                </tr>
                                <tr>
                                <th>{_t(t("Total Amount"))}</th>
                                <td className="font-bold text-center">
                                    {currencySymbolLeft()}
                                    {parseFloat(
                                    paymentTypeModalState.totalPayable
                                    ).toFixed(2)}
                                    {currencySymbolRight()}
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{justifyContent: "end !important"}}>
                        <div className="row m-0 py-2 px-0">
                        <button
                            className="btn btn-primary"
                            onClick={closePaymentTypeTax}
                        >
                            {_t(t("OK"))}
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
    </>
  )
}

export default CustomPosModal
