import React, { useState, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import StockReport from "./StockReport";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from 'react-icons/md';

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const toastObj = {
  position: "bottom-center",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  className: "text-center toast-notification",
}
const OpeningStock = () => {
    const { t } = useTranslation();
    //getting context values here
    let {
      //common
      loading,
      setLoading,
    } = useContext(SettingsContext);

    const {
      branchForSearch,
    } = useContext(RestaurantContext)
  
    let {

      //opening stock
      getOpeningStock,
      openingStockList,
      setOpeningStockList,
      setPaginatedOpeningStock,
      openingStockForSearch,
      setOpeningStockForSearch,

      //pagination
      dataPaginating,
    } = useContext(UserContext);
  
    //search result
    let [searchedStockTransfer, setSearchedStockTransfer] = useState({
      list: null,
      searched: false,
    });
  
    //search customers here
    const handleSearch = (e) => {
      let searchInput = e.target.value.toLowerCase();
      if (searchInput.length === 0 || openingStockForSearch === null) {
        setSearchedStockTransfer({ ...searchedStockTransfer, searched: false });
      } 
    else {
        let searchedList = openingStockForSearch.filter((item) => {
          let lowerCaseDocumentNo = item.document_identity.toLowerCase();
          let lowerCaseDocumentDate = item.document_date.toLowerCase();
          let lowerCaseRemarks = item.remarks.toLowerCase();
          let lowerCaseAmount = item.total_amount.toLowerCase();
  
          return (
            lowerCaseDocumentNo.includes(searchInput) ||
            lowerCaseDocumentDate.includes(searchInput) ||
            lowerCaseRemarks.includes(searchInput) ||
            lowerCaseAmount.includes(searchInput)
          );
        });
        setSearchedStockTransfer({
          ...searchedStockTransfer,
          list: searchedList,
          searched: true,
        });
      }
    };
  
    //delete confirmation modal of waiter
    const handleDeleteConfirmation = (id) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card card-body">
              <h1>{_t(t("Are you sure?"))}</h1>
              <p className="text-center">{_t(t("You want to delete this?"))}</p>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleDeleteCustomer(id);
                    onClose();
                  }}
                >
                  {_t(t("Yes, delete it!"))}
                </button>
                <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                  {_t(t("No"))}
                </button>
              </div>
            </div>
          );
        },
      });
    };
  
    //delete customer here
    const handleDeleteCustomer = (id) => {
      setLoading(true);
      const customerUrl = BASE_URL + `/settings/delete-opening-stock/${id}`;
      return axios
        .get(customerUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setOpeningStockList(res.data[0]);
          setOpeningStockForSearch(res.data[1]);
          setSearchedStockTransfer({
            ...searchedStockTransfer,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Opening stock has been deleted successfully"))}`, toastObj);
        })
        .catch(() => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, toastObj);
        });
    };

    //print bills states and function
  const [newRow,setNewRow]=useState([]);
  const [purchaseDate,setPurchaseDate]=useState(new Date());
  let [newOpeningStock, setNewOpeningStock] = useState({
    invoice: '',
    branch: '',
    remarks: '',
    total_amount:''
  });
  const componentRef = useRef();
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleGetPrintData = async(id) => {
    const url =
    BASE_URL +
      "/settings/get-opening-stock-edit/" +
      parseInt(id);
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res.data && res.data.stock && res.data.stockDetails){
            let purchaseDate = new Date(res.data.stock.document_date);
            setPurchaseDate(purchaseDate);
            let branch=branchForSearch?.find((item)=>item.id === res.data.stock.branch_id)            
            setNewOpeningStock({
              ...newOpeningStock,
              invoice: res.data.stock.document_identity,
              branch,
              remarks: res.data.stock.remarks,
              total_amount: res.data.stock.total_amount
            })
            let newArr=[];
            if(res.data.stockDetails && res.data.stockDetails.length>0){
              res.data.stockDetails.map((item)=>{
                newArr.push({
                ingredient_name: item.item_name,
                qty: parseFloat(item.qty).toFixed(0),
                rate: parseFloat(item.price).toFixed(2),
                amount: parseFloat(item.amount).toFixed(2),
                unit_name: item.unit_name
                })
              })
              setNewRow([...newArr])
            }
            handleOnlyPrint();
          }
          
        } catch(error) {
          console.log("error==>",error)
          toast.success(`${_t(t("Please try again"))}`, toastObj);
        }
  }

  const handleGetAmount = (index) => {
    let qty = 0;
    let rate = 0;
    if (newRow !== null && newRow[index].qty !== "") {
      qty = parseFloat(newRow[index].qty);
    }
    if (newRow !== null && newRow[index].rate !== "") {
      rate = parseFloat(newRow[index].rate);
    }
    return rate * qty;
  };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Opening Stock"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div className="fk-scroll--pos-table-menu" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      {/* Loading effect */}
                      {/* {loading === true ? (
                        tableLoading()
                      ) : ( */}
                        <div key="fragment3">
                          {/* next page data spin loading */}
                          <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          {/* spin loading ends */}
  
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {!searchedStockTransfer.searched
                                      ? _t(t("Opening Stock List"))
                                      : _t(t("Search Result"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center">
                                {/* Search customer */}
                                <div className="col-md-9 t-mb-15 mb-md-0">
                                  <div className="input-group">
                                    <div className="form-file">
                                      <input
                                        type="text"
                                        className="form-control border-0 form-control--light-1 rounded-left"
                                        placeholder={_t(t("Search")) + ".."}
                                        onChange={handleSearch}
                                      />
                                    </div>
                                    <button
                                      className="btn btn-primary rounded-right"
                                      type="button"
                                    >
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
  
                                {/* Add customer modal trigger button */}
                                <div className="col-md-3 text-md-right">
                                  <NavLink
                                    to="/dashboard/manage/stock/opening-stock-add"
                                    className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  >
                                    {_t(t("add new"))}
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Table */}
                          <div className="">
                            <table className="table table-bordered table-hover min-table-height">
                              <thead className="align-middle">
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Document Number"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Document Date"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Remarks"))}
                                  </th>

                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Total"))}
                                  </th>

                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Created Date"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Action"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                {!searchedStockTransfer.searched
                                  ? [
                                    openingStockList && [
                                      openingStockList.data.length === 0 ? (
                                          <tr className="align-middle">
                                            <td
                                              scope="row"
                                              colSpan="7"
                                              className="xsm-text align-middle text-center"
                                            >
                                              {_t(t("No data available"))}
                                            </td>
                                          </tr>
                                        ) : (
                                          openingStockList.data.map((item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (openingStockList.current_page -
                                                      1) *
                                                      openingStockList.per_page}
                                                </th>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.document_identity}
                                                </td>
  
                                                <td className="xsm-text align-middle text-center">
                                                  <Moment format="DD-MMMM-YYYY">
                                                    {item.document_date}
                                                  </Moment>
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">                                                  
                                                  {item.remarks}
                                                </td>
                                                

                                                <td className="xsm-text align-middle text-center">
                                                  {parseFloat(item.total_amount).toFixed(2)}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">                                        
                                                  <Moment format="DD-MMMM-YYYY hh:mm A">
                                                    {item?.created_at}
                                                  </Moment>                                          
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <div className="d-flex justify-content-center">                                                    
                                                    {/* <NavLink
                                                    className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                    to={`/dashboard/manage/stock/opening-stock-edit/${item.id}`}                                                       
                                                    >
                                                      <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                    </NavLink>
                                                      <button
                                                      className="btn btn-danger btn-sm ml-2 rounded-sm"
                                                      onClick={()=>handleDeleteConfirmation(item.id)}
                                                    >
                                                    <MdDelete style={{cursor: "pointer", fontSize: "1.3rem", color:"white"}} />
                                                    </button>
                                                      <button
                                                      className="btn btn-secondary btn-sm ml-2 rounded-sm"
                                                        onClick={()=>handleGetPrintData(item.id)}
                                                      >
                                                        <i className="fa fa-print"></i>
                                                      </button> */}
                                                    <NavLink
                                                      className="btn btn-primary ml-2 rounded-sm"
                                                      to={`/dashboard/manage/stock/opening-stock-edit/${item.id}`}
                                                      style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                    >
                                                      <div className="d-flex align-items-center justify-content-center">
                                                        <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                      </div>
                                                    </NavLink>
                                                      <button
                                                      className="btn btn-danger ml-2 rounded-sm del-btn"
                                                      onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                      >                                                    
                                                        <MdDelete className="del-icon" />                                                    
                                                    </button>
                                                    <button
                                                      className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                        onClick={()=>handleGetPrintData(item.id)}
                                                      >
                                                        <i className="fa fa-print edit-icon"></i>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ),
                                      ],
                                    ]
                                  : [
                                      /* searched data, logic === haveData*/
                                      searchedStockTransfer && [
                                        searchedStockTransfer.list.length === 0 ? (
                                          <tr className="align-middle">
                                            <td
                                              scope="row"
                                              colSpan="7"
                                              className="xsm-text align-middle text-center"
                                            >
                                              {_t(t("No data available"))}
                                            </td>
                                          </tr>
                                        ) : (
                                          searchedStockTransfer.list.map(
                                            (item, index) => {
                                              return (
                                                <tr
                                                  className="align-middle"
                                                  key={index}
                                                >
                                                  <th
                                                    scope="row"
                                                    className="xsm-text text-capitalize align-middle text-center"
                                                  >
                                                    {index +
                                                      1 +
                                                      (openingStockList.current_page -
                                                        1) *
                                                        openingStockList.per_page}
                                                  </th>
  
                                                  <td className="xsm-text text-capitalize align-middle text-center">
                                                    {item.document_identity}
                                                  </td>
  
                                                  <td className="xsm-text align-middle text-center">
                                                    <Moment format="DD-MMMM-YYYY">
                                                      {item.document_date}
                                                    </Moment>
                                                  </td>
  
                                                  <td className="xsm-text text-capitalize align-middle text-center">
                                                    {item.remarks}
                                                  </td>

                                                  <td className="xsm-text align-middle text-center">
                                                    {parseFloat(item.total_amount).toFixed(2)}
                                                  </td>

                                                  <td className="xsm-text text-capitalize align-middle text-center">                                        
                                                    <Moment format="DD-MMMM-YYYY hh:mm A">
                                                      {item?.created_at}
                                                    </Moment>                                          
                                                  </td>
  
                                                  <td className="xsm-text text-capitalize align-middle text-center">
                                                    <div className="d-flex justify-content-center">                                                    
                                                    {/* <NavLink
                                                    className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                    to={`/dashboard/manage/stock/opening-stock-edit/${item.id}`}                                                       
                                                  >
                                                    <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                  </NavLink>
                                                    <button
                                                    className="btn btn-danger btn-sm ml-2 rounded-sm"
                                                    onClick={()=>handleDeleteConfirmation(item.id)}
                                                    >
                                                    <MdDelete style={{cursor: "pointer", fontSize: "1.3rem", color:"white"}} />
                                                  </button>
                                                    <button
                                                    className="btn btn-secondary btn-sm ml-2 rounded-sm"
                                                      onClick={()=>handleGetPrintData(item.id)}
                                                    >
                                                      <i className="fa fa-print"></i>
                                                    </button> */}
                                                    <NavLink
                                                    className="btn btn-primary ml-2 rounded-sm"
                                                    to={`/dashboard/manage/stock/opening-stock-edit/${item.id}`}
                                                    style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                  >
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                    </div>
                                                  </NavLink>
                                                    <button
                                                    className="btn btn-danger ml-2 rounded-sm del-btn"
                                                    onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                    >                                                    
                                                      <MdDelete className="del-icon" />                                                    
                                                  </button>
                                                  <button
                                                    className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                      onClick={()=>handleGetPrintData(item.id)}
                                                    >
                                                      <i className="fa fa-print edit-icon"></i>
                                                    </button>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ),
                                      ],
                                    ]}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
  
                {/* pagination loading effect */}
                {loading === true
                  ? paginationLoading()
                  : [
                      // logic === !searched
                      !searchedStockTransfer.searched ? (
                        <div key="fragment4">
                          <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                            <div className="row align-items-center t-pl-15 t-pr-15">
                              <div className="col-md-7 t-mb-15 mb-md-0">
                                {/* pagination function */}
                                {pagination(openingStockList, setPaginatedOpeningStock)}
                              </div>
                              <div className="col-md-5">
                                <ul className="t-list d-flex justify-content-md-end align-items-center">
                                  <li className="t-list__item">
                                    <span className="d-inline-block sm-text">
                                      {showingData(openingStockList)}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // if searched
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              <ul className="t-list d-flex">
                                <li className="t-list__item no-pagination-style">
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      setSearchedStockTransfer({
                                        ...searchedStockTransfer,
                                        searched: false,
                                      })
                                    }
                                  >
                                    {_t(t("Clear Search"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {searchedShowingData(
                                      searchedStockTransfer,
                                      openingStockForSearch
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ),
                    ]}
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
        <StockReport
        name = "Opening Stock"
        purchaseDate={purchaseDate}
        newItem={newOpeningStock}
        newRow={newRow}
        invoiceNo = {"Document No"}
        inoviceDate = {"Document Date"}
        handleGetTotal={""}
        handleAmount={""}
        handleTaxAmount={""} 
        handleDisAmount={""}
        handleGetSubTotal={""}
        handleGetAmount={handleGetAmount}
        stockPrint={componentRef}
        /> 
      </>
    );
}

export default OpeningStock