import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  customStyle,
  getDate,  
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdDelete } from 'react-icons/md';
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import { FoodContext } from "../../../../../contexts/Food";

const WastageFoodAdd = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
      loading,
      setLoading,
      branchForSearch,
      ingredientItemForSearch,
    } = useContext(RestaurantContext);

    let { foodForPOS, propertyItemList } = useContext(FoodContext)
  
    let {
      authUserInfo,
      //wastage      
      setWastageItemList,
      setWastageItemListForSearch,
    } = useContext(UserContext);

    const [branchSearch, setBranchSearch] = useState(null);

    useEffect(() => {
      if (authUserInfo.details.user_type === "manager") {
        setBranchSearch(
          branchForSearch?.filter(
            (branch) => branch.id === authUserInfo.details.branch_id
          )
        );
      } else {
        setBranchSearch(branchForSearch);
      }
    }, []);


    // States hook here
    //new item
    let [newWastage, setNewWastage] = useState({
      documentDate: new Date,
      branch:null,
      variation:'',
      type:'',
      remarks:'',
    });

    let common_toast = {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }
  
    //selected items states
    const [property, setProperty] = useState(null);
    const [food, setFood] = useState(null);
    const [raw, setRaw] = useState(null);

    //variation
    const [showVariation, setShowVariation] = useState(false);
    const [variation, setVariation] = useState(null);

    //radio item
    const [optionType, setOptionType] = useState(null);
    const [optionFG, setOptionFG] = useState(null);

    //set Option type
    const handleOption = (type) => {
        setShowVariation(false);
        setVariation(null);
        setProperty(null);
        setFood(null);
        setNewRow([]);
        setRaw(null);
        setOptionFG(null);
        setOptionType(type);
    }

    //new Row
    const [newRow,setNewRow]=useState([]);
    const [rowId,setRowId]=useState(0);

    const handleRawMaterial = (rawItem) => {        
        setRaw(rawItem)
        setNewRow((pre)=>[...pre,{
            id:rowId,
            item_id:rawItem?.id,
            item_name:rawItem?.name,
            qty:"",
            unit_id:rawItem?.unit_id,
            unit_name:rawItem?.unit,
        }])
        setRowId(rowId+1);
    }

    //set option finished goods
    const handleOptionFG = (type) => {
        setShowVariation(false);
        setVariation(null);
        setProperty(null);
        setFood(null);
        setNewRow([]);
        setOptionFG(type);
    }

    const extractRecipeData = (item,variationId="") => {
      const url = BASE_URL + "/settings/get-recipe-report";
      let formData = new FormData();
      formData.append("type_name", optionFG==='property_item' ? "Property" : "item");
      formData.append("item_id", item ? item?.id : "");
      formData.append("variation_id", variationId);

      return axios
      .post(url,formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if(res.data === "Recipe Not Found!"){
          toast.error(res.data);
          return false;
        }
        if(res.data.recipeDetail !== null && res.data.recipeDetail.length > 0){
          let rows = res.data.recipeDetail.map((value)=>{
            return {
              id: value?.id,
              item_id: value?.ingredient_id,
              item_name: value?.item_name,
              qty: parseInt(value?.qty).toFixed(0),
              unit_id: value?.unit_id,
              unit_name: value?.unit_name ? value.unit_name : "Pcs",
            }
          });
          setNewRow([...rows]);        
        }else{
          toast.error("Selected item not contain recipe",common_toast);
        }
      })
      .catch((error) => {
      });
    }

    //handle property/fg items
    const handleSetItem = (item) => {
        setNewRow([]);
        setShowVariation(false);
        if(optionFG==='property_item'){
            setProperty(item);            
            extractRecipeData(item)
        }else{
            setFood(item);
            if(item?.has_variation === "1"){
              setShowVariation(true)              
            }else{
              extractRecipeData(item)
            }
        }
    }

    //select variation
    const handleVariation = (value) => {
      setNewRow([]);
      setVariation(value);  
      extractRecipeData(food,value.variation_id);
    }
  
    //set each item qty
    const handleQty = (index,e) => {
      newRow[index].qty = e.target.value;
      setNewRow([...newRow]);
    };
  
    //handle Set branch hook
    const handleSetBranch = (branch) => {
      setNewWastage({ ...newWastage, branch});        
    };
  
    //handle Set remarks
    const handleSetRemarks = (remarks) => {
      setNewWastage({ ...newWastage, remarks});        
    };

    //post req of stock item add
    const wastageAxios = async (formData) => {    
      setLoading(true)
      const url = BASE_URL + "/settings/stock-wastage";
      
      try {
          const res = await axios.post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });    
        console.log("res data==>",res.data);    
        setWastageItemList(res.data[0]);
        setWastageItemListForSearch(res.data[1]);
        setLoading(false);
        toast.success(`${_t(t("Wastage has been added"))}`,common_toast);
        history.push(
          "/dashboard/manage/stock/wastage-food"
        )
      } catch (error) {        
        console.log("check error==>",error);
        setLoading(false);
        toast.error(`${_t(t("Please try again later"))}`, common_toast);
      }
        
    };

    //send to server
    const handleSubmit = (e) => {
      e.preventDefault();      
      if ((newRow !== null && newRow.length > 0) && newWastage.documentDate && newWastage.branch) {
        let tableData = newRow.map((item)=>{
          let {id,...newItem} = item;
          return newItem;
        });
        let formData={
          document_date: getDate(newWastage?.documentDate),
          branch_id: newWastage?.branch?.id,
          type: optionType === 'raw_material' ? 'raw' : (optionFG==='property_item' ? 'property' : 'food'),
          item_id: optionType === 'raw_material' ? 0 : (optionFG==='property_item' ? property.id : food.id),
          variation: variation ? variation?.variation_id : null,
          variation_name: variation ? variation?.variation_name : null,
          items: tableData,
          remarks: newWastage.remarks,
        };
        console.log("check form==>",formData)
        handleSaveConfirmation(formData);        
      } else {
        toast.error(`${_t(t("Please fill all the required fields"))}`,common_toast);
      }
    };

    //delete confirmation modal of waiter
    const handleSaveConfirmation = (formData) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card card-body">
              <h1>{_t(t("Are you sure?"))}</h1>
              <p className="text-center">{_t(t("You want to save this?"))}</p>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    wastageAxios(formData);
                    onClose();
                  }}
                >
                  {_t(t("Yes, save it!"))}
                </button>
                <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                  {_t(t("No"))}
                </button>
              </div>
            </div>
          );
        },
      });
    };

    const handleDltHandler = (id) => {
      let row=[...newRow]
      row= row.filter((item,i)=>id!==item.id);
      setNewRow(row);
    }

    return (
      <>
        <Helmet>
          <title>{_t(t("Add Wastage Item"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                    <div className={`${(loading) && "loadingBlur"}`}></div>
                    <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* Loading effect */}
                      {/* {loading === true ? (
                        tableLoading()
                      ) : ( */}
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Add Wastage Item"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"
                            onSubmit={handleSubmit}
                          >
                            <div className="col-12">

                              {/* master fields */}
                              <div className="row">
                              <div className="form-group col-4 mt-3">
                                <div className="mb-2">
                                  <label
                                    htmlFor="document no"
                                    className="control-label"
                                  >
                                    {_t(t("Document no"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    readOnly
                                    type="text"
                                    className="form-control table-text"
                                    id="document_no"
                                    name="document_no"                                    
                                    placeholder="Auto no"
                                    required
                                  />
                                </div>
                              </div>

                              <div className="form-group col-4 text-right mt-3">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="document_date"
                                    className="control-label"
                                  >
                                    {_t(t("Document Date"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <DatePicker
                                    selected={newWastage.documentDate || ""}
                                    onChange={(e) => setNewWastage({...newWastage,documentDate:e})}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("document date"))}
                                    shouldCloseOnSelect={false}
                                  />
                                </div>
                              </div>
                              
                              <div className="form-group mt-3 col-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="branch"
                                      className="control-label"
                                    >
                                      {_t(t("Branch"))}
                                      <span className="text-danger">*</span>                                      
                                    </label>
                                  </div>
                                  <Select
                                    options={branchSearch ? branchSearch : []}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={newWastage.branch || null}
                                    classNamePrefix="select"
                                    onChange={handleSetBranch}
                                    maxMenuHeight="200px"
                                    styles={customStyle}
                                    placeholder={
                                      _t(t("Please select a branch")) + ".."
                                    }
                                  />
                              </div> 
                            </div>

                                {/* header with select of raw material */}
                              <div className="row">
                                <div className="form-group mt-4 col-4 d-flex align-items-center">
                                    <div className="d-inline mr-3 d-flex align-items-center">
                                        <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="raw_material"
                                        name="raw_material"
                                        value="raw_material"
                                        onChange={(e) => {
                                            handleOption(e.target.value);
                                        }}
                                        checked={
                                            optionType === "raw_material"
                                            ? 1
                                            : 0
                                        }
                                        />
                                        <label
                                        className="form-radio-label pointer-cursor ml-2 pt-1"
                                        htmlFor="raw_material"
                                        // style={{fontSize:"14px",borderRadius:"5px"}}
                                        >
                                            {_t(t("Raw Material"))}
                                        </label>
                                    </div>

                                    <div className="form-check d-inline mr-2 d-flex align-items-center">
                                        <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="finished_goods"
                                        name="finished_goods"
                                        value="finished_goods"
                                        onChange={(e) => {
                                            handleOption(e.target.value);
                                        }}
                                        checked={
                                            optionType === "finished_goods"
                                            ? 1
                                            : 0
                                        }
                                        />
                                        <label
                                        className="form-radio-label pointer-cursor ml-2 pt-1"
                                        htmlFor="finished_goods"                                        
                                        >
                                         {_t(t("Finished Goods"))}
                                        </label>
                                    </div>
                                </div>  
                              
                                <div className="form-group mt-2 col-8">
                                    <label
                                      className="mb-2 control-label"
                                      htmlFor="remarks"
                                      >
                                          {_t(t("Remarks"))}
                                      </label>
                                      
                                      <input
                                      type="text"
                                      className="form-control table-text"
                                      id="remarks"
                                      name="remarks"
                                      onChange={(e)=>handleSetRemarks(e.target.value)}
                                      value={newWastage.remarks}
                                      placeholder="Remarks"
                                      />
                                 </div>                            
                              </div>

                              {optionType === 'raw_material' && (<div className="row">
                                <div className="form-group mt-3 col-4">                                    
                                    <Select
                                      options={ingredientItemForSearch ? ingredientItemForSearch : []}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      value={raw}
                                      classNamePrefix="select"
                                      onChange={handleRawMaterial}
                                      maxMenuHeight="200px"
                                      styles={customStyle}
                                      placeholder={
                                       _t(t("Please select ingredients")) + ".."
                                      }
                                    />
                                </div>
                              </div>)}


                              {/* finished goods option */}
                              <div className="row">
                                {optionType === 'finished_goods' && <div className="form-group mt-3 col-4 d-flex align-items-center">
                                    <div className="form-check d-inline mr-3 d-flex align-items-center">
                                        <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="food_item"
                                        name="food_item"
                                        value="food_item"
                                        onChange={(e) => {
                                            handleOptionFG(e.target.value);
                                        }}
                                        checked={
                                            optionFG === "food_item"
                                            ? 1
                                            : 0
                                        }
                                        />
                                        <label
                                        className="form-radio-label pointer-cursor ml-2 pt-1"
                                        htmlFor="food_item"                                        
                                        >
                                            {_t(t("Food Item"))}
                                        </label>
                                    </div>

                                    <div className="form-check d-inline mr-2 d-flex align-items-center">
                                        <input
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="property_item"
                                        name="property_item"
                                        value="property_item"
                                        onChange={(e) => {
                                            handleOptionFG(e.target.value);
                                        }}
                                        checked={
                                            optionFG === "property_item"
                                            ? 1
                                            : 0
                                        }
                                        />
                                        <label
                                        className="form-radio-label pointer-cursor ml-2 pt-1"
                                        htmlFor="property_item"                                        
                                        >
                                         {_t(t("Property Item"))}
                                        </label>
                                    </div>
                                </div>}                             
                              </div>

                              {optionFG !== null && (<div className="row">
                                    <div className="form-group mt-3 col-4">                                    
                                        <Select
                                        options={optionFG==='food_item' ? (foodForPOS ? foodForPOS : []) :
                                        (propertyItemList ? propertyItemList : [])}
                                        components={makeAnimated()}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        value={optionFG==='food_item' ? food : property}
                                        classNamePrefix="select"
                                        onChange={handleSetItem}
                                        maxMenuHeight="200px"
                                        styles={customStyle}
                                        placeholder={
                                            optionFG==='food_item' ?
                                            _t(t("Please select food")) + ".." :
                                            _t(t("Please select property item")) + ".."
                                        }
                                        />
                                    </div>
                                </div>)}
                                

                                {/* show variation */}
                                {showVariation && (<div className="mt-3" style={{width:"200px"}}>
                                    <div className="mb-2">
                                        <label
                                            className="control-label"
                                        >
                                            {_t(t("Select Variation"))}
                                            <span className="text-danger">*</span>
                                        </label>
                                        </div>
                                    <div className="d-flex align-items-center gap-4">
                                    {
                                        food?.variation_recipe?.map((item) => (
                                        <div class="form-check mt-1 mx-2" key={item.food_with_variation_id}>
                                            <input class="form-check-input pointer-cursor"                                                
                                                type="radio" 
                                                name="variations"                                                 
                                                id={item.variation_name} 
                                                checked={variation?.variation_name === item.variation_name}
                                                onChange={(e) => handleVariation(item)}
                                                required                                                
                                            /> 
                                            <label class="form-radio-label mx-1 text-capitalize pointer-cursor" htmlFor={item.variation_name}>
                                                {item.variation_name} 
                                            </label>
                                        </div>
                                        ))
                                    }
                                    </div>
                                </div>)}                              
                              
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>                                      
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"35rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Unit"))}
                                          </span>
                                        </th>

                                        {optionType==="raw_material" && <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Action"))}
                                          </span>
                                        </th>  }                                                                           
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >                                            
                                              <td className="table-text text-capitalize align-middle text-center">                                              
                                                {item.item_name}
                                              </td>                                      

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input                                                  
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={item.id}
                                                  name={item.id}
                                                  onChange={(e)=>handleQty(index,e)}
                                                  value={item.qty || ""}
                                                  placeholder="Qty"
                                                  required 
                                                  disabled={optionType==='finished_goods'}                                                 
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">                                                
                                                {item.unit_name}
                                              </td>

                                              {optionType==="raw_material" &&<td className="table-text text-capitalize align-middle text-center" style={{width:"10%"}}>
                                                <MdDelete className="text-danger" style={{cursor: "pointer", fontSize: "20px"}} 
                                                onClick={()=>handleDltHandler(item.id)}
                                                />
                                              </td>}                                                                                       
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}                                    
                                  </table>
                              </div>
                                
  
                              <div className="form-group mt-4 pb-2">                            
                                <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/wastage-food"))}                                
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default WastageFoodAdd;