import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import { UserContext } from "../../../../contexts/User";

const DailyCashSaleReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, generalSettings } = useContext(SettingsContext);
  let { paymentTypeForSearch } = useContext(RestaurantContext);

  let [newDailyCashSaleReport, setNewDailyCashSaleReport] = useState(null);

  //all data
  const [reportData, setReportData] = useState(null);

  const componentRef = useRef();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  const handlePayment = (payment) => {
    setNewDailyCashSaleReport({ ...newDailyCashSaleReport, payment });
    // setBranch(branch);
  };

  let Sales = [];
  if (
    reportData !== null &&
    reportData !== undefined &&
    generatedReport === true
  ) {
    for (const keys in reportData[0]) {
      Sales.push(
        <th
          scope="col"
          className="sm-text text-capitalize align-middle text-center border-1 border"
        >
          {_t(t(keys))}
        </th>
      );
    }
  }
  console.log("categoryWiseSales", Sales);
  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
    setReportData(null);
    console.log("newDailyCashSaleReport ", newDailyCashSaleReport);
    if (
      // newDailyCashSaleReport?.branch?.id !== null &&
      startDate !== null
    ) {
      setLoading(true);
      var fromDate = startDate.toISOString();

      const url = BASE_URL + "/settings/get-daily-cash-sales-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("payment", newDailyCashSaleReport?.payment?.name);

      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("DailyCashSaleReportResult", res);
          setReportData(res.data[0]);

          setLoading(false);
          console.log("Component ", componentRef);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setNewDailyCashSaleReport(null);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date, branch and report group to generate report"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };
  let gross_sale = 0;
  let net_sale = 0;
  let commission = 0;
  let total_tax = 0;
  let total_commission = 0;
  let net_amount = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Daily Cash Sale Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Daily Cash Sale Report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef}
      />

      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {"\
          @page { size: landscape; }\
        "}
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          {console.log("reportData", reportData)}
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="25" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Daily Cash Sale Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>{Sales}</tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here*/}
                                {reportData.map((item, i) => {
                                  return (
                                    <div key={i}>
                                      <tr>
                                        {Sales.map((item_key, a) => {
                                          console.log(
                                            "KEsa diaa ",
                                            Sales[0].props.children
                                          );
                                          return (
                                            <div key={a}>
                                              <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {
                                                  reportData[i][
                                                    item_key.props.children
                                                  ]
                                                }
                                              </td>
                                            </div>
                                          );
                                        })}
                                      </tr>
                                    </div>
                                  );
                                })}
                                <tr>
                                  <th className="sm-text text-capitalize align-middle text-center border-1 border"></th>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(gross_sale)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(net_sale)}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(commission)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_tax)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(total_commission)}
                                  </td>
                                  <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                    {formatPrice(net_amount)}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Daily Cash Sale Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showMonthYearPicker
                              className="sm-text form-control py-1"
                              style={{ zIndex: "10" }}
                              placeholderText={_t(t("Pick a month")) + ".."}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={
                                paymentTypeForSearch && paymentTypeForSearch
                              }
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              //value={branch}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handlePayment}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Select a payment type")) + ".."
                              }
                            />
                          </div>
                          <div className="col-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            {/* Action Button */}
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected(e)}
                            >
                              Print Daily Cash Sales Report
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default DailyCashSaleReport;
