import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const RiderReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, dataPaginating,setDataPaginating, generalSettings } = useContext(SettingsContext);
  const { deliveryForSearch } = useContext(UserContext);
  const { branchForSearch } = useContext(RestaurantContext);
  console.log("abcd4",branchForSearch)
  //all data
  const [reportData, setReportData] = useState(null);
  const [reportDataTable, setreportDataTable] = useState(null);
  const [reportActivityData, setReportActivityData] = useState(null);
  // const [reportDataPerformance, setReportDataPerformance] = useState(null);
  // const [reportDataPerformanceTable, setreportDataPerformanceTable] = useState(null);
  const componentRef = useRef();
  const componentRef1 = useRef();
  const componentRef2 = useRef();
  const componentRef3 = useRef();
  const componentRef4 = useRef();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [activityReport, setActivityReport] = useState(false);
  const [generatedPerformanceReport, setGeneratedPerformanceReport] = useState(false);
  const [generatedReportSummary, setGeneratedReportSummary] = useState(false);
  const [generatedReportDetail, setGeneratedReportDetail] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const [viewPerformanceReport, setViewPerformanceReport] = useState(false);
  const [viewSummaryReport, setViewSummaryReport] = useState(false);
  const [viewDetailReport, setViewDetailReport] = useState(false);

  const [siteName, setSiteName] = useState("");
  const [rider, setRider] = useState(null);
  const [branch, setBranch] = useState(null);
  const [riderOption, setRiderOption] = useState("view");
  const [reportType,setReportType] = useState([{id:1,name:"Rider Report"},
    {id:2,name:"Rider Performance"},
    {id:3,name:"Rider Fuel Summary"},
    {id:4,name:"Rider Fuel Detail"}
  ]);
  const [showReportBtn,setShowReportBtn]= useState(null);

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
  }, [generalSettings]);

  const handleRider = (rider) =>{
   setRider(rider)
  }

  const handleReportType = (btn) => {
    setShowReportBtn(btn);
  }

  const handleBranch = (branches) => {
    setBranch(branches);
  }

  const handleRiderOption  = (e) =>{
    setRiderOption(e.target.value)
  }

   //get BranchWise reports filter
   const getRiderActivity = () => {
    setActivityReport(false);
    setReportActivityData(null)
    if (
      // newRiderReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null &&
      branch 
    ) {
      setDataPaginating(true);
      const fromDate = moment(startDate).format("YYYY-MM-DD");
      const toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/rider-activity-report";
      let formData = new FormData();
      formData.append("from_date", fromDate);
      formData.append("to_date", toDate);
      formData.append("branch_id", branch?.id ? branch?.id : "");
      formData.append("rider_id", rider?.id ? rider.id : "");

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setActivityReport(true);
          setReportActivityData(res.data);
          componentRef4.current.handleDownload();

          setDataPaginating(false);
          setReportActivityData(null);
          setBranch(null);
          setRider(null);
        })
        .catch((error) => {
          setDataPaginating(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select date and branch"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  //get BranchWise reports filter
  const getRiderWiseSelected = () => {
    setReportData(null);
    setreportDataTable(null)
    if (
      // newRiderReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null
    ) {
      setDataPaginating(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-rider-detail-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("rider", rider?.id ? rider?.id : null);

      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("RiderReportResult", res);
          if(riderOption == "view"){
            setGeneratedReport(false);
            setGeneratedPerformanceReport(false)
            setGeneratedReportSummary(false)
            setGeneratedReportDetail(false)
            setViewPerformanceReport(false)
            setViewReport(true);
            setViewSummaryReport(false)
            setViewDetailReport(false)
            setreportDataTable(res.data[0])
          }else{
            setViewReport(false);
            setViewSummaryReport(false)
            setViewDetailReport(false)
            setGeneratedPerformanceReport(false)
            setGeneratedReportSummary(false);
            setGeneratedReportDetail(false);
            setViewPerformanceReport(false)
            setReportData(res.data[1]);
            setGeneratedReport(true);
            componentRef.current.handleDownload();
          }
          setDataPaginating(false);
          setRider(null)
        })
        .catch((error) => {
          setDataPaginating(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

   //get BranchWise reports filter
   const getRiderPerformance = () => {
    setReportData(null);
    setreportDataTable(null)
    if (
      // newRiderReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null
    ) {
      setDataPaginating(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-rider-performance-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("rider", rider?.id ? rider?.id : null);

      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("RiderReportResult", res);
          if(riderOption == "view"){
            setGeneratedReport(false);
            setViewReport(false);
            setViewSummaryReport(false)
            setViewDetailReport(false)
            setGeneratedPerformanceReport(false)
            setGeneratedReportSummary(false)
            setGeneratedReportDetail(false)
            setViewPerformanceReport(true)
            setreportDataTable(res.data[0])
          }else{
            setViewReport(false);
            setViewPerformanceReport(false)
            setViewSummaryReport(false)
            setViewDetailReport(false)
            setGeneratedReport(false);
            setGeneratedReportSummary(false)
            setGeneratedReportDetail(false)
            setReportData(res.data[1]);
            setGeneratedPerformanceReport(true)
            componentRef1.current.handleDownload();
          }
          setDataPaginating(false);
          setRider(null)
        })
        .catch((error) => {
          setDataPaginating(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  const getRiderFuelSummary = () => {
    setReportData(null);
    setreportDataTable(null)
    if (
      // newRiderReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null
    ) {
      setDataPaginating(true);
      var fromDate = moment(startDate).format("YYYY-MM-DD");
      var toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/rider-fuel-summary-report";
      // let formData = new FormData();
      // formData.append("fromDate", fromDate.toString());
      // formData.append("toDate", toDate.toString());
      // formData.append("rider", rider ? rider.id : null);

      // console.log("formData ", formData);
      let formData={
        "rider":rider ? rider.id : null,
        "fromDate": fromDate,
        "toDate": toDate
      }

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("RiderReportResult", res.data);
          if(riderOption == "view"){
            setGeneratedReport(false);
            setViewReport(false);
            setViewSummaryReport(true)
            setViewDetailReport(false)
            setGeneratedPerformanceReport(false)
            setGeneratedReportSummary(false)
            setGeneratedReportDetail(false)
            setViewPerformanceReport(false)
            setreportDataTable(res.data)
          }else{            
            setViewReport(false);
            setViewPerformanceReport(false)
            setViewSummaryReport(false)
            setViewDetailReport(false);
            setGeneratedPerformanceReport(false)
            setGeneratedReport(false);
            setGeneratedReportDetail(false)
            setReportData(res.data);
            setGeneratedReportSummary(true)
            componentRef2.current.handleDownload();
          }
          setDataPaginating(false);
          setRider(null)
        })
        .catch((error) => {
          setDataPaginating(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  const getRiderFuelDetail = () => {
    setReportData(null);
    setreportDataTable(null)
    if (
      // newRiderReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null &&
      rider !== null
    ) {
      setDataPaginating(true);
      const fromDate = moment(startDate).format("YYYY-MM-DD");
      const toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/rider-fuel-detail-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("rider", rider?.id ? rider?.id : null);

      // console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("RiderReportResult", res);
          if(riderOption == "view"){
            setGeneratedReport(false);
            setViewReport(false);
            setViewSummaryReport(false)
            setViewDetailReport(true)
            setGeneratedPerformanceReport(false)
            setGeneratedReportSummary(false)
            setGeneratedReportDetail(false)
            setViewPerformanceReport(false)
            setreportDataTable(res.data)
          }else{            
            setViewReport(false);
            setViewPerformanceReport(false)
            setViewSummaryReport(false)
            setViewDetailReport(false);
            setGeneratedReport(false);
            setGeneratedReportSummary(false)
            setGeneratedReportDetail(true)
            setReportData(res.data);
            setGeneratedPerformanceReport(false)
            componentRef3.current.handleDownload();
          }
          setDataPaginating(false);
          setRider(null)
        })
        .catch((error) => {
          setDataPaginating(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date and rider"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  let g_total = 0;
  let Dcharges = 0;
  let g_noOfOrder = 0;
  let order_bill = 0;
  let service_charge = 0;
  let rider_total_amount = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Rider Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls1"
        filename="Rider Report"
        sheet="sheet 1"
        buttonText="rider Detail"
        ref={componentRef}
      />
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Rider Performance Report"
        sheet="sheet 1"
        buttonText="rider Detail"
        ref={componentRef1}
      />
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="fuel-summary"
        filename="Rider Fuel Summary"
        sheet="sheet 1"
        buttonText="rider Detail"
        ref={componentRef2}
      />
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="fuel-detail"
        filename="Rider Fuel Details"
        sheet="sheet 1"
        buttonText="rider Detail"
        ref={componentRef3}
      />
       <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="activity-detail"
        filename="Rider Activity Details"
        sheet="sheet 1"
        buttonText="rider Detail"
        ref={componentRef4}
      />

       {/* Print Button rider activity*/}
       <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportActivityData !== null &&
                    reportActivityData !== undefined &&
                    activityReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="activity-detail"
                            >
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="13" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Rider Activity Report
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          {branch && branch?.name}
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>

                                <tr></tr>

                                <tr>
                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Rider Name"),)}
                                  </th>

                                  <th scope="col">
                                    {_t(t("S/L"),)}
                                  </th>

                                  <th
                                  scope="col"
                                  >
                                    {_t(t("Order ID"))}
                                  </th>
                                 
                                  <th
                                  scope="col"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  
                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Total Amount"),)}
                                  </th>
                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Order Status"),)}
                                  </th>
                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Payment Type"),)}
                                  </th>
                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Order Pickup Time"),)}
                                  </th>

                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Delivery Time"),)}
                                  </th>

                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Actual Ride"),)}
                                  </th>

                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Estimated Ride"),)}
                                  </th>
                                  
                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Total KM Travelled"),)}
                                  </th>

                                  <th
                                    scope="col"
                                    >
                                    {_t(t("Amount Collected"),)}
                                  </th>
                                  
                                  </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here*/}
                                {reportActivityData && Object.keys(reportActivityData).map((group_name, index) => {
                                  rider_total_amount= 0;

                                    return (
                                     <>
                                      <tr key={index}>
                                        <th
                                          scope="row"
                                        >{group_name}</th>
                                      </tr>
                                      {reportActivityData[group_name].map((item,index)=>{
                                        rider_total_amount += Number(item.amount);
                                        return (
                                          <tr key={index}>
                                            <td></td>
                                            <td
                                              scope="row"
                                            >
                                              {index + 1}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.order_id}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              <Moment format="DD-MMM-YYYY">{item.order_date}</Moment>
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.amount}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.order_status || "-"}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.payment_type || "-"}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.pickup_time || '-'}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.delivery_time || '-'}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.total_ride_actual_time ? (item.total_ride_actual_time + " min") : '-'}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {(item.total_ride_time) || '-'}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.total_km_travel || '-'}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.total_amount}
                                            </td>
                                          </tr>
                                        );
                                      })}  
                                     <tr>
                                      <th colSpan={11}></th>
                                      <th>Total</th>
                                      <th>{rider_total_amount}</th>
                                     </tr> 
                                     <tr></tr>   
                                     </>
                                  )}
                                )}
                              </tbody>
                             
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
       </div>

       {/* Print Button rider performance*/}
       <div className="d-none">
       <div>
         <style type="text/css" media="print">
           {
             "\
         @page { size: landscape; }\
       "
           }
         </style>
         <div className="fk-print">
           <div className="container">
             <div className="row">
               <div className="col-12">
                 {reportData !== null &&
                  reportData !== undefined &&
                  generatedPerformanceReport === true && (
                     <>
                       <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                         <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                           <table
                             className="table table-bordered table-hover min-table-height mt-3"
                             id="table-to-xls"
                           >
                             <div className="page-break"></div>
                             <thead className="align-middle">
                               <tr style={{ border: "0px" }}>
                                 <th colspan="2" style={{ border: "0px" }}>
                                   <div className="row">
                                     <div className="col-12">
                                       <h3 className="text-center mb-0">
                                         {siteName}
                                       </h3>
                                       <h3 className="text-center mt-0">
                                         Rider Performance Report
                                       </h3>
                                       <p className="text-center">
                                         FROM&nbsp;
                                         <Moment format="LL">
                                           {startDate}
                                         </Moment>
                                         &nbsp;TO&nbsp;
                                         <Moment format="LL">{endDate}</Moment>
                                       </p>
                                     </div>
                                   </div>
                                 </th>
                               </tr>
                             </thead>
                             <tbody className="align-middle">
                               {console.log("309 ", reportData)}
                               {/* loop here*/}
                               {Object.keys(reportData).map(
                                 (group_name, index) => {
                                   return (
                                     <div key={index}>
                                       <tr>
                                         <th
                                          colSpan={2}
                                           scope="row"
                                           className="lg-text text-capitalize align-middle text-center"
                                         >
                                           {group_name}
                                         </th>
                                       </tr>
                                       {reportData[group_name]?.map(
                                         (item, i) => {
                                           return (
                                            <>
                                             <tr>
                                             <th
                                             scope="col"
                                             className="sm-text text-capitalize align-middle text-center border-1 border"
                                             >
                                              {_t(t("No of orders"))}
                                            </th>
                                            <td
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.no_of_orders}
                                            </td>
                                             </tr>
                                             <tr>
                                             <th
                                             scope="col"
                                             className="sm-text text-capitalize align-middle text-center border-1 border"
                                             >
                                              {_t(t("D.charges"))}
                                            </th>
                                            <td
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                             >
                                                {item.delivery_charges}
                                             </td>
                                            </tr>
                                            <tr>
                                            <th
                                              scope="col"
                                              className="sm-text text-capitalize align-middle text-center border-1 border"
                                              >
                                              {_t(t("Net Amount"))}
                                            </th>
                                            <td
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {formatPrice(item.total)}
                                              </td>
                                            </tr>
                                            </>
                                           );
                                         }
                                       )}
                                     </div>
                                   );
                                 }
                               )}
                             </tbody>
                             <tfoot style={{ border: "0px" }}>
                               <tr style={{ border: "0px" }}>
                                 <td
                                   colspan="7"
                                   className="text-center"
                                   style={{ border: "0px" }}
                                 >
                                   <h5 className="mt-3">
                                     Powered by indolj.pk
                                   </h5>
                                 </td>
                               </tr>
                             </tfoot>
                           </table>
                         </div>
                       </div>
                     </>
                   )}
               </div>
             </div>
           </div>
         </div>
       </div>
       </div>

      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          {console.log("reportData", reportData)}
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls1"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="11" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Rider Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  ></th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Order"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Customer"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Cell #"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Address"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Dispatch Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Delivery Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("D.Charges"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Net Amount"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {console.log("309 ", reportData)}
                                {/* loop here*/}
                                {Object.keys(reportData).map(
                                  (group_name, index) => {
                                    let DCharge = 0;
                                    let total = 0;

                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {reportData[group_name]?.map(
                                          (item, i) => {
                                            g_total += parseFloat(item.total_payable);
                                            Dcharges += parseFloat(item.delivery_charges)
                                            DCharge += parseFloat(item.delivery_charges)
                                            total += parseFloat(item.total_payable)
                                            return (
                                              <tr>
                                                <td></td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >
                                                  {i + 1}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  <Moment format="LL">
                                                    {item.order_date}
                                                  </Moment>
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.token.id}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.customer_name}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                 {item.delivery_phn_no}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                 {item.delivery_address}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.punching_time}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.ready_time}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.delivery_charges}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.total_payable}
                                                </td>
                                               
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                          <th colspan="8"></th>
                                          <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                            Total
                                          </th>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(DCharge)}
                                          </td>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(total)}
                                          </td>
                                          {/*
                                          <td>After Tax Price</td>
                                        <td>{formatPrice(parseFloat(sum) + parseFloat(tax_total))}</td>*/}
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                                {
                                  <>
                                  <tr>
                                    <th colspan="8"></th>
                                    <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                      Grand Total
                                    </th>
                                    <td className="xsm-text align-middle text-center">
                                      {formatPrice(Dcharges)}
                                    </td>
                                    <td>{formatPrice(g_total)}</td>
                                  </tr>
                                </>
                                }
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Fuel Summary */}
      <div className="d-none">
        <div>         
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReportSummary === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          {console.log("reportData", reportData)}
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="fuel-summary"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="5" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Rider Fuel Summary
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >{_t(t("S/L"))}</th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Rider Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Spent"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Given"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Balance"))}
                                  </th>                                                                    
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {console.log("309 ", reportData)}
                                {/* loop here*/}
                                {Object.keys(reportData).map(
                                  (rider_name, index) => {                                    
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {index + 1}
                                          </td>
                                        
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >                                                  
                                                  {rider_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >                                                  
                                                  {reportData[rider_name].spent}                                                  
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {reportData[rider_name].given}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {reportData[rider_name].balance}
                                                </td>                                                                                                                                             
                                        </tr>
                                            
                                        {/* <tr>
                                          <th colspan="8"></th>
                                          <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                            Total
                                          </th>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(DCharge)}
                                          </td>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(total)}
                                          </td>
                                          
                                          <td>After Tax Price</td>
                                        <td>{formatPrice(parseFloat(sum) + parseFloat(tax_total))}</td>
                                        </tr> */}
                                      </div>
                                    );
                                  }
                                )}
                                {/* {
                                  <>
                                  <tr>
                                    <th colspan="8"></th>
                                    <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                      Grand Total
                                    </th>
                                    <td className="xsm-text align-middle text-center">
                                      {formatPrice(Dcharges)}
                                    </td>
                                    <td>{formatPrice(g_total)}</td>
                                  </tr>
                                </>
                                } */}
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Fuel Detail */}
      <div className="d-none">
        <div>         
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReportDetail === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">                          
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="fuel-detail"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="8" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Rider Fuel Details
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                        <p></p>
                                        <p className="text-center">
                                          Orders
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>                                  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Order No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Customer Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Rider Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Bill Amount"))}
                                  </th>                                  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Delivery Charges"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Delivery Address"))}
                                  </th>                                   
                                </tr>
                              </thead>
                              <tbody className="align-middle">                                
                                {reportData[Object.keys(reportData)[0]].map(                                 
                                  (item, index) => {  
                                    order_bill += parseFloat(item.order_bill)
                                    service_charge += item.service_charge                                  
                                    return (
                                      <div key={index}>                                                                               
                                          <tr>                                                
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >                                                  
                                                  {item.token}                                                  
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  <Moment format="LL">
                                                    {item.order_date}
                                                  </Moment>
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.customer_name}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                 {item.rider_name}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                 {item.order_bill}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.service_charge}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.delivery_address}
                                                </td>                                               
                                          </tr>                                                                                    
                                      </div>
                                    );
                                  }
                                )}
                                <>
                                <tr>
                                          <th colspan="4"></th>
                                          <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                            Total
                                          </th>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(order_bill)}
                                          </td>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(service_charge)}
                                          </td>
                                          
                                          {/* <td>After Tax Price</td>
                                        <td>{formatPrice(parseFloat(sum) + parseFloat(tax_total))}</td> */}
                                        </tr>
                                        </>
                                {/* {
                                  <>
                                  <tr>
                                    <th colspan="8"></th>
                                    <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                      Grand Total
                                    </th>
                                    <td className="xsm-text align-middle text-center">
                                      {formatPrice(Dcharges)}
                                    </td>
                                    <td>{formatPrice(g_total)}</td>
                                  </tr>
                                </>
                                } */}
                              </tbody>
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr></tr>
                                <tr style={{ border: "0px" }}>
                                  <th colspan="8" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">                                                                       
                                        <p className="text-center mt-0">
                                          Expenses
                                        </p>                                        
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>                                                                   
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Invoice No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Rider Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Total Amount"))}
                                  </th>                                                                  
                                </tr>
                              </thead>
                              <tbody className="align-middle">                                
                                {reportData[Object.keys(reportData)[1]].map(
                                  (item, index) => {
                                    return (
                                      <div key={index}>                                                                               
                                          <tr>                                                                                           
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >                                                  
                                                  <Moment format="LL">
                                                    {item.document_date}
                                                  </Moment>                                                
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.document_identity}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.rider_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.amount}
                                                </td>                                                                                                                                            
                                          </tr>
                                            
                                        {/* <tr>
                                          <th colspan="8"></th>
                                          <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                            Total
                                          </th>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(DCharge)}
                                          </td>
                                          <td className="sm-text text-capitalize align-middle text-center border-1 border">
                                            {formatPrice(total)}
                                          </td>
                                          
                                          <td>After Tax Price</td>
                                        <td>{formatPrice(parseFloat(sum) + parseFloat(tax_total))}</td>
                                        </tr> */}
                                      </div>
                                    );
                                  }
                                )}
                                {/* {
                                  <>
                                  <tr>
                                    <th colspan="8"></th>
                                    <th className="sm-text text-capitalize align-middle text-center border-1 border">
                                      Grand Total
                                    </th>
                                    <td className="xsm-text align-middle text-center">
                                      {formatPrice(Dcharges)}
                                    </td>
                                    <td>{formatPrice(g_total)}</td>
                                  </tr>
                                </>
                                } */}
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                   
                    {/* spin loading ends */}

                    {/* Loading effect {loading === true ? (
                      tableLoading()
                    ) : ( */}
                    
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Rider Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>

                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <Select
                              options={branchForSearch ? branchForSearch : []}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="select"
                              className="sm-text "
                              value={branch || ""}
                              styles={customStyle}
                              onChange={handleBranch}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                              isClearable
                            />
                          </div>
                         
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <Select
                              options={deliveryForSearch && deliveryForSearch}
                              getOptionLabel={(option) => option.name + "-" + option?.phn_no}
                              getOptionValue={(option) => option.name}
                              classNamePrefix="select"
                              className="sm-text "
                              value={rider || ""}
                              styles={customStyle}
                              onChange={handleRider}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a rider")) + ".."}
                              isClearable
                            />
                          </div>

                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <Select
                              options={reportType}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              classNamePrefix="select"
                              className="sm-text"
                              value={showReportBtn}
                              styles={customStyle}
                              onChange={handleReportType}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select Report Type")) + ".."}
                              isClearable
                            />
                          </div>
                          <div className="col-2 d-flex align-items-center">
                            
                            <div className="d-inline mr-2">
                            <input
                              type="radio"
                              className="form-check-input pointer-cursor"
                              id="view_report"
                              name="view_report"
                              value="view"
                              onChange={(e) => {
                                handleRiderOption(e);
                              }}
                              checked={
                                riderOption === "view"
                                  ? 1
                                  : 0
                              }
                            />
                            <label
                              className="form-radio-label pointer-cursor ml-2"
                              htmlFor="view_report"
                            >
                              {_t(t("View"))}
                            </label>
                          </div>

                          <div className="d-inline mr-2">
                            <input
                              type="radio"
                              className="form-check-input pointer-cursor"
                              id="download_report"
                              name="download_report"
                              value="download"
                              onChange={(e) => {
                                handleRiderOption(e);
                              }}
                              checked={
                                riderOption === "download"
                                  ? 1
                                  : 0
                              }
                            />
                            <label
                              className="form-radio-label pointer-cursor ml-2"
                              htmlFor="download_report"
                            >
                              {_t(t("Download"))}
                            </label>
                          </div>
                          </div>

                        </div>
                        
                        <div className="row gx-2 mt-2">
                          <div className="col-8"></div>
                          <div className="col-2">
                            <button
                              className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getRiderActivity(e)}
                              >
                              Rider Activity Report
                              </button>
                          </div>
                          {showReportBtn?.name==="Rider Report" && <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                          <button
                          className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                          onClick={(e) => getRiderWiseSelected(e)}
                          >
                            Rider Report
                          </button>
                          </div>}
                          {showReportBtn?.name==="Rider Performance" && <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                          <button
                          className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                          onClick={(e) => getRiderPerformance(e)}
                          >
                            Rider Performance
                          </button>
                        </div>}
                        {showReportBtn?.name==="Rider Fuel Summary" && <div className="col-2">
                          <button
                          className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                          onClick={(e) => getRiderFuelSummary(e)}
                          >
                            Rider Fuel Summary
                          </button>
                          </div>}
                          {showReportBtn?.name==="Rider Fuel Detail" && <div className="col-2">
                          <button
                          className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                          onClick={(e) => getRiderFuelDetail(e)}
                          >
                            Rider Fuel Details
                          </button>
                          </div>}
                        </div>

                        
                      </div>
                   
                    {/* rider performance*/}
                     {
                      viewPerformanceReport === true && reportDataTable && (
                           <div className="mt-3">
                            <div className="text-center xslg-text">
                              Rider Performance Report
                            </div>
                            <div className="fk-scroll--order-submitted" data-simplebar>
                            <div className="t-pl-15 t-pr-15">
                              <div className="">
                                <table className="table table-bordered table-hover min-table-height mt-4">
                                  <thead className="align-middle">
                                  <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Rider Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("No of orders"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Delivery Charges"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Net Amount"))}
                                  </th>
                                  </tr>
                                  </thead>
                                  <tbody className="align-middle">
                                  {console.log("309 ", reportDataTable)}
                                  {/* loop here*/}
                                    {reportDataTable?.map(
                                        (item, i) => {
                                        return (
                                            <tr>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center "
                                            >
                                                {i + 1}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.delivery_boy_name}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.no_of_orders}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.delivery_charges}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {formatPrice(item.total)}
                                            </td>
                                            
                                            </tr>
                                        );
                                        }
                                    )}                                 
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          </div>   
                        )
                      }
                      {
                        viewReport === true && reportDataTable && (
                           <div className="mt-3">
                            <div className="text-center xslg-text">
                              Rider Report
                            </div>
                            <div className="fk-scroll--order-submitted" data-simplebar>
                            <div className="t-pl-15 t-pr-15">
                              <div className="">
                                <table className="table table-bordered table-hover min-table-height mt-4">
                                  <thead className="align-middle">
                                  <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Order"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Customer"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Cell #"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Address"))}
                                    </th>
                                    <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                    >
                                     {_t(t("Delivery Boy"))}
                                    </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Dispatch Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Delivery Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("D.Charges"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Net Amount"))}
                                   </th>
                                  </tr>
                                  </thead>
                                  <tbody className="align-middle">
                                  {console.log("309 ", reportDataTable)}
                                  {/* loop here*/}
                                    {reportDataTable?.map(
                                        (item, i) => {
                                        return (
                                            <tr>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center "
                                            >
                                                {i + 1}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                <Moment format="LL">
                                                {item.order_date}
                                                </Moment>
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.token.id}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.customer_name}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.delivery_phn_no}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.delivery_address}
                                            </td>
                                            <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {item.delivery_boy_name}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.punching_time}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.ready_time}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.delivery_charges}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item.total_payable}
                                            </td>
                                            
                                            </tr>
                                        );
                                        }
                                    )}                                 
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          </div>   
                        )
                      }
                      {/* fuel summary */}
                      {
                        viewSummaryReport === true && reportDataTable && (
                           <div className="mt-3">
                            <div className="text-center xslg-text">
                              Rider Fuel Summary Report
                            </div>
                            <div className="fk-scroll--order-submitted" data-simplebar>
                            <div className="t-pl-15 t-pr-15">
                              <div className="">
                                <table className="table table-bordered table-hover min-table-height mt-4">
                                  <thead className="align-middle">
                                  <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Rider Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Spent"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Given"))}
                                  </th>   
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Balance"))}
                                  </th>                                  
                                  </tr>
                                  </thead>
                                  <tbody className="align-middle">                                 
                                    {Object.keys(reportDataTable)?.map(
                                        (item, i) => {
                                        return (
                                            <tr>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center "
                                            >
                                                {i + 1}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {item}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {reportDataTable[item].spent}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {reportDataTable[item].given}
                                            </td>
                                            <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                            >
                                                {reportDataTable[item].balance}
                                            </td>                                                                                                                               
                                            </tr>
                                        );
                                        }
                                    )}                                 
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          </div>   
                        )
                      }
                      {/* fuel detail */}
                      {
                        viewDetailReport === true && reportDataTable && (
                           <div className="mt-3">
                            <div className="text-center xslg-text">
                              Rider Fuel Detail Report
                            </div>
                            <div className="fk-scroll--order-submitted" data-simplebar>
                            <div className="t-pl-15 t-pr-15">                                                           
                                <table className="table table-bordered table-hover min-table-height mt-4">                                  
                                  <thead className="align-middle">
                                  <tr>                                  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Order No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Customer Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Rider Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Bill Amount"))}
                                  </th>                                  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Delivery Charges"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Delivery Address"))}
                                  </th>                                   
                                </tr>
                                  </thead>
                                  <tbody className="align-middle">                                  
                                  {reportDataTable[Object.keys(reportDataTable)[0]].map(
                                  (item, index) => {                                    
                                    return (                                                                                                                  
                                          <tr>                                                
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >                                                  
                                                  {item.token}                                                  
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  <Moment format="LL">
                                                    {item.order_date}
                                                  </Moment>
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.customer_name}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                 {item.rider_name}
                                                </td>
                                                <td
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                 {item.order_bill}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.service_charge}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.delivery_address}
                                                </td>                                               
                                          </tr>                                                                                                                          
                                    );
                                  }
                                )}                                 
                                  </tbody>
                                </table>
                                <table className="table table-bordered table-hover min-table-height mt-4">                                  
                                  <thead className="align-middle">
                                  <tr>                                  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Invoice No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Rider Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Total Amount"))}
                                  </th>                                                                   
                                </tr>
                                  </thead>
                                  <tbody className="align-middle">                                  
                                  {reportDataTable[Object.keys(reportDataTable)[1]].map(
                                  (item, index) => {                                    
                                    return (                                                                                                                  
                                          <tr>                                                
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center "
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >                                                  
                                                  <Moment format="LL">
                                                    {item.document_date}
                                                  </Moment>                                                
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.document_identity}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.rider_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {item.amount}
                                                </td>                                              
                                          </tr>                                                                                                                          
                                    );
                                  }
                                )}                                 
                                  </tbody>
                                </table>                              
                            </div>
                          </div>
                          </div>   
                        )
                      }
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>

    </>
  );
};

export default RiderReport;
