import React, { useEffect, useContext, useState, useRef, Fragment } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  getSystemSettings,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";

const RecipeReport = () => {
    const { t } = useTranslation();
    //getting context values here
    let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
    let { ingredientItemForSearch } = useContext(RestaurantContext);
    let { foodForPOS, propertyItemForSearch } = useContext(FoodContext);      
  
    //all data
    const [reportData, setReportData] = useState(null);
    const [reportItemData, setReportItemData] = useState(null);
    const componentRef = useRef();
    const componentRefThermal = useRef();
    const componentRef1 = useRef();
    const componentRefThermal1 = useRef();
    const [type, setType] = useState(null);
    const [variation, setVariartion] = useState(null);
    const [item, setItem] = useState(null); 
    const [generatedReport, setGeneratedReport] = useState(false);
    const [generatedItemReport, setGeneratedItemReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [option,setOption] = useState("excel");
    const recipeType = [
        {
            id:1,
            name: "item"
        },
        {
            id:2,
            name: "Sub Recipe"
        },
        {
            id:3,
            name: "Property"
        },
    ];

    const recipesStatus = [
      {
          id:"all",
          name: "All"
      },
      {
          id: "Made",
          name: "Made"
      },
      {
          id: "Not Found",
          name: "Un Made"
      },
  ]

  const [recipeStatus, setRecipeStatus] = useState(recipesStatus[0]); 
  const [qty, setQty] = useState(1);

    useEffect(() => {
        setSiteName(getSystemSettings(generalSettings, "siteName"));
        setType(recipeType[0])
    
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, [generalSettings]);

      const thermalPrint = useReactToPrint({
        content: () => componentRefThermal.current,
      });

      const thermalItemPrint  = useReactToPrint({
        content: () => componentRefThermal1.current,
      });

      const handleType=(type)=>{
        setType(type);
        setItem(null)
      }

      const handleRecipeStatus=(recipe)=>{
        setRecipeStatus(recipe)
      }

      const handleItem=(item)=>{
        setItem(item)
      }

      const handleOption  = (e) =>{
        setOption(e.target.value)
      }

      //select variation
      const handleVariation = (value) => {
        setVariartion(value);
      }

      
      //get BranchWise reports filter
      const getBranchItemSelected = () => {
        setReportItemData(null);
        setGeneratedItemReport(false)   
        if (type !== null) {
          setDataPaginating(true);
          const url = BASE_URL + "/settings/get-itemLinkRecipe-report";
          let formData = new FormData();
          formData.append("type_name", type?.name);
    
          return axios
            .post(url,formData, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setDataPaginating(false);
              setItem(null);
              setVariartion(null);
              setGeneratedItemReport(true);
              setReportItemData(res.data)
              if(option==="excel"){
                componentRef1.current.handleDownload();
              }else{
                thermalItemPrint();
              }
              setQty(1)
            })
            .catch((error) => {
              setDataPaginating(false);
            });
        } else {
          toast.error(
            `${_t(t("Please select type and item"))}`,
            {
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        }
      };
    
      //get BranchWise reports filter
      const getBranchWiseSelected = () => {
        setReportData(null);
        setGeneratedReport(false)   
        if (        
          type !== null && item &&
          (item.has_variation === "1" ? (variation) : true)
        ) {
          setDataPaginating(true);
          const url = BASE_URL + "/settings/get-recipe-report";
          let formData = new FormData();
          formData.append("type_name", type?.name);
          formData.append("item_id", item ? item?.id : "");
          formData.append("variation_id", variation ? variation.variation_id : "");
    
          return axios
            .post(url,formData, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setDataPaginating(false);
              setItem(null);
              setVariartion(null);
              if(res.data === "Recipe Not Found!"){
                toast.error(res.data);
                return false;
              }
              setGeneratedReport(true);
              setReportData(res.data)
              if(option==="excel"){
                componentRef.current.handleDownload();
              }else{
                thermalPrint();
              }
              setQty(1)
            })
            .catch((error) => {
              setDataPaginating(false);
            });
        } else {
          toast.error(
            `${_t(
              t(
                "Please select type and item"
              )
            )}`,
            {
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        }
      };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Recipe Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Recipe Report"
          sheet="sheet 1"
          buttonText="Recipe Detail"
          ref={componentRef}
        />  

        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-xls"
          filename="Item Recipe Report"
          sheet="sheet 1"
          buttonText="Recipe Item Detail"
          ref={componentRef1}
        />       
  
        {/* Excel Report*/}
        <div className="d-none">
          <div>           
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {reportData !== null &&
                      reportData !== undefined &&
                      generatedReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                                className="table table-bordered table-hover min-table-height mt-3"
                                id="table-to-xls"
                              >
                                <div className="page-break"></div>
                                <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                    <th colspan={reportData.recipe.type === "Sub Recipe" ? "5" : "4" } style={{ border: "0px" }}>
                                      <div className="row">
                                        <div className="col-12">
                                          <h3 className="text-center mb-0">
                                            {siteName}
                                          </h3>
                                          <h3 className="text-center mt-0">
                                            Recipe Report
                                          </h3>
                                        </div>
                                      </div>
                                    </th>
                                  </tr>                                
                                </thead>

                                <thead className="align-middle">
                                  <tr>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Item Type"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Item Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Qty"))}
                                    </th>
                                    {
                                      reportData.recipe.type === "Sub Recipe" ? (
                                        <Fragment>
                                          <th
                                            scope="col"
                                          >
                                            {_t(t("Unit"))}
                                          </th>
                                        </Fragment>
                                      ) : (
                                        <th
                                          scope="col"
                                        >
                                          {_t(t("Variation"))}
                                        </th>
                                        )
                                      }
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Remarks"))}
                                    </th>
                                  
                                  </tr>
                                </thead>
                                <tbody className="align-middle">
                                    <tr>
                                      <td
                                        scope="col"
                                      >
                                        {reportData.recipe.type}
                                      </td>
                                      <td
                                        scope="col"
                                      >
                                        {reportData.recipe.item}
                                      </td>
                                      <td
                                        scope="col"
                                      >
                                        {qty ? qty : 1}
                                      </td>
                                      {
                                        reportData.recipe.type === "Sub Recipe" ? (
                                          <Fragment>
                                              <td
                                                scope="col"
                                              >
                                                {reportData.recipe.unit_name}
                                              </td>
                                          </Fragment>
                                        ) : (
                                          <td
                                            scope="col"
                                          >
                                            {reportData.recipe.variation ? reportData.recipe.variation : "-"}
                                          </td>
                                        )
                                      }
                                    
                                      <td
                                        scope="col"
                                      >
                                        {reportData.recipe.remarks}
                                      </td>
                                    </tr>
                                </tbody>

                                {reportData && reportData.recipeDetail?.length > 0 && <div>
                                  <thead className="align-middle">
                                    <tr>
                                      <th
                                        scope="col"
                                      >
                                        {_t(t("Ingredient Item"))}
                                      </th>
                                      <th
                                        scope="col"
                                      >
                                        {_t(t("Order Type"))}
                                      </th>
                                      <th
                                        scope="col"
                                      >
                                        {_t(t("Qty"))}
                                      </th>
                                      <th
                                        scope="col"
                                      >
                                        {_t(t("Unit"))}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="align-middle">
                                    {reportData.recipeDetail.map((item, index) => {
                                      return (<div key={index}>
                                        <tr>
                                          <td
                                            scope="row"
                                          >
                                            {item.item_name}
                                          </td>
                                          <td
                                            scope="row"
                                          >
                                            {item.order_type}
                                          </td>
                                          <td
                                            scope="row"
                                          >
                                            {item.qty * (qty ? (qty / (reportData.recipe.type === "Sub Recipe" ? reportData.recipe.qty : 1))  : 1)}
                                          </td>
                                          <td
                                            scope="row"
                                            className="lg-text text-capitalize align-middle text-center"
                                          >
                                            {item.unit_name}
                                          </td>
                                        </tr>                                          
                                      </div>)
                                    })}                                      
                                  </tbody>
                                  <thead>
                                    <th colSpan="4"></th>
                                  </thead>
                                </div>
                                }
                                  
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*item Excel Report*/}
        <div className="d-none">
          <div>           
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {reportItemData !== null &&
                      reportItemData !== undefined &&
                      generatedItemReport === true && (
                        <>
                          <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                            <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                                className="table table-bordered table-hover min-table-height mt-3"
                                id="table-xls"
                              >
                                <div className="page-break"></div>
                                <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                    <th colspan="3" style={{ border: "0px" }}>
                                      <div className="row">
                                        <div className="col-12">
                                          <h3 className="text-center mb-0">
                                            {siteName}
                                          </h3>
                                          <h3 className="text-center mt-0">
                                            Recipe Report
                                          </h3>
                                          <p>
                                           Item Type: {type?.name === "item" ? "Food Item" : type?.name }
                                          </p>
                                        </div>
                                      </div>
                                    </th>
                                  </tr> 
                                  <tr></tr>                               
                                </thead>

                                <thead className="align-middle">
                                  <tr>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Item Name"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Variation"))}
                                    </th>
                                    <th
                                      scope="col"
                                    >
                                      {_t(t("Status"))}
                                    </th>
                                  
                                  </tr>
                                </thead>

                                <tbody className="align-middle">
                                  {reportItemData && reportItemData?.length > 0 &&
                                    reportItemData.map((item, index) => {
                                      if(recipeStatus.id !== "all" && recipeStatus.id === item.status){
                                        return (
                                          <tr key={index}>
                                            <td
                                              scope="row"
                                            >
                                              {item.name}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.variations ? item.variations.name : "-"}
                                            </td>
                                            <td
                                              scope="row"
                                              className="lg-text text-capitalize align-middle text-center"
                                            >
                                              {item.status}
                                            </td>
                                          </tr>                                          
                                        )
                                      }else if(recipeStatus.id === "all"){
                                        return (
                                          <tr key={index}>
                                            <td
                                              scope="row"
                                            >
                                              {item.name}
                                            </td>
                                            <td
                                              scope="row"
                                            >
                                              {item.variations ? item.variations.name : "-"}
                                            </td>
                                            <td
                                              scope="row"
                                              className="lg-text text-capitalize align-middle text-center"
                                            >
                                              {item.status}
                                            </td>
                                          </tr>                                          
                                        )
                                      }
                                    })                                     
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* item recipe print */}
        <div className="d-none">
         <div ref={componentRefThermal1}>
            <div className="fk-print"  style={{margin:"20px 30px"}}>
                <div className="row">
                 <div className="col-12">
                    <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                        {siteName}
                    </span>
                    </div>
                    <div>
                    </div>
                    <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      Print Time:{" "} 
                      <Moment format="LLL">
                        {new Date()}
                      </Moment>
                    </span>
                    </div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                     Recipe Report
                    </span>

                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                     Item Type: {type?.name === "item" ? "Food Item" : type?.name}
                    </span>
                   
                    {/*Recipe wise report */}
                    {
                      reportItemData &&
                      reportItemData !== null && generatedItemReport && (
                        <div className="col-12 mt-2">
                          <table className="table table-bordered-sm">
                            <thead className="align-middle">
                              <th scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Item Name</strong></th>
                              <th scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Variation</strong></th>
                              <th scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Status</strong></th>
                            </thead>
                            
                            <tbody className="align-middle">
                              {reportItemData.map((item, ind) => {
                                if(recipeStatus.id !== "all" && recipeStatus.id === item.status){
                                  return (
                                    <tr className="align-middle" key={ind}>
                                      <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.name}</td>
                                      <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.variations ? item.variations.name : "-"}</td>
                                      <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.status}</td>
                                    </tr>
                                    )
                                }else if(recipeStatus.id === "all"){
                                  return (
                                    <tr className="align-middle" key={ind}>
                                      <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.name}</td>
                                      <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.variations ? item.variations.name : "-"}</td>
                                      <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.status}</td>
                                    </tr>
                                    )
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      )
                    }
                 </div> 
                </div> 
            </div> 
         </div> 
        </div>

       {/* print */}
       <div className="d-none">
        <div ref={componentRefThermal}>
            <div className="fk-print"  style={{margin:"20px 30px"}}>
                <div className="row">
                 <div className="col-12">
                    <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                        {siteName}
                    </span>
                    </div>
                    <div>
                    </div>
                    <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      Print Time:{" "} 
                      <Moment format="LLL">
                        {new Date()}
                      </Moment>
                    </span>
                    </div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                     Recipe Report
                    </span>
                    {/*Recipe wise report */}
                    {
                      reportData &&
                      reportData !== null && (
                        <div className="col-12 mt-2">
                          <table className="table table-bordered-sm">
                            <thead className="align-middle">
                                <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Item Type</strong></th>
                                <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Item Name</strong></th>
                                <th scope="col"
                                    className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Qty</strong></th>
                                {
                                  reportData.recipe.type === "Sub Recipe" ? (
                                    <Fragment>
                                      <th scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Unit</strong></th>
                                    </Fragment>
                                  ) : (
                                    <th scope="col"
                                    className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Variation</strong></th>
                                  )
                                }
                                <th scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Remarks</strong></th>
                            
                            </thead>
                            <tbody className="align-middle">
                                <tr className="align-middle">
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{reportData.recipe.type}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{reportData.recipe.item}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{qty ? qty : 1}</td>
                                  {
                                    reportData.recipe.type === "Sub Recipe" ? (
                                      <Fragment>
                                        <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{reportData.recipe.unit_name}</td>
                                      </Fragment>
                                    ) : (
                                        <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{reportData.recipe.variation ? reportData.recipe.variation : "-"}</td>
                                      )
                                    }
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{reportData.recipe.remarks}</td>
                                </tr>
                            </tbody>
                          </table>
    
                          {reportData &&
                            reportData.recipeDetail?.length && <table className="table table-bordered-sm mb-5">
                            <thead className="align-middle">
                              <th scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Ingredient Item</strong></th>
                              <th scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Order Type</strong></th>
                              <th scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Qty</strong></th>
                              <th scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border-dark p-1"><strong>Unit</strong></th>
                            </thead>
                            <tbody className="align-middle">
                            {reportData.recipeDetail.map((item, ind) => {
                                return (<tr className="align-middle" key={ind}>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.item_name}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.order_type}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.qty * (qty ? (qty / (reportData.recipe.type === "Sub Recipe" ? reportData.recipe.qty : 1)) : 1)}</td>
                                  <td className="table-text text-capitalize align-middle text-center border-1 border-dark">{item.unit_name}</td>
                                </tr>)
                            })}
                            </tbody>
                          </table>}
                              
                        </div>
                      )
                    }
                 </div> 
                </div> 
            </div> 
         </div> 
       </div>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                     
                      {/* spin loading ends */}
  
                      {/* Loading effect {loading === true ? (
                        tableLoading()
                      ) : ( */}
                      
                        <div key="smtp-form">
                          <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="row t-mb-15 mb-md-0" >
                              <ul className="t-list fk-breadcrumb col-6">
                                <li className="fk-breadcrumb__list">
                                  <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Recipe Report"))}
                                  </span>
                                </li>
                              </ul>
                              <div className="col-6 d-flex justify-content-end p-0">
                              <div className="d-inline mr-3 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="excel_report"
                                  name="excel_report"
                                  value="excel"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "excel"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="excel_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Excel"))}
                                </label>
                              </div>

                              <div className="d-inline mr-2 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="print_report"
                                  name="print_report"
                                  value="print"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "print"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="print_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-print" aria-hidden="true"></i> {_t(t("Print"))}
                                </label>
                              </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
  
                            <div className="col-2">
                              <Select
                                options={recipeType}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={type}
                                classNamePrefix="select"
                                className="table-text text-capitalize"
                                onChange={handleType} 
                                styles={customStyle} 
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a type")) + ".."}
                              />
                            </div>

                            <div className="col-2">
                              <Select
                                options={recipesStatus}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={recipeStatus}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleRecipeStatus} 
                                styles={customStyle}                                 
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select item")) + ".."}
                              />
                            </div>
  
                            <div className="col-2">
                              <Select
                                options={type && (type.name === "item" ? (foodForPOS ? foodForPOS : []) : (type.name === "Sub Recipe" ? (ingredientItemForSearch ? ingredientItemForSearch : []) : (propertyItemForSearch ? propertyItemForSearch : [])))}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={item}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleItem} 
                                styles={customStyle}                                 
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select item")) + ".."}
                              />
                            </div> 

                            {
                              <div className="col-4 d-flex align-items-center">
                                {
                                  item && item.has_variation === "1" && (
                                    item?.variation_recipe?.map((item) => (
                                    <div class="form-check mt-1 mx-2" key={item.food_with_variation_id}>
                                        <input class="form-check-input pointer-cursor"                                                
                                            type="radio" 
                                            name="variations"                                                 
                                            id={item.variation_name} 
                                            checked={variation?.variation_name === item.variation_name}
                                            onChange={(e) => handleVariation(item)}
                                            required                                                
                                        /> 
                                        <label class="form-radio-label mx-1 text-capitalize pointer-cursor" htmlFor={item.variation_name}>
                                            {item.variation_name} 
                                        </label>
                                    </div>
                                    ))
                                  )
                                }
                              </div>
                            } 

                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getBranchItemSelected()}
                              >
                               ITEM LINK WITH RECIPE
                              </button>
                            </div>

                          </div> 
                          <div className="row mt-2">
                            <div className="col-8"></div>
                            <div className="col-2">
                              <input
                                class="form-control rounded-25"
                                type="number"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}  
                                min="1"
                                value={qty}
                                onChange={(e) => setQty(parseFloat(e.target.value))}
                                placeholder="Recipe Qty"
                              />
                            </div>
                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                                <button
                                className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                                onClick={(e) => getBranchWiseSelected()}
                                >
                                RECIPE REPORT
                                </button>
                              </div>
                          </div>                          
                          
                        </div>                                          
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
  
      </>
    );
}

export default RecipeReport;