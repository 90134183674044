import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const SalesTenderReport = () => {
    const { t } = useTranslation();
    //getting context values here
    let { loading, setLoading, dataPaginating, setDataPaginating, generalSettings } = useContext(SettingsContext);
    const { authUserInfo } = useContext(UserContext);
    let { branchForSearch } = useContext(RestaurantContext);
  
    //all data
    const [reportData, setReportData] = useState(null);
    const componentRef = useRef();
    const componentRefThermal = useRef();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [branch, setBranch] = useState(null);
    const [generatedReport, setGeneratedReport] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [option,setOption] = useState("excel");
    const [isDeal,setIsDeal] = useState("1");

    const [branchSearch, setBranchSearch] = useState(null);
    useEffect(() => {
        setSiteName(getSystemSettings(generalSettings, "siteName"));
        if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
          setBranchSearch(
            branchForSearch.filter(
              (branch) => branch.id === authUserInfo.details.branch_id
            )
          );
        } else {
          setBranchSearch(branchForSearch);
        }
    
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, [generalSettings]);

      const thermalPrint = useReactToPrint({
        content: () => componentRefThermal.current,
        pageStyle: `
        @page {
          size: A4;
          margin: 0;
        }
      `,
      });

      const handleBranch=(brnch)=>{
        setBranch(brnch)
      }


      const handleOption  = (e) =>{
        setOption(e.target.value)
      }
  
    //get BranchWise reports filter
    const getBranchWiseSelected = () => {
      setReportData(null);
      setGeneratedReport(false)   
      if (        
        startDate !== null &&
        endDate !== null
      ) {
        setDataPaginating(true);
        var fromDate = moment(startDate).format("YYYY-MM-DD");
        var toDate = moment(endDate).format("YYYY-MM-DD");
        const url = BASE_URL + "/settings/sales-tender-report";
        let formData = new FormData();
        formData.append("fromDate", fromDate);
        formData.append("toDate", toDate);
        formData.append("branch_id", branch?.id ? branch.id : "");
        formData.append("is_deal", isDeal);
  
        return axios
          .post(url,formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            console.log("ReportResult", res);
            setGeneratedReport(true);
            setReportData(res.data[0])
            setDataPaginating(false);
            if(option==="excel"){
              componentRef.current.handleDownload();
            }else{
              thermalPrint();
            }
          })
          .catch((error) => {
            setDataPaginating(false);
          });
      } else {
        toast.error(
          `${_t(
            t(
              "Please select from date, to date and branch"
            )
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      }
    };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Sales Tender Report"))}</title>
        </Helmet>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success float-right mr-3 invisible"
          table="table-to-xls"
          filename="Sales Tender Report"
          sheet="sheet 1"
          buttonText="Discount Detail"
          ref={componentRef}
        />        
  
         {/* Excel Report*/}
         <div className="d-none">
         <div>           
           <div className="fk-print">
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                       <>
                         <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                           <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                           <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="10" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Sales Tender Report
                                        </h3>
                                        <div>
                                          <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                                            {branch ? branch?.name : "All"}
                                          </span>
                                        </div>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                {_t(t("Group Name"))}
                                </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Item"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Dine In"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Delivery"))}
                                </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Takeaway"))}
                                </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Food Panda"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                  {_t(t("Waste"))}
                                  </th>
                                  <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                   {_t(t("Return"))}
                                  </th> 
                                <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                    {_t(t("Total"))}
                                </th>  
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* {console.log("309 ", reportData)} */}
                                {/* loop here*/}
                                {
                                  Object.keys(reportData).length>0 && Object.keys(reportData).map(
                                    (group_name, index) => {
                                      return(
                                      <div key={index}>
                                    
                                      <tr>
                                      <th
                                        scope="row"
                                        className="lg-text text-capitalize align-middle text-center"
                                      >
                                        {group_name}
                                      </th>
                                    </tr>
                                {reportData[group_name]?.length > 0 &&reportData[group_name].map((item, i) => {
                                  let total = parseFloat(item.DineIn) + parseFloat(item.Delivery) + parseFloat(item.Takeaway) + parseFloat(item.FoodPanda) - parseFloat(item.Wasted) - parseFloat(item.Refund);
                                  
                                  return (
                                    <tr>
                                    <td></td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center "
                                      >
                                        {i + 1}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.food_item}                                        
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.DineIn}    
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.Delivery}                                        
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.Takeaway}                                        
                                      </td>
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                     >
                                      {item.FoodPanda}
                                     </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.Wasted}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.Refund}
                                      </td>   
                                      <td
                                      scope="row"
                                      className="xsm-text text-capitalize align-middle text-center"
                                    >
                                      {total?.toFixed(2)}
                                    </td>                                  
                                    
                                    </tr>
                                  );
                                })}
                                </div>
                                );
                              }
                              )
                             }
                          </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                           </div>
                         </div>
                       </>
                     )}
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

       
       {/* print */}
       <div className="d-none">
      <div ref={componentRefThermal}>
          <div className="fk-print">
          <div className="container">
              <div className="row">
              <div className="col-12">
                <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    {siteName}
                  </span>
                </div>
                <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    {branch && branch?.name}
                  </span>
                </div>
                <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                   FROM&nbsp;
                   <Moment format="LL">
                      {startDate}
                    </Moment>
                    &nbsp;TO&nbsp;
                    <Moment format="LL">{endDate}</Moment>
                  </span>
                  </div>
                  <div>
                  <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    Print Time:{" "} 
                    <Moment format="LLL">
                      {new Date()}
                    </Moment>
                  </span>
                  </div>
                <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                  Sales Tender Report
                </span>

                <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                  {branch ? branch.name : "All"}
                </span>
            {/*variance report */}
            <div className="col-12">
            <table className="table table-bordered-sm table-hover">
            <thead className="align-middle">
                <th scope="col"
                className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"5px" }}>
                    Group Name
                </th>
                  <th scope="col"
                    className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"5px" }}>
                    Item
                  </th>
                  <th scope="col"
                    className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"5px" }}>
                    Dine In
                  </th>
                  <th scope="col"
                    className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"5px" }}>
                    Delivery
                  </th>

                  <th scope="col"
                    className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"5px" }}>
                    Takeaway
                  </th>

                  <th scope="col"
                    className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"5px" }}>
                    Food Panda
                  </th>

                  <th scope="col"
                    className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"5px" }}>
                     Waste
                  </th>

                  <th scope="col"
                    className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"5px" }}>
                    Return
                  </th>

                  <th scope="col"
                    className="sm-text text-capitalize align-middle text-center border-1 border" style={{ minWidth: "40px", padding:"5px" }}>
                    Total
                  </th>

                </thead>                                                           
          {
            reportData &&
            reportData !== null &&
             Object.keys(reportData)?.map((group)=>{                                       
            return(
              <tbody className="align-middle">

                <tr>
                   <td className="sm-text text-capitalize align-middle text-center border">{group}</td>
                   <td className="sm-text text-capitalize align-middle text-center border"></td>
                   <td className="sm-text text-capitalize align-middle text-center border"></td>
                   <td className="sm-text text-capitalize align-middle text-center border"></td>
                   <td className="sm-text text-capitalize align-middle text-center border"></td>
                   <td className="sm-text text-capitalize align-middle text-center border"></td>
                   <td className="sm-text text-capitalize align-middle text-center border"></td>
                   <td className="sm-text text-capitalize align-middle text-center border"></td>
                   <td className="sm-text text-capitalize align-middle text-center border"></td>
                  
                </tr>

                {
                    reportData[group]?.map((item)=>{
                      let total = parseFloat(item.DineIn) + parseFloat(item.Delivery) + parseFloat(item.Takeaway) + parseFloat(item.FoodPanda) - parseFloat(item.Wasted) - parseFloat(item.Refund);

                        return(
                            <tr>
                               <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"5px" }}></td>
                                <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"5px" }}>{item?.food_item}</td>
                                <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"5px" }}>{item.DineIn}</td>
                                <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"5px" }}>{item.Delivery}</td>
                                <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"5px" }}>{item.Takeaway}</td>
                                <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"5px" }}>{item.FoodPanda}</td>
                                <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"5px" }}>{item.Wasted}</td> 
                                <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"5px" }}>{item.Refund}</td>
                                <td className="sm-text text-capitalize align-middle text-center border" style={{ minWidth: "40px", padding:"5px" }}>{total?.toFixed(2)}</td>
                               
                            </tr>    
                        )

                    })
                }   
              
               </tbody>
                )
               })
             }
             </table> 
             
           </div>
          </div> 
         </div> 
        </div> 
       </div> 
      </div> 
    </div>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ReportSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                     
                      {/* spin loading ends */}
  
                      {/* Loading effect {loading === true ? (
                        tableLoading()
                      ) : ( */}
                      
                        <div key="smtp-form">
                          <div className="gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="row t-mb-15 mb-md-0" >
                              <ul className="t-list fk-breadcrumb col-6">
                                <li className="fk-breadcrumb__list">
                                  <span className="pl-3 t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("Sales Tender Report"))}
                                  </span>
                                </li>
                              </ul>
                              <div className="col-6 d-flex justify-content-end p-0">
                              <div className="d-inline mr-3 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="excel_report"
                                  name="excel_report"
                                  value="excel"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "excel"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="excel_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Excel"))}
                                </label>
                              </div>

                              <div className="d-inline mr-2 d-flex align-items-center">
                                <input
                                  type="radio"
                                  className="form-check-input pointer-cursor"
                                  id="print_report"
                                  name="print_report"
                                  value="print"
                                  onChange={(e) => {
                                    handleOption(e);
                                  }}
                                  checked={
                                    option === "print"
                                      ? 1
                                      : 0
                                  }
                                />
                                <label
                                  className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                  htmlFor="print_report"
                                  style={{fontSize:"16px",borderRadius:"5px"}}
                                >
                                  <i class="fa fa-print" aria-hidden="true"></i> {_t(t("Print"))}
                                </label>
                              </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("From date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>

                            <div className="col-md-2 d-md-block">
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control t-mb-15 mb-md-0"
                                placeholderText={_t(t("To date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>                                                                                 
  
                            <div className="col-3">
                              <Select
                                options={branchSearch && branchSearch}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                value={branch}
                                isClearable
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleBranch}                                
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select a branch")) + ".."}
                              />
                            </div>

                            <div className="col-3 d-flex justify-content-center p-0">
                             <div className="d-inline mr-3 d-flex align-items-center">
                              <input
                                type="radio"
                                className="form-check-input pointer-cursor"
                                id="is_deal"
                                name="is_deal"
                                value="1"
                                onChange={(e) => {
                                  setIsDeal(e.target.value);
                                }}
                                checked={
                                  isDeal === "1"
                                    ? 1
                                    : 0
                                }
                              />
                              <label
                                className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                htmlFor="is_deal"
                                style={{fontSize:"16px",borderRadius:"5px"}}
                              >
                                {_t(t("With Deal"))}
                              </label>
                            </div>

                            <div className="d-inline mr-2 d-flex align-items-center">
                              <input
                                type="radio"
                                className="form-check-input pointer-cursor"
                                id="isDeal"
                                name="is_deal"
                                value="0"
                                onChange={(e) => {
                                  setIsDeal(e.target.value);
                                }}
                                checked={
                                  isDeal === "0"
                                    ? 1
                                    : 0
                                }
                              />
                              <label
                                className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                htmlFor="isDeal"
                                style={{fontSize:"16px",borderRadius:"5px"}}
                              >
                                {_t(t("Without Deal"))}
                              </label>
                            </div>
                            </div>

                            <div className="col-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                              <button
                              className="ml-1 btn btn-block btn-primary sm-text py-2 rounded"
                              onClick={(e) => getBranchWiseSelected()}
                              >
                               TENDER REPORT
                              </button>
                            </div>                             
                          </div> 
                          
                        </div>                                          
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
  
      </>
    );
}

export default SalesTenderReport
