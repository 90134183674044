import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import {
  BASE_URL,

} from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const Email = () => {
    const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
    getPaginatedEmails,
    emailSearch,
    setEmailSearch,
    pageEmail,
    setPageEmail,
    getEmailSettings,
     //pagination
     dataPaginating,
  } = useContext(SettingsContext);


  //type states
  const emailType = [
    {
    id:1,
    name: "Workperiod Report"},
  ]
  // States hook here
  //new group
  let [newEmail, setNewEmail] = useState({
    email_type: "",
    email_to: "",
    email_cc: "",
    email_subject: "",
    email_msg: "",
    edit: false,
    id: null,
    uploading:false,
  });

  //search result
  let [searchedEmail, setSearchedEmail] = useState({
    list: null,
    searched: false,
  });

  useEffect(()=>{
    getPaginatedEmails();
    getEmailSettings()
  },[])

   // on change type
   const handleType = (type) => {
    setNewEmail({
      ...newEmail, 
      email_type: type,
    })
  };
 
  //set to email
  const handleSetoEmail = (e) => {
    setNewEmail({ ...newEmail, [e.target.name]: e.target.value });
  };

   //set email cc
   const handleSetCC = (e) => {
    setNewEmail({
      ...newEmail,
      [e.target.name]:e.target.value,
    });
  };

  const handleSubject=(e)=>{
  setNewEmail({
    ...newEmail,
    [e.target.name]:e.target.value
  })
  }
 //if mssage
  const handleMessage=(e)=>{
    setNewEmail({
      ...newEmail,
      [e.target.name]:e.target.value,
    })
    }

  //Save New branch
  const handleSaveNewEmail = (e) => {
    e.preventDefault();
    setNewEmail({
      ...newEmail,
      uploading: true,
    });
    const emailUrl = BASE_URL + `/settings/new-email-template`;
    let formData = new FormData();
    formData.append("email_type", newEmail.email_type.name);
    formData.append("email_to", newEmail.email_to);
    formData.append("email_cc", newEmail.email_cc);
    formData.append("email_subject", newEmail.email_subject);
    formData.append("email_msg", newEmail.email_msg);

    return axios
      .post(emailUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setEmailSearch(res.data[0])
        setNewEmail({
          email_type:"",
          email_to:"",
          email_subject:"",
          email_cc:"",
          email_msg:"",
          uploading: false,
        });
        setSearchedEmail({
          ...searchedEmail,
          list: res.data[1],
        });
        setPageEmail(res.data[0]);
        setEmailSearch(res.data[1])
        setLoading(false);
        toast.success(`${_t(t("Email has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch(() => {
        setLoading(false);
        setNewEmail({
          ...newEmail,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  //set edit true & values
  const handleSetEdit = (slug) => {
    let email = emailSearch.find((item) => {
      return item.id === slug;
    });
      const emailtpe = email && emailType.find((mail)=>{
        return mail.name == email.email_type
      });
 
    setNewEmail({
      ...newEmail,
      email_type: emailtpe,
      email_to: email.email_to,
      email_cc: email.email_cc ? email.email_cc : "",
      email_subject: email.email_subject,
      email_msg: email.email_msg ? email.email_msg : "",
      id: email.id,
      edit: true,
    });

  };
  //update branch
  const handleUpdateEmail = (e) => {
    e.preventDefault();
    setNewEmail({
      ...newEmail,
      uploading: true,
    });
    const emailUrl = BASE_URL + `/settings/update-email-template`;
    let formData = new FormData();
    formData.append("email_type", newEmail.email_type.name);
    formData.append("email_to", newEmail.email_to);
    formData.append("email_cc", newEmail.email_cc);
    formData.append("email_subject", newEmail.email_subject);
    formData.append("email_msg", newEmail.email_msg);
    formData.append("id", newEmail.id);
    if(newEmail.email_type && newEmail.email_to && newEmail.email_subject){     
 
    return axios
      .post(emailUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewEmail({
            email_type:"",
            email_to:"",
            email_subject:"",
            email_cc:"",
            email_msg:"",
            uploading:false,
          });
       
        setSearchedEmail({
          ...searchedEmail,
          list: res.data[1],
        });
        setPageEmail(res.data[0]);
        setEmailSearch(res.data[1])
        setLoading(false);
        toast.success(`${_t(t("Email has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch(() => {
        setLoading(false);
        setNewEmail({
          ...newEmail,
          uploading: false,
        });
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
    }else{
      toast.error(`${_t(t("Please fill type, email to or email subject"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //search branch here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedEmail({ ...searchedEmail, searched: false });
    } else {
      let searchedList = emailSearch.filter((item) => {
        let lowerCaseItemSubject = item.email_subject.toLowerCase();
        let lowerCaseItemEmailType =
          item.email_type.name !== null && item.email_type.name.toLowerCase();
        let lowerCaseItemEmailTo =
          item.email_to !== null && item.email_to.toLowerCase();
        return (
          lowerCaseItemSubject.includes(searchInput) ||
          (lowerCaseItemEmailType && lowerCaseItemEmailType.includes(searchInput)) ||
          (lowerCaseItemEmailTo && lowerCaseItemEmailTo.includes(searchInput))
        );
      });
      setSearchedEmail({
        ...searchedEmail,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of branch
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteEmail(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete branch here
  const handleDeleteEmail = (slug) => {
    setLoading(true);
    const branchUrl = BASE_URL + `/settings/delete-email-template/${slug}`;
    return axios
      .get(branchUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
         
          setSearchedEmail({
            ...searchedEmail,
            list: res.data[1],
          });
          setPageEmail(res.data[0]);
          setEmailSearch(res.data[1])
          setLoading(false);
          toast.success(`${_t(t("Email has been deleted successfully"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  return (
    <>
    <Helmet>
      <title>{_t(t("Email"))}</title>
    </Helmet>

    {/* Add modal */}
    <div className="modal fade" id="addEmail" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header align-items-center">
            <div className="fk-sm-card__content">
              <h5 className="text-capitalize fk-sm-card__title">
                {!newEmail.edit
                  ? _t(t("Add new Email"))
                  : _t(t("Update Email"))}
              </h5>
            </div>
            <button
              type="button"
              id="close-btn"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {/* show form or show saving loading */}
            {newEmail.uploading === false ? (
              <div key="fragment-branch-1">
                <form
                  onSubmit={
                    !newEmail.edit ? handleSaveNewEmail : handleUpdateEmail
                  }
                  autoComplete="off"
                >
                  <div className="mt-3">
                    <label className="form-label">
                        {_t(t("Type"))}
                        {""}
                        <small className="text-danger">*</small>
                    </label>
                    <Select
                        options={emailType}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        className="basic-multi-select mt-2"
                        classNamePrefix="select"
                        value={newEmail?.email_type}
                        onChange={handleType}
                        required
                        placeholder={_t(t("Please select "))}
                    />
                    </div>

                  <div className="mt-3">
                    <label htmlFor="email_to" className="form-label">
                      {_t(t("Email To"))}
                      <small className="text-danger">*</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email_to"
                      name="email_to"
                      placeholder="test@gmail.com"
                      value={newEmail.email_to || ""}
                      onChange={handleSetoEmail}
                      required
                    />
                  </div>

                  <div className="mt-3">
                    <label htmlFor="email_cc" className="form-label">
                      {_t(t("Email CC (Enter multiple email with comma)"))}
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="email_cc"
                      name="email_cc"
                      placeholder="test@gmail.com, abc@gmail.com"
                      value={newEmail.email_cc || ""}
                      onChange={handleSetCC}
                    />
                  </div>
                 
                      <div className="mt-3">
                        <label
                          htmlFor="email_subject"
                          className="form-label"
                        >
                          {_t(t("Subject"))}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email_subject"
                          name="email_subject"
                          onChange={handleSubject}
                          value={newEmail.email_subject || ""}
                          placeholder="Pos Report"
                          required
                        />{" "}
                      </div>
                      <div className="mt-3">
                        <label
                          htmlFor="msg"
                          className="form-label"
                        >
                          {_t(t("Message"))}
                        </label>
                        <textarea
                          className="form-control"
                          id="email_msg"
                          name="email_msg"
                          onChange={handleMessage}
                          value={newEmail?.email_msg}
                          placeholder="email body"
                          // required
                        >
                        </textarea>
                      </div>

                      <div className="mt-4">
                      <div className="row">
                        <div className="col-6">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          >
                            {!newEmail.edit ? _t(t("Save")) : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                </form>
              </div>
            ) : (
              <div key="fragment2">
                <div className="text-center text-primary font-weight-bold text-uppercase">
                  {_t(t("Please wait"))}
                </div>
                {modalLoading(3)}
                <div className="mt-4">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="button"
                        className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {!newEmail.edit ? _t(t("Save")) : _t(t("Update"))}
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="button"
                        className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                        data-dismiss="modal"
                      >
                        {_t(t("Close"))}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    {/* Add modal Ends*/}

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ManageSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div className="fk-scroll--pos-menu" data-simplebar>
                <div className="t-pl-15 t-pr-15">
                  {/* Loading effect */}
                  {/* {newEmail.uploading === true || loading === true ? (
                    tableLoading()
                  ) : ( */}
                    <div key="fragment3">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* spin loading ends */}

                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {!searchedEmail.searched
                                  ? _t(t("Email List"))
                                  : _t(t("Search Result"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center">
                            {/* Search group */}
                            <div className="col-md-9 t-mb-15 mb-md-0">
                              <div className="input-group">
                                <div className="form-file">
                                  <input
                                    type="text"
                                    className="form-control border-0 form-control--light-1 rounded-0"
                                    placeholder={_t(t("Search")) + ".."}
                                    onChange={handleSearch}
                                  />
                                </div>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>

                            {/* Add group modal trigger button */}
                            <div className="col-md-3 text-md-right">
                             
                                    <button
                                      type="button"
                                      className="btn btn-primary xsm-text text-uppercase btn-lg btn-block"
                                      data-toggle="modal"
                                      data-target="#addEmail"
                                      onClick={() => {
                                        setNewEmail({
                                          ...newEmail,
                                          email_type:"",
                                          email_to:"",
                                          email_subject:"",
                                          email_cc:"",
                                          email_msg:"",
                                          edit: false,
                                          uploading: false,
                                        });
                                      }}
                                    >
                                      {_t(t("add new"))}
                                    </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Table */}
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover min-table-height">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Type"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Email To"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Email CC"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Subject"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {!searchedEmail.searched
                              ? [
                                emailSearch && [
                                  pageEmail && pageEmail.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="6"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      pageEmail && pageEmail?.data?.map((item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (pageEmail.current_page -
                                                  1) *
                                                  pageEmail.per_page}
                                            </th>

                                            <td className="xsm-text align-middle text-center">
                                              {item.email_type}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.email_to || "-"}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.email_cc ? (
                                                  item.email_cc
                                              ) : (
                                                "-"
                                              )}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                            {item.email_subject}
                                          </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              <div className="dropdown">
                                                <button
                                                  className="btn t-bg-clear t-text-dark--light-40"
                                                  type="button"
                                                  data-toggle="dropdown"
                                                >
                                                  <i className="fa fa-ellipsis-h"></i>
                                                </button>
                                                <div className="dropdown-menu">
                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    onClick={() =>
                                                      handleSetEdit(item.id)
                                                    }
                                                    data-toggle="modal"
                                                    data-target="#addEmail"
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-pencil"></i>
                                                    </span>
                                                    {_t(t("Edit"))}
                                                  </button>
                                             
                                                        <button
                                                          className="dropdown-item sm-text text-capitalize"
                                                          onClick={() => {
                                                            handleDeleteConfirmation(
                                                              item.id
                                                            );
                                                          }}
                                                        >
                                                          <span className="t-mr-8">
                                                            <i className="fa fa-trash"></i>
                                                          </span>
                                                          {_t(t("Delete"))}
                                                        </button>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ),
                                  ],
                                ]
                              : [
                                  /* searched data, logic === haveData*/
                                  searchedEmail && [
                                    searchedEmail.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="6"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedEmail.list.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (pageEmail.current_page -
                                                    1) *
                                                    pageEmail.per_page}
                                              </th>

                                              <td className="xsm-text align-middle text-center">
                                                {item.email_type.name}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.email_to || "-"}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.email_cc ? (
                                                    item.email_cc
                                                ) : (
                                                  "-"
                                                )}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.email_subject}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() =>
                                                        handleSetEdit(
                                                          item.id
                                                        )
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#addEmail"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.id
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  {/* )} */}
                </div>
              </div>
            </div>

            {/* pagination loading effect */}
            {newEmail.uploading === true || loading === true
              ? paginationLoading()
              : [
                  // logic === !searched
                  !searchedEmail.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(pageEmail, getPaginatedEmails)}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(pageEmail)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedEmail({
                                    ...searchedEmail,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {searchedShowingData(
                                  searchedEmail,
                                  emailSearch
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>

  )
}

export default Email
