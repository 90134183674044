const parseParties = (parties) => {
  const partiesToRetun = {};
  parties.forEach((party) => {
    if (party.show_header !== "1") return;
    const associates = party.associate_with.replace(/[\[\]]/g, "").split(",");
    associates.forEach((a) => {
      if (partiesToRetun[a]) partiesToRetun[a] = [...partiesToRetun[a], party];
      else partiesToRetun[a] = [party];
    });
  });
  return partiesToRetun;
};

const parseFoodItems = (foodItems) => {
  const parsedFoodItems = {};
  foodItems.forEach((foodItem) => {
    const menus = foodItem.menu_ids.replace(/[\[\]]/g, "").split(",");
    menus.forEach((menu) => {
      if (!menu) return;
      if (parsedFoodItems[menu]) {
        foodItem.food_groups.forEach((foodGroupId) => {
          if (parsedFoodItems[menu][foodGroupId])
            parsedFoodItems[menu][foodGroupId] = [
              ...parsedFoodItems[menu][foodGroupId],
              foodItem,
            ];
          else parsedFoodItems[menu][foodGroupId] = [foodItem];
        });
      } else {
        parsedFoodItems[menu] = {};
        foodItem.food_groups.forEach((foodGroupId) => {
          if (parsedFoodItems[menu][foodGroupId])
            parsedFoodItems[menu][foodGroupId] = [
              ...parsedFoodItems[menu][foodGroupId],
              foodItem,
            ];
          else parsedFoodItems[menu][foodGroupId] = [foodItem];
        });
      }
    });
  });
  return parsedFoodItems;
};

const qtyCounter = (action, qty) => {
  if (action === "increament") return qty + 1;
  if (action === "decreament") return qty === 0 ? 0 : qty - 1;
};

const getParsedProperties = (itemData, propertyGroups, menuWisePrice, menuId) => {
  
  const parsed = {};
  itemData.item.properties.forEach((property) => {
    if (!property.length) return
    const propertyGroupId = property[0].property_group_id.replace(
      /[\[\]]/g,
      ""
    );
     if(menuWisePrice){
      parsed[propertyGroupId] = {
        properties : property && property.map((prp)=>({
          ...prp,
          extra_price: prp.extra_price[menuId]
        }))
      }
      //parsed[propertyGroupId] = { properties: property };
     }else{
       parsed[propertyGroupId] = { properties: property };
     }
  });
  let found = 0,
    toFind = Object.keys(parsed).length;
  for (let i = 0; i < propertyGroups.length; i++) {
    const propertyGroup = propertyGroups[i];
    for (const id of Object.keys(parsed)) {
      if (parseInt(id) === propertyGroup.id) {
        parsed[id].details = propertyGroup;
        found++;
        break;
      }
    }
    if (found === toFind) break;
  }
  return parsed;
};

const createHash = (item) => {
  let hash = item.item.id;
  if (item.item.has_variation === "1")
    hash += String(item.variation?.food_with_variation_id);
  if (item.item.has_property === "1") {
    if (
      item.properties &&
      Object.keys(item.properties).length
    ) {
      for (const propertyGroupId in item.properties) {
        const allproperties = Object.keys(
          item.properties[propertyGroupId]
        ).join("");
        hash += propertyGroupId + allproperties;
      }
    }
  }
  if(item.offer_type === "bogo" && item.item_discount > 0){
    return hash+"x";
  }else{

    return hash;
  }
};

const getInitialItemDiscount = (price, scheme,qty = 1) => {
  const discount = parseFloat(scheme.how_much_discount);
  return (
    scheme.discount_type == 'percentage'
    ? ((discount / 100) * price)
    : discount * parseInt(qty)
  )
}

const getItemDiscount = (price, item, qty) => {
  const discount = item.discount ? parseFloat(item.discount) : 0;
  return (
    item.discount_type && item.discount_type == 'percentage'
    ? ((discount / 100) * price)
    : discount * parseInt(qty)
  )
}

const calculatePriceForCartVariation = (cartItem, qty=0) => {
  let price = (
    (cartItem && cartItem.item.has_variation === "1")
    ? (parseFloat(cartItem.variation.food_with_variation_price) * qty)
    : (parseFloat(cartItem.item.price) * qty)
  );
  return parseFloat(price.toFixed(2))
}

const calculatePriceForCartItem = (cartItem, qty=0) => {
 
  let price = (
    (cartItem.variation && Object.keys(cartItem.variation)?.length)
    ? parseFloat(cartItem.variation.food_with_variation_price)
    : parseFloat(cartItem.item.price)
  );

  if (cartItem.item.has_property === "1") {
    for (const propertyGroupId in cartItem.properties) {
      const allpropertiesPrice = Object.keys(
        cartItem.properties[propertyGroupId]
      ).reduce((total, propertyId) => {
        const property =
        cartItem.properties[propertyGroupId][propertyId];
        let qty = 1;
        if (
          property.item.hasOwnProperty("allow_multi_quantity") &&
          property.item.allow_multi_quantity === 1 &&
          property.hasOwnProperty("quantity")
        )
          qty = property.quantity;
        return parseFloat(property.item.extra_price) * qty + total;
      }, 0);
      price += allpropertiesPrice;
    }
  }
  return price * parseFloat((qty ? qty : (cartItem.quantity ? cartItem.quantity : 1)).toFixed(2))
}

const calculateSubTotal = (cartItems) => {
  return Object.values(cartItems).reduce((total, item) => {
    return calculatePriceForCartItem(item) + total;
  }, 0);
};

const getTaxedTotal = (total, tax, taxType) => {
  if (taxType === "inclusive") return total;
  return total * (1 + tax / 100);
};

const getServiceCharges = (total,serviceCharge)=>{
  return total * (serviceCharge / 100)
}

const getDiscount = (total,discount)=>{
  return total * (discount / 100)
}

const getTax = (tax, amount) => {
  return amount * (tax / 100);
};

//auto property select 
const autoPropertySelect = (
  properties,
  id,
  pickedProperties,
  validation
) => {
  if(properties && id && properties[id] && properties[id].properties?.length === 1 && !pickedProperties[id] && validation && validation[id].required){
      let item = properties[id].properties[0];
      return {
          ...pickedProperties,
          [id]: {
            [item.id]: {
              item,
              quantity: 1,
            },
          },
      };
  }else{
    return false;
  }
}

//Check validity on group select required and limit
const validationGroup = (
  newData,
  selectedPropertyGroup,
  validation,
  setTempQty
) => {
 
  // prp validation
  const items = validation;
  const selectGrop = items[selectedPropertyGroup];
  const itemGroup = newData[selectedPropertyGroup];
  if (selectGrop?.required === 1) {
    const picked = itemGroup ? Object.values(itemGroup) : [];
    const qty = picked.reduce((acc, itm) => acc + (itm?.quantity || 1), 0);
    setTempQty((curr)=>({
      ...curr,
      [selectedPropertyGroup]: qty
    }))
  }

  // if (selectGrop?.required === 1) {
  //   setRequired(true);
    
  //   if (selectGrop && selectGrop.limit > 0) {
  //     // const items = pickedProperties;
  //     // const itemGroup = items[selectedPropertyGroup];
  //     const picked = itemGroup ? Object.values(itemGroup) : [];
  //     const qty = picked.reduce((acc, itm) => acc + (itm?.quantity || 1), 0);
  //     setTempQty(qty);
  //     // const picked = [];
  //     // const itemx =
  //     // // itemGroup?.[
  //     //   itemGroup &&
  //     //   Object.values(itemGroup).map((itm) => {
  //     //       picked.push(itm.quantity ? itm.quantity : 1);
  //     //       const initial = 0;
  //     //       const qty = picked.reduce((prev, next) => prev + next, initial);
  //     //       setTempQty(qty);
  //     //     })

  //     if (tempQty > 0) {
  //       setRequired(false);
  //     }
  //   } else {
  //     setRequired(false);
  //     //setTempQty(0)
  //   }
  // } else {
  //   setRequired(false);
  // }
};

export {
  parseParties,
  parseFoodItems,
  qtyCounter,
  getParsedProperties,
  createHash,
  calculateSubTotal,
  calculatePriceForCartItem,
  getInitialItemDiscount,
  getItemDiscount,
  getTaxedTotal,
  getTax,
  validationGroup,
  autoPropertySelect,
  getServiceCharges,
  calculatePriceForCartVariation,
  getDiscount,
};
