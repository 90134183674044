import React, { useState, useContext, useRef } from "react";
import Switch from "react-switch";
import { NavLink } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";


//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";

const UserTill = () => {
  const { t } = useTranslation();
  //getting context values here
  let {
    //common
    loading,
    //permission group
    permissionGroupForSearch,
  } = useContext(SettingsContext);

  let {
    //till user
    dataPaginating,
    setPaginatedTillUser,
    tillUserForSearch,
    tillUserList,
  } = useContext(UserContext);
  
  //search result
  let [searchedAdminStaff, setSearchedAdminStaff] = useState({
    list: null,
    searched: false,
  });

  //search admin staff here
  const handleSearch = (e) => {
    let searchInput = e.target.value?.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedAdminStaff({ ...searchedAdminStaff, searched: false });
    } else {
      const searchedList = tillUserForSearch.filter((item) => {
        let lowerCaseItemName = item.name?.toLowerCase();
        let lowerCaseItemBranch = item.branch.name !== null && item.branch.name?.toLowerCase();
        return (
          (lowerCaseItemName && lowerCaseItemName.includes(searchInput)) ||
          (lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
        );
      });
      setSearchedAdminStaff({
        ...searchedAdminStaff,
        list: searchedList,
        searched: true,
      });
    }
  };


  return (
    <>
      <Helmet>
        <title>{_t(t("Till User"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newAdminStaff.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedAdminStaff.searched
                                    ? _t(t("Till User List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <NavLink
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  to="/dashboard/manage/user/add-till"
                                 
                                >
                                  {_t(t("add new"))}
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Name"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Branch"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedAdminStaff.searched
                                ? [
                                    tillUserList && [
                                      tillUserList.data?.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        tillUserList.data.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (tillUserList.current_page -
                                                      1) *
                                                      tillUserList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.branch.name || "-"}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <NavLink
                                                        className="btn btn-primary ml-2 rounded-sm"
                                                        to={`/dashboard/manage/user/edit-till/${item.id}`}
                                                        style={{height:"1.7rem",width:"1.7rem"}}                                                      
                                                        >
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedAdminStaff && [
                                      searchedAdminStaff.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedAdminStaff.list.map(
                                          (item, index) => {
                                            let permissionGrp = permissionGroupForSearch && permissionGroupForSearch.find((group)=> group.id === item.permission_group_id)
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (tillUserList.current_page -
                                                      1) *
                                                      tillUserList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.branch.name || "-"}
                                                </td>
                                              
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <div className="d-flex align-items-center justify-content-center">
                                                      <NavLink
                                                        className="btn btn-primary ml-2 rounded-sm"
                                                        to={`/dashboard/manage/user/edit-till/${item.id}`}
                                                        style={{height:"1.7rem",width:"1.7rem"}}                                                      
                                                        >
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                            </div>
                                                        </NavLink>
                                                   </div>
                                                 </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {[
                    // logic === !searched
                    !searchedAdminStaff.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(
                                tillUserList,
                                setPaginatedTillUser
                              )}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(tillUserList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm rounded"
                                  onClick={() =>
                                    setSearchedAdminStaff({
                                      ...searchedAdminStaff,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedAdminStaff,
                                    tillUserForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
               ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default UserTill;
