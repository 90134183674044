import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const WaiteReport = () => {
  const { t } = useTranslation();
  //getting context values here
  let { loading, dataPaginating,setDataPaginating, generalSettings } = useContext(SettingsContext);
  const { waiterForSearch } = useContext(UserContext);
  const { branchForSearch } = useContext(RestaurantContext);
 
  //all data
  const [reportData, setReportData] = useState(null);

  const componentRef = useRef();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);

  const [siteName, setSiteName] = useState("");
  const [waiter, setWaiter] = useState(null);
  const [branch, setBranch] = useState(null);

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
  }, [generalSettings]);

  const handleWaiter = (waiter) =>{
   setWaiter(waiter)
  }

  const handleBranch = (branches) => {
    setBranch(branches);
  }

  //get BranchWise reports filter
  const getwaiterWiseSelected = () => {
    setReportData(null);
    if (
      startDate !== null &&
      endDate !== null
    ) {
      setDataPaginating(true);
      let fromDate = moment(startDate).format("YYYY-MM-DD");
      let toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-waiter-orders-reports";
      let formData = new FormData();
      formData.append("from_date", fromDate);
      formData.append("to_date", toDate);
      formData.append("waiter_id", waiter?.id ? waiter?.id : "");
      formData.append("branch_id", branch?.id ? branch?.id : "");


      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          
          setReportData(res.data);
          setGeneratedReport(true);
          setDataPaginating(false);
          componentRef.current.handleDownload();
          setBranch(null);
          setWaiter(null);
        })
        .catch((error) => {
          setDataPaginating(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, to date"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  let g_total = 0;
  let total = 0;
  let g_Amount = 0;
  let Amount = 0;
  let tax = 0;
  let g_tax = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Waiter Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls1"
        filename="Waiter Report"
        sheet="sheet 1"
        buttonText="waiter Detail"
        ref={componentRef}
      />

      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls1"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="15" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Waiter Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >{_t(t("Branch"))}</th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Waiter"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Order No"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Order Type"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Party Type"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Customer"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Subtotal"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Tax"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Discount"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("D.Charges"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S.Charges"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Payment Type"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Net Amount"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here*/}
                                {Object.keys(reportData).map(
                                  (group_name, index) => {
                                    total = 0;
                                    Amount = 0;
                                    tax = 0;
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {reportData[group_name] && Object.keys(reportData[group_name])?.map(
                                          (waiter) => {
                                            return (
                                              <>
                                              <tr>
                                                <td></td>
                                                <td>{waiter}</td> 
                                              </tr>  
                                                { reportData[group_name][waiter] &&
                                                    reportData[group_name][waiter].map((item, i) =>{
                                                      g_total += parseFloat(item.total_payable);
                                                      total += parseFloat(item.total_payable);
                                                      g_Amount += parseFloat(item.order_bill);
                                                      Amount += parseFloat(item.order_bill);
                                                      tax += parseFloat(item.vat);
                                                      g_tax += parseFloat(item.vat);

                                                        return (
                                                            <tr>
                                                              <td></td>
                                                              <td></td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                {i + 1}
                                                              </td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                <Moment format="LL">
                                                                  {item.order_date}
                                                                </Moment>
                                                              </td>
                                                              <td>
                                                                {item.order_no}
                                                              </td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                {item.order_type_name}
                                                              </td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                {item.party_name}
                                                              </td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                {item.customer_name}
                                                              </td>
                                                              <td
                                                              scope="row"
                                                              >
                                                               {item.order_bill}
                                                              </td>
                                                              <td
                                                              scope="row"
                                                              >
                                                               {item.vat}
                                                              </td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                {item.discount_amount}
                                                              </td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                {item.delivery_charges}
                                                              </td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                {item.service_charges}
                                                              </td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                {item.payment_type}
                                                              </td>
                                                              <td
                                                                scope="row"
                                                              >
                                                                {item.total_payable}
                                                              </td>
                                                             
                                                            </tr>
                                                          );
                                                    })
                                                }
                                              </>                                                
                                            );
                                          }
                                        )}
                                        <tr>
                                          <th colspan="7"></th>
                                          <th>
                                            Total
                                          </th>
                                          <td>
                                            {formatPrice(Amount)}
                                          </td>
                                          <td>
                                            {formatPrice(tax)}
                                          </td>
                                          <td>
                                          </td>
                                          <td>
                                          </td>
                                          <td>
                                          </td>
                                          <td>
                                          </td>
                                          <td>
                                            {formatPrice(total)}
                                          </td>
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                                 <tr>
                                  <th colspan="7"></th>
                                  <th>
                                    Grand Total
                                  </th>
                                  <td>
                                    {formatPrice(g_Amount)}
                                  </td>
                                  <td>
                                    {formatPrice(g_tax)}
                                  </td>
                                  <td>
                                  </td>
                                  <td>
                                  </td>
                                  <td>
                                  </td>
                                  <td>
                                  </td>
                                  <td>
                                    {formatPrice(g_total)}
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                   
                    {/* spin loading ends */}

                    {/* Loading effect {loading === true ? (
                      tableLoading()
                    ) : ( */}
                    
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Waiter Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                        <div className="col-2 t-mb-15 mb-md-0">
                            <Select
                              options={waiterForSearch ? waiterForSearch : []}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="select"
                              className="sm-text "
                              value={waiter || ""}
                              styles={customStyle}
                              onChange={handleWaiter}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a waiter")) + ".."}
                              isClearable
                            />
                          </div>
                          <div className="col-2">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>

                          <div className="col-2 t-mb-15 mb-md-0">
                            <Select
                              options={branchForSearch ? branchForSearch : []}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              classNamePrefix="select"
                              className="sm-text "
                              value={branch || ""}
                              styles={customStyle}
                              onChange={handleBranch}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                              isClearable
                            />
                          </div>
                          <div className="col-2"></div>
                          <div className="col-2">
                            <button
                              className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getwaiterWiseSelected(e)}
                              >
                               Waiter Report
                              </button>
                          </div>

                        </div>
                        
                      </div>
                   
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>

    </>
  );
};

export default WaiteReport;
