import React, { useEffect, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";

const ProductionAction = () => {
    const { t } = useTranslation();
  const history = useHistory();
  let { loading, setLoading, ingredientItemForSearch, dataPaginating, branchForSearch, setProductionItemList,
    setProductionItemForSearch } = useContext(RestaurantContext);

  let { foodForPOS } = useContext(FoodContext);

  // States hook here
  const [production, setProduction] = useState({
    doc_no: "",
    type: "row",
    branch:null,
    date: new Date(),
    item:null,
    recipe_raw: 1,
    recipe_qty: 1,
    qty: 1, 
    actual_qty: 1,
    additional_amount: 0,
    remarks: "",
    unit: "",
  });
  const [productionDetails, setProductionDetails] = useState([])
  const {id} = useParams(); 

  const getIngredient = async (item)=>{
    if(production.branch === null){
      toast.error(`${_t(t("Please select branch"))}`);
    }else{
      const fromData = {
        branch_id: production.branch.id,
        item_id: item.id,
        prd_type: production.type,
      }
      const url = BASE_URL + "/settings/get-production-item";
      try {
       const res = await axios
        .post(url,fromData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res){
            if(res.data === "Recipe not found"){
              toast.error("Item Reciepe not found");
            }else{ 
              setProductionDetails(res.data.recipe_details);
              
              const qtyRecipe =  production.qty / (res.data?.recipe_qty ? res.data.recipe_qty : 1);
              setProduction({
                ...production,
                item: item,
                recipe_raw: qtyRecipe,
                recipe_qty: res.data?.recipe_qty ? res.data.recipe_qty : 1,
                unit: item?.unit_name ? item.unit_name : "Piece",
              })
            }
          }          
        } catch {
          toast.error(`${_t(t("Please try again"))}`);
        }
    }
  }
 
  const TypeChangeHandler = ({target}) => {
    setProduction({
      ...production,
      item:null,
      recipe_raw: 1,
      recipe_qty: 1,
      qty: 1,
      actual_qty: 1, 
      additional_amount: 0,
      remarks: "",
      unit: "",
      type: target.value,
    });
    setProductionDetails([])
  }

  const handleGetAmount = () =>{
    let total = 0 + (production.additional_amount ? parseFloat(production.additional_amount) : 0);
    total += productionDetails?.length && productionDetails.reduce((acc,item)=>{
      return acc + (parseFloat(item.cost_price) * parseFloat(item.actual_qty) * (production.recipe_raw ? production.recipe_raw : 1))
    },0);
    return total.toFixed(3);
  }

  const handleGetRate = () =>{
    const rate = handleGetAmount();
    return Number(production.actual_qty) > 0 ? ((Number(rate) / (production.actual_qty ? Number(production.actual_qty) : 1)).toFixed(3)) : 0;
  }

  const handleItemChange = (item) => {
    getIngredient(item)
  }

  const handleChangeProduction = ({target}) => {
    const {name, value} = target;
    if(name === "qty"){
      const reciepeQty = (value ? parseFloat(value) : 1) / production.recipe_qty;
      setProduction({...production, [name]: value, actual_qty: value, recipe_raw:reciepeQty})
    }else{
      setProduction({...production, [name]: value})
    }
  }

  const handleSetAdditionalAmount = (e) => {
    const value = e.target.value;
    
      setProduction({
        ...production,
        additional_amount: value,
      })
  }

  const setProductionApi = async () => {
    const dupUrl = id ? (BASE_URL + "/settings/update-production") :  BASE_URL + "/settings/new-production";
    
    if(productionDetails === null || productionDetails?.length === 0){
      setLoading(false);
      toast.error(`${_t(t("Please add ingredient item"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return false
    }
    setLoading(true);
    let date = moment(production.date).format("YYYY-MM-DD");
    let item = productionDetails?.length > 0 && productionDetails?.map((item)=>({
          ...item,
          ingredient_qty: parseFloat(item.actual_qty) * production.recipe_raw,
          cost_amount: parseFloat(item.cost_price) * parseFloat(item.actual_qty) * production.recipe_raw,
    }))
    let formData = {
      ...((id && {id: id})),
      document_no : production.doc_no,
      prd_type: production.type,
      item_id: production.item?.id,
      document_date: date,
      branch_id: production.branch?.id,
      qty: production.qty,
      recipe_qty: production.recipe_qty,
      actual_qty: production.actual_qty,
      items: JSON.stringify(item),
      cost: handleGetRate(),
      amount: handleGetAmount(),
      additional_amount: production.additional_amount ? production.additional_amount : 0,
      remarks: production.remarks,
      unit_code: production.unit,
    }
    try {
      let res = await axios
        .post(dupUrl, formData, {
          headers: { "Authorization": `Bearer ${getCookie()}`, 'Content-Type': 'application/json'},
        });

      setLoading(false);
      setProductionItemList(res.data[0])
      setProductionItemForSearch(res.data[1]);
      setProduction({
        doc_no: "",
        type: "row",
        branch:null,
        date: new Date(),
        item:null,
        recipe_raw: 1,
        recipe_qty: 1,
        qty: 1,
        actual_qty: 1,
        additional_amount: 0,
        remarks: "",
        unit: "",
      })
      setProductionDetails([])

      if(id){
        toast.success(`${_t(t("Production has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }else{
        toast.success(`${_t(t("Production has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
      history.push("/dashboard/manage/stock/production")
    } catch (error) {
      setLoading(false);
      toast.error(`${_t(t("Please try again later " + error))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };
  
    //send to server
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (
        production.item !== null && production.date !== null && production.branch !== null 
      ) {
        await setProductionApi();
      } else {
        toast.error(`${_t(t("Please fill all the required fields"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    };

    const handleSetBranch = (branch) =>{
      setProduction({
          ...production,
          branch,
          item:null,
          additional_amount: 0,
          remarks: "",
          unit: "",
      });
      setProductionDetails([]);
    }

    useEffect(() => {
      if(id){
        getEditProduction()
      }
    }, [id])
  
    const getEditProduction = async () => {
        const url =
          BASE_URL +
          "/settings/get-production-edit/" + parseInt(id);
        try {
          const res = await axios
            .get(url, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            });
            let ProductionDetail = {
              doc_no: res.data.production.document_no,
              type: res.data.production?.prd_type,
              date: new Date(res.data.production.document_date),
              branch: res.data.production.branch,
              item: res.data.production.item,
              recipe_raw: Number(res.data.production.qty) / Number(res.data.production.recipe_qty),
              recipe_qty: res.data.production.recipe_qty,
              qty: res.data.production.qty,
              actual_qty: res.data.production.actual_qty,
              additional_amount: res.data.production.additional_amount,  
              remarks: res.data.production.remarks,  
              unit: res.data.production.unit_code,         
            }
            setProduction(ProductionDetail)
            setProductionDetails(res.data.productionDetail);
            setLoading(false);
        } catch {
          setLoading(false);
        }
      };
  return (
    <>
    <Helmet>
      <title>{_t(t(`${id ? "Update Production" : "Add Production"}`))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ManageSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {loading === true ? (
                    tableLoading()
                  ) : (
                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t(`${id ? "Update Production" : "Add Production"}`))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center"></div>
                        </div>
                      </div>

                      {/* Form starts here */}
                      <form
                        className="row card p-2 mx-3 mb-5 table-text"
                        onSubmit={(e)=>handleSubmit(e)}
                        autoComplete="off"
                      >
                        <div className="col-12">

                        <div className="row">
                        <div className="form-group col-4 mt-3">
                          <div className="mb-2">
                            <label
                              htmlFor="document no"
                              className="control-label"
                            >
                              {_t(t("Document no"))}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="mb-2">
                            <input
                              readOnly
                              type="text"
                              className="form-control table-text"
                              id="document_no"
                              name="document_no"                                      
                              placeholder="Auto no"
                              value={production.doc_no}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group col-4 text-right mt-3">
                          <div className="mb-2 text-left">
                            <label
                              htmlFor="transfer_date"
                              className="control-label"
                            >
                              {_t(t("Document Date"))}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="mb-2">
                            <DatePicker
                              selected={production.date}
                              onChange={(date) => setProduction({...production, date: date})}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control table-text"
                              placeholderText={_t(t("Document date"))}
                              shouldCloseOnSelect={false}
                              required
                            />
                          </div>
                        </div>
                        
                        <div className="form-group mt-3 col-4">
                            <div className="mb-2">
                              <label
                                htmlFor="from-branch"
                                className="control-label"
                              >
                                {_t(t("Branch"))}
                                <span className="text-danger">*</span>                                        
                              </label>
                            </div>
                            <Select
                              options={branchForSearch ? branchForSearch : []}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              value={production.branch || null}
                              classNamePrefix="select"
                              onChange={handleSetBranch}
                              maxMenuHeight="200px"
                              styles={customStyle}
                              placeholder={
                                _t(t("Please select a branch")) + ".."
                              }
                            />
                        </div> 

                      </div>

                        <div className="row">
                          
                          <div className="form-group mt-3 col-2 d-flex align-items-center">
                            <div class="mt-4 form-check mx-2">
                              <input class="form-check-input" type="radio" name="type" value="row" id="row" checked={production.type === "row"} onChange={TypeChangeHandler} style={{width: "16px", height:"16px"}}/> 
                              <label class="form-check-label mx-1" for="row"> Raw </label>
                            </div>
                            <div class="mt-4 form-check mx-2">
                              <input class="form-check-input" type="radio" name="type" value="food" id="food" checked={production.type === "food"} onChange={TypeChangeHandler} style={{width: "16px", height:"16px"}}/> 
                              <label class="form-check-label mx-1" for="food"> Food </label>
                            </div>
                          </div> 
                          {(/*filteredItems || */ingredientItemForSearch || foodForPOS) && (
                            <div className="form-group mt-4 col-2">
                            <div className="mb-2">
                              <label
                                htmlFor="Production"
                                className="control-label"
                              >
                                  {_t(t("Production Item"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <Select
                                options={/* filteredItems */production.type === "row" ? ingredientItemForSearch : foodForPOS}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                classNamePrefix="select"
                                value={production.item}
                                onChange={handleItemChange}
                                maxMenuHeight="200px"
                                styles={customStyle}
                                id="Production"
                                placeholder={
                                  _t(t("Select Item")) + ".."
                                }
                              />
                            </div>
                          )}

                          <div className="form-group mt-4 col-2">
                            <div className="mb-2">
                              <label htmlFor="qty" className="control-label">
                                {_t(t("Recipe Qty"))}
                              </label>
                            </div>
                              <input
                                type="number"
                                className="form-control table-text"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()} 
                                min="1"
                                id="recipe_qty"
                                name="recipe_qty"
                                onChange={handleChangeProduction}
                                value={production.recipe_qty}
                                placeholder="reciepe qty"
                                style={{lineHeight:"1.5rem"}}
                                readOnly
                              />
                          </div>

                          <div className="form-group mt-4 col-2">
                            <div className="mb-2">
                              <label htmlFor="qty" className="control-label">
                                {_t(t("Qty"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                              <input
                                type="number"
                                className="form-control table-text"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()} 
                                min="1"
                                id="qty"
                                name="qty"
                                onChange={handleChangeProduction}
                                value={production.qty}
                                placeholder="qty"
                                style={{lineHeight:"1.5rem"}}
                                required
                              />
                          </div>

                          <div className="form-group mt-4 col-2">
                            <div className="mb-2">
                              <label htmlFor="actual_qty" className="control-label">
                                {_t(t("Actual Qty"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                              <input
                                type="number"
                                className="form-control table-text"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()} 
                                min="0"
                                id="actual_qty"
                                name="actual_qty"
                                onChange={handleChangeProduction}
                                value={production.actual_qty}
                                placeholder="actual qty"
                                style={{lineHeight:"1.5rem"}}
                                required
                              />
                          </div>

                          <div className="form-group mt-4 col-2">
                          <div className="mb-2">
                            <label htmlFor="unit" className="control-label">
                              {_t(t("Unit"))}
                            </label>
                          </div>
                            <input
                              type="text"
                              className="form-control table-text"
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              id="unit"
                              name="unit"
                              value={production.unit}
                              placeholder="unit"
                              style={{lineHeight:"1.5rem"}}
                              readOnly
                            />
                          </div>
                        </div>
                         
                         <div className="row">
                          <div className="form-group mt-4 col-2">
                            <div className="mb-2">
                              <label htmlFor="cost" className="control-label">
                                {_t(t("Rate Per Unit"))}
                              </label>
                            </div>
                              <input
                                type="number"
                                className="form-control table-text"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                id="cost"
                                name="cost"
                                value={handleGetRate()}
                                placeholder="rate"
                                style={{lineHeight:"1.5rem"}}
                                readOnly
                              />
                          </div>

                          <div className="form-group mt-4 col-2">
                            <div className="mb-2">
                              <label htmlFor="amount" className="control-label">
                                {_t(t("Amount"))}
                              </label>
                            </div>
                                <input
                                  type="number"
                                  className="form-control table-text"
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-","ArrowUp","ArrowDown",].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  id="amount"
                                  name="amount"
                                  value={handleGetAmount()}
                                  placeholder="amount"
                                  style={{lineHeight:"1.5rem"}}
                                  readOnly
                                />
                          </div>

                          <div className="form-group mt-4 col-8">
                          <div className="mb-2">
                            <label htmlFor="remarks" className="control-label">
                              {_t(t("Remarks"))}
                            </label>
                            </div>
                              <input
                                type="text"
                                className="form-control table-text"
                                id="remarks"
                                name="remarks"
                                onChange={handleChangeProduction}
                                value={production.remarks}
                                placeholder="remarks"
                              />
                          </div>
                        </div>

                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label
                                htmlFor="payment_type"
                                className="control-label"
                              >
                                {_t(t("Items"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>
                              <div className="table-responsive mt-2">
                                <table className="table table-bordered table-hover">
                                  <thead className="align-middle">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                        style={{width:"50%"}}
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Name"))}
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                        style={{width:"20%"}}
                                       >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                        style={{width:"20%"}}
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Cost Price"))}
                                        </span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                        style={{width:"20%"}}
                                       >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Cost Amount"))}
                                        </span>
                                      </th>
                                      
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                        style={{width:"20%"}}
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Unit"))}
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody className="align-middle">
                                    {productionDetails && productionDetails?.map(
                                      (ingredientItem, index) => {
                                        return (
                                          <tr
                                            className="align-middle table_row_dlt"
                                            key={index}
                                            style={{width: '100%'}}
                                          >
                                            <td className="table-text text-capitalize align-middle text-center show_dlt" style={{width:"50%"}}>
                                              {ingredientItem.ingredient_name}
                                            </td>


                                            <td className="sm-text text-capitalize align-middle text-center show_dlt">
                                              <input
                                                type="number"
                                                step="0.001"
                                                min="0"
                                                onKeyDown={(evt) =>
                                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                                  evt.preventDefault()
                                                }
                                                className="form-control text-center"
                                                id={ingredientItem.id}
                                                name={ingredientItem.id}
                                                value={(ingredientItem.actual_qty * (production.recipe_raw ? production.recipe_raw : 1)).toFixed(2)}
                                                placeholder="Qty"
                                                readOnly
                                              />
                                            </td>

                                            <td className="sm-text text-capitalize align-middle text-center show_dlt">
                                              <input
                                                type="number"
                                                step="0.001"
                                                min="0"
                                                onKeyDown={(evt) =>
                                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                                  evt.preventDefault()
                                                }
                                                className="form-control text-center"
                                                id={ingredientItem.id}
                                                name={ingredientItem.id}
                                                value={ingredientItem.cost_price}
                                                placeholder="cost"
                                                readOnly
                                              />
                                            </td>

                                            <td className="sm-text text-capitalize align-middle text-center show_dlt">
                                              <input
                                                type="number"
                                                step="0.001"
                                                min="0"
                                                onKeyDown={(evt) =>
                                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                                  evt.preventDefault()
                                                }
                                                className="form-control text-center"
                                                id={ingredientItem.id}
                                                name={ingredientItem.id}
                                                value={(parseFloat(ingredientItem.cost_price) * parseFloat(ingredientItem.actual_qty) * (production.recipe_raw ? production.recipe_raw : 1)).toFixed(3)}
                                                placeholder="amount"
                                                readOnly
                                              />
                                            </td>

                                            <td className="table-text text-capitalize align-middle text-center" style={{width:"20%"}}>
                                              {ingredientItem.unit_code}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                     <tr>
                                      <td></td>
                                      <td></td>
                                      <td className="sm-text text-capitalize align-middle text-center">Additional Amount :</td>
                                      <td className="sm-text text-capitalize align-middle text-center show_dlt">
                                        <input
                                          disabled={production.item === null || production.item === undefined}
                                          type="number"
                                          step="0.01"
                                          min="0"
                                          onKeyDown={(evt) =>
                                            ["e", "E", "+", "-"].includes(evt.key) &&
                                            evt.preventDefault()
                                          }
                                          onChange={(e)=>handleSetAdditionalAmount(e)}
                                          className="form-control text-center"
                                          id="additional"
                                          name="additional_amount"
                                          value={production.additional_amount}
                                          placeholder="Additional Amount"
                                        />
                                      </td>
                                      <td></td>
                                     </tr>
                                  </tbody>
                                </table>
                              </div>
                           
                          <div className="form-group mt-4 pb-2">
                            {/* <div className="col-lg-12">
                              <button
                                className="btn btn-primary px-5"
                                type="submit"
                              >
                                {_t(t(`${id ?  "Update" : "Create"}`))}
                              </button>
                            </div> */}
                            <div className="d-flex justify-content-end align-items-center">
                          <div className="col-3 d-flex justify-content-end">
                              <button
                              type="button"
                              className="btn rounded hov-btn mr-3"
                              onClick={()=>(history.push("/dashboard/manage/stock/production"))}
                              //   data-dismiss="modal"
                              >
                                  <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                              </button>
                              <button
                              disabled={loading}
                              type="submit"
                              className="btn btn-primary rounded"
                              >
                                  <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t(`${id ? "Update" : "Create"}`))}
                              </button>                                                    
                          </div>                                                    
                         </div>
                         </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
  </>
  )
}

export default ProductionAction
