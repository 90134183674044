import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  customStyle,
  getDate,  
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";

const WastageFoodView = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    //getting context values here
    let {
      loading,
      setLoading,
      branchForSearch,
    } = useContext(RestaurantContext);
   
    const { foodForPOS, propertyItemList } = useContext(FoodContext)

    // States hook here
    //new item
    let [newWastage, setNewWastage] = useState({
      documentDate: new Date,
      branch:null,
      variation:'',
      type:'',
      document_no:''
    });    
  

    //selected items states
    const [property, setProperty] = useState(null);
    const [food, setFood] = useState(null);

    //variation
    const [showVariation, setShowVariation] = useState(false);

    //radio item
    const [optionType, setOptionType] = useState(null);
    const [optionFG, setOptionFG] = useState(null);

    //new Row
    const [newRow,setNewRow]=useState([]);

    //specific item
    const [wastageItem,setWastageSetItem] = useState(null);

    const getSpecificWastage = async () => {
        setLoading(true);
        const url =
        BASE_URL +
        "/settings/signal-stock-wastage/" +
        parseInt(params.id);
        try {
        const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res){
            console.log("check data==>",res.data)
            setWastageSetItem(res.data)
          }
          setLoading(false);
        } catch(e) {
          console.log("Error==>",e)
        }
    }

    useEffect(() => {
      if(params.id){
        getSpecificWastage();
      }
    }, [params.id]);

    useEffect(() => {
      if(wastageItem && wastageItem !== null && foodForPOS && branchForSearch){
          const documentDate= new Date(wastageItem?.document_date);
          const branch= branchForSearch?.find((item)=>wastageItem?.branch_id === item.id)
          if(wastageItem.type === "food"){
            const foodItem = foodForPOS?.find((item)=> item.id === wastageItem.item_id);
            setOptionFG("food_item");
            setFood(foodItem)
          }else if(wastageItem.type === "property"){
            const propertyItem = propertyItemList?.find((item)=> item.id === wastageItem.item_id);
            setOptionFG("property_item");
            setProperty(propertyItem)
          }
          
          setNewWastage({
            ...newWastage,
            document_no:wastageItem?.document_identity,
            documentDate,
            variation:wastageItem?.variation_name,
            branch,
            remarks:wastageItem.remarks,            
          })
          setOptionType(wastageItem.type === "raw" ? "raw_material" : "finished_goods");
          setShowVariation(wastageItem?.variation_name !== null ? true : false);              
          let rowArr=[];
          if(wastageItem && wastageItem.stock_wastage_details.length > 0){
              wastageItem.stock_wastage_details.map((item)=>{                
              rowArr.push({
                item_id: item.id,                 
                item_name:item.item_name,
                qty:parseInt(item.qty).toFixed(0),
                unit_id:item.unit_id,
                unit_name:item.unit_name || "Pcs",
              })
            })
            setNewRow([...rowArr]);
          }
      }
    }, [wastageItem,branchForSearch,foodForPOS]);                      

    return (
      <>
        <Helmet>
          <title>{_t(t("View Wastage Item"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div
                    className="fk-scroll--pos-menu table-bottom-info-hide"
                    data-simplebar
                  >
                    <div className="t-pl-15 t-pr-15">
                    <div className={`${(loading) && "loadingBlur"}`}></div>
                    <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* Loading effect */}
                      {/* {loading === true ? (
                        tableLoading()
                      ) : ( */}
                        <div key="smtp-form">
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {_t(t("View Wastage Item"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center"></div>
                            </div>
                          </div>
  
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 mb-5 table-text"                            
                          >
                            <div className="col-12">

                              {/* master fields */}
                              <div className="row">
                              <div className="form-group col-4 mt-3">
                                <div className="mb-2">
                                  <label
                                    htmlFor="document no"
                                    className="control-label"
                                  >
                                    {_t(t("Document no"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    readOnly
                                    type="text"
                                    className="form-control table-text"
                                    id="document_no"
                                    name="document_no"                                    
                                    placeholder="Auto no"
                                    required
                                    value={newWastage?.document_no}
                                  />
                                </div>
                              </div>

                              <div className="form-group col-4 text-right mt-3">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="document_date"
                                    className="control-label"
                                  >
                                    {_t(t("Document Date"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <DatePicker
                                    readOnly
                                    selected={newWastage.documentDate || ""}                                    
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("document date"))}
                                    shouldCloseOnSelect={false}
                                  />
                                </div>
                              </div>
                              
                              <div className="form-group mt-3 col-4">
                                  <div className="mb-2">
                                    <label
                                      htmlFor="branch"
                                      className="control-label"
                                    >
                                      {_t(t("Branch"))}
                                      <span className="text-danger">*</span>                                      
                                    </label>
                                  </div>
                                  <Select
                                    options={branchForSearch ? branchForSearch : []}
                                    components={makeAnimated()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={newWastage.branch || null}
                                    classNamePrefix="select"                                    
                                    maxMenuHeight="200px"
                                    styles={customStyle}
                                    placeholder={
                                      _t(t("Please select a branch")) + ".."
                                    }
                                    isDisabled={true}
                                  />
                              </div> 
                            </div>

                              {/* header with select of raw material */}
                              <div className="row">
                                <div className="form-group mt-3 col-4 d-flex align-items-center">
                                    <div className="d-inline mr-3 d-flex align-items-center">
                                        <input
                                        readOnly
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="raw_material"
                                        name="raw_material"
                                        value="raw_material"                                    
                                        checked={
                                            optionType === "raw_material"
                                            ? 1
                                            : 0
                                        }
                                        />
                                        <label
                                        className="form-radio-label pointer-cursor ml-2 pt-1"
                                        htmlFor="raw_material"                                        
                                        >
                                            {_t(t("Raw Material"))}
                                        </label>
                                    </div>

                                    <div className="form-check d-inline mr-2 d-flex align-items-center">
                                        <input
                                        readOnly
                                        type="radio"
                                        className="form-check-input pointer-cursor"
                                        id="finished_goods"
                                        name="finished_goods"
                                        value="finished_goods"                                        
                                        checked={
                                            optionType === "finished_goods"
                                            ? 1
                                            : 0
                                        }
                                        />
                                        <label
                                        className="form-radio-label pointer-cursor ml-2 pt-1"
                                        htmlFor="finished_goods"                                        
                                        >
                                         {_t(t("Finished Goods"))}
                                        </label>
                                    </div>
                                </div>  

                                <div className="form-group mt-2 col-8">
                                  <label
                                    className="mb-2 control-label"
                                    htmlFor="remarks"
                                    >
                                        {_t(t("Remarks"))}
                                    </label>
                                    
                                    <input
                                    type="text"
                                    className="form-control table-text"
                                    id="remarks"
                                    name="remarks"
                                    value={newWastage.remarks}
                                    placeholder="Remarks"
                                    readOnly
                                    />
                                </div>                                                         
                              </div>   
                              
                               {/* finished goods option */}
                               <div className="row">
                               {optionType === 'finished_goods' && <div className="form-group mt-3 col-4 d-flex align-items-center">
                                   <div className="form-check d-inline mr-3 d-flex align-items-center">
                                       <input
                                       type="radio"
                                       className="form-check-input pointer-cursor"
                                       id="food_item"
                                       name="food_item"
                                       value="food_item"
                                       checked={
                                           optionFG === "food_item"
                                           ? 1
                                           : 0
                                       }
                                       />
                                       <label
                                       className="form-radio-label pointer-cursor ml-2 pt-1"
                                       htmlFor="food_item"                                        
                                       >
                                           {_t(t("Food Item"))}
                                       </label>
                                   </div>

                                   <div className="form-check d-inline mr-2 d-flex align-items-center">
                                       <input
                                       type="radio"
                                       className="form-check-input pointer-cursor"
                                       id="property_item"
                                       name="property_item"
                                       value="property_item"
                                       checked={
                                           optionFG === "property_item"
                                           ? 1
                                           : 0
                                       }
                                       />
                                       <label
                                       className="form-radio-label pointer-cursor ml-2 pt-1"
                                       htmlFor="property_item"                                        
                                       >
                                        {_t(t("Property Item"))}
                                       </label>
                                   </div>
                               </div>}                             
                             </div>

                             {optionFG !== null && (<div className="row">
                                   <div className="form-group mt-3 col-4">                                    
                                       <Select
                                       isDisabled={true}
                                       getOptionLabel={(option) => option.name}
                                       getOptionValue={(option) => option.id}
                                       value={optionFG==='food_item' ? food : property}
                                       classNamePrefix="select"
                                       maxMenuHeight="200px"
                                       styles={customStyle}
                                       placeholder={
                                           optionFG==='food_item' ?
                                           _t(t("Please select food")) + ".." :
                                           _t(t("Please select property item")) + ".."
                                       }
                                       />
                                   </div>
                               </div>)}

                                {/* show variation */}
                                {showVariation && (<div className="mt-3" style={{width:"200px"}}>
                                    <div className="mb-2">
                                        <label
                                            className="control-label"
                                        >
                                            {_t(t("Variation"))}
                                            <span className="text-danger">*</span>
                                        </label>
                                        </div>
                                    <div className="d-flex align-items-center gap-4">                                    
                                        <div class="form-check mt-1 mx-2">
                                            <input class="form-check-input pointer-cursor"                                                
                                                type="radio"                                                                                                  
                                                checked={true}
                                                readOnly                                                
                                            /> 
                                            <label class="form-radio-label mx-1 text-capitalize pointer-cursor">
                                                {newWastage?.variation} 
                                            </label>
                                        </div>                                        
                                    </div>
                                </div>)}                              
                              
                              <div className={`table-responsive mt-3 pr-1 `}
                              style={{maxHeight:"23rem",overflow: "auto"}}>
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">                                    
                                      <tr>                                      
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                          style={{width:"35rem"}}
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Item"))}
                                          </span>
                                        </th>                                     
                                        
                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Qty"))}
                                          </span>
                                        </th>

                                        <th
                                          scope="col"
                                          className="table-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          <span style={{fontWeight:500}}>
                                          {_t(t("Unit"))}
                                          </span>
                                        </th>                                                                            
                                      </tr>
                                    </thead>
                                      {newRow && newRow.length>0 ?
                                    <tbody className="align-middle">
                                      {newRow.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >                                            
                                              <td className="table-text text-capitalize align-middle text-center">                                              
                                                {item.item_name}
                                              </td>                                      

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <input                                                  
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) =>
                                                    ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                                    evt.preventDefault()
                                                  }
                                                  onWheel={(e) => e.target.blur()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={item.id}
                                                  name={item.id}                                                  
                                                  value={item.qty || ""}
                                                  placeholder="Qty"
                                                  required 
                                                  disabled={true}                                                 
                                                />
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">                                                
                                                {item.unit_name}
                                              </td>                                                                                        
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    :null}                                    
                                  </table>
                              </div>
                                
  
                              <div className="form-group mt-4 pb-2">                            
                                <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/wastage-food"))}                                
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>                                                                                   
                            </div>                                                    
                        </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
      </>
    );
}

export default WastageFoodView