import React, { useState, useContext, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";
import { UserContext } from "../../../../../contexts/User";
import Moment from "react-moment";

const Settlement = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  let {
    //settlement list
    getSettlementList,
    settlementList, 
    setSettlementList,
    setPaginatedSettlementList,
    settlementListForSearch, 
    setSettlementListForSearch,
  } = useContext(UserContext);


  //search result
  let [searchedList, setSearchedList] = useState({
    list: null,
    searched: false,
  });
  //useEffect == componentDidMount
  // useEffect(() => {
  //   getSettlementList();
  // }, []);

  //search food group here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedList({ ...searchedList, searched: false });
    } else {
      let searchedList = settlementListForSearch.filter((item) => {
        let lowerCaseItemName = item.payment_type.toLowerCase();
        return lowerCaseItemName.includes(searchInput);
      });
      setSearchedList({
        ...searchedList,
        list: searchedList,
        searched: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Settlements"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newKOT.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(loading) && "loadingBlur"}`}></div>
                      <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedList.searched
                                    ? _t(t("Settlement List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <NavLink
                                  to="/dashboard/manage/restaurant/settlement-add"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                >
                                  {_t(t("add new"))}
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("From Date"))}
                                </th>

                                <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                               >
                                 {_t(t("To Date"))}
                               </th>
                               <th
                               scope="col"
                               className="sm-text text-capitalize align-middle text-center border-1 border"
                               >
                                 {_t(t("Payment Type"))}
                               </th>

                               <th
                               scope="col"
                               className="sm-text text-capitalize align-middle text-center border-1 border"
                               >
                                 {_t(t("Amount"))}
                               </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedList.searched
                                ? [
                                    settlementList && [
                                      settlementList?.data?.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        settlementList?.data && settlementList.data.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (settlementList.current_page - 1) *
                                                    settlementList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <Moment format="LL">
                                                  {item.from_date}
                                                </Moment>
                                              </td>
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <Moment format="LL">
                                                  {item.to_date}
                                                </Moment>
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.payment_type}
                                              </td>
                                              
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {parseFloat(item.amount).toFixed(2)}
                                              </td>
                                              <td className="xsm-text text-capitalize align-middle text-center">                                                
                                                <NavLink
                                                  className="btn btn-warning ml-2 rounded-sm"
                                                  to={`/dashboard/manage/restaurant/settlement-view/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                           
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-eye edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedList && [
                                      searchedList.list?.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedList.list.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (settlementList.current_page - 1) *
                                                    settlementList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.branch_name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.printer_ip}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {/* <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() =>
                                                        handleSetEdit(item.slug)
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#addPaymentType"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.slug
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                </div> */}
                                                <NavLink
                                                  className="btn btn-warning ml-2 rounded-sm"
                                                  to={`/dashboard/manage/restaurant/settlement-view/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                           
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-eye edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedList.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(settlementList, setPaginatedSettlementList)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(settlementList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedList({
                                      ...searchedList,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedList,
                                    settlementListForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
}

export default Settlement;