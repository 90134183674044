import React, { useEffect, useContext, useState } from "react";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  customStyle,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import Select from "react-select";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";

const WebMapping = () => {
  const { t } = useTranslation();
  let {
    loading,
    setLoading,
    dataPaginating,
  } = useContext(SettingsContext);

  const webMapping = [
    {
      id: 1,
      name: "Branch",
      value:"branches"
    },
    {
      id: 2,
      name: "Food Item",
      value:"food_items"
    },
    {
      id: 3,
      name: "Food Groups",
      value: "food_groups"

    },
    {
      id: 4,
      name: "Property Groups",
      value:"property_groups"
    },
    {
      id: 5,
      name: "Property Items",
      value:"property_items"
    },
    {
      id: 6,
      name: "Variations",
      value:"variations"
    },
  
  ]

  const [mapping, setMapping] = useState({
    type: null,
    pos_id: "",
    web_id: "",
  });

  const handleChange = (e) => {
    setMapping({ ...mapping, [e.target.name]: e.target.value });
  };

  const handleSetType = (type) => {
    setMapping({ ...mapping, type: type });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (mapping.type !== null) {
      let formData = {
        type: mapping.type?.value,
        pos_id: mapping.pos_id,
        web_id: mapping.web_id,
      };
      const url = BASE_URL + "/settings/web-pos-mapping";
      setLoading(true);
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setMapping({
              type: null,
              pos_id: "",
              web_id: "",
          })
          toast.success(<p style={{whiteSpace:"pre-line"}}>{res.data.message}</p>,{
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          })
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      toast.error(`${_t(t("Please fill required fields"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Web Mapping"))}</title>
      </Helmet>
      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div className="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0 ">
                            <ul className="t-list mb-3 fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Web Mapping"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                          {/* Form starts here */}
                          <form
                            className="row card p-2 mx-3 sm-text my-2"
                            style={{ width: "97%" }}
                            onSubmit={handleSubmit}
                            autoComplete="off"
                          >
                           <div className="row pl-4 mt-3">
                              <div className="col-4 form-group">
                                <div className="mb-2">
                                  <label
                                  className="control-label"
                                  >
                                   {_t(t("Type"))}
                                   <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <Select
                                    options={webMapping ? webMapping : []}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={mapping.type}
                                    onChange={(type) => handleSetType(type)}
                                    classNamePrefix="Branch"
                                    placeholder="Branch"
                                    styles={customStyle}
                                  />
                                </div>
                              </div>
                              <div className="col-4 form-group">
                                <div className="mb-2">
                                  <label
                                  htmlFor="discount"
                                  className="control-label"
                                  >
                                   {_t(t("Pos Id"))}
                                   <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input type="text" className="form-control"
                                    name="pos_id"
                                    onChange={handleChange}
                                    value={
                                        mapping.pos_id || ""
                                    }
                                    required
                                    placeholder="Pos id"
                                  />
                                </div>
                              </div>

                              <div className="col-4 form-group">
                                <div className="mb-2">
                                  <label
                                  className="control-label"
                                  >
                                   {_t(t("Web Id"))}
                                   <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input type="text" className="form-control"
                                    name="web_id"
                                    onChange={handleChange}
                                    value={
                                        mapping.web_id || ""
                                    }
                                    required
                                    placeholder="Web id"
                                  />
                                </div>
                              </div>
                               
                            </div> 
                           
                            <div className="form-group mt-4 pb-3">
                              <div className="mr-4 float-right">
                                <button
                                  className="btn btn-primary px-5 rounded"
                                  type="submit"
                                >
                                  {_t(t("Save"))}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default WebMapping;
