import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
import { useReactToPrint } from "react-to-print";
// import StockReport from "./StockReport";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import { FoodContext } from "../../../../../contexts/Food"


const GoodsReceivedNoteEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  //getting context values here
  let {
    loading,
    setLoading,
    branchForSearch,
    getIngredientItem,
    ingredientItemForSearch,
    ingredientItemStock,
    getWorkPeriod,
    dataPaginating,
  } = useContext(RestaurantContext);
  let { getSupplier, supplierForSearch } = useContext(UserContext);
  let { workPeriodWeb, getFoodWeb } = useContext(FoodContext);
  // States hook here
  //new item
  let [newItem, setNewItem] = useState({
    gr_no:"",
    branch: null,
    supplier: null,
    description: "",      
  });
  //let [reverseItem,setReverseItem] = useState(null);
  //datepicker
  const [goodsDate, setgoodsDate] = useState(new Date());

  //edit notes
  const [editNotes,setEditNotes]=useState();

  //qty and rate
  let [qtyOfItem, setQtyOfItem] = useState(null);
  let [rateOfItem, setRateOfItem] = useState(null);

  //tax
  let [taxPercent, setTaxPercent] = useState(null);
  let [discountPercent, setDiscountPercent] = useState(null);
  const componentRef = useRef("");
  

  //Get Specific item for edit
  const getGoodsNotes = async () => {
    setLoading(true);
    const url =
    BASE_URL +
      "/settings/get-ingredient_purchase_items/" +
      parseInt(params.id);
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });        
          if(res){
            setEditNotes(res.data)
          }         
          
          setLoading(false);
        } catch {
          setLoading(false);
        }
      };

  //useEffect == componentDidMount()
  useEffect(() => {
    getFoodWeb();
    getSupplier();
    getIngredientItem();
    getGoodsNotes();
  }, []);
  let rowId=useRef(0);
  useEffect(()=>{
    if(editNotes && editNotes !== null){
      let docDate=new Date(editNotes[1].purchase_date);
      let branch=branchForSearch?.find((item)=>editNotes[1].branch_id === item.id)
      let suppAcc=supplierForSearch?.find((item)=>editNotes[1].supplier_id === item.id)
      setNewItem({
        ...newItem,
        gr_no:editNotes[1].invoice_number,
        branch,
        supplier:suppAcc,        
        description:editNotes[1].desc,
      })
      setgoodsDate(docDate);
      let rowArr=[];
      if(editNotes[0] && editNotes[0].length > 0){
        editNotes[0].map((item,index)=>{
          let ing_item=ingredientItemForSearch?.find((v)=>v.id===item.ingredient_id);
          rowArr.push({
            id:rowId.current,
            item_name:ing_item,
            qty:item.qty,
            unitId:item.unit_id,
            unit:item.unit_name,
            rate:item.rate,
            amount:item.total_amount,
            discount_percent:parseFloat(item.discount_percent).toFixed(2),
            discount:item.discount,
            tax_percent:parseFloat(item.tax_percent).toFixed(2),
            tax:item.tax, 
            total:item.total,
            base_unit:item.base_unit,
            base_qty:item.base_qty,
            conversion_qty:parseFloat(item.conversion_qty).toFixed(2),
            isEdit:true,
          })
          rowId.current += 1
        })
        setNewRow([...rowArr]);
      }
    }
    },[editNotes,supplierForSearch,branchForSearch])


const handleOnlyPrint = useReactToPrint({
  content: () => componentRef.current,
  
});

  //select style
  const customStyle = {
    control: (provided) => ({
      ...provided,
      minHeight: "2rem",
      marginTop: "0",
      marginBottom: "0",
    }),

    singleValue: (provided) => ({
      ...provided,
      alignSelf: "center",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
    }),
    valueContainer: (provided) => ({
      ...provided,
      marginTop: "0",
      padding: "0 0.5rem",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0 0.4rem",
      svg: {
        height: "2rem",
      },
    }),
  };

  //on change input field
  const handleDesc = (e) => {
    setNewItem({ ...newItem, description: e.target.value });
  };

  //set items hook
  const handleSetItems = async (index,item) => {
    // let data = newItem?.items ? newItem.items : []
    // if(data.includes(item)){
    //   toast.error("Already exist please select another item")
    // }else{
    //   //newItem?.items && data.push(...newItem.items.reverse());
    //   data.unshift(item);
    //   setNewItem({
    //     ...newItem,
    //     items: data,
    //   });
    // }
    newRow[index].unit="";
    newRow[index].base_unit="";
    newRow[index].base_qty="";
    newRow[index].conversion_qty="";
    // console.log("check item==>",item);
    newRow[index].item_name=item;
    newRow[index].base_unit=item.unit_id;
    newRow[index].item_units=""
    setNewRow([...newRow]);
    const url = BASE_URL + "/settings/get-unit_item/"+item.id;
    await axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
    .then((res) => {
      if(res.data){
      newRow[index].item_units = res.data;
      setNewRow([...newRow]);
      }      
    }).catch((error) =>{
      console.log("check error",error);
    })
  };


  //handle unit
  const handleSetUnit=(index,unit)=>{
    newRow[index].unit=unit;
    newRow[index].conversion_qty=parseFloat(unit.qty).toFixed(2);
    setNewRow([...newRow]);
  }

     //subtotal
     const handleGetAmount = (slug) => {
      let qty = 0;
      let rate = 0;
      if (newRow !== null && newRow[slug].qty) {
        qty = parseFloat(newRow[slug].qty);
      }
      if (newRow !== null && newRow[slug].rate) {
        rate = parseFloat(newRow[slug].rate);
      }
      return rate * qty
    };

  //subtotal
  const handleGetSubTotal = (slug) => {
    let qty = 0;
    let rate = 0;
    let tax = 0;
    let discount = 0;

    if (newRow !== null && newRow[slug].qty) {
      qty = parseFloat(newRow[slug].qty);
    }
    if (newRow !== null && newRow[slug].rate) {
      rate = parseFloat(newRow[slug].rate);
    }
    if (newRow !== null && newRow[slug].tax_percent) {
      tax = parseFloat(newRow[slug].tax_percent);
    }
    if (newRow !== null && newRow[slug].discount_percent) {
      discount = parseFloat(newRow[slug].discount_percent);
    }
    return rate * qty +( rate * qty * (discount / 100) ) - (rate * qty * ( tax / 100));
  };

  const handleTaxItem = (slug) => {
    let tax = 0;
    let rate = 0;
    let qty = 0;

    if (newRow !== null && newRow[slug].qty) {
      qty = parseFloat(newRow[slug].qty);
    }
    
    if (newRow !== null && newRow[slug].tax_percent) {
      tax = parseFloat(newRow[slug].tax_percent);
    }

    if (newRow !== null && newRow[slug].rate) {
      rate = parseFloat(newRow[slug].rate);
    }
    return rate * qty * ( tax / 100);
    
  };

  const handleDiscountItem = (slug) => {
    let discount = 0;
    let rate = 0;
    let qty = 0;

    if (newRow !== null && newRow[slug].qty) {
      qty = parseFloat(newRow[slug].qty);
    }
    
    if (newRow !== null && newRow[slug].discount_percent) {
      discount = parseFloat(newRow[slug].discount_percent);
    }
    if (newRow !== null && newRow[slug].rate) {
      rate = parseFloat(newRow[slug].rate);
    }
 
    return rate * qty * ( discount / 100) ;
  };

  const handleDisAmount = () => {
    let discount = 0;
    if (newItem.items !== null) {
      newItem.items.map((item) => {
        if (discountPercent !== null && discountPercent[item?.id]) {
          discount =
            discount +
            rateOfItem[item.id] *
              qtyOfItem[item.id] *
              (discountPercent[item.id] / 100);
        }
      });
    }
    // setNewItem({
    //   ...newItem,
    //   item_dis:total
    // });
    return discount;
  };

   // total amount
const handleAmount = () => {
  let total = 0;
  if (newItem.items !== null) {
    newItem.items.map((item) => {
      if (
        qtyOfItem &&
        rateOfItem &&
        qtyOfItem[item.id] &&
        rateOfItem[item.id]
      ) {
        total = total + qtyOfItem[item.id] * rateOfItem[item.id];
      }
    });
  }
  // setNewItem({
  //   ...newItem,
  //   item_amount:total
  // });
  return total;
};
 
const handleTaxAmount = () => {
  let tax = 0;
  if (newItem.items !== null) {
    newItem.items.map((item) => {
      if (taxPercent !== null && taxPercent[item?.id]) {
        tax =
          tax +
          rateOfItem[item.id] *
            qtyOfItem[item.id] *
            (taxPercent[item.id] / 100);
      }
    });
  }
  // setNewItem({
  //   ...newItem,
  //   item_tax:total
  // });
  return tax;
};

  //total
  const handleGetTotal = () => {
    // let total = 0;
    // let tax = 0;
    // let discount = 0;
    // if (newRow !== null) {
    //   newRow.map((item) => {
    //     if(taxPercent !== null && taxPercent[item?.id]){
    //       tax = rateOfItem[item.id] *  qtyOfItem[item.id] * ( taxPercent[item.id] / 100);
    //     }
    //     if(discountPercent !== null && discountPercent[item?.id]){
    //       discount = rateOfItem[item.id] * qtyOfItem[item.id] * ( discountPercent[item.id] / 100)
    //     }
    //     if (
    //       qtyOfItem &&
    //       rateOfItem &&
    //       qtyOfItem[item.id] &&
    //       rateOfItem[item.id]
    //     ) {
    //       total = total + qtyOfItem[item.id] * rateOfItem[item.id] +  tax - discount ;
    //     }
    //   });
    // }
    // return total;
    let total = 0;
    let tax = 0;
    let discount = 0;
    if (newRow !== null) {
      newRow.map((item) => {
        if(item.rate && item.qty && item.tax_percent){
          tax = parseFloat(item.rate) *  parseFloat(item.qty) * ( parseFloat(item.tax_percent) / 100);
        }
        if(item.rate && item.qty && item.discount_percent){
          discount = parseFloat(item.rate) * parseFloat(item.qty) * ( parseFloat(item.discount_percent) / 100)
        }
        if (item.rate && item.qty) {
          total = total + parseFloat(item.rate) * parseFloat(item.qty) +  tax - discount ;
        }
      });
    }
    return total;
  };

  //set each item qty
  const handleItemQty = (index,e) => {
    newRow[index].qty=e.target.value;
    setNewRow([...newRow]);
  };

  //set each item qty
  const handleItemRate = (index,e) => {
    // setRateOfItem({
    //   ...rateOfItem,
    //   rate: e.target.value,
    // });
    newRow[index].rate=e.target.value;
    setNewRow([...newRow]);
  };


    //set each item qty
    const handleTax = (index,e) => {
      // setTaxPercent({
      //   ...taxPercent,
      //   [e.target.name]: e.target.value,
      // });
      newRow[index].tax_percent=e.target.value;
      setNewRow([...newRow]);
    };

      //set each item qty
  const handleDiscount = (index,e) => {
    // setDiscountPercent({
    //   ...discountPercent,
    //   discount_percent: e.target.value,
    // });
    newRow[index].discount_percent=e.target.value;
    setNewRow([...newRow]);
  };

  //handle Set branch hook
  const handleSetBranch = (branch) => {
    let theWorkPeriod =
      workPeriodWeb &&
      workPeriodWeb.find((thisItem) => {
        return parseInt(thisItem.branch_id) === parseInt(branch.id);
      });
    if (theWorkPeriod !== undefined) {
      setNewItem({ ...newItem, branch });
    } else {
      setNewItem({ ...newItem, branch: null });
    }
  };

  //handle Set supplier hook
  const handleSetSupplier = (supplier) => {
    setNewItem({ ...newItem, supplier });
  };

  //post req of food item add
  // const ingredientItemAxios = async () => {
  //   // setLoading(true);
  //   let date =
  //     new Date(goodsDate).getFullYear() +
  //     "-" +
  //     (new Date(goodsDate).getMonth() + 1) +
  //     "-" +
  //     new Date(goodsDate).getDate();

  //   //converting items and prices to array
  //   let slugArray = [];
  //   newRow.map((newIngredientItem) => {
  //     slugArray.push({
  //       qty: qtyOfItem && qtyOfItem[newIngredientItem?.id],
  //       rate: rateOfItem && rateOfItem[newIngredientItem?.id],
  //       item_id: newIngredientItem?.id,
  //       item_name: newIngredientItem.name,
  //       unit_name: newIngredientItem.unit,
  //       amount: handleGetAmount(newIngredientItem?.id),
  //       discount_percent: discountPercent && discountPercent[newIngredientItem?.id],
  //       discount_amount: handleDiscountItem(newIngredientItem?.id),
  //       tax_percent: taxPercent && taxPercent[newIngredientItem?.id],
  //       tax_amount: handleTaxItem(newIngredientItem?.id),
  //       net_amount: handleGetSubTotal(newIngredientItem?.id),
  //     });
  //   });
  //   let formData = {
  //     branch_id : newItem.branch.id,
  //     supplier_id : newItem.supplier.id,
  //     document_no : newItem.invoice,
  //     document_date : date,
  //     remarks : newItem.description,
  //     paid_amount : newItem.paid,
  //     total_amount : handleGetTotal(),
  //     total_net_amount : handleAmount(),
  //     total_tax : handleTaxAmount(),
  //     total_discount : handleDisAmount(),
  //     items : slugArray
  //   }

  //   // const url = BASE_URL + "/settings/new-ingredient_purc";
  //   // try {
  //   //   const res = await axios
  //   //     .post(url, formData, {
  //   //       headers: { Authorization: `Bearer ${getCookie()}` },
  //   //     });
  //   //   getIngredientItem();
  //   //   setNewItem({
  //   //     branch: null,
  //   //     supplier: null,
  //   //     invoice: "",
  //   //     description: "",
  //   //     paymentType: "",
  //   //     items: null,
  //   //     paid: null,
  //   //     total: null,
  //   //     due: null,
  //   //   });
  //   //   setQtyOfItem(null);
  //   //   setRateOfItem(null);
  //   //   setTaxPercent(null);
  //   //   setDiscountPercent(null)
  //   //   setLoading(false);
  //   //   toast.success(`${_t(t("Purchase has been added"))}`, {
  //   //     position: "bottom-center",
  //   //     autoClose: 10000,
  //   //     hideProgressBar: false,
  //   //     closeOnClick: true,
  //   //     pauseOnHover: true,
  //   //     className: "text-center toast-notification",
  //   //   });
  //   // } catch (error) {
  //   //   handleOnlyPrint()
  //   //   setLoading(false);
  //   //   toast.error(`${_t(t("Please try again later"))}`, {
  //   //     position: "bottom-center",
  //   //     autoClose: 10000,
  //   //     hideProgressBar: false,
  //   //     closeOnClick: true,
  //   //     pauseOnHover: true,
  //   //     className: "text-center toast-notification",
  //   //   });
  //   // }
  // };
  const ingredientItemAxios = async () => {
    let date =
      new Date(goodsDate).getFullYear() +
      "-" +
      (new Date(goodsDate).getMonth() + 1) +
      "-" +
      new Date(goodsDate).getDate();
    let slugArray=[];
    newRow.map((newIngredientItem,index) => {
      if(newIngredientItem.item_name === "" || newIngredientItem.unit===""){
        slugArray.push();
      }
      else{
        slugArray.push({
          qty: parseInt(newIngredientItem.qty),
          price: parseFloat(newIngredientItem.rate),
          item_id: newIngredientItem.item_name.id,
          item_name: newIngredientItem.item_name.name,          
          unit_id: newIngredientItem.isEdit ? newIngredientItem.unitId : newIngredientItem.unit.id,
          unit_name: newIngredientItem.isEdit ? newIngredientItem.unit : newIngredientItem.unit.name,
          amount: handleGetAmount(index),
          discount_percent: newIngredientItem.discount_percent !== "" ? parseFloat(newIngredientItem.discount_percent) : 0,
          discount: handleDiscountItem(index),
          tax_percent: newIngredientItem.tax_percent !== "" ?  parseFloat(newIngredientItem.tax_percent) : 0,
          tax: handleTaxItem(index),
          total: handleGetSubTotal(index),
          base_unit: newIngredientItem.base_unit,
          base_qty: calBaseQty(index),
          conversion_qty: parseFloat(newIngredientItem.conversion_qty)
        });
      }
      });

    let formData = {
      branch_id: newItem.branch.id,
      supplier_id: newItem.supplier.id,      
      goods_date: date,
      desc: newItem.description,
      item: slugArray,
      total_bill: handleGetTotal(),
      // total_amount: handleAmount(),
      // total_tax: handleTaxAmount(),
      // total_discount: handleDisAmount(),
    };

    if(newRow.length === slugArray.length){
      console.log("check form data==>",formData);
    // setLoading(true);
    // const url = BASE_URL + "/settings/new-ingredient_purchase";
    // try {
    //   const res = await axios.post(url, formData, {
    //     headers: { Authorization: `Bearer ${getCookie()}` },
    //   });
    //   getIngredientItem();
    //   setLoading(false);
    //   toast.success(`${_t(t("Notes has been added"))}`, {
    //     position: "bottom-center",
    //     autoClose: 10000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     className: "text-center toast-notification",
    //   });
    //   history.push(
    //   "/dashboard/manage/stock/purchase-invoice"
    //   )
    // } catch (error) {    
    //   console.log("check error==>",error);
    //   setLoading(false);
    //   toast.error(`${_t(t("Please try again later"))}`, {
    //     position: "bottom-center",
    //     autoClose: 10000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     className: "text-center toast-notification",
    //   });
    // }
    }else{
      toast.error(`${_t(t("Please select all dropdowns"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //send to server
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      newItem.branch !== null &&
      newItem.supplier !== null &&
      goodsDate !== null
    ) {
      if(newRow.length > 0){
        ingredientItemAxios();
      }
      else{
        toast.error(`${_t(t("Please add notes"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      toast.error(`${_t(t("Please fill all the required fields"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  const calBaseQty=(index)=>{
    let qty=newRow[index].qty;
    let conversion_qty=newRow[index].conversion_qty
    if(qty && conversion_qty){
      return qty * conversion_qty;
    }else{
      return "";
    }
  }

  //new Row
  const [newRow,setNewRow]=useState([]);
  // const [rowId,setRowId]=useState(0);
  const addNewRow=()=>{
    // if(newOpeningStock.branch !== null){
      setNewRow((pre)=>[...pre,{
        id:rowId,
        item_name:"",
        qty:"",
        unit:"",
        item_units:"",
        rate:"",          
        discount_percent:"",
        tax_percent:"",
        base_unit:"",
        base_qty:"",
        conversion_qty:""
      }])
      rowId.current = rowId.current + 1;
      // setRowId(rowId+1);
    // }else{
    //   toast.error(`${_t(t("Please select branch"))}`, {
    //     position: "bottom-center",
    //     autoClose: 10000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     className: "text-center toast-notification",
    //   });
    // }   
  }

  const delRow=(id)=>{
    let row=[...newRow]
    row= row.filter((item,i)=>id!==item.id);
    setNewRow(row);
  }

return (
  <>
  <Helmet>
    <title>{_t(t("Edit Good Recieved Note"))}</title>
  </Helmet>

  {/* main body */}
  <main id="main" data-simplebar>
    <div className="container-fluid">
      <div className="row t-mt-10 gx-2">
        {/* left Sidebar */}
        <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
          <ManageSidebar />
        </div>
        {/* left Sidebar ends */}

        {/* Rightbar contents */}
        <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
          <div className="t-bg-white">
            <div
              className="fk-scroll--pos-menu table-bottom-info-hide"
              data-simplebar
            >
              <div className="t-pl-15 t-pr-15">
                {/* next page data spin loading */}
                <div className={`${dataPaginating && "loading"}`}></div>
                {/* spin loading ends */}

                {/* Loading effect */}
                {loading === true ? ( 
                  tableLoading()
                ) : (
                  <div key="smtp-form">
                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                      <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                        <ul className="t-list fk-breadcrumb">
                          <li className="fk-breadcrumb__list">
                            <span className="t-link fk-breadcrumb__link text-capitalize">
                              {_t(t("Edit Good Recieved Note"))}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-7">
                        <div className="row gx-3 align-items-center"></div>
                      </div>
                    </div>

                    {/* Form starts here */}
                    <form
                      className="row card p-2 mx-3 mb-5 table-text"
                      onSubmit={handleSubmit}
                      autoComplete="off"
                    >
                      <div className="col-12">
                        <div className="row">
                      
                          <div className="row">
                          <div className="form-group col-4 mt-3">
                            <div className="mb-2">
                              <label
                                htmlFor="invoice"
                                className="control-label"
                              >
                                {_t(t("GRN #"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                readOnly
                                className="form-control table-text"
                                id="invoice"
                                name="invoice"
                                // onChange={handleChange}
                                value={newItem.gr_no}
                                placeholder="Auto no"
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group col-4 text-right mt-3">
                            <div className="mb-2 text-left">
                              <label
                                htmlFor="goodsDate"
                                className="control-label"
                              >
                                {_t(t("GRN Date"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="mb-2">
                              <DatePicker
                                selected={goodsDate}
                                onChange={(date) => setgoodsDate(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control table-text"
                                placeholderText={_t(t("GR date"))}
                                shouldCloseOnSelect={false}
                              />
                            </div>
                          </div>                      

                              <div className="form-group mt-3 col-4">
                                    <div className="mb-1 text-left">
                                      <label
                                        htmlFor="branch"
                                        className="control-label text-truncate"
                                      >
                                        {_t(t("Branch"))}
                                        <span className="text-danger">*</span>
                                      </label>
                                    </div>
                                    <Select
                                      options={branchForSearch}
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      value={newItem.branch || ""}
                                      classNamePrefix="select"
                                      onChange={handleSetBranch}
                                      maxMenuHeight="200px"
                                      placeholder={
                                        _t(t("Please select a branch")) + ".."
                                      }
                                      styles={customStyle}
                                      required
                                    />
                                  </div>

                          {/* <div className="form-group mt-3 col-3">
                            <div className="mb-2 text-left">
                              <label
                                htmlFor="supplier"
                                className="control-label"
                              >
                                {_t(t("Supplier/Vendor"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <Select
                              options={supplierForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              value={newItem.supplier || ""}
                              classNamePrefix="select table-text"
                              onChange={handleSetSupplier}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Please select a supplier")) + ".."
                              }
                              styles={customStyle}
                            />
                          </div> */}

                        </div>
                                                 
                      </div>
                       <div className="row w-100">
                       <div className="form-group mt-3 col-4">
                            <div className="mb-2 text-left">
                              <label
                                htmlFor="supplier"
                                className="control-label"
                              >
                                {_t(t("Supplier/Vendor"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <Select
                              options={supplierForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              value={newItem.supplier || ""}
                              classNamePrefix="select table-text"
                              onChange={handleSetSupplier}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Please select a supplier")) + ".."
                              }
                              styles={customStyle}
                            />
                          </div>
                       
                        {/* {ingredientItemForSearch && (
                          <div className="form-group mt-3 col-4">
                            <div className="mb-2">
                              <label
                                htmlFor="item"
                                className="control-label"
                              >
                                {_t(t("Ingredient Items (add item)"))}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <Select
                              options={ingredientItemForSearch}
                              components={makeAnimated()}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              classNamePrefix="select table-text"
                              onChange={handleSetItems}
                              maxMenuHeight="200px"
                              //isMulti
                              //menuPlacement="top"
                              //isDisabled={newItem.branch === null}
                              placeholder={
                                _t(t("Please select items")) + ".."
                              }
                              styles={customStyle}
                            />
                          </div>
                        )} */}

                        <div className="form-group mt-3 col-8">
                        <div className="mb-2">
                          <label
                            htmlFor="description"
                            className="control-label"
                          >
                            {_t(t("Description"))}
                          </label>
                        </div>
                        <div className="mb-2">
                          <input
                            type="text"
                            className="form-control table-text"
                            id="description"
                            name="description"
                            onChange={handleDesc}
                            value={newItem.description}
                            placeholder="Short description"
                          />
                        </div>
                       </div>
                      </div>                           
                            <div className="table-responsive mt-3 pr-1" style={{maxHeight:"23rem",overflow: "auto"}}>
                              <table className="table table-bordered-sm table-hover">
                                <thead className="align-middle">
                                  <tr>
                                  {newRow.length==0 ?<th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                        <span className="t-mr-10" onClick={()=>addNewRow()}>
                                            <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                        </span>
                                    </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                    <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border table-firstChild"
                                    >
                                      <span style={{fontWeight:500}}>
                                      {_t(t("Name"))}
                                      </span>
                                    </th>
                                  
                                    <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      <span style={{fontWeight:500}}>
                                      {_t(t("Qty"))}
                                      </span>
                                    </th>

                                    <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      <span style={{fontWeight:500}}>
                                      {_t(t("Unit"))}
                                      </span>
                                    </th>

                                    <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      <span style={{fontWeight:500}}>
                                      {_t(t("Rate"))}
                                      </span>
                                    </th>

                                    <th
                                    scope="col"
                                    className="table-text text-capitalize align-middle text-center border-1 border"
                                   >
                                    <span style={{fontWeight:500}}>
                                    {_t(t("Amount"))}
                                    </span>
                                   </th>

                                    <th
                                    scope="col"
                                    className="table-text text-capitalize align-middle text-center border-1 border"
                                    style={{minWidth:"170px"}}
                                  >
                                    <span style={{fontWeight:500}}>
                                    {_t(t("Discount Percent"))}
                                    </span>
                                  </th>

                                  <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border"
                                   >
                                    <span style={{fontWeight:500}}>
                                    {_t(t("Discount"))}
                                    </span>
                                  </th>
                                   
                                  <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    <span style={{fontWeight:500}}>
                                    {_t(t("Tax Percent"))}
                                    </span>
                                  </th>

                                  <th
                                  scope="col"
                                  className="table-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    <span style={{fontWeight:500}}>
                                  {_t(t("Tax"))}
                                    </span>
                                   </th>
                                     
                                    <th
                                    scope="col-2"
                                    className="table-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    <span style={{fontWeight:500}}>                                        
                                    {_t(t("Total"))}
                                    </span>
                                    </th>

                                    <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                        {_t(t("Base unit"))}
                                        </span>
                                      </th>
                                      
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                        {_t(t("Base qty"))}
                                        </span>
                                      </th>
                                      
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                        style={{minWidth:"9rem"}}
                                      >
                                        <span style={{fontWeight:500}}>
                                        {_t(t("Conversion unit"))}
                                        </span>
                                      </th>

                                  {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}

                                  </tr>
                                </thead>
                                {newRow && newRow.length>0 ?
                                <tbody className="align-middle">
                                  {
                                    newRow?.map(
                                    (ingredientItem, index) => {
                                      return (
                                        <tr
                                          className="align-middle"
                                          key={index}
                                        >
                                          <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                        {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                            <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                        </span>}&nbsp;
                                        <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} 
                                        onClick={()=>{delRow(ingredientItem.id)}}
                                        ></i>
                                        </td>
                                          <td className="table-text text-capitalize align-middle text-center table-Child">
                                          {ingredientItem.isEdit ?
                                                <input
                                                readOnly
                                                type="text"
                                                className={`form-control text-center table-Input table-text`}
                                                value={ingredientItem.item_name?.name || ""}
                                                />
                                                : 
                                              <Select
                                                options={ingredientItemForSearch !== null ? ingredientItemForSearch : []}
                                                components={makeAnimated()}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                classNamePrefix="select"
                                                onChange={(e)=>handleSetItems(index,e)}
                                                //maxMenuHeight="12.8rem"                                                
                                                value={ingredientItem.item_name}
                                                styles={customStyle}
                                                isMenuOpen={true}
                                                menuPosition="fixed"
                                                className="bank-table-input"                                              
                                                placeholder={
                                                _t(t("Please select items")) + ".."
                                                }                                                
                                              />}
                                          </td>

                                          <td className="table-text text-capitalize align-middle text-center" style={{width:"15%"}}>
                                          <input
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            className="form-control text-center table-Input table-text"
                                            id={ingredientItem.id}
                                            name={ingredientItem.id}
                                            onChange={(e)=>handleItemQty(index,e)}
                                            value={ingredientItem.qty ? ingredientItem.qty : ""}
                                            placeholder="Qty"
                                            required
                                          />
                                        </td>

                                          <td className="table-text text-capitalize align-middle text-center">
                                          {ingredientItem.isEdit ?
                                                <input
                                                readOnly
                                                type="text"
                                                className={`form-control text-center table-Input table-text`}
                                                value={ingredientItem.unit || ""}
                                                /> 
                                                :                                                
                                                <Select
                                                  menuPosition="fixed"
                                                  options={ingredientItem.item_units ? ingredientItem.item_units : []}
                                                  components={makeAnimated()}
                                                  getOptionLabel={(option) => option.name}
                                                  getOptionValue={(option) => option.id}
                                                  value={ingredientItem.unit}
                                                  classNamePrefix="select"
                                                  onChange={(e)=>handleSetUnit(index,e)}
                                                  className="bank-table-input"
                                                  maxMenuHeight="200px"
                                                  styles={customStyle}                                                  
                                                  placeholder={
                                                  _t(t("please select unit")) + ".."
                                                  }            
                                                  isDisabled={ingredientItem.item_units?false:true}                                        
                                                />}
                                          </td>
                                          <td className="table-text text-capitalize align-middle text-center" style={{width:"15%"}}>
                                            <input
                                              type="number"
                                              step="0.01"
                                              min="0"
                                              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                              className="form-control text-center table-Input table-text"
                                              id={ingredientItem.id}
                                              name={ingredientItem.id}
                                              onChange={(e)=>handleItemRate(index,e)}
                                              value={ingredientItem.rate ? ingredientItem.rate : ""}
                                              placeholder="Price"
                                              required
                                             
                                            />
                                          </td>

                                          <td className="table-text align-middle text-center">
                                          {formatPrice(
                                            handleGetAmount(
                                              index
                                            )
                                          )}
                                         </td>

                                          <td className="table-text text-capitalize align-middle text-center">
                                          <input
                                              type="number"
                                              step="0.1"
                                              min="0"
                                              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                              className={`form-control text-center table-Input table-text`}
                                              id={ingredientItem.id}
                                              name={ingredientItem.id}
                                              onChange={(e)=>handleDiscount(index,e)}
                                              value={ingredientItem.discount_percent}
                                              placeholder="Discount"
                                              required
                                             
                                            />
                                        </td>

                                        <td className="table-text text-capitalize align-middle text-center">
                                        {formatPrice(
                                          handleDiscountItem(
                                            index
                                          )
                                         )}
                                        </td>

                                        <td className="table-text text-capitalize align-middle text-center">
                                        <input
                                        type="number"
                                        step="0.1"
                                        min="0"
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                        className={`form-control text-center table-Input table-text`}
                                        id={ingredientItem.id}
                                        name={ingredientItem.id}
                                        onChange={(e)=>handleTax(index,e)}
                                        value={ingredientItem.tax_percent}
                                        placeholder="Tax"
                                        required
                                         />
                                        </td>

                                        <td className="table-text text-capitalize align-middle text-center">
                                         {formatPrice(
                                          handleTaxItem(
                                            index
                                           )
                                         )}
                                        </td>

                                        <td className="table-text text-capitalize align-middle text-center">
                                         {formatPrice(
                                          handleGetSubTotal(
                                             index
                                            )
                                          )}
                                        </td>
                                        
                                        {/* hidden columns */}
                                            
                                        <td className="table-text align-middle text-center">
                                              {ingredientItem.base_unit}
                                            </td> 
                                            
                                            <td className="table-text align-middle text-center">                                                
                                              {formatPrice(calBaseQty(index))}
                                            </td>
                                            
                                            <td className="table-text align-middle text-center">
                                              {ingredientItem.conversion_qty}
                                            </td> 

                                            <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                              <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(ingredientItem.id)}}></i>&nbsp;
                                              {index==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                              </span>}                                             
                                            </td>
                                        
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                                : null }
                                {newRow && newRow.length>0 ?
                                <tfoot className="align-middle">
                                  <tr>
                                    <th
                                    style={{minWidth:"100px"}}
                                      scope="col"
                                      colSpan="9"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    ></th>

                                    <th
                                    style={{minWidth:"100px"}}
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      <span style={{fontWeight:"bold"}}>
                                            {_t(t("Total Amount"))}
                                        </span>
                                    </th>

                                    <th
                                    style={{minWidth:"100px"}}
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      {formatPrice(handleGetTotal())}
                                    </th>
                                    <th
                                    style={{minWidth:"100px"}}
                                      scope="col"
                                      colSpan="4"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    ></th>
                                  </tr>
                                 
                                </tfoot>
                                : null }
                              </table>
                            </div>
                          

                        <div className="form-group mt-4 pb-2">
                          {/* <div className="col-lg-12">
                            <button
                              className="btn btn-primary px-5 rounded"
                              type="submit"
                            >
                              {_t(t("Save"))}
                            </button>
                            <button
                              className="btn btn-primary px-5 mx-2 rounded"
                              onClick={()=>(history.push("/dashboard/manage/stock/purchase-goods"))}
                            >
                              {_t(t("Back"))}
                            </button>
                          </div> */}
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="col-3 d-flex justify-content-end">
                                <button
                                type="button"
                                className="btn rounded hov-btn mr-3"
                                onClick={()=>(history.push("/dashboard/manage/stock/purchase-goods"))}
                                //   data-dismiss="modal"
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                </button>
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded"
                                >
                                    <i class="fa fa-floppy-o" aria-hidden="true"></i> {_t(t("Save"))}
                                </button>                                                    
                            </div>                                                    
                        </div>
                          
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Rightbar contents end*/}
      </div>
    </div>
  </main>
  {/* main body ends */}
  {/* <StockReport
  name = "Goods Recieved Note"
  purchaseDate={goodsDate}
  newItem={newItem}
  qtyOfItem={qtyOfItem}
  rateOfItem={rateOfItem}
  handleGetTotal={handleGetTotal}
  handleAmount={handleAmount}
  handleTaxAmount={handleTaxAmount}
  handleDisAmount={handleDisAmount}
  handleGetSubTotal={handleGetSubTotal}
  handleGetAmount={handleGetAmount}
  stockPrint={componentRef}
  /> */}
</>
)
}

export default GoodsReceivedNoteEdit
