import React, {useContext} from 'react';
import {
    _t,
    formatPrice,
    getSystemSettings,
    showPriceOfEachOrderItemPrint,
    showPropertyPrice,
    useCustomHooks,
  } from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";
import Moment from "react-moment";
import QRCode from "react-qr-code";

const OrderPrintView = ({
    componentRef,
    checkOrderDetails,
    taxType,
    taxPercent,
    isSettle,
    fbrInvoiceNum,
    srbInvoiceNum,
    paidMoney,
    isOnline = 0,
}) => {
    const { t } = useTranslation();
    const { generalSettings } = useContext(SettingsContext);
    const { renderNetBill } = useCustomHooks();
    //print online
    const onlinePayment = (item) =>{
        if(item.payment_method === "Card" || item.payment_method === "Online"){
          return " Online Paid"
        }else if(item.payment_method === "Cash"){
          return " Cash On Delivery"
        }else{
          return " Swipe Card at your Door Step"
        }
    }

    //print payment
    const printPayment = (payment) =>{
        if(typeof(payment) === "string"){
            return payment
        }else{
            const paymentType = payment && payment.map((item) => (item.name || item.payment_type)).join(", ");
            return paymentType
        }
    }

    let printText = "";
    let taxTaken = "";
    let taxImage = "";
    const qrPrint = (taxTaken,printText,InvoiceNum,taxImage) =>{
      return(
        <div className="mb-1">
          <p className="print-text fk-print-text text-capitalize text-center">
          {taxTaken} Invoice Number# {InvoiceNum}
          </p>
          <div className="mb-1">
            <div className="float-left">
              <img
                src={taxImage}
                height="80"
                alt={`${taxTaken} Invoice`}
              />
            </div>
            <div className="float-right">
              {InvoiceNum && (
                <QRCode value={InvoiceNum} size="80" />
              )}
            </div>
            <div style={{ clear: "both" }}></div>
            </div>
            <p className="print-text fk-print-text text-center">
              {printText} <br></br> and win exciting prizes in
              draw.
            </p>
        </div>
      )
    }

  return (
    <div className="d-none">
        <div ref={componentRef}>
        {checkOrderDetails && checkOrderDetails.item && (
            <div className="fk-print">
            <div className="container">
                <div className="row">
                <div className="col-12">
                {
                    parseInt(getSystemSettings(generalSettings, "print_logo")) === 1 && (
                    <div className="mx-auto t-w-120">
                        <img
                        src={`${getSystemSettings(
                            generalSettings,
                            "type_logo"
                        )}`}
                        className="w-100"
                        //height={100}
                        />
                    </div>
                    ) 
                }

                    {
                    parseInt(getSystemSettings(generalSettings, "print_siteName")) === 1 && (
                        <div className="fk-print-text font-weight-bold text-center sm-text mt-2">
                        {getSystemSettings(generalSettings, "siteName")}
                        </div>
                    ) 
                }
                {
                    parseInt(getSystemSettings(generalSettings, "print_branchName")) === 1 && (
                    <div className="fk-print-text font-weight-bold text-uppercase text-center sm-text mt-2">
                        {checkOrderDetails.item.branch_name}
                    </div>
                    ) 
                }  
                {
                    parseInt(getSystemSettings(generalSettings, "print_branchAddress")) === 1 && (
                    <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                    {checkOrderDetails.item.theBranch !== null &&
                    checkOrderDetails.item.theBranch.address
                        ? checkOrderDetails.item.theBranch.address
                        : ""}
                    </p>
                    )
                }
                {
                    parseInt(getSystemSettings(generalSettings, "print_branchPhone")) === 1 &&(
                    <p className="mb-0 sm-text fk-print-text text-center text-capitalize">
                    {_t(t("call"))}:{" "}
                    {checkOrderDetails.item.theBranch !== null &&
                    checkOrderDetails.item.theBranch.phn_no
                        ? checkOrderDetails.item.theBranch.phn_no
                        : ""}
                    </p>
                    )
                }
                
                    {checkOrderDetails.item?.table_name && checkOrderDetails.item?.table_name !== "-" ? (
                    <p className="mb-0 sm-text fk-print-text text-capitalize text-center">
                        {_t(t("Table # "))}: {checkOrderDetails.item?.table_name}
                    </p>
                    ) : (
                    ""
                    )}

                    {
                    parseInt(getSystemSettings(generalSettings, "print_heading")) === 1 && (
                        <p className="mb-0 sm-text fk-print-text text-center">
                        {getSystemSettings(generalSettings, "type_print_heading")}
                        </p>
                    )
                    }
                    
                    {
                    getSystemSettings(generalSettings, "duplicate_print_text") && getSystemSettings(generalSettings, "duplicate_print_text") !== "" && (
                        <p className="mb-0 print-text fk-print-text">
                        {getSystemSettings(generalSettings, "duplicate_print_text")}
                        </p>
                    )
                    }
                    <div className = "d-flex align-items-center justify-content-between myBorderTopCustomer pt-1"> 
                    <span className="fk-print-text text-uppercase text-center print-text mr-1">
                    {_t(t(getSystemSettings(generalSettings, "token_text")))} {" - "} {checkOrderDetails.item.token?.id}
                    </span>

                    {
                        parseInt(getSystemSettings(generalSettings, "order_no")) === 1 && (
                        <span className="fk-print-text text-uppercase text-right print-text ml-1">
                            Invoice No - {" "}
                            {checkOrderDetails.item?.order_no && checkOrderDetails.item.order_no}
                        </span>
                        )
                    }
                    </div>  
                    {
                    getSystemSettings(generalSettings, "print_orderParty") === "both" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {checkOrderDetails.item.order_type_name +
                        "-" +
                        checkOrderDetails.item?.party_name}
                        </p>
                    )
                    }
                    {
                    getSystemSettings(generalSettings, "print_orderParty") === "order_type" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {checkOrderDetails.item.order_type_name}
                        </p>
                    )
                    }
                    {
                    getSystemSettings(generalSettings, "print_orderParty") === "party" && (
                        <p className="mb-0 fk-print-text text-capitalize print-text text-center">
                        {
                        checkOrderDetails.item?.party_name}
                        </p>
                    )
                    }
                    
                
                    <p className="mb-0 mt-0 print-text fk-print-text text-capitalize text-center">
                    {_t(t("Customer Copy"))}
                    </p>

                    <div className="myBorder mb-2"></div>
                    
                    {
                        checkOrderDetails.item.customer_name &&(
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                        {_t(t("Customer Name"))}:{" "}
                        {checkOrderDetails.item.customer_name}
                        </p>
                        )
                    }
                    {
                        checkOrderDetails.item.delivery_phn_no && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                        {_t(t("Phone #"))}:{" "}
                        {checkOrderDetails.item.delivery_phn_no}
                        </p>
                        )
                    }
                    {
                        checkOrderDetails.item?.alt_phn_no && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("Alt Phone #"))}:{" "}
                            {checkOrderDetails.item.alt_phn_no}
                        </p>
                        )
                    }
                    {
                        (checkOrderDetails.item?.ntn_no) && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                        {_t(t("NTN No"))}:{" "}
                        {checkOrderDetails.item?.ntn_no}
                        </p>
                        )
                    }
                    {
                        checkOrderDetails.item.delivery_address && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("Address "))}:{" "}
                            {checkOrderDetails.item.delivery_address}
                        </p>
                        )
                    }

                    {
                        checkOrderDetails.item?.nearest_landmark && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                            {_t(t("Nearest Landmark"))}:{" "}
                            {checkOrderDetails.item.nearest_landmark}
                        </p>
                        )
                    }

                    
                    <p className="mb-1 print-text fk-print-text text-capitalize">
                        {"ref no :" +
                        (checkOrderDetails.item?.ref_no
                            ? checkOrderDetails.item?.ref_no
                            : "")}
                    </p>

                    {
                    checkOrderDetails.item.delivery_boy_name && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                        {_t(t("Rider name "))}:{" "}
                        {checkOrderDetails.item.delivery_boy_name}
                        </p>
                    )
                    }
                    {isOnline ? onlinePayment(checkOrderDetails.item) : ""}
                    {!isOnline && parseInt(getSystemSettings(generalSettings, "print_paymentType")) === 1 && (
                    !checkOrderDetails.settle ? (
                    parseInt(checkOrderDetails.item.is_cancelled) === 0 ? (
                        parseInt(checkOrderDetails.item.is_paid) === 0 ? (
                        <span>
                            <p className="mb-0 md-text fk-print-text text-capitalize">
                             Unpaid
                            </p>
                        </span>
                        ) : (
                        <span>
                            <p className="mb-0 md-text fk-print-text text-capitalize">
                            PAID -{" "}
                            {checkOrderDetails.item.bill_distribution
                                ? printPayment(checkOrderDetails.item.bill_distribution)
                                : ""}
                            </p>
                        </span>
                        )
                    ) : (
                        <span>
                        <p className="mb-0 md-text fk-print-text text-capitalize">
                            Cancelled
                        </p>
                        </span>
                    )
                    ) : (
                    ""
                    ))}
                    <p className="mb-1 print-text fk-print-text text-capitalize">
                    {_t(t("date"))}:{" "}
                    <Moment format="MMMM D, YYYY, h:mm A">
                        {checkOrderDetails.item.created_at}
                    </Moment>
                    </p>
                    {checkOrderDetails.item?.waiter_name && checkOrderDetails.item?.waiter_name !== "-" && (
                    <p className="mb-1 print-text fk-print-text text-capitalize">
                        {_t(t("waiter name"))}:{" "}
                        {checkOrderDetails.item.waiter_name}
                    </p>
                    )}
                    {checkOrderDetails.item.order_type_name === "Dine-In" && (
                        <p className="mb-1 print-text fk-print-text text-capitalize">
                        {_t(t("guest"))}:{" "}
                        {checkOrderDetails.item.total_guest}
                        </p>
                    )
                    }
                    {parseInt(getSystemSettings(generalSettings, "print_paymentType")) === 1 && checkOrderDetails?.payment_type && (
                    <p className="mb-0 print-text fk-print-text text-capitalize lg-text">
                        PAID - {printPayment(checkOrderDetails.payment_type)}
                    </p>
                    )}

                    <table className="w-100 mb-0 table-borderless">
                    <thead>
                        <tr>
                        <th
                        scope="col"
                        className="fk-print-text print-text text-capitalize "
                        >
                        {_t(t("qty"))}  {_t(t("item"))}
                        </th>
                        <th
                        scope="col"
                        className="fk-print-text print-text text-capitalize text-right"
                        >      
                        {_t(t("T"))}.{_t(t("price"))} 
                        </th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        {checkOrderDetails.item &&
                        checkOrderDetails.item.orderedItems.map(
                            (thisItem, indexThisItem) => {
                            return (
                                <>
                                <tr>
                                    <td className="fk-print-text print-text text-capitalize">
                                    <div className="d-flex flex-wrap align-items-center">
                                    <span style={{width:"9%"}} className="fk-print-text d-inline-block">
                                    {thisItem.quantity}{" "}
                                    </span>
                                    <span style={{width:"83%"}} className="fk-print-text d-inline-block ml-2">
                                        {thisItem.food_item}
                                        {thisItem.variation !== null &&
                                        "(" + thisItem.variation + ")"}
                                    </span>
                                    </div>
                                    </td>
                                    <td className="fk-print-text print-text text-capitalize text-right">
                                        {showPriceOfEachOrderItemPrint(thisItem,taxType,checkOrderDetails.item.theBranch.branch_tax)}
                                    </td>
                                    </tr>
                                    {/* properties */}
                                    {thisItem.properties !== null && 
                                    JSON.parse(thisItem.properties).map(
                                        (propertyItem, thisIndex) => {
                                        return (
                                            <tr>
                                            <td className="fk-print-text print-text text-capitalize">
                                            <span className="fk-print-text text-capitalize print-text d-inline-block mr-1 " style={{marginLeft:"14%"}}>
                                                -{thisItem.quantity}
                                                {propertyItem.quantity > 1
                                                ? "*" +
                                                    propertyItem.quantity
                                                : ""}{" "}
                                                {propertyItem.property}
                                            </span>
                                            </td>
                                            <td className="fk-print-text print-text text-capitalize text-right">
                                                {showPropertyPrice(
                                                thisItem.quantity,
                                                propertyItem.quantity,
                                                propertyItem.price_per_qty,
                                                taxType,
                                                checkOrderDetails.item.theBranch.branch_tax
                                                )}
                                            </td>
                                            </tr>
                                        );
                                        }
                                    )}
                                </>
                            );
                            }
                        )}
                    </tbody>
                    </table>

                    <div className="myBorder mb-1"></div>
                    <table className="w-100 mb-0 table-borderless">
                    <tbody>
                        <tr>
                        <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            {_t(t("total"))}
                        </th>
                        <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {formatPrice(checkOrderDetails.item.order_bill)}
                        </td>
                        </tr>
                    </tbody>
                    </table>

                    {parseFloat(checkOrderDetails.item.vat) > 0 && (
                    <table className="w-100 mb-0 table-borderless">
                        <tbody>
                        {checkOrderDetails.item.vat_system === "igst" ? (
                            <tr>
                            <th className="py-0 pb-1 fk-print-text print-text text-uppercase">
                                {checkOrderDetails.item.theBranch?.print_tax}
                                {taxPercent > 0
                                    ? "(" + taxPercent + `%)`
                                : "(" + checkOrderDetails.item.theBranch?.branch_tax + `%)`}
                            </th>
                            <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                    checkOrderDetails.item?.vat ? checkOrderDetails.item.vat :
                                    Math.ceil(
                                        (parseFloat(taxPercent) / 100) *
                                        parseFloat(
                                            checkOrderDetails.item.order_bill
                                        )
                                    )
                                )}
                            </td>
                            </tr>
                        ) : (
                            <span>
                            <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                CGST
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                    parseFloat(checkOrderDetails.item.cgst)
                                )}
                                </td>
                            </tr>
                            <tr>
                                <th className="py-0 pb-1 fk-print-text print-text">
                                SGST
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                    parseFloat(checkOrderDetails.item.sgst)
                                )}
                                </td>
                            </tr>
                            </span>
                        )}
                        </tbody>
                    </table>
                    )}
                    {getSystemSettings(generalSettings, "sDiscount") ===
                    "flat" && (
                    <>
                        {parseFloat(checkOrderDetails.item.service_charge) >
                        0 && (
                        <table className="w-100 mb-0 table-borderless">
                            <tbody>
                            <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("D.Charge"))}
                                </th>

                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                    checkOrderDetails.item.service_charge
                                )}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        )}
                        {checkOrderDetails.item.service_amount &&
                        parseFloat(checkOrderDetails.item.service_amount) >
                        0 ? (
                        <table className="w-100 mb-0 table-borderless">
                        <tbody>
                        <tr>
                            <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                {_t(t("S.Charge"))}
                            </th>
                            <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {checkOrderDetails.item.service_amount
                                ? formatPrice(
                                    parseFloat(
                                    checkOrderDetails.item.service_amount
                                    )
                                )
                                : 0}
                            </td>
                        </tr>
                        </tbody>
                        </table>
                        ) : null}
                        {parseFloat(checkOrderDetails.item.discount) > 0 ||
                        parseFloat(checkOrderDetails.item.discount_amount) >
                        0 ? (
                        <table className="w-100 mb-0 table-borderless">
                            <tbody>
                            <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("discount"))}
                                    {checkOrderDetails &&
                                    checkOrderDetails.item.scheme_id !==
                                        null && parseFloat(checkOrderDetails.item.discount) > 0 &&
                                    `(${parseFloat(
                                        checkOrderDetails.item.discount
                                    )}%)`}
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                    checkOrderDetails.item.discount_amount
                                )}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        ) : null}
                    </>
                    )}

                    {getSystemSettings(generalSettings, "sDiscount") ===
                    "percentage" && (
                    <>
                        {parseFloat(checkOrderDetails.item.service_charge) >
                        0 && (
                        <table className="w-100 mb-0 table-borderless">
                            <tbody>
                            <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("D.Charge"))}
                                    {checkOrderDetails.item &&
                                    "(" +
                                        checkOrderDetails.item.service_charge +
                                        "%)"}
                                </th>

                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                    checkOrderDetails.item.order_bill *
                                    (checkOrderDetails.item.service_charge /
                                        100)
                                )}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        )}
                        {checkOrderDetails.item.service_amount &&
                        parseFloat(checkOrderDetails.item.service_amount) >
                            0 ? (
                            <table className="w-100 mb-0 table-borderless">
                            <tbody> 
                            <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("S.Charge"))}
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {checkOrderDetails.item.service_amount
                                    ? formatPrice(
                                        parseFloat(
                                        checkOrderDetails.item.service_amount
                                        )
                                    )
                                    : 0}
                                </td>
                            </tr>
                            </tbody>
                            </table>
                        ) : null}

                        {parseFloat(checkOrderDetails.item.discount) > 0 ||
                        parseFloat(checkOrderDetails.item.discount_amount) >
                        0 ? (
                        <table className="w-100 mb-0 table-borderless">
                            <tbody>
                            <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t("discount"))}
                                    {checkOrderDetails.item && parseFloat(checkOrderDetails.item.discount) > 0 &&
                                    "(" +
                                        checkOrderDetails.item.discount +
                                        "%)"}
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                    checkOrderDetails.item.discount_amount
                                )}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        ) : null}
                    </>
                    )}

                    {
                    parseFloat(checkOrderDetails.item?.order_tip) > 0 && (
                        <table className="w-100 mb-0 table-borderless">
                        <tbody>
                            <tr>
                            <th className="fk-print-text print-text text-capitalize">
                                {_t(t("Order Tip"))}
                            </th>
                            <td className="fk-print-text print-text text-capitalize text-right">
                                {formatPrice(checkOrderDetails.item.order_tip)}
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    )
                    }
                
                    {checkOrderDetails?.payment_type && checkOrderDetails.payment_type?.length > 1 && (
                    <>
                        <div className="myBorder mb-1"></div>
                        <table className="w-100 mb-0 table-borderless">
                        <tbody>
                            {checkOrderDetails?.payment_type.map((item)=>{
                                return (
                                <tr>
                                <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                    {_t(t(item.name))}
                                </th>
                                <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                    {_t(t(item.bill_amount))}
                                </td>
                                </tr>
                                )
                            }) 
                            }
                        </tbody>
                        </table>
                    </>
                    )}
                    <div className="myBorder mb-1"></div>
                    <table className="w-100 mb-0 table-borderless">
                    <tbody>
                        {
                        (checkOrderDetails.item.theBranch.fbr_integration ===
                        1  || checkOrderDetails.item.theBranch.pbr_integration === 1 || checkOrderDetails.item?.theBranch?.sbr_integration === 1) &&
                        isSettle.current &&
                        (fbrInvoiceNum || srbInvoiceNum) ? (
                        <tr>
                            <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                {_t(t("POS Invoice Fee"))}
                            </th>
                            <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {_t(t("1.00"))}
                            </td>
                        </tr>
                        ) : null}
                        <tr>
                        <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            {_t(t("grand total"))}
                        </th>
                        <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {formatPrice(checkOrderDetails.item.total_payable)}
                        </td>
                        </tr>
                        <tr>
                        {checkOrderDetails.item.is_refund === 1 ? (
                            <>
                            <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                {_t(t("Refund Amount"))}
                            </th>
                            <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                checkOrderDetails.item.paid_amount
                                )}
                            </td>
                            </>
                        ) : checkOrderDetails.item.is_foc === 1 ? (
                          <>
                          </>
                        ) : (
                            <>
                            <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                                {_t(t("Paid Amount"))}
                            </th>
                            <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                                {formatPrice(
                                checkOrderDetails.settle
                                    ? paidMoney
                                    : (checkOrderDetails.item.paid_amount || 0)
                                )}
                            </td>
                            </>
                        )}
                        </tr>

                        <tr>
                        <th className="py-0 pb-1 fk-print-text print-text text-capitalize">
                            {_t(t("Return Amount"))}
                        </th>
                        <td className="py-0 pb-1 fk-print-text print-text text-capitalize text-right">
                            {(checkOrderDetails.settle
                            ? paidMoney
                            : checkOrderDetails.item.paid_amount) -
                            parseFloat(checkOrderDetails.item.total_payable) >
                            0
                            ? formatPrice(
                                (checkOrderDetails.settle
                                    ? paidMoney
                                    : checkOrderDetails.item.paid_amount) -
                                    parseFloat(
                                    checkOrderDetails.item.total_payable
                                    )
                                )
                            : formatPrice(0)}
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    {parseInt(getSystemSettings(generalSettings,"view_cash_card")) === 1 &&  !isSettle.current && (
                    <React.Fragment>
                        <div className="myBorder mt-1"></div>
                        {renderNetBill(checkOrderDetails.item.total_payable,checkOrderDetails.item.order_bill, checkOrderDetails.item.vat, checkOrderDetails.item?.discount_amount, checkOrderDetails.item, "cash", "Cash Net Bill")}
                        
                        {renderNetBill(checkOrderDetails.item.total_payable, checkOrderDetails.item.order_bill, checkOrderDetails.item.vat, checkOrderDetails.item?.discount_amount, checkOrderDetails.item, "card", "Card Net Bill")}
                    </React.Fragment>  
                    )
                    }
                    {(checkOrderDetails.item.theBranch.fbr_integration === 1 || checkOrderDetails.item.theBranch.pbr_integration === 1) &&
                    (fbrInvoiceNum) && (
                        printText = `Verify this invoice through ${checkOrderDetails.item.theBranch.fbr_integration ? "FBR" : "PRA"} TaxAsaan MobileApp or SMS at 9966`,
                        taxTaken = checkOrderDetails.item.theBranch.fbr_integration ? "FBR" : "PBR",
                        taxImage = checkOrderDetails.item.theBranch.fbr_integration ? "/assets/img/pos-invoice-system.png" : "/assets/img/PRA.png",
                        qrPrint(taxTaken,printText,fbrInvoiceNum,taxImage)  
                    )}
                    
                    {
                    //getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : checkOrderDetails.item.theBranch.sbr_integration === 1 &&
                    srbInvoiceNum && (
                        printText = "Verify this invoice through eSRB MobileApp or SMS at 9697",
                        taxTaken = "SRB",
                        taxImage = "/assets/img/srb_pos_image.png",
                        qrPrint(taxTaken,printText,srbInvoiceNum,taxImage)   
                    )}
                
                    <div className="myBorder"></div>
                    {checkOrderDetails.item?.note_to_rider && checkOrderDetails.item?.note_to_rider !== "" && 
                    <p className="mb-1 print-text fk-print-text text-capitalize mx-1">
                    {"Rider Note : " +
                        (checkOrderDetails.item.note_to_rider && 
                            checkOrderDetails.item.note_to_rider !== "" &&
                            checkOrderDetails.item.note_to_rider
                        )}
                    </p>    
                    }
                    <p className="white-space my-0 sm-text fk-print-text text-center text-capitalize">
                    {getSystemSettings(generalSettings, "type_print_footer")}
                    </p>
                    <p className="my-0 sm-text fk-print-text text-capitalize text-center">
                    {_t(t("bill prepared by"))}:{" "}
                    {checkOrderDetails.item &&
                        checkOrderDetails.item.user_name}
                    </p>
                    {
                    parseInt(getSystemSettings(generalSettings, "print_bill_footer")) === 1 && (
                        <p className="my-0 sm-text fk-print-text text-capitalize text-center">
                        {_t(t(""))}
                        </p>
                    )
                    }
                </div>
                </div>
            </div>
            </div>
        )}
        </div>
    </div>
  )
}

export default OrderPrintView
