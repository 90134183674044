import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartThemeColorsDonut } from "./index";

 const parseData = (data) => {
  return data ? data.filter((data) => data.value > 0) : [];
 };

 const tooltipFunc = ({ series, seriesIndex, w }) => {
  let selected = series[seriesIndex];
  return (
    `<div style="color:white;background-color:${chartThemeColorsDonut[seriesIndex]};padding: 0.4rem 0.3rem;" >` +
    w.config.labels[seriesIndex] +
    ": " +
    `<span className='ml-2 donut-text'>${selected}</span>` +
    "</div>"
  );
};

const PieWidget = ({ data, heading, size }) => {
  const [formattedData, setFormattedData] = useState([]);
  
  useEffect(() => {
    const newData = parseData(data);
    setFormattedData(newData);
  }, [data]);

  return (
    <div className={`col ${size} row mx-0 mt-3 px-2`}>
      <div className= "row mx-0 justify-content-between col col-12 px-0 ">
        <div className="dashboard__card col col-12 w-100 flex-basis-100 d-flex flex-column justify-content-between">
          <div>{heading ? <h5 className="mb-1 mt-0">{heading}</h5> : ""}</div>
          <div>
            <Chart
              width="100%"
              height="350px"
              type="pie"
              series={formattedData.map((x) => parseFloat(parseFloat(x.value)?.toFixed(2)))}
              options={{
                dataLabels: {
                  enabled: true,
                },
                chart: {
                    width: 400,
                    type: 'pie',
                },
                tooltip: {
                  enabled: true,
                  custom: (p) => tooltipFunc(p),
                },
                
                colors: chartThemeColorsDonut,
                labels: formattedData.map((x) => x.name),
                legend: {
                  position: "bottom",
                  fontSize:"11px",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieWidget;
