import React, { useContext, useState } from "react";


//functions
import {
  _t,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";

const ChartOfAccPrint = ({
 name,
 ChartOfAcc,
 chartofAccPrint
}) => {
    const { t } = useTranslation();

    const {
        //common
        generalSettings,
    } = useContext(SettingsContext);
      const landscapePrintStyles = {
        '@media print': {
          '@page': {
            size: 'landscape',
          },
        },
      };

  return (
    
    <>
       {/* print */}
       <div className="d-none">
       <div ref={ChartOfAcc} style={landscapePrintStyles}>
         {chartofAccPrint && Object.keys(chartofAccPrint)?.length > 0 && (
           <div className="fk-print" style={{margin:"20px 30px"}}>
             <div className="container">
               <div className="row">
                 <div className="col-12">
                   <div className="mx-auto" style={{ width: "90px" }}>
                     <img
                       src={`${getSystemSettings(
                         generalSettings,
                         "type_logo"
                       )}`}
                       style={{ width: "100%" }}
                     />
                   </div>

                   <div className="my-2 xslg-text print-text-stock text-center text-capitalize print-text-stock--bold">
                    {`${getSystemSettings(
                        generalSettings,
                        "siteName"
                      )}`}
                   </div>

                   <div className="my-2 xslg-text print-text-stock text-center text-capitalize print-text-stock--bold">
                     {name}
                   </div>
                   <div className="mt-4 ml-4">
                    {
                        chartofAccPrint && Object.keys(chartofAccPrint)?.length > 0 && (
                          Object.keys(chartofAccPrint)?.map((chartofAcc)=>{
                            return (
                               <div className="my-2 ml-4 xxslg-text print-text-stock text-capitalize print-text-stock--bold">
                                 {chartofAcc}
                                {
                                  Object.keys(chartofAccPrint[chartofAcc]).map((chartofAccl2)=>{
                                    return (
                                        <div className="my-2 xslg-text print-text-stock text-capitalize print-text-stock--bold" style={{marginLeft:"4rem"}}>
                                          {chartofAccl2}
                                         {
                                            chartofAccPrint[chartofAcc][chartofAccl2].map((chartofAccl3)=>{
                                                return (
                                                 <div className="my-2 lg-text print-text-stock text-capitalize print-text-stock--bold" style={{marginLeft:"4rem"}}>
                                                   {chartofAccl3}
                                                 </div>
                                                )
                                            })
                                         }
                                        </div>
                                    )
                                 })
                                }
                               </div> 
                            )
                          })
                        ) 
                    }
                   </div>
                  
                 </div>
               </div>
             </div>
           </div>
         )}
       </div>
     </div>
    </>
  )
}

export default ChartOfAccPrint
