import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const IngredientItemReport = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch ,ingredientItemForSearch} = useContext(RestaurantContext);

  let [newIngredientItemReport, setNewIngredientItemReport] = useState({
    branch: null,
    item: null,
  });
  const [option,setOption] = useState("excel");


  //all data
  const [reportData, setReportData] = useState(null);
  const [reportSummary, setReportSummary] = useState(null)

  const componentRef = useRef();
  //settle order
  const [generatedReport, setGeneratedReport] = useState(false);
  const [generatedSummary, setGeneratedSummary] = useState(false)
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  //Print Report
  const ReportPrint = useRef();

  const handlePrintBtn = useReactToPrint({
    content: () => ReportPrint.current,
  });


  const handleItem = (item) => {
    
    setNewIngredientItemReport({
      ...newIngredientItemReport,
      item: item,
    })
    // setBranch(branch);
  };

  const handleOption  = (e) =>{
    setOption(e.target.value)
  }

  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-ingredient-item-report";

      let formData = new FormData();
      formData.append("item_id", newIngredientItemReport?.item?.id ? newIngredientItemReport?.item?.id : null);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if(option==="excel"){
            setReportData(res.data);
            console.log("Component ", componentRef);
            setGeneratedReport(true);
            componentRef.current.handleDownload();
          }else{
            setReportSummary(res.data);
            setGeneratedSummary(true);
          }
          setNewIngredientItemReport(null);
          setLoading(false);
         
        })
        .catch((error) => {
          setLoading(false);
        });
    
  };



  return (
    <>
      <Helmet>
        <title>{_t(t("Ingredient Item Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Ingredient Item Report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef}
      />

      {/* Print Button */}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="12" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                           Ingredient Item Report
                                        </h3>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                {_t(t("S/L"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                {_t(t("Category"))}
                                </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Item Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Unit"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Base Qty"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Default Unit"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Default Qty"))}
                                  </th>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Price"))}
                                  </th>

                                </tr>
                              </thead>
                              <tbody className="align-middle">
                               
                                {reportData.map((item, i) => {
                                  
                                  return (
                                    <tr>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center "
                                      >
                                        {i + 1}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >

                                        {item.group_name}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.name}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.itemunit.code}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.base_qty}
                                      </td>
                                     
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.unit}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {formatPrice(item.stock_inn)}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {item.default_qty}
                                      </td>
                                      <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center"
                                      >
                                        {formatPrice(item.price)}
                                      </td>
                                      
                                    </tr>
                                  );
                                })}
                                
                              </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-2 mb-lg-0">
              <div className="t-bg-white p-1">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                     <>
                      <div key="smtp-form mt-3">
                        <div className="row gx-2 align-items-center t-pt-2 t-pb-2">
                          <div className="col-6 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize mt-1">
                                  {_t(t("Ingredient Item Report"))}
                                </span>
                              </li>
                            </ul>
                            </div>
                            <div className="col-6 d-flex justify-content-end p-0">
                            <div className="d-inline mr-3 d-flex align-items-center">
                              <input
                                type="radio"
                                className="form-check-input pointer-cursor"
                                id="excel_report"
                                name="excel_report"
                                value="excel"
                                onChange={(e) => {
                                  handleOption(e);
                                }}
                                checked={
                                  option === "excel"
                                    ? 1
                                    : 0
                                }
                              />
                              <label
                                className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                htmlFor="excel_report"
                                style={{fontSize:"16px",borderRadius:"5px"}}
                              >
                                <i class="fa fa-table" aria-hidden="true"></i> {_t(t("Excel"))}
                              </label>
                            </div>

                            <div className="d-inline mr-2 d-flex align-items-center">
                              <input
                                type="radio"
                                className="form-check-input pointer-cursor"
                                id="view_report"
                                name="view_report"
                                value="view"
                                onChange={(e) => {
                                  handleOption(e);
                                }}
                                checked={
                                  option === "view"
                                    ? 1
                                    : 0
                                }
                              />
                              <label
                                className="form-radio-label pointer-cursor ml-2 pt-1 text-primary"
                                htmlFor="view_report"
                                style={{fontSize:"16px",borderRadius:"5px"}}
                              >
                                <i class="fa fa-eye" aria-hidden="true"></i> {_t(t("View"))}
                              </label>
                            </div>
                            </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          
                          <div className="col-md-3 t-mb-15 mb-md-0">
                          <Select
                            options={ingredientItemForSearch && ingredientItemForSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            //value={branch}
                            classNamePrefix="select"
                            className="sm-text "
                            onChange={handleItem}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Select item")) + ".."}
                          />
                        </div>
                          <div className="col-md-8 t-mb-15 mb-md-0">
                            {/* Action Button */}
                           <div className="row ml-2">
                           <button
                              className="btn btn-primary text-uppercase xsm-text mr-1 col-md-3 px-2 rounded"
                              onClick={(e) => getBranchWiseSelected(e)}
                            >
                              Ingredient Report
                            </button>
                           </div>
                          </div>
                        </div>
                      </div>

                          {/* summary  */}

                          {reportSummary !== null &&
                          reportSummary !== undefined &&
                          reportSummary.length > 0 && (
                            <div className="row gx-2 justify-content-center p-2 mt-2">
                          
                              <div className="col-12 t-mb-15 mb-md-0 table-responsive"  ref={ReportPrint}>
                                <table className="table table-bordered table-hover min-table-height mt-3" id="summaryTable-xls">
                                  <thead className="align-middle">
                                  <tr style={{ border: "0px" }}>
                                  <th colspan="7" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Ingredient Item Summary
                                        </h3>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                    <tr>
                                   
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("S/L"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Category"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Item Name"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Unit"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Base Qty"))}
                                      </th>

                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Default Unit"))}
                                      </th>

                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Default Qty"))}
                                      </th>
                                      <th
                                      scope="col"
                                      className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                       {_t(t("Price"))}
                                     </th>
                                    </tr>
                                  </thead>
                                  <tbody className="align-middle">
                                    {/* loop here*/}
                                   
                                    {reportSummary.map((item, index) => {
                                      return (
                                        <tr
                                          className="align-middle"
                                          key={index}
                                        >
                                        <td
                                        scope="row"
                                        className="xsm-text text-capitalize align-middle text-center "
                                        >
                                          {index + 1}
                                        </td>
                                        <td
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        >

                                          {item.group_name}
                                        </td>
                                        <td
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        >
                                          {item.name}
                                        </td>
                                        <td
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        >
                                          {item.unit}
                                        </td>
                                        <td
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        >
                                          {item.base_qty}
                                        </td>
                                      
                                        <td
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        >
                                          {item.itemunit.code}
                                        </td>
                                        <td
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        >
                                          {item.default_qty}
                                        </td>
                                        <td
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        >
                                          {formatPrice(item.price)}
                                        </td>
                                        </tr>
                                      );
                                    })}
                                
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}


                     </>
                    {/* )} */}
                  </div>

                </div>
                  
              </div>
              
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default IngredientItemReport;
