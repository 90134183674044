import React from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import "./itemModal.css";
import { useHistory } from "react-router-dom";


const CallCenterOrdertype = ({
 setModalOrderType,
 modalOrderType,
 setModal9Show,
 availableDeliveryTypes,
 setSelectedDeliveryType,
 setSelectedParty,
 selectedParty,
 availableDeliveryParties,
 selectedDeliveryType
}) => {
  let history = useHistory();
  return (
    <Modal
      show={modalOrderType}
      onHide={() => {
        history.push("/dashboard")
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      style={{zIndex: "1040"}}
      centered
     
    >
      <Modal.Header closeButton className="customer-header">
        <Modal.Title className="customer-title" id="contained-modal-title-vcenter">
          {/* Modal Header */}
          <div className="container-fluid mt-1">
            <h4 className="fw-bold mb-0 input-field" id="exampleModalLabel">
              Call Center: Select Order Type & Party
            </h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Modal Body*/}
        <div className="modal-body pt-0">
          <div className="container-fluid" style={{ paddingLeft: "0px" }}>
              <div className="row">
               <div className="col-12">
               <div className="lg-text mb-2 font-weight-md">Select Delivery Type:</div>
                {availableDeliveryTypes.length
                  ? availableDeliveryTypes.map((deliveryType) => {
                    if(deliveryType?.name !== "Dine-In" && deliveryType?.name !== "dine-in"){
                      return (
                      <button style={{width:"22.5%"}} className={`rounded mr-3 py-2 font-weight-md text-uppercase genAcc-select text-primary t-width-max ${selectedDeliveryType?.id === deliveryType.id ? "order-type-selected" : "order-type"}`} onClick={()=>{
                        setSelectedDeliveryType(deliveryType)
                      }}>{deliveryType?.name}</button>
                      )
                    }
                    return null
                  })
                  : null}
               </div>
               <div className="col-12">
                <div className="lg-text mb-2 font-weight-md mt-3">Select Party:</div>
                 <div className="call_centerModal">
                    {selectedDeliveryType
                      ? availableDeliveryParties[selectedDeliveryType.id] && availableDeliveryParties[selectedDeliveryType.id].map((partyType) => (
                          <button style={{width:"22.5%"}} className={`rounded font-weight-md genAcc-select py-2 mr-3 mb-2 text-success text-uppercase t-width-max ${selectedParty?.id === partyType.id ? "party-selected" : "party"}`} onClick={()=>{
                            setSelectedParty(partyType)
                          }}>{partyType?.name}</button>
                      ))
                      : null}
                 </div>
                </div>
               </div>
              <Modal.Footer
              style={{
                borderTop: "none",
                justifyContent: "space-between",
                display: "block",
                marginBottom:"-15px",
                paddingRight:"0"
              }}
            >
              {/* Modal Footer */}
              <div className="modal-footer" style={{ borderTop: "none",paddingBottom:"0",paddingRight:"0" }}>
                <div className="container-fluid " style={{ padding: "0px" }}>
                  <div className="row d-flex justify-content-end">
                    <div className="col-sm-3 mb-1">
                      <button
                        type="submit"
                        className="btn w-100 fw-bold rounded-25"
                        style={{
                          backgroundColor: "#0a8d6e",
                          color: "#fff",
                          borderRadius: "0.5rem",
                        }}
                        onClick={()=>{
                          if(!(selectedDeliveryType && selectedDeliveryType?.id)){
                            toast.error("Please select order type!");
                            return false
                          }
                          if(!(selectedParty && selectedParty?.id)){
                            toast.error("Please select party!");
                            return false
                          }
                          setModalOrderType(false);
                          setModal9Show(true)
                        }}
                      >
                        Next
                      </button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </div>
        </div>
      </Modal.Body>
     
    </Modal>
      
  )
}

export default CallCenterOrdertype
