import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import StockReport from "./StockReport";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { MdDelete } from 'react-icons/md';

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";

const toastObj = {
  position: "bottom-center",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  className: "text-center toast-notification",
}

const DayEndSetting = () => {
    const { t } = useTranslation();
  
    // States hook here
  
    //search result
    let [searchedList, setSearchedList] = useState({
      list: null,
      searched: false,
    });

    const [dayEndSettingsList, setDayEndSettingsList] = useState(null);
    const [loading, setLoading] = useState(null);
  
    //useEffect == componentDidMount
    useEffect(() => { getDayEndSettingList() }, []);

    const getDayEndSettingList = () => {
      setLoading(true);
      const desUrl = BASE_URL + "/settings/get-type-list";
      return axios
        .get(desUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setDayEndSettingsList(res.data);
          setLoading(false);
        }).catch((error) => {
          setLoading(false)
        });
    };
  
    //search purchase order here
    const handleSearch = (e) => {
      let searchInput = e.target.value.toLowerCase();
      if (searchInput.length === 0) {
        setSearchedList({ ...searchedList, searched: false });
      } else {
        let searchedList = dayEndSettingsList?.details.filter((item) => {
          //name
          let lowerCaseItemName = item.name.toLowerCase();
          return (
            lowerCaseItemName.includes(searchInput)
          );
        });
        setSearchedList({
          ...searchedList,
          list: searchedList,
          searched: true,
        });
      }
    };

    //print bills states and function
//   const [newRow,setNewRow]=useState([]);
//   const [purchaseDate,setPurchaseDate]=useState(new Date());
//   let [newOpeningStock, setNewOpeningStock] = useState({
//     invoice: '',
//     branch: '',
//     remarks: '',
//     total_amount:''
//   });
//   const componentRef = useRef();
//   const handleOnlyPrint = useReactToPrint({
//     content: () => componentRef.current,
//   });
//   const handleGetPrintData = async(id) => {
//     const url =
//     BASE_URL +
//       "/settings/get-opening-stock-edit/" +
//       parseInt(id);
//     try {
//       const res = await axios
//         .get(url, {
//           headers: { Authorization: `Bearer ${getCookie()}` },
//         });
//           if(res.data && res.data.stock && res.data.stockDetails){
//             let purchaseDate = new Date(res.data.stock.document_date);
//             setPurchaseDate(purchaseDate);
//             // let branch=branchForSearch?.find((item)=>item.id === res.data.stock.branch_id)            
//             setNewOpeningStock({
//               ...newOpeningStock,
//               invoice: res.data.stock.document_identity,
//               // branch,
//               remarks: res.data.stock.remarks,
//               total_amount: res.data.stock.total_amount
//             })
//             let newArr=[];
//             if(res.data.stockDetails && res.data.stockDetails.length>0){
//               res.data.stockDetails.map((item)=>{
//                 newArr.push({
//                 ingredient_name:item.item_name,
//                 qty:parseFloat(item.qty).toFixed(0),
//                 rate:parseFloat(item.rate).toFixed(2),
//                 unit_name:item.unit_name})
//               })
//               setNewRow([...newArr])
//             }
//             handleOnlyPrint();
//           }
          
//         } catch(error) {
//           console.log("error==>",error)
//           toast.success(`${_t(t("Please try again"))}`, toastObj);
//         }
//   }

//   const handleGetAmount = (index) => {
//     let qty = 0;
//     let rate = 0;
//     if (newRow !== null && newRow[index].qty !== "") {
//       qty = parseFloat(newRow[index].qty);
//     }
//     if (newRow !== null && newRow[index].rate !== "") {
//       rate = parseFloat(newRow[index].rate);
//     }
//     return rate * qty;
//   };
  return (
    <>
    <Helmet>
      <title>{_t(t("Day End Setting"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ManageSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div className="fk-scroll--pos-menu" data-simplebar>
                <div className="t-pl-15 t-pr-15">
                  {/* Loading effect */}
                  {/* {newSupplier.uploading === true || loading === true ? (
                    tableLoading()
                  ) : ( */}
                    <div key="fragment3">
                      {/* next page data spin loading */}
                      <div className={`${(loading) && "loadingBlur"}`}></div>
                      <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* spin loading ends */}

                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {!searchedList.searched
                                  ? _t(t("Day End Setting List"))
                                  : _t(t("Search Result"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center">
                            {/* Search customer */}
                            <div className="col-md-9 t-mb-15 mb-md-0">
                              <div className="input-group">
                                <div className="form-file">
                                  <input
                                    type="text"
                                    className="form-control border-0 form-control--light-1 rounded-left"
                                    placeholder={_t(t("Search")) + ".."}
                                    onChange={handleSearch}
                                  />
                                </div>
                                <button
                                  className="btn btn-primary rounded-right"
                                  type="button"
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>

                            {/* Add customer modal trigger button */}
                            <div className="col-md-3 text-md-right">
                              <NavLink
                                to="/dashboard/manage/stock/day-end-setting-add"
                                className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                              >
                                {_t(t("add new"))}
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Table */}
                      <div className="">
                        <table className="table table-bordered table-hover min-table-height">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Type"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Stock Type"))}
                              </th>                             

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {!searchedList.searched
                              ? [
                                dayEndSettingsList && [
                                  dayEndSettingsList?.details.length ===
                                    0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="3"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    dayEndSettingsList?.details.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 }
                                            </th>

                                            <td className="xsm-text align-middle text-center">
                                            {item.name}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                            {item.stock_type}
                                            </td>                                    

                                            <td className="xsm-text text-capitalize align-middle text-center">                                              
                                              <div className="d-flex justify-content-center">                                                  
                                                  <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/day-end-setting-edit/${item.stock_type}/${item.type}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>
                                                  {/* <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                                 <button
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                    // onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print edit-icon"></i>
                                                  </button> */}
                                                  </div> 
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]
                              : [
                                /* searched data, logic === haveData*/
                                searchedList && [
                                  searchedList.list.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="3"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    searchedList.list.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1}
                                            </th>

                                            <td className="xsm-text align-middle text-center">
                                            {item.name}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                            {item.stock_type}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">                                              
                                              <div className="d-flex justify-content-center">                                                    
                                              <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/purchase-order-edit/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>
                                                  {/* <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                                 <button
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                    // onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print edit-icon"></i>
                                                  </button> */}
                                                  </div> 
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]}
                          </tbody>
                        </table>
                      </div>
                    </div>                  
                </div>
              </div>
            </div>            
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
    {/* <StockReport
      name = "Purchase Order"
      purchaseDate={purchaseOrderDate}
      newItem={newItem}
      qtyOfItem={qtyOfItem}
      rateOfItem={rateOfItem}
      handleGetTotal={handleGetTotal}
      handleAmount={handleAmount}
      handleTaxAmount={0}
      handleDisAmount={0}
      handleGetSubTotal={handleGetSubTotal}
      handleGetAmount={handleGetAmount}
      stockPrint={componentRef}
      /> */}
  </>
  )
}

export default DayEndSetting
