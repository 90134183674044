import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartThemeColorsWeek } from "./index";

const parseData = (data) => {
  if (data && (data.length || Object.keys(data)?.length))
    return {
      bar: data.bar ? Object.values(data.bar) : Array.isArray(data) ? data : [],
    };
  return {
    bar: [],
  };
};

const BarWidget = ({ data, heading, size, handleReq = null, url=null, loading=false }) => {
    const [formattedData, setFormattedData] = useState({
        bar: null,
    });
    
    useEffect(() => {
      if(data){
        setFormattedData(parseData(data));
      }else{
        setFormattedData({
          bar: null,
        });
      }
    }, [data]);
     
  return (
    <div className={`col row mx-0 mt-3 ${size} px-2 position-relative`}>
      <div className="row px-0 mx-0 justify-content-between col col-12 mt-0">
        <div className="dashboard__card col col-12 w-100 flex-basis-100">
           {heading ? <h5 className="mb-1 mt-0">{heading}</h5> : ""}
           <div className="d-flex flex-col mx-3 my-2" style={{ gap: "1rem" }}>
           </div>
           {
              formattedData.bar ? (
                <Chart
                  width="100%"
                  height="220px"
                  type="bar"
                  series={[
                    {
                      name: heading,
                      data: formattedData.bar.map((data) => parseFloat(data.value)),
                    },
                  ]}
                  options={{
                    chart: {
                      type: "bar",
                    },
                    plotOptions: {
                      bar: {
                      horizontal: true,
                      borderRadius: 10,
                      distributed: true,
                      },
                    },
                    colors: chartThemeColorsWeek,
                    dataLabels: {
                      enabled: false,
                    },
                    tooltip: {/*
                      enabled: true,
                      custom: ({ series, seriesIndex, dataPointIndex, w }) =>
                        `<div style="color:white;background-color:${chartThemeColorsWeek[seriesIndex]};" className="p-2">
                    <p >${
                      formattedData.bar[dataPointIndex].value_formatted
                        ? formattedData.bar[dataPointIndex].value_formatted
                        : formattedData.bar[dataPointIndex].value
                    }</p>
                    </div>`,
                  */},
                    xaxis: {
                        categories: formattedData.bar.map((data) => data.name),
                        labels: { 
                          show: true,
                          style:{
                            fontSize:"9.5px"
                          }
                        },
                        tickAmount: 3,
                        tickPlacement: 'between',
                      },
                      yaxis: {
                          labels: {
                              formatter: function (value) {
                                  return value;
                                },
                                style:{
                                  fontSize:"10px"
                                }
                            },
                      },
                  }}
                />
              ):(
                <div className="btn_load">
                  {
                    loading ? (
                      <>
                      {/*loading */}
                      <div className={`${(loading) && "loadingBlur"}`}></div>
                      <div className={`${(loading) && "lds-roller"}`} style={{top:"38%",left:"45%"}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      </>
                    ) : (
                      <button className="btn btn-sm btn-primary rounded-md" onClick={()=> url && handleReq(url)}>Load Data</button>
                    )
                  }
                </div>
              )
           }
          
        </div>
      </div>
    </div>
  )
}

export default BarWidget
