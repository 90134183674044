import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  checkPermission,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { MdDelete } from 'react-icons/md';

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import Moment from "react-moment";
import SupplierPaymentPrint from "../managePrintReports/SupplierPaymentPrint";

const SupplierPayment = () => {
    const { t } = useTranslation();
    //getting context values here
    let {
      //common
      loading,
      setLoading
    } = useContext(SettingsContext);
  
    const {
      getIngredientItem,      
    } = useContext(RestaurantContext)
  
    const {
      //supplier payments      
      supplierPaymentList,
      setSupplierPaymentList,
      getPaginatedSupplierPaymentList,
      supplierPaymentForSearch,
      setSupplierPaymentForSearch,
      authUserInfo,
      //pagination
      dataPaginating,
    } = useContext(UserContext);
  
    //search result
    let [searchedSupplierPayment, setSearchedSupplierPayment] = useState({
      list: null,
      searched: false,
    });
  
  
    //useEffect == componentDidMount
    useEffect(() => {
      getIngredientItem()
    }, []);

    const toastObj = {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }
  
    //search customers here
    const handleSearch = (e) => {
      let searchInput = e.target.value.toLowerCase();
      if (searchInput.length === 0 || supplierPaymentForSearch === null) {
        setSearchedSupplierPayment({ ...searchedSupplierPayment, searched: false });
      } else {
        let searchedList = supplierPaymentForSearch.filter((item) => {
          let lowerCaseTransferNo = item.document_identity.toLowerCase();
          let lowerCasePaymentType = item.payment_type.toLowerCase();          
          return (
            lowerCaseTransferNo.includes(searchInput) ||
            lowerCasePaymentType.includes(searchInput)            
          );
        });
        setSearchedSupplierPayment({
          ...searchedSupplierPayment,
          list: searchedList,
          searched: true,
        });
      }
    };

    //delete confirmation modal of payment
    const handleDeleteConfirmation = (id) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card card-body">
              <h1>{_t(t("Are you sure?"))}</h1>
              <p className="text-center">{_t(t("You want to delete this?"))}</p>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleDeletePayment(id);
                    onClose();
                  }}
                >
                  {_t(t("Yes, delete it!"))}
                </button>
                <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                  {_t(t("No"))}
                </button>
              </div>
            </div>
          );
        },
      });
    };
  
    //delete customer here
    const handleDeletePayment = (id) => {
      setLoading(true);
      const customerUrl = BASE_URL + `/settings/delete-supplier-payment/${id}`;
      return axios
        .get(customerUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setSupplierPaymentList(res.data[0]);
          setSupplierPaymentForSearch(res.data[1]);
          setSearchedSupplierPayment({
            ...searchedSupplierPayment,
            list: res.data[1],
          });
          setLoading(false);
          toast.success(`${_t(t("Supplier Payment has been deleted successfully"))}`, toastObj);
        })
        .catch(() => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, toastObj);
        });
    };
  
    //print bills states and function
    const [chequeDate,setChequeDate]=useState();
    const [docDate,setdocDate]=useState();
    let [newSupplierPayment, setNewSupplierPayment] = useState();
    const componentRef = useRef();
    const handleOnlyPrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const handleGetPrintData = async(id) => {
      const url =
      BASE_URL +
        "/settings/get-supplier-payment/" +
        parseInt(id);
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
            if(res.data && Object.keys(res.data).length > 0){
              let docDate = new Date(res.data.document_date);
              setdocDate(docDate);                          
              let chequeDate = new Date(res.data.cheque_date);
              setChequeDate(chequeDate);

              setNewSupplierPayment({
                ...newSupplierPayment,
                doc_no: res.data.document_identity,
                cheque_no: res.data.cheque_no,
                ref_invoice_no: res.data.ref_invoice_no,
                payment_type: res.data.payment_type,
                bank_name: res.data.bank_name,
                supplier: res.data.supplier,
                branch: res.data.branch,
                remarks: res.data.remarks,
                amount: res.data.amount
              });
              handleOnlyPrint();
            }
            
          } catch(error) {
            console.log("error==>",error)
            toast.success(`${_t(t("Please try again"))}`, toastObj);
          }
    }

    //post api supplier
    const handlePostApi = async (id) => {
      setLoading(true);
      const url = BASE_URL + "/settings/mark-is-post-supplier-payment"
      let formData = new FormData();
      formData.append('id',id)
      formData.append('is_post',1)
      try {
        const res = await axios.post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res){
            // console.log("res post==>",res.data);
            toast.success(`${_t(t("Supplier Payment has been post"))}`,toastObj);
          }
          setLoading(false);
      } catch {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`,toastObj);
      }
    }

    return (
      <>
        <Helmet>
          <title>{_t(t("Supplier Payment List"))}</title>
        </Helmet>
  
        {/* main body */}
        <main id="main" data-simplebar>
          <div className="container-fluid">
            <div className="row t-mt-10 gx-2">
              {/* left Sidebar */}
              <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <ManageSidebar />
              </div>
              {/* left Sidebar ends */}
  
              {/* Rightbar contents */}
              <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                <div className="t-bg-white">
                  <div className="fk-scroll--pos-table-menu" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      {/* Loading effect */}
                      {/* {loading === true ? (
                        tableLoading()
                      ) : ( */}
                        <div key="fragment3">
                          {/* next page data spin loading */}
                          <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          {/* spin loading ends */}
  
                          <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                            <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                              <ul className="t-list fk-breadcrumb">
                                <li className="fk-breadcrumb__list">
                                  <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {!searchedSupplierPayment.searched
                                      ? _t(t("Supplier Payment List"))
                                      : _t(t("Search Result"))}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6 col-lg-7">
                              <div className="row gx-3 align-items-center">
                                {/* Search customer */}
                                <div className="col-md-9 t-mb-15 mb-md-0">
                                  <div className="input-group">
                                    <div className="form-file">
                                      <input
                                        type="text"
                                        className="form-control border-0 form-control--light-1 rounded-left"
                                        placeholder={_t(t("Search")) + ".."}
                                        onChange={handleSearch}
                                      />
                                    </div>
                                    <button
                                      className="btn btn-primary rounded-right"
                                      type="button"
                                    >
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
  
                                {/* Add customer modal trigger button */}
                                <div className="col-md-3 text-md-right">
                                  <NavLink
                                    to="/dashboard/manage/stock/supplier-payment-add"
                                    className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  >
                                    {_t(t("add new"))}
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Table */}
                          <div className="">
                            <table className="table table-bordered table-hover min-table-height">
                              <thead className="align-middle">
                                <tr>
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Document Number"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Document Date"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Payment Type"))}
                                  </th>

                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
  
                                  <th
                                    scope="col"
                                    className="sm-text text-capitalize align-middle text-center border-1 border"
                                  >
                                    {_t(t("Action"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                {!searchedSupplierPayment.searched
                                  ? [
                                    supplierPaymentList && [
                                        supplierPaymentList.data?.length === 0 ? (
                                          <tr className="align-middle">
                                            <td
                                              scope="row"
                                              colSpan="7"
                                              className="xsm-text align-middle text-center"
                                            >
                                              {_t(t("No data available"))}
                                            </td>
                                          </tr>
                                        ) : (
                                          supplierPaymentList.data && supplierPaymentList.data.map((item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (supplierPaymentList.current_page -
                                                      1) *
                                                      supplierPaymentList.per_page}
                                                </th>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.document_identity}
                                                </td>
  
                                                <td className="xsm-text align-middle text-center">
                                                  <Moment format="DD-MMMM-YYYY">
                                                    {item.document_date}
                                                  </Moment>
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.payment_type}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {parseFloat(item.amount).toFixed(2)}
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">                                                
                                                  <div className="d-flex justify-content-center">  
                                                   
                                                    <NavLink
                                                    className="btn btn-primary ml-2 rounded-sm"
                                                    to={`/dashboard/manage/stock/supplier-payment-edit/${item.id}`}
                                                    style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                    >
                                                    <div className="d-flex align-items-center justify-content-center">
                                                      <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                    </div>
                                                    </NavLink>
                                                    <button
                                                    className="btn btn-danger ml-2 rounded-sm del-btn"
                                                    onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                    >                                                    
                                                      <MdDelete className="del-icon" />                                                    
                                                    </button>
                                                                                                      
                                                    <button
                                                    className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                      onClick={()=>handleGetPrintData(item.id)}
                                                    >
                                                      <i className="fa fa-print edit-icon"></i>
                                                    </button>
                                                    {/*
                                                      checkPermission(authUserInfo.permissions, "Supplier Payment Post") && (item.is_post !== 1 ? <button
                                                      className="btn btn-dark ml-2 rounded-sm del-btn"
                                                      title="Post supplier payment" 
                                                      style={{padding:"1px 0px 0px 1px"}}                                               
                                                        onClick={()=>handlePostApi(item.id)}
                                                       >
                                                        <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                                      </button> : null)*/}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ),
                                      ],
                                    ]
                                  : [
                                      /* searched data, logic === haveData*/
                                      searchedSupplierPayment && [
                                        searchedSupplierPayment.list.length === 0 ? (
                                          <tr className="align-middle">
                                            <td
                                              scope="row"
                                              colSpan="7"
                                              className="xsm-text align-middle text-center"
                                            >
                                              {_t(t("No data available"))}
                                            </td>
                                          </tr>
                                        ) : (
                                          searchedSupplierPayment.list.map(
                                            (item, index) => {
                                              return (
                                                <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (supplierPaymentList.current_page -
                                                      1) *
                                                      supplierPaymentList.per_page}
                                                </th>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.document_identity}
                                                </td>
  
                                                <td className="xsm-text align-middle text-center">
                                                  <Moment format="DD-MMMM-YYYY">
                                                    {item.document_date}
                                                  </Moment>
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.payment_type}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {parseFloat(item.amount).toFixed(2)}
                                                </td>
  
                                                <td className="xsm-text text-capitalize align-middle text-center">                                                
                                                  <div className="d-flex justify-content-center"> 
                                                   
                                                      <NavLink
                                                        className="btn btn-primary ml-2 rounded-sm"
                                                        to={`/dashboard/manage/stock/supplier-payment-edit/${item.id}`}
                                                        style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                      >
                                                        <div className="d-flex align-items-center justify-content-center">
                                                          <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                        </div>
                                                      </NavLink>
                                                      <button
                                                        className="btn btn-danger ml-2 rounded-sm del-btn"
                                                        onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                        >                                                    
                                                          <MdDelete className="del-icon" />                                                    
                                                      </button>
                                                                                                       
                                                    <button
                                                      className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                        onClick={()=>handleGetPrintData(item.id)}
                                                      >
                                                        <i className="fa fa-print edit-icon"></i>
                                                      </button>
                                                      {/*
                                                        checkPermission(authUserInfo.permissions, "Supplier Payment Post") && (item.is_post !== 1 ? <button
                                                        className="btn btn-dark ml-2 rounded-sm del-btn"
                                                        title="Post supplier payment" 
                                                        style={{padding:"1px 0px 0px 1px"}}                                               
                                                          onClick={()=>handlePostApi(item.id)}
                                                         >
                                                          <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                                        </button> : null)*/}
                                                    </div>
                                                </td>
                                              </tr>
                                              );
                                            }
                                          )
                                        ),
                                      ],
                                    ]}
                              </tbody>
                            </table>
                          </div>
                        </div>                    
                    </div>
                  </div>
                </div>
  
                {/* pagination loading effect */}
                {loading === true
                  ? paginationLoading()
                  : [
                      // logic === !searched
                      !searchedSupplierPayment.searched ? (
                        <div key="fragment4">
                          <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                            <div className="row align-items-center t-pl-15 t-pr-15">
                              <div className="col-md-7 t-mb-15 mb-md-0">
                                {/* pagination function */}
                                {pagination(supplierPaymentList, getPaginatedSupplierPaymentList)}
                              </div>
                              <div className="col-md-5">
                                <ul className="t-list d-flex justify-content-md-end align-items-center">
                                  <li className="t-list__item">
                                    <span className="d-inline-block sm-text">
                                      {showingData(supplierPaymentList)}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // if searched
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              <ul className="t-list d-flex">
                                <li className="t-list__item no-pagination-style">
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      setSearchedSupplierPayment({
                                        ...searchedSupplierPayment,
                                        searched: false,
                                      })
                                    }
                                  >
                                    {_t(t("Clear Search"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {searchedShowingData(
                                      searchedSupplierPayment,
                                      supplierPaymentForSearch
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ),
                    ]}
              </div>
              {/* Rightbar contents end*/}
            </div>
          </div>
        </main>
        {/* main body ends */}
        <SupplierPaymentPrint
          componentRef = {componentRef}
          thermalPrintData = {newSupplierPayment}
          cheque_date = {chequeDate} 
          doc_date = {docDate}
        />
      </>
    );
}

export default SupplierPayment;