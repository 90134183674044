import React, { useState, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import ProductionPrint from "./ProductionPrint";
import { useReactToPrint } from "react-to-print";
//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";

const ProductionList = () => {
    const { t } = useTranslation();
  
    let {
      //pagination
      loading,
      setLoading,
      dataPaginating,
      setProductionItemList,
      setProductionItemForSearch,
      setPaginatedProductionItem,
      productionItemList,
      productionItemForSearch
    } = useContext(RestaurantContext);

    const [printData, setPrintData] = useState({
      data: null,
      item: null,
    })
  
  
    // States hook here
    //new customer
    //search result
    let [searchedProduction, setSearchedProduction] = useState({
      list: null,
      searched: false,
    });

    const componentRef = useRef();

    const handleOnlyPrint = useReactToPrint({
      content: () => componentRef.current,      
    });

    //search purchase here
    const handleSearch = (e) => {
      let searchInput = e.target.value.toLowerCase();
      if (searchInput.length === 0) {
        setSearchedProduction({ ...searchedProduction, searched: false });
      } else {
        let searchedList = productionItemForSearch?.filter((item) => {
          //name
          let lowerCaseProductionName = item.item_name.toLowerCase();
          let lowerBranchName = item.branch_name.toLowerCase();
          let lowerDocNo = item.document_identity.toLowerCase()

          return (
            lowerCaseProductionName.includes(searchInput) ||
             (lowerBranchName && lowerBranchName.includes(searchInput)) ||
             (lowerDocNo && lowerDocNo.includes(searchInput))
          );
        });
        setSearchedProduction({
          ...searchedProduction,
          list: searchedList,
          searched: true,
        });
      }
    };

    const handleGetPrintData = async(id) =>{
      const url = BASE_URL + `/settings/get-production-edit/${id}`;
      try {
        const res = await axios
            .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            });
            let ProductionDetail = {
              doc_no: res.data.production.document_identity,
              date: res.data.production.document_date,
              type: res.data.production?.prd_type,
              branch: res.data.production.branch,
              item: res.data.production.item,
              qty: res.data.production.qty,
              recipe_qty: res.data.production.recipe_qty,
              actual_qty: res.data.production.actual_qty,
              rate: (parseFloat(res.data.production.cost)).toFixed(3),
              cost: res.data.production.cost,
              amount: res.data.production.amount,
              additional_amount: res.data.production.additional_amount,
              unit: res.data.production.unit_code,
              remarks: res.data.production.remarks,                
            }

            setPrintData({
              data: ProductionDetail,
              items: res.data.productionDetail
            })
            setTimeout(() => {
              handleOnlyPrint();              
            }, 500);
            setLoading(false);
            
        } catch {
            setLoading(false);
            toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        }
    }

    const handlePostProduction = async(item) =>{
      const url = BASE_URL + `/settings/mark-is-post-production`;

      let formData = {
        id: item.id,
        item_id: item.id,
        branch_id: item.branch_id,
        prd_type : item?.prd_type,
      }
      setLoading(true);
      try {
        const res = await axios
            .post(url, formData, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            });
            setProductionItemList(res.data[0])
            setProductionItemForSearch(res.data[1]);
            setSearchedProduction({
                ...searchedProduction,
                list: res.data[1],
                searched: false,
            });
            setLoading(false);
            toast.success(`${_t(t("Production has been post successfully"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        } catch {
            setLoading(false);
            toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        }
     
    }
  
    //delete confirmation modal of waiter
    const handleDeleteConfirmation = (id) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card card-body">
              <h1>{_t(t("Are you sure?"))}</h1>
              <p className="text-center">{_t(t("You want to delete this?"))}</p>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleDeleteProduction(id);
                    onClose();
                  }}
                >
                  {_t(t("Yes, delete it!"))}
                </button>
                <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                  {_t(t("No"))}
                </button>
              </div>
            </div>
          );
        },
      });
    };
  
    //delete purchase here
    const handleDeleteProduction = async (id) => {
      setLoading(true);
      const url = BASE_URL + `/settings/Production/${id}`;
      try {
            const res = await axios
                .delete(url, {
                    headers: { Authorization: `Bearer ${getCookie()}` },
                });
            
            setSearchedProduction({
                ...searchedProduction,
                list: res.data[1],
            });
            setLoading(false);
            toast.success(`${_t(t("Production has been deleted successfully"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        } catch {
            setLoading(false);
            toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        }
    };
  return (
    <>
    <Helmet>
      <title>{_t(t("Productions"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <ManageSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div className="fk-scroll--pos-table-menu" data-simplebar>
                <div className="t-pl-15 t-pr-15">
                  {/* Loading effect */}
                  {/* {loading === true ? (
                    tableLoading()
                  ) : ( */}
                    <div key="fragment3">
                      {/* next page data spin loading */}
                      <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                      <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      {/* spin loading ends */}

                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {!searchedProduction.searched
                                  ? _t(t("Production List"))
                                  : _t(t("Search Result"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center">
                            {/* Search customer */}
                            <div className="col-md-9 t-mb-15 mb-md-0">
                              <div className="input-group">
                                <div className="form-file">
                                  <input
                                    type="text"
                                    className="form-control border-0 form-control--light-1 rounded-left"
                                    placeholder={_t(t("Search")) + ".."}
                                    onChange={handleSearch}
                                  />
                                </div>
                                <button
                                  className="btn btn-primary rounded-right"
                                  type="button"
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>

                            {/* Add customer modal trigger button */}
                            <div className="col-md-3 text-md-right">
                              <NavLink
                                to="/dashboard/manage/stock/add-production"
                                className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                              >
                                {_t(t("add new"))}
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Table */}
                      <div className="">
                        <table className="table table-bordered table-hover min-table-height">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Document No"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Document Date"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Production Item"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>

                              <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Amount"))}
                              </th>

                              <th
                              scope="col"
                              className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Created Date"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {!searchedProduction.searched
                              ? [
                                productionItemList && [
                                  productionItemList.data.length ===
                                    0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="7"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    productionItemList.data && productionItemList.data.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (productionItemList.current_page -
                                                  1) *
                                                  productionItemList.per_page}
                                            </th>

                                            <td  className="xsm-text text-capitalize align-middle text-center">
                                             {item.document_identity}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              <Moment format="DD-MMMM-YYYY">
                                                {item.document_date}
                                              </Moment>  
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.item_name}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                             {item.branch_name}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                             {item.amount}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">                                        
                                              <Moment format="DD-MMMM-YYYY hh:mm A">
                                                {item?.created_at}
                                              </Moment>                                          
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                            {
                                              item.is_post === 0 ? (
                                                <>
                                                <NavLink
                                                title="edit"
                                                 to={`/dashboard/manage/stock/edit-production/${item.id}`}
                                                 className="btn btn-primary ml-2 rounded-sm del-btn"
                                               >
                                                   <i className="fa fa-pencil"></i>
                                               </NavLink>
                                               <button
                                                 className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                 onClick={()=>handleGetPrintData(item.id)}
                                               >
                                                 <i className="fa fa-print edit-icon"></i>
                                               </button>
                                                <button
                                                  disabled={loading}
                                                  className="btn btn-dark ml-2 rounded-sm del-btn"
                                                  title="Post production" 
                                                  style={{padding:"1px 0px 0px 1px"}}                                               
                                                  onClick={()=>handlePostProduction(item)}
                                                >
                                                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                  </button>
                                                </>  
                                              ) : (
                                                <button
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleGetPrintData(item.id)}
                                                 >
                                                  <i className="fa fa-print edit-icon"></i>
                                                </button>
                                              )
                                             }
                                                                                        
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]
                              : [
                                /* searched data, logic === haveData*/
                                searchedProduction && [
                                  searchedProduction.list.length === 0 ? (
                                    <tr className="align-middle">
                                      <td
                                        scope="row"
                                        colSpan="7"
                                        className="xsm-text align-middle text-center"
                                      >
                                        {_t(t("No data available"))}
                                      </td>
                                    </tr>
                                  ) : (
                                    searchedProduction.list.map(
                                      (item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (productionItemList.current_page -
                                                  1) *
                                                  productionItemList.per_page}
                                            </th>

                                            <td  className="xsm-text text-capitalize align-middle text-center">
                                              {item.document_identity}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              <Moment format="DD-MMMM-YYYY">
                                                {item?.document_date}
                                              </Moment>                                          
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.item_name}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.branch_name}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                             {item.amount}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">                                        
                                              <Moment format="DD-MMMM-YYYY hh:mm A">
                                                {item?.created_at}
                                              </Moment>                                          
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                            {
                                              item.is_post === 0 ? (
                                                <>
                                                <NavLink
                                                title="edit"
                                                 to={`/dashboard/manage/stock/edit-production/${item.id}`}
                                                 className="btn btn-primary ml-2 rounded-sm del-btn"
                                               >
                                                   <i className="fa fa-pencil"></i>
                                               </NavLink>
                                               <button
                                                className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                onClick={()=>handleGetPrintData(item.id)}
                                               >
                                                 <i className="fa fa-print edit-icon"></i>
                                               </button>
                                                <button
                                                  disabled={loading}
                                                  className="btn btn-dark ml-2 rounded-sm del-btn"
                                                  title="Post production" 
                                                  style={{padding:"1px 0px 0px 1px"}}                                               
                                                  onClick={()=>handlePostProduction(item)}
                                                >
                                                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                  </button>
                                                </>  
                                              ) : (
                                                <button
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleGetPrintData(item.id)}
                                                 >
                                                  <i className="fa fa-print edit-icon"></i>
                                                </button>
                                              )
                                             }
                                             
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ),
                                ],
                              ]}
                          </tbody>
                        </table>
                      </div>
                    </div>                    
                </div>
              </div>
            </div>

            {/* pagination loading effect */}
            {loading === true
              ? paginationLoading()
              : [
                // logic === !searched
                !searchedProduction.searched ? (
                  <div key="fragment4">
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          {/* pagination function */}
                          {pagination(
                            productionItemList,
                            setPaginatedProductionItem
                          )}
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {showingData(productionItemList)}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  // if searched
                  <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                    <div className="row align-items-center t-pl-15 t-pr-15">
                      <div className="col-md-7 t-mb-15 mb-md-0">
                        <ul className="t-list d-flex">
                          <li className="t-list__item no-pagination-style">
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                setSearchedProduction({
                                  ...searchedProduction,
                                  searched: false,
                                })
                              }
                            >
                              {_t(t("Clear Search"))}
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-5">
                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                          <li className="t-list__item">
                            <span className="d-inline-block sm-text">
                              {searchedShowingData(
                                searchedProduction,
                                productionItemForSearch
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ),
              ]}
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
      <div>
   </div>
    </main>
    {/* main body ends */}
    <ProductionPrint 
      name = "Production"
      production={printData.data}
      newRow={printData.items}
      print={componentRef}
    />
  </>
  )
}

export default ProductionList
