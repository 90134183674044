import React, { useEffect, useContext, useState,useRef } from "react";
import { useHistory,useParams } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
import AccountPrint from "./AccountPrint";
//functions
import {
  _t,
  getCookie,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { MdDelete } from 'react-icons/md';
// import StockReport from "./StockReport";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//context consumer
// import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import { FoodContext } from "../../../../../contexts/Food"
import { FinanceContext } from "../../../../../contexts/Finance";

const EditCashPayment = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params=useParams();
    //getting context values here
    let {
      // loading,
      // setLoading,
      branchForSearch,
      getIngredientItem,
      ingredientItemForSearch,
      setIngredientPurchaseHistory,
      setIngredientPurchaseHistoryForSearch,
      getWorkPeriod,
      // dataPaginating,
    } = useContext(RestaurantContext);
    let { getSupplier, supplierForSearch } = useContext(UserContext);
    // let { workPeriodWeb, getFoodWeb } = useContext(FoodContext);
    //finance context
    const {
      loading,
      setLoading,
  
      // coa level 1
        coa1,
        setCoa1,
        coa1ForSearch,
        setCoa1ForSearch,
        setPaginatedCoa1,
  
      //coa2
      coa2, 
      setCoa2,
      coa2ForSearch,
      setCoa2ForSearch,
      getCoaL2,
      setPaginatedCoa2,
  
        //coa3
        coa3, 
        setCoa3,
        coa3ForSearch,
        setCoa3ForSearch,
        getCoaL3,
        setPaginatedCoa3,
  
        //BPList
        bankPaymentList,
        setBankPaymentList,
        bankPaymentListForSearch,
        setBankPaymentListForSearch,
        getBPList,
        setPaginatedBPList,

        //CPList
        cashPaymentList,
        setCashPaymentList,
        cashPaymentListForSearch,
        setCashPaymentListForSearch,
        getCPList,
        setPaginatedCPList,
  
        //transaction account
        // bankAccounts,
        // setBankAccounts,
  
        //pagination
        dataPaginating,
        setDataPaginating,
    } = useContext(FinanceContext);
    // console.log("check tran acc==>",bankAccounts);
    // States hook here
    //new item
    // let [newItem, setNewItem] = useState({
    //   branch: null,
    //   supplier: null,
    //   invoice: "",
    //   description: "",
    //   paymentType: "",
    //   items: null,
    //   paid: null,
    //   total: null,
    //   due: null,
    // });
  
    //Add new row
    const [newRow,setNewRow]=useState([]);
  
    //datepicker
    // const [purchaseOrderDate, setpurchaseOrderDate] = useState(new Date());
  
    //overflow state
    const [overflowStyle,setOverflowStyle]=useState(false);
    const componentRef = useRef("");
  
    //cashpayment state
    const [newRowPrint,setNewRowPrint]=useState([]);
    const [cashPayment,setCashPayment]=useState({
      doc_no:"",
      doc_date:new Date(),
      transactionAccount:"",
      supplierName:"",
      remarks:""
    });
  
    //Totals of rows
    const [totalRowAmount,setTotalRowAmount]=useState(0);
    const [totalRowWhtAmount,setTotalRowWhtAmount]=useState(0);
    const [totalRowTaxAmount,setTotalRowTaxAmount]=useState(0);
    const [totalRowNetAmount,setTotalRowNetAmount]=useState(0);
    const [bankPaymentPrint,setBankPaymentPrint]=useState({
      doc_no:"",
      doc_date:new Date(),
      transactionAccount:"",
      supplierName:"",
      remarks:""
    });
     const [totalRowAmountPrint,setTotalRowAmountPrint]=useState(0);
     const [totalRowWhtAmountPrint,setTotalRowWhtAmountPrint]=useState(0);
     const [totalRowTaxAmountPrint,setTotalRowTaxAmountPrint]=useState(0);
     const [totalRowNetAmountPrint,setTotalRowNetAmountPrint]=useState(0);
  
    //bank transaction accounts
    const [cashAccounts,setCashAccounts]=useState([]);
  
    //edit account
    const [editAccount,setEditAccount]=useState(null);
  
    const getTransactionAccount = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-cash-account";
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setCashAccounts(res.data);
        setLoading(false)
      //   setDataToLocal(url, res);
      console.log("check bank accounts==>",res.data);
      } catch (error) {
        setLoading(false)
          console.log("error==>",error)
          // if (offline(url)) {
          //   let parseRes = getDataFromLocal(url);
          //   setGl(parseRes);
          // }
      }
    };
  
    //qty and rate
    // let [qtyOfItem, setQtyOfItem] = useState(null);
    // let [rateOfItem, setRateOfItem] = useState(null);
     //print bills
    //  const componentRef = useRef();
  
    //useEffect == componentDidMount()
    useEffect(() => {
      // getFoodWeb();
      getSupplier();
      getTransactionAccount();
      getSpecificAccount()
      // getIngredientItem();
    }, []);
    let rowId=useRef(0);
    useEffect(() => {
      if(editAccount && editAccount !== null){
        let docDate=new Date(editAccount.cashPayment.document_date);
        let transAcc=cashAccounts?.find((item)=>editAccount.cashPayment.transaction_account_id === item.id)
        let suppAcc=supplierForSearch?.find((item)=>editAccount.cashPayment.supplier_id === item.id)
        setCashPayment({
          ...cashPayment,
          doc_no:editAccount.cashPayment.document_identity,
          doc_date:docDate,
          remarks:editAccount.cashPayment.remarks,
          transactionAccount:transAcc,
          supplierName:suppAcc
        })
        let rowArr=[]
        if(editAccount.cashPaymentDetail && editAccount.cashPaymentDetail.length > 0){
          editAccount.cashPaymentDetail.map((item)=>{
            let acc_name=coa3ForSearch?.find((v,i)=>item.coa_id==v.id)            
            rowArr.push({
              id:rowId.current,
              account_name:acc_name,            
              amount:item.amount,
              tax_percent:item.other_tax_percent,
              tax_amount:item.other_tax_amount,
              wht_percent:item.wht_percent,
              wht_amount:item.wht_amount,
              net_amount:item.net_amount,        
              remarks:item.remarks
            })
            rowId.current += 1
          })
          setNewRow([...rowArr]);
        }
        setTotalRowAmount(editAccount.cashPayment.total_amount);
        setTotalRowTaxAmount(editAccount.cashPayment.total_other_tax_amount);
        setTotalRowWhtAmount(editAccount.cashPayment.total_wht_amount);
        setTotalRowNetAmount(editAccount.cashPayment.total_net_amount);      
      }
    }, [editAccount,coa3ForSearch,supplierForSearch,cashAccounts]);
  
    const getSpecificAccount = async () => {
      setLoading(true);
      const url =
        BASE_URL +
        "/settings/show-cash-payment/" +
        parseInt(params.id);
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          // console.log("check specific account==>",res);
          if(res){
            setEditAccount(res.data)
          }
          
          // console.log("check bank acc==>",bankAccounts);
          
          // setgoodsDate(goodDate);
          // setPurchasedItem(res.data[0])
          // setPurchaseGroup(res.data[1])
       
        // setNewItem({
        //   ...newItem,
        //   items: res.data[0],
        //   invoice: res.data[1].invoice_number,
        //   description: res.data[1].desc,
        // });
  
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
  
    const handleOnlyPrint = useReactToPrint({
      content: () => componentRef.current,
      pageStyle: "@page { size: landscape; margin: 30px}"
    });
  
    const handlePrint = async() =>{
      setLoading(true);
      const url = BASE_URL + "/settings/cash-payment-print/" + params.id;
      try {
        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        const dateFormat = new Date(res.data.document_date);
        setNewRowPrint(res.data.Details)
        setBankPaymentPrint({
          doc_date: dateFormat,
          doc_no: res.data.document_identity,
          transactionAccount: res.data.transaction_accounts,
          remarks: res.data.remarks
        })
        setTotalRowAmountPrint(res.data.total_amount)
        setTotalRowNetAmountPrint(res.data.total_net_amount);
        setTotalRowTaxAmountPrint(res.data.total_other_tax_amount)
        setTotalRowWhtAmountPrint(res.data.total_wht_amount)
        setTimeout(() => {
          handleOnlyPrint()
        }, 500);
        setLoading(false)
      //   setDataToLocal(url, res);
      } catch (error) {
        setLoading(false)
          console.log("error==>",error)
      }
    }
  
    // const handleOnlyPrint = useReactToPrint({
    //   content: () => componentRef.current,
      
    // });
  
    //select style
    const customStyle = {
        control: (provided) => ({
          ...provided,
          minHeight : '2rem',
          marginTop:"0",
          marginBottom:"0",
        
        }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        singleValue: (provided) =>({
          ...provided,
          alignSelf: "center",
        }),
        input:(provided)=>({
          ...provided,
         margin:"0",
        }),
        valueContainer: (provided) => ({
          ...provided,
          marginTop:"0",
          padding:"0 0.5rem",
         
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding:"0 0.4rem",
          "svg": {
            height: '2rem',
          }
        }),
        // container: (provided, state) => ({
        //   ...provided,
        //   position: 'relative', // Ensure relative positioning
        // }),
        // menu: (provided, state) => ({
        //   ...provided,
        //   position: 'absolute', // Set position to absolute
        //   zIndex: 99999999999999, // Set a higher zIndex value
        // }),
       
      }
    
    //on change input field
    // const handleChange = (e) => {
    //   setNewItem({ ...newItem, [e.target.name]: e.target.value });
    // };
  
    //set items hook
    // const handleSetItems = (item) => {
    //   let data = newItem?.items ? newItem.items : []
    //   if(data.includes(item)){
    //     toast.error("Already exist please select another item")
    //   }else{
    //     //newItem?.items && data.push(...newItem.items.reverse());
    //     data.unshift(item);
    //     setNewItem({
    //       ...newItem,
    //       items: data,
    //     });
    //   }
    // };
  
  
    //subtotal
    // const handleGetSubTotal = (id) => {
    //   let qty = 0;
    //   let rate = 0;
    //   if (qtyOfItem !== null && qtyOfItem[id]) {
    //     qty = parseFloat(qtyOfItem[id]);
    //   }
    //   if (rateOfItem !== null && rateOfItem[id]) {
    //     rate = parseFloat(rateOfItem[id]);
    //   }
    //   return rate * qty;
    // };
  
     //subtotal
    //  const handleGetAmount = (id) => {
    //   let qty = 0;
    //   let rate = 0;
    //   if (qtyOfItem !== null && qtyOfItem[id]) {
    //     qty = parseFloat(qtyOfItem[id]);
    //   }
    //   if (rateOfItem !== null && rateOfItem[id]) {
    //     rate = parseFloat(rateOfItem[id]);
    //   }
    //   return rate * qty;
    // };
  
      // total amount
      // const handleAmount = () => {
      //   let total = 0;
      //   if (newItem.items !== null) {
      //     newItem.items.map((item) => {
      //       if (
      //         qtyOfItem &&
      //         rateOfItem &&
      //         qtyOfItem[item.id] &&
      //         rateOfItem[item.id]
      //       ) {
      //         total = total + qtyOfItem[item.id] * rateOfItem[item.id];
      //       }
      //     });
      //   }
      //   // setNewItem({
      //   //   ...newItem,
      //   //   item_amount:total
      //   // });
      //   return total;
      // };
  
    //total
    // const handleGetTotal = () => {
    //   let total = 0;
    //   if (newItem.items !== null) {
    //     newItem.items.map((item) => {
    //       if (
    //         qtyOfItem &&
    //         rateOfItem &&
    //         qtyOfItem[item.id] &&
    //         rateOfItem[item.id]
    //       ) {
    //         total = total + qtyOfItem[item.id] * rateOfItem[item.id];
    //       }
    //     });
    //   }
    //   return total;
    // };
  
    //set each item qty
    // const handleItemQty = (e) => {
    //   setQtyOfItem({
    //     ...qtyOfItem,
    //     [e.target.name]: e.target.value,
    //   });
    // };
  
    //set each item qty
    // const handleItemRate = (e) => {
    //   setRateOfItem({
    //     ...rateOfItem,
    //     [e.target.name]: e.target.value,
    //   });
    // };
  
  
    //handle Set supplier hook
    // const handleSetSupplier = (supplier) => {
    //   setNewItem({ ...newItem, supplier });
    // };
  
    //post req of food item add
    // const ingredientItemAxios = async () => {
    //   setLoading(true);
    //   let date =
    //     new Date(purchaseOrderDate).getFullYear() +
    //     "-" +
    //     (new Date(purchaseOrderDate).getMonth() + 1) +
    //     "-" +
    //     new Date(purchaseOrderDate).getDate();
       
  
    //   // formData.append("item_amount", handleAmount());
  
    //   //converting items and prices to array
    //   let slugArray = [];
    //   newItem.items.map((newIngredientItem) => {
    //     slugArray.push({
    //       qty: qtyOfItem && qtyOfItem[newIngredientItem?.id],
    //       rate: rateOfItem && rateOfItem[newIngredientItem?.id],
    //       item_id: newIngredientItem?.id,
    //       item_name: newIngredientItem.name,
    //       unit_name: newIngredientItem.unit,
    //       amount: handleGetAmount(newIngredientItem?.id),
    //     });
    //   });
  
    //   let formData = {
    //     supplier_id : newItem.supplier.id,
    //     // document_identity: newItem.invoice,
    //     document_date : date,
    //     remarks : newItem.description,
    //     total_amount : handleGetTotal(),
    //     items : slugArray
    //   }
  
    //   const url = BASE_URL + "/settings/new-purchase-order";
    //    await axios
    //     .post(url, formData, {
    //       headers: { Authorization: `Bearer ${getCookie()}` },
    //     })
    //   .then((res) => {
        
    //     handleOnlyPrint();
    //     getIngredientItem();
    //     setIngredientPurchaseHistory(res.data[0])
    //     setIngredientPurchaseHistoryForSearch(res.data[1])
    //     setNewItem({
    //       branch: null,
    //       supplier: null,
    //       invoice: "",
    //       description: "",
    //       paymentType: "",
    //       items: null,
    //       paid: null,
    //       total: null,
    //       due: null,
    //     });
    //     setQtyOfItem(null);
    //     setRateOfItem(null);
    //     setLoading(false);
    //     toast.success(`${_t(t("Purchase Order has been added"))}`, {
    //       position: "bottom-center",
    //       autoClose: 10000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       className: "text-center toast-notification",
    //     });
    //   }).catch((error) =>{
    //     setLoading(false);
    //     toast.error(`${_t(t("Please try again later"))}`, {
    //       position: "bottom-center",
    //       autoClose: 10000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       className: "text-center toast-notification",
    //     });
    //   })
    // };
  
    //send to server
    // const handleSubmit = (e) => {
    //   e.preventDefault();
    //   if (
    //     //newItem.branch !== null &&
    //     newItem.supplier !== null &&
    //     newItem.items !== null &&
    //     newItem.purchaseOrderDate !== null
    //   ) {
    //     ingredientItemAxios();
    //   } else {
    //     toast.error(`${_t(t("Please select the supplier"))}`, {
    //       position: "bottom-center",
    //       autoClose: 10000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       className: "text-center toast-notification",
    //     });
    //   }
    // };
  
    const saveAccount=async(formData)=>{
      setLoading(true);
      //converting items and prices to array
      // let slugArray = [];
      // newItem.items.map((newIngredientItem) => {
      //   slugArray.push({
      //     qty: qtyOfItem && qtyOfItem[newIngredientItem?.id],
      //     rate: rateOfItem && rateOfItem[newIngredientItem?.id],
      //     item_id: newIngredientItem?.id,
      //     item_name: newIngredientItem.name,
      //     unit_name: newIngredientItem.unit,
      //     amount: handleGetAmount(newIngredientItem?.id),
      //   });
      // });
  
      // let formData = {
      //   supplier_id : newItem.supplier.id,
      //   // document_identity: newItem.invoice,
      //   document_date : date,
      //   remarks : newItem.description,
      //   total_amount : handleGetTotal(),
      //   items : slugArray
      // }
  
      const url = BASE_URL + "/settings/update-cash-payment";
       await axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
      .then((res) => {
        // console.log("Updated response==>",res)
        // setBankPayment({
        //   ...bankPayment,
        //   doc_date:new Date(),
        //   doc_no:"",
        //   remarks:"",
        //   supplierName:"",
        //   transactionAccount:""
        // })
        // setNewRow([]);
        getSpecificAccount();
        getCPList();
        // handleOnlyPrint();
        // getIngredientItem();
        // setIngredientPurchaseHistory(res.data[0])
        // setIngredientPurchaseHistoryForSearch(res.data[1])
        // setNewItem({
        //   branch: null,
        //   supplier: null,
        //   invoice: "",
        //   description: "",
        //   paymentType: "",
        //   items: null,
        //   paid: null,
        //   total: null,
        //   due: null,
        // });
        // setQtyOfItem(null);
        // setRateOfItem(null);
        setLoading(false);
        toast.success(`${_t(t("Cash payment has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        history.push("/dashboard/manage/transaction/cash-payment")
      }).catch((error) =>{
        setLoading(false);
        toast.error(`${_t(t("Please try again later"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
    }
    
    const handleSubmit=(e)=>{
      e.preventDefault();
      if(cashPayment.transactionAccount == undefined || cashPayment.transactionAccount == ""){
        toast.error(`${_t(t("Please select transaction account"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }else{
        if(newRow && newRow.length>0){
          let noFilledRows=newRow.length>0 && newRow.filter((v,i)=> v.account_name == "")
          if(noFilledRows.length>0){
        toast.error(`${_t(t("Please select account name"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
          }else{
        const newArr = newRow.map((v,i) => {
          let obj={ 
            coa_account_id:v.account_name.id,            
            amount:v.amount,
            other_tax_percent:isNaN(v.tax_percent) ? 0 : parseFloat(v.tax_percent),
            other_tax_amount:parseFloat(v.tax_amount),
            wht_percent:isNaN(v.wht_percent) ? 0 : parseFloat(v.wht_percent),
            wht_amount:parseFloat(v.wht_amount),
            net_amount:parseFloat(v.net_amount),         
            remarks:v.remarks
          }
          return obj;
        })
        let docDate = cashPayment.doc_date.getFullYear() + "-" + (parseInt(cashPayment.doc_date.getMonth()) + 1) + "-" + cashPayment.doc_date.getDate();
        let saveObj={
          id:params.id,
          document_date:docDate,
          transaction_account_id:cashPayment.transactionAccount.id,
          supplier_id:cashPayment.supplierName ? cashPayment.supplierName.id : "",
          remarks:cashPayment.remarks,
          rows:newArr,
          total_amount:totalRowAmount,
          total_wht_amount:totalRowWhtAmount,
          total_other_tax_amount:totalRowTaxAmount,
          total_net_amount:totalRowNetAmount       
        }
        // console.log("check save==>",saveObj);
        saveAccount(saveObj)
          }
        }else{
          toast.error(`${_t(t("Please add accounts"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      }
      
    }
    const handleDocDate=(date)=>{
      setCashPayment({
        ...cashPayment,
        doc_date:date
      })
    }
  
    const handleTransactionAcc=(acc)=>{
        setCashPayment({
          ...cashPayment,
          transactionAccount:acc
        })
    }
  
    const handleSetSupplier = (supplier) => {
      setCashPayment({
        ...cashPayment,
        supplierName:supplier
      })
    };
    
    const handleRemarks = (remarks) =>{
      setCashPayment({
        ...cashPayment,
        remarks
      })
    } 
  
    // const [rowId,setRowId]=useState(0);
    const addNewRow=()=>{      
      setNewRow((pre)=>[...pre,{
        id:rowId.current,
        account_name:"",        
        amount:"",
        tax_percent:0,
        tax_amount:"",
        wht_percent:0,
        wht_amount:"",
        net_amount:"",      
        remarks:""
      }])
      rowId.current += 1
      // setRowId(rowId+1);
    }
    const delRow=(id)=>{
      let row=[...newRow]
      row= row.filter((item,i)=>id!==item.id);
      setNewRow(row);
      totalAmount(row);
      totalTaxAmount(row);
      totalWhtAmount(row);
      totalNetAmount(row);
    }
  
    const handleAccountName=(index,acc)=>{
      newRow[index].account_name=acc  
      setNewRow([...newRow]);
    }
    
    //Financial Calculations
  const accCalculation=(row,field,index)=>{
    // console.log("function==>",row,field,index);
    if(field=='amount'){
      let taxAmount=(row[index].amount*row[index].tax_percent)/100;
      // console.log("check calTax==>",(isNaN(taxAmount)))
      row[index].tax_amount= (isNaN(taxAmount) ? 0 : taxAmount.toFixed(3));
      let whtAmount=(row[index].amount*row[index].wht_percent)/100;
      row[index].wht_amount=(isNaN(whtAmount) ? 0 : whtAmount.toFixed(3));
      setNewRow([...row]);
    }else if(field=='tax_percent'){
      let taxAmount=(row[index].amount*row[index].tax_percent)/100;
      row[index].tax_amount= (isNaN(taxAmount) ? 0 : taxAmount.toFixed(3));
      setNewRow([...row]);
    }else{
      let whtAmount=(row[index].amount*row[index].wht_percent)/100;
      row[index].wht_amount=(isNaN(whtAmount) ? 0 : whtAmount.toFixed(3));
      setNewRow([...row]);
    }
    let netAmount=parseFloat(row[index].amount)-parseFloat(row[index].tax_amount)-parseFloat(row[index].wht_amount);
    row[index].net_amount=netAmount.toFixed(3);
    setNewRow([...row]);
    totalAmount(newRow);
    totalTaxAmount(newRow);
    totalWhtAmount(newRow);
    totalNetAmount(newRow);
  }
  
    const handleAmt=(index,e)=>{
      newRow[index].amount= parseInt(e.target.value)
      setNewRow([...newRow])
      accCalculation(newRow,'amount',index);
    }    

    const handleTaxPercent=(index,e)=>{
        newRow[index].tax_percent=parseFloat(e.target.value)
        setNewRow([...newRow])
        accCalculation(newRow,'tax_percent',index);
    }
    
    const handleWhtPercent=(index,e)=>{
        newRow[index].wht_percent=parseFloat(e.target.value)
        setNewRow([...newRow])
        accCalculation(newRow,'wht_percent',index);
    }
  
    const handleRowRemarks=(index,e)=>{
      newRow[index].remarks = e.target.value;
      setNewRow([...newRow]);
    }
  
    console.log("check newRow==>",newRow);
    const totalAmount=(newRow)=>{
      let total = 0;
      if (newRow !== null && newRow.length > 0) {
        newRow.map((item) => {
          // console.log("check total amount==>",(isNaN(item.amount) || item.amount=="" ? 0 : parseInt(item.amount)));
          // if(item.amount){
            total = total + (isNaN(item.amount) || item.amount=="" ? 0 : parseInt(item.amount));
          // }
        });
      }
      setTotalRowAmount(total);      
      // return total;
    }

    const totalTaxAmount=(newRow)=>{
        let total = 0;
        if (newRow !== null && newRow.length > 0) {
          newRow.map((item) => {
            // if(item.tax_amount){
              total = total + (isNaN(item.tax_amount) || item.tax_amount=="" ? 0 : parseFloat(item.tax_amount));
            // }
          });
        }
        setTotalRowTaxAmount(total)
        // return total;
    }

    const totalWhtAmount=(newRow)=>{
        let total = 0;
        if (newRow !== null && newRow.length > 0) {
          newRow.map((item) => {
            // if(item.wht_amount){
              total = total + (isNaN(item.wht_amount) || item.wht_amount=="" ? 0 : parseFloat(item.wht_amount));
            // }
          });
        }
        setTotalRowWhtAmount(total)
        // return total;
    }
    const totalNetAmount=(newRow)=>{
        let total = 0;
        if (newRow !== null && newRow.length > 0) {
          newRow.map((item) => {
            // if(item.net_amount){
              total = total + (isNaN(item.net_amount) || item.net_amount=="" ? 0 : parseFloat(item.net_amount));
            // }
          });
        }
        setTotalRowNetAmount(total);
        // return total;
    }

    return (
      <>
      <Helmet>
        <title>{_t(t("Edit Cash Payment"))}</title>
      </Helmet>
  
      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                <AccountsSidebar />
            </div>
            {/* left Sidebar ends */}
  
            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}
  
                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-11 col-lg-11 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Edit Cash Payment"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-1 col-lg-1">
                           <button
                            className="btn btn-secondary btn-sm ml-2 rounded-sm"
                            onClick={()=>handlePrint()}
                            >
                             <i className="fa fa-print" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>
                           </button>
                        </div>
                        </div>
  
                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 table-text"
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <div className="col-12">
                            <div className="row">                            
                              <div className="row">
                              <div className="form-group col-4 mt-2">
                                <div className="mb-2">
                                  <label
                                    htmlFor="invoice"
                                    className="control-label"
                                  >
                                    {_t(t("Document #"))}
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <input
                                    disabled={true}
                                    type="text"
                                    className="form-control table-text"
                                    id="invoice"
                                    name="invoice"
                                    // onChange={handleChange}
                                    value={cashPayment.doc_no}
                                    placeholder="Auto No"
                                  />
                                </div>
                              </div>
  
                              <div className="form-group col-4 text-right mt-2">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="purchaseDate"
                                    className="control-label"
                                  >
                                    {_t(t("Document Date"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div className="mb-2">
                                  <DatePicker
                                    selected={cashPayment.doc_date}
                                    onChange={(date) => handleDocDate(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("Document date"))}
                                    shouldCloseOnSelect={false}
                                    required
                                  />
                                </div>
                              </div>
  
                              <div className="form-group mt-2 col-4">
                                <div className="mb-2 text-left">
                                  <label
                                    htmlFor="transaction"
                                    className="control-label"
                                  >
                                    {_t(t("Transaction Account"))}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <Select
                                  options={cashAccounts ? cashAccounts : []}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.level3_name}
                                  getOptionValue={(option) => option.id}
                                  value={cashPayment.transactionAccount || ""}
                                  classNamePrefix="select table-text"
                                  onChange={(acc)=>handleTransactionAcc(acc)}
                                  //maxMenuHeight="12.8rem"
                                  placeholder={
                                    _t(t("Please select an account")) + ".."
                                  }
                                  styles={customStyle}
                                  required
                                 
                                />
                                </div>
                              </div>                          
                          </div>
                           <div className="row">
                           
                            {supplierForSearch && (
                              <div className="form-group mt-2 col-4">
                                <div className="mb-2">
                                  <label
                                    htmlFor="item"
                                    className="control-label"
                                  >
                                    {_t(t("Supplier name"))}                                    
                                  </label>
                                </div>
                                <Select
                                  options={supplierForSearch ? supplierForSearch : []}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  value={cashPayment.supplierName}
                                  classNamePrefix="select table-text"
                                  onChange={(e)=>handleSetSupplier(e)}
                                  //maxMenuHeight="50px"
                                  placeholder={
                                    _t(t("Please select a supplier")) + ".."
                                  }
                                  styles={customStyle}                                  
                                />
                              </div>
                            )}
  
                            <div className="form-group mt-2 col-8">
                            <div className="mb-2">
                              <label
                                htmlFor="description"
                                className="control-label"
                              >
                                {_t(t("Remarks"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control table-text"
                                id="description"
                                name="description"
                                onChange={(e)=>handleRemarks(e.target.value)}
                                value={cashPayment.remarks}
                                placeholder="Short description"
                              />
                            </div>
                           </div>
                           
                          </div> 
                            {/* {newItem.items !== null && [
                              newItem.items.length > 0 && ( */}
                                <div className={`table-responsive pt-3 mt-2 pr-1 ${overflowStyle ? "add-bank-table" : "overflow-visible"}`
                                } style={{maxHeight:"23rem",overflow: "auto"}}>                              
                                  <table className="table table-bordered-sm table-hover">
                                    <thead className="align-middle">
                                    <tr>
                                      {newRow.length==0 ?<th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                          <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>
                                      </th>:<th style={{borderBottomColor:"lightgray"}}></th>}
                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border table-Child-bank"
                                        style={{minWidth:"120px"}}
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Account name"))}
                                        </span>
                                      </th>                                                                      

                                      <th
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:500}}>
                                          {_t(t("Amount"))}
                                        </span>
                                      </th>

                                      <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                     >
                                      <span style={{fontWeight:500}}>
                                        {_t(t("Tax Percent"))}
                                      </span>
                                     </th>

                                      <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      <span style={{fontWeight:500}}>
                                        {_t(t("Tax amount"))}
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      <span style={{fontWeight:500}}>
                                        {_t(t("WHT Percent"))}
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      <span style={{fontWeight:500}}>
                                        {_t(t("WHT Amount"))}
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      <span style={{fontWeight:500}}>
                                        {_t(t("Net Amount"))}
                                      </span>
                                    </th>
                                      
                                    <th
                                      scope="col"
                                      className="table-text text-capitalize align-middle text-center border-1 border"
                                    >
                                      <span style={{fontWeight:500}}>
                                        {_t(t("Remarks"))}
                                      </span>
                                    </th>
                                    {newRow.length>0 && <th style={{borderBottomColor:"lightgray",minWidth:"60px"}}></th>}                                        
                                    </tr>
                                    </thead>
  
                                    <tbody className="align-middle">
                                      {/* {newItem.items.reverse().map(
                                        (ingredientItem, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <td className="table-text text-capitalize align-middle text-center table-firstChild">
                                                {ingredientItem.name}
                                              </td>
  
                                              <td className="table-text text-capitalize align-middle text-center" style={{width:"15%"}}>
                                              <input
                                                type="number"
                                                step="0.1"
                                                min="0"
                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                className="form-control text-center table-Input table-text"
                                                id={ingredientItem.id}
                                                name={ingredientItem.id}
                                                onChange={handleItemQty}
                                                value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                                placeholder="Qty"
                                                required
                                              />
                                            </td>
  
                                              <td className="table-text text-capitalize align-middle text-center">
                                                {ingredientItem.unit}
                                              </td>
                                              <td className="table-text text-capitalize align-middle text-center" style={{width:"15%"}}>
                                                <input
                                                  type="number"
                                                  step="0.1"
                                                  min="0"
                                                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                  className="form-control text-center table-Input table-text"
                                                  id={ingredientItem.id}
                                                  name={ingredientItem.id}
                                                  onChange={handleItemRate}
                                                  value={rateOfItem && rateOfItem[ingredientItem?.id] ? rateOfItem[ingredientItem?.id] : ""}
                                                  placeholder="Price"
                                                  required
                                                 
                                                />
                                              </td>
  
                                              <td className="table-text align-middle text-center">
                                                {formatPrice(
                                                  handleGetSubTotal(
                                                    ingredientItem.id
                                                  )
                                                )}
                                               </td>
  
                                              <td className="table-text text-capitalize align-middle text-center" style={{width:"10%"}}>
                                                  <MdDelete className="text-danger" style={{cursor: "pointer", fontSize: "1.3rem"}} onClick={()=>handleDltHandler(ingredientItem.id)}/>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )} */}
                                        {newRow.length > 0 ? newRow.map((v,i)=>{
                                          return <tr
                                          className="align-middle"
                                          key={i}
                                        >
                                          <td className="table-text text-capitalize align-middle text-center table-firstChild" style={{minWidth:"60px"}}>
                                          {i==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                              <i className="fa fa-plus-circle btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                          </span>}&nbsp;
                                          <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(v.id)}}></i>
                                          </td>
                                          <td className="table-text text-capitalize align-middle" style={{minWidth:"250px"}}>
                                            <Select
                                             
                                             menuPlacement={newRow.length>3 && i>2 ? "top" : "bottom"}
                                              options={coa3ForSearch && coa3ForSearch}
                                              components={makeAnimated()}
                                              getOptionLabel={(option) => option.level3_name}
                                              getOptionValue={(option) => option.id}
                                              value={v.account_name|| ""}
                                              // classNamePrefix="select table-text"
                                              onChange={(e)=>handleAccountName(i,e)}
                                              maxMenuHeight="12.8rem"
                                              placeholder={
                                                _t(t("Account Name")) + ""
                                              }
                                              isMenuOpen={true}
                                              styles={customStyle}
                                              className="bank-table-input"
                                              required
                                              onFocus={()=>{
                                                if(newRow && newRow?.length > 0 && newRow?.length < 5){
                                                  setOverflowStyle(true)
                                                }
                                              }}
                                              onBlur={()=>setOverflowStyle(false)}
                                              onMenuOpen={()=>{
                                                if(newRow && newRow?.length > 0 && newRow?.length < 5){
                                                  setOverflowStyle(true)
                                                }
                                              }}
                                              onMenuClose={()=>setOverflowStyle(false)}
                                            />
                                          </td>

                                          <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                          <input
                                            type="number"
                                            // step="0.1"
                                            min="0"
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                              evt.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            className="form-control text-center table-Input table-text"
                                          //   id={ingredientItem.id} 
                                          //   name={ingredientItem.id}
                                            onChange={(e)=>handleAmt(i,e)}
                                          //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                            placeholder="0"
                                            required
                                            value={v.amount}
                                          />
                                          </td>

                                          <td className="table-text text-capitalize align-middle text-center">
                                          <input
                                            type="number"
                                            // step="0.1"
                                            min="0"
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                              evt.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            className="form-control text-center table-Input table-text"
                                          //   id={ingredientItem.id} 
                                          //   name={ingredientItem.id}
                                            onChange={(e)=>handleTaxPercent(i,e)}
                                          //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                            placeholder="0"
                                            value={v.tax_percent || ""}
                                          />
                                          </td>
                                          <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                          <input
                                          readOnly
                                            type="number"
                                            // step="0.1"
                                            min="0"
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                              evt.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            className="form-control text-center table-Input table-text"
                                          //   id={ingredientItem.id} 
                                          //   name={ingredientItem.id}
                                          //   onChange={handleItemQty}
                                          //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                            placeholder="0"
                                            value={v.tax_amount}
                                          />
                                          </td>
                                          <td className="table-text text-capitalize align-middle text-center">
                                          <input
                                            type="number"
                                            // step="0.1"
                                            min="0"
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                              evt.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            className="form-control text-center table-Input table-text"
                                          //   id={ingredientItem.id} 
                                          //   name={ingredientItem.id}
                                            onChange={(e)=>handleWhtPercent(i,e)}
                                          //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                            placeholder="0"                                              
                                            value={v.wht_percent || ""}
                                          />
                                          </td>
                                          <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                          <input
                                            type="number"
                                            readOnly
                                            // step="0.1"
                                            min="0"
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                              evt.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            className="form-control text-center table-Input table-text"
                                          //   id={ingredientItem.id} 
                                          //   name={ingredientItem.id}
                                          //   onChange={handleItemQty}
                                          //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}                                              
                                            value={v.wht_amount}
                                            placeholder="0" 
                                            required
                                          />
                                          </td>
                                          <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                          <input
                                            type="number"
                                            readOnly
                                            // step="0.1"
                                            min="0"
                                            onKeyDown={(evt) =>
                                              ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) &&
                                              evt.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            className="form-control text-center table-Input table-text"
                                          //   id={ingredientItem.id} 
                                          //   name={ingredientItem.id}
                                          //   onChange={handleItemQty}
                                          //   value={qtyOfItem && qtyOfItem[ingredientItem?.id] ? qtyOfItem[ingredientItem?.id] : ""}
                                            placeholder="0"                                              
                                            value={v.net_amount}
                                          />
                                          </td>

                                          <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"140px"}}>
                                          <input
                                            type="text"                                              
                                            // step="0.1"
                                            // min="0"
                                            //onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            className="form-control text-center table-Input table-text"
                                          //   id={ingredientItem.id} 
                                          //   name={ingredientItem.id}
                                            onChange={(e)=>handleRowRemarks(i,e)}                                              
                                            placeholder="remarks"                                              
                                            value={v.remarks}
                                          />
                                          </td>
                                          <td className="table-text text-capitalize align-middle text-center" style={{minWidth:"60px"}}>
                                              <i className="fa fa-minus-circle cursor-pointer text-danger" style={{fontSize:"22px"}} onClick={()=>{delRow(v.id)}}></i>&nbsp;
                                              {i==(newRow.length-1) && <span className="t-mr-10" onClick={()=>addNewRow()}>
                                                  <i className="fa fa-plus-circle cursor-pointer btn-add-icon" style={{fontSize:"22px"}} aria-hidden="true"></i>
                                              </span>}
                                             
                                          </td>
                                    </tr>
                                      }):null}
                                    </tbody>
                                    {newRow.length > 0 &&
                                  <tfoot className="align-middle">
                                    <tr>
                                      <th
                                      // style={{minWidth:"100px"}}
                                        scope="col"
                                        colSpan="1"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th>                                        

                                      <th
                                      // style={{minWidth:"100px"}}
                                        scope="col"
                                        colSpan="1"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:"bold"}}>
                                        {_t(t("Total Amount"))}
                                        </span>
                                      </th>
                                      <th
                                        style={{minWidth:"100px"}}
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border font-weight-bold"
                                      >
                                        <span style={{fontWeight:"bold"}}>
                                          {formatPrice(totalRowAmount)}
                                          {/* {totalRowAmount} */}
                                        </span>
                                      </th>
                                      <th
                                        style={{minWidth:"100px"}}
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {/* {formatPrice(totalRowTaxAmount)} */}
                                      </th>
                                      <th
                                        style={{minWidth:"100px"}}
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:"bold"}}>
                                          {formatPrice(totalRowTaxAmount)}
                                        </span>
                                      </th>
                                      <th
                                        style={{minWidth:"100px"}}
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {/* {formatPrice(totalWhtPercent())}                                       */}
                                      </th>
                                      <th
                                        style={{minWidth:"100px"}}
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:"bold"}}>
                                          {formatPrice(totalRowWhtAmount)}
                                        </span>
                                      </th>
                                      <th
                                        style={{minWidth:"100px"}}
                                        scope="col"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        <span style={{fontWeight:"bold"}}>
                                          {formatPrice(totalRowNetAmount)}
                                        </span>
                                      </th>
                                      <th
                                      // style={{minWidth:"100px"}}
                                        scope="col"
                                        // colSpan="3"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th> 
                                      <th
                                      // style={{minWidth:"100px"}}
                                        scope="col"
                                        // colSpan="3"
                                        className="table-text text-capitalize align-middle text-center border-1 border"
                                      ></th> 
                                    </tr>
                                  </tfoot>}
                                  </table>                                  
                                </div>
                              {/* ),
                            ]} */}
  
                            <div className="form-group mt-4 pb-2">
                              <div className="col-lg-12">
                                <button
                                  className="btn btn-primary px-5 rounded"
                                  type="submit"
                                >
                                  {_t(t("Save"))}
                                </button>
                                <button
                                  className="btn btn-primary px-5 mx-2 rounded"
                                  onClick={()=>(history.push("/dashboard/manage/transaction/cash-payment"))}
                                >
                                  {_t(t("Back"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
        {/* main body ends */}
        <AccountPrint
        name = "Bank Payment"
        bankPayment={bankPaymentPrint}
        totalRowAmount={totalRowAmountPrint}
        totalRowWhtAmount={totalRowWhtAmountPrint}
        totalRowTaxAmount={totalRowTaxAmountPrint}
        totalRowNetAmount={totalRowNetAmountPrint}
        newRow={newRowPrint}
        AccountPrint={componentRef}
      />
    </>
    )
}

export default EditCashPayment;