import React, { useEffect, useState } from 'react';
import { BASE_URL } from "../../../../../../BaseUrl";


const ProductionCardWidget = ({ data }) => {
  const [ cards, setCards ] = useState([]);

  useEffect(() => {
    data && setCards(data);
  }, [data]);
  
  return (
    <div className="col col-12 row mx-0 mt-3 px-1">
     <div className='row mx-0 px-0 flex-wrap dashboard__card-container'>
      {cards.map((card,index) => (
        <div key={index} className='col-3 p-0' >
         <div className='col p-0 flex-nowrap dashboard__card_view p-3 border mx-2'>
          <div className='col m-0 p-0'>
            <div className='d-flex align-items-center justify-content-center'>
              <div className='text-capitalize lg-text mb-0 font-weight-bold'>{card.name}</div>
            </div>
            <div className='d-flex align-items-center justify-content-center my-3'>
              <div className='d-block'>
               <p className='font-weight-bold xxxslg-text mb-0 text-primary'>{card.value?.toLocaleString()}</p>
              </div>
            </div>
           </div>
          </div>
         </div>
      ))}
     </div>
    </div>
  )
}

export default ProductionCardWidget